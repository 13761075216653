import * as ReactGA from "react-ga";
import { IRUser } from "@app/api/users/helper-schemas";

interface IEvent {
	category: string;
	action: string;
	label: string;
	value?: number;
}
// tslint:disable:no-console

export default function triggerEvent(
	data: IEvent,
	extraInfo?: { [key: string]: any }
) {
	console.log(data, extraInfo);
	try {
		ReactGA.event(data);
		let eventname = data.action;
		if (data.label) eventname += " - " + data.label;
		(window as any).amplitude.getInstance().logEvent(eventname, extraInfo);
	} catch (e) {
		console.error(e);
	}
}

export function setUserForAnalytics(user: IRUser) {
	try {
		// FIXME: uncomment this when you will need amplitude
		// (window as any).amplitude.setUserId(user.id);
		// (window as any).amplitude.getInstance().setUserProperties({
		// 	id: user.id,
		// });
	} catch (e) {
		console.error(e);
	}
}

export function cleawUserForAnalytics() {
	try {
		// (window as any).amplitude.regenerateDeviceId();
		(window as any).amplitude.setUserId(null);
	} catch (e) {
		console.error(e);
	}
}
