import createCRUDReducer from "./crud";
import { IReduxStateInstance } from "./root-state";
import { IRProject } from "@app/api/projects/helper-schemas";
import { ProjectActions, projectCRUDActionTypes } from "@app/actions/projects";

function getNewProjectState(
	state: IStateProjects = {},
	action: ProjectActions
): IStateProjects {
	switch (action.type) {
		default:
			return state;
	}
}

const crudReducer = createCRUDReducer<IRProject>(projectCRUDActionTypes);

export default function projects(
	state = {},
	action: ProjectActions
): IStateProjects {
	const state1 = crudReducer(state, action);
	const newState = getNewProjectState(state1, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("projects", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export type IStateProjects = IReduxStateInstance<IRProject>;
