import api from "@app/api";
import createCRUDActions from "./crud";
import {
	DELETE_COLUMN,
	LOAD_COLUMN_BY_ID,
	LOAD_COLUMNS,
	UPDATE_COLUMN,
} from "@app/action-types";
import { IRColumn } from "@app/api/columns/helper-schemas";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { ThunkAction } from "redux-thunk";
import {
	IAGETColumn,
	IAPOSTCreateColumn,
	IAPUTColumn,
	IRGETManyColumns,
	IRGETColumn,
	IRPOSTCreateColumn,
	IADELETEColumn,
	IAGETManyColumns,
} from "@app/api/columns/validators";

export const addColumn = (
	args: IAPOSTCreateColumn
): ThunkAction<
	Promise<IRPOSTCreateColumn>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.columns.add(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const updateColumn = (
	args: IAPUTColumn
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.columns.update(args).then(() => {
		dispatch(crudActions.update(args));
	});
};

export const loadColumns = (
	args: IAGETManyColumns
): ThunkAction<
	Promise<IRGETManyColumns>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.columns.getReadableColumns(args).then(data => {
		dispatch(crudActions.loadMany(data));
		return data;
	});
};

export const loadColumnById = (
	args: IAGETColumn
): ThunkAction<
	Promise<IRGETColumn>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.columns.getById(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const deleteColumn = (
	args: IADELETEColumn
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.columns.deleteById(args).then(() => {
		dispatch(crudActions.delete(args.columnId));
	});
};

export type ColumnActions =
	| ReturnType<typeof crudActions["delete"]>
	| ReturnType<typeof crudActions["loadMany"]>
	| ReturnType<typeof crudActions["loadOne"]>
	| ReturnType<typeof crudActions["update"]>;

export const columnCRUDActionTypes = {
	update: UPDATE_COLUMN as typeof UPDATE_COLUMN,
	loadOne: LOAD_COLUMN_BY_ID as typeof LOAD_COLUMN_BY_ID,
	loadMany: LOAD_COLUMNS as typeof LOAD_COLUMNS,
	delete: DELETE_COLUMN as typeof DELETE_COLUMN,
};

const crudActions = createCRUDActions<IRColumn, typeof columnCRUDActionTypes>(
	columnCRUDActionTypes
);
export { crudActions as columnCRUDActions };
