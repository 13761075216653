import Joi from "@app/utils/joi";
import {
	APICallRequestDataSchema,
	ApiCallType,
	RAPICallSchema,
	RequestPropertiesDataSchema,
	IRAPICall,
	IAPICallRequestData,
	IRequestPropertiesData,
} from "./helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";
import {
	IResIdOneOrMany,
	IResIds,
	ResIdOneOrManySchema,
	ResIdsSchema,
} from "../common-schemas";

export const APOSTCreateAPICallSchema = RAPICallSchema.keys(insertStripKeys);

export type IAPOSTCreateAPICall = ToInsertKeys<IRAPICall>;

export const RPOSTCreateAPICallSchema = RAPICallSchema;
export type IRPOSTCreateAPICall = IRAPICall;

///

export const AGETAPICallSchema = Joi.object({
	projectId: Joi.objectId().required(),
	APICallId: Joi.objectId().required(),
});
export interface IAGETAPICall {
	projectId: ObjectId;
	APICallId: ObjectId;
}

export const RGETAPICallSchema = RAPICallSchema;
export type IRGETAPICall = IRAPICall;

///

export const APUTAPICallSchema = RAPICallSchema.keys(updateStripKeys);
export type IAPUTAPICall = ToUpdateKeys<IRAPICall>;

///

export const ADELETEAPICallSchema = AGETAPICallSchema;
export type IADELETEAPICall = ToUpdateKeys<IAGETAPICall>;

///

export const AGETManyAPICallsSchema = Joi.object({
	projectId: Joi.objectId().required(),
	apiType: Joi.string().valid(
		ApiCallType.action,
		ApiCallType.columnInfo,
		ApiCallType.refreshResourceIds
	),
});
export interface IAGETManyAPICalls {
	projectId: ObjectId;
	apiType?: ApiCallType;
}

export const RGETManyAPICallsSchema = Joi.array().items(RAPICallSchema);
export type IRGETManyAPICalls = IRAPICall[];

///

export const APOSTFilledAPICallSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	resIds: ResIdOneOrManySchema,
});
export interface IAPOSTFilledAPICall {
	_id: ObjectId;
	projectId: ObjectId;
	resIds?: IResIdOneOrMany;
}

export const RPOSTFilledAPICall = Joi.object({
	requestData: APICallRequestDataSchema.required(),
	requestType: RequestPropertiesDataSchema.required(),
	apiInfo: Joi.array().items(RAPICallSchema),
});
export interface IRPOSTFilledAPICall {
	requestData: IAPICallRequestData;
	requestType: IRequestPropertiesData;
	apiInfo?: IRAPICall[];
}

export const APOSTCallAPISchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	requestData: APICallRequestDataSchema.required(),
	columnId: Joi.objectId(),
	resIds: ResIdOneOrManySchema,
});
export interface IAPOSTCallAPI {
	_id: ObjectId;
	projectId: ObjectId;
	requestData: IAPICallRequestData;
	columnId?: ObjectId;
	resIds?: IResIdOneOrMany;
}

///

const AResGroupRefreshBodySchema = Joi.object({
	projectId: Joi.objectId().required(),
	resouceGroups: Joi.array()
		.items(
			Joi.object({
				_id: Joi.objectId().required(),
				name: Joi.string(),
				description: Joi.string().allow("", null),
				resourceIds: ResIdsSchema,
			})
		)
		.required(),
});
export interface IAResGroupRefreshBody {
	projectId: ObjectId;
	resouceGroups: {
		_id: ObjectId;
		name?: string;
		description?: string | null;
		resourceIds: IResIds;
	}[];
}

export const RPOSTGetRefreshedResourceGroupsSchema = Joi.array().items(
	Joi.object({
		resourceGroupId: Joi.objectId().required(),
		resourceIds: ResIdsSchema.required(),
		action: Joi.string()
			.valid("add", "remove", "set")
			.required(),
	})
);
export type IRPOSTGetRefreshedResourceGroups = {
	resourceGroupId: ObjectId;
	resourceIds: IResIds;
	action: "add" | "remove" | "set";
}[];
