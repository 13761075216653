import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const RTableSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string().allow(""),
	columnOrder: Joi.array()
		.items(
			Joi.object({
				id: Joi.objectId().required(),
				customName: Joi.string(),
			})
		)
		.required(),
	statsTableOrder: Joi.array().items(
		Joi.object({
			id: Joi.objectId().required(),
			customName: Joi.string(),
		})
	),
	primaryColor: Joi.string(),
	author: Joi.number().required(),
});

export interface IRTable {
	_id: ObjectId;
	projectId: ObjectId;
	name: string;
	author: number;
	description?: string;
	columnOrder: {
		id: ObjectId;
		customName?: string;
	}[];
	statsTableOrder?: {
		id: ObjectId;
		customName?: string;
	}[];
	primaryColor?: string;
}
