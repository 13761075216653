import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useContext,
} from "react";
import { ObjectId } from "@app/utils/generics";
import {
	IAPUTStatsTable,
	IAPOSTCreateStatsTable,
} from "@app/api/stats-tables/validators";
import { inject } from "@app/modules";
import {
	StatsTableGroupBy,
	IRStatsTableColumn,
	StatsTableColumnType,
	IRStatsTableFilterColumn,
	IRStatsTableColumnCommon,
	StatsColTransformationValue,
} from "@app/api/stats-tables/helper-schemas";
import {
	useSetProps,
	SetState,
	SetProp,
	HelperFn,
} from "@app/hooks/state-management";
import { DynamicContext } from "react-flexible-contexts";
import Loading from "../widgets/loading";
import { InputWithLabel, CheckboxWithLabel } from "../widgets/input";
import { RadioNavigation } from "../widgets/radio-navigation";
import { useSelectProps } from "@app/hooks/values";
import { wrapInLoading } from "@app/utils/promise";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { deepEqual } from "react-dependency-hooks/lib/equality";
import { SelectModelData } from "../widgets/select";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import SortIcon from "@material-ui/icons/OpenWith";
import CloseIcon from "@material-ui/icons/Close";
import { css } from "emotion";
import { useFuncDistributor, useBoolean } from "@app/hooks/general";
import { reorderArray } from "@app/utils";
import { generateObjectId, removeKeys, pickKeys } from "@app/utils/common";
import { useColumns } from "@app/hooks/columns";
import { IRColumn } from "@app/api/columns/helper-schemas";
import {
	IFilterOptionWithoutDollar,
	IFilterOption,
} from "@app/api/resource-data/helper-schemas";
import Popup, { PopupContentWithClose } from "../widgets/popup";
import { FilterOptionsComponent } from "../tables/filter";
import { withoutDollarToWith, withDollarToWithout } from "@app/utils/filter";
import { TransformationChooser } from "./transformation";
import {
	TranformationType,
	TransformationValue,
} from "@app/api/transformation-schemas";
import { BasicInputDatum } from "../tables/data/edit";

type SortEndArg = {
	oldIndex: number;
	newIndex: number;
};

const SaveStatsCont = DynamicContext.createDestructured<{
	stats: Stats;
	setProps: SetProp<
		Stats,
		{
			onSortEnd: HelperFn<Stats, [SortEndArg]>;
			addStatCol: HelperFn<Stats, []>;
		}
	>;
	columns: IRColumn[];
}>();
const SaveStatsValueCont = SaveStatsCont.addInternalContext(x => x.stats);

type Stats = IAPUTStatsTable | IAPOSTCreateStatsTable;

export const SaveStats: React.FC<{
	id?: ObjectId;
	projectId: ObjectId;
}> = React.memo(({ id, projectId, ...rest }) => {
	const history = useHistory();
	const [stats, setStats] = useState<Stats>();
	const statsRef = useRef(stats);
	statsRef.current = stats;
	const columns = useColumns();

	useEffect(() => {
		if (id) {
			inject("StatsTable")
				.findById({ id, projectId })
				.then(setStats);
		} else {
			setStats(getDefaultStats(projectId));
		}
	}, [id, projectId]);
	const setProps = useSetProps(setStats as SetState<Stats>, {
		onSortEnd: (state, { oldIndex, newIndex }: SortEndArg) => ({
			...state,
			statColumns: reorderArray(state.statColumns, oldIndex, newIndex),
		}),
		addStatCol: (state): Stats => {
			return {
				...state,
				statColumns: [
					...state.statColumns,
					{
						type: StatsTableColumnType.filter,
						_id: generateObjectId(),
						name: "",
						filterOptions: {},
					},
				],
			};
		},
	});

	const onSave = useCallback(() => {
		const stats = statsRef.current;
		if (!stats) return;
		const gotoList = () => {
			history.push(`/projects/${projectId}/stats/`);
		};
		if (!(stats as IAPUTStatsTable)._id) {
			wrapInLoading(
				inject("StatsTable")
					.create(stats)
					.then(gotoList)
			);
		} else {
			wrapInLoading(
				inject("StatsTable")
					.update(stats as IAPUTStatsTable)
					.then(gotoList)
			);
		}
	}, [history, projectId]);

	if (!stats || !columns.doc) return <Loading />;

	return (
		<div className="main">
			<SaveStatsCont.Provider
				setProps={setProps}
				stats={stats}
				columns={columns.doc}
			>
				<StatsMainSettings />
				<StatColumns distance={3} onSortEnd={setProps("onSortEnd")} />
				<SortOptions />
				<GlobalFilters />
				<br />
				<Button variant="contained" color="primary" onClick={onSave}>
					შენახვა
				</Button>
			</SaveStatsCont.Provider>
		</div>
	);
});

export const StatsMainSettings: React.FC<{}> = React.memo(() => {
	const setProps = SaveStatsCont.useProperty("setProps");
	const { name, description, groupBy } = SaveStatsValueCont.useProperties(
		"name",
		"description",
		"groupBy"
	);

	const groupByProps = useSelectProps(groupBy, setProps("groupBy"), [
		[StatsTableGroupBy.admins, "ადმინების"],
		[StatsTableGroupBy.resourceGroups, "მომხმარებელთა ჯგუფის"],
	]);

	return (
		<div>
			<InputWithLabel
				value={name}
				onChange={setProps("name")}
				label="სახელი"
			/>
			<br />
			<InputWithLabel
				value={description}
				onChange={setProps("description")}
				label="განმარტება"
			/>
			<RadioNavigation
				{...groupByProps}
				prefix="დაჯგუფდეს "
				suffix="მიხედვით"
				direction="left"
			/>
		</div>
	);
});

const selectStatCols = (x: Stats) =>
	x.statColumns.map(e => ({ _id: e._id, name: e.name }));

export const SortOptions: React.FC<{}> = React.memo(() => {
	const columns = SaveStatsValueCont.useSelector(
		selectStatCols,
		deepEqual,
		[]
	);
	const setSortOptions = SaveStatsCont.useProperty("setProps")("sortOptions");
	const sortOptions = SaveStatsValueCont.useProperty("sortOptions");
	const onSortExistenceChange = useCallback(
		(checked: boolean) => {
			if (!checked) {
				setSortOptions(undefined);
			} else {
				setSortOptions({
					colId: "",
					type: "DESC",
				});
			}
		},
		[setSortOptions]
	);
	const setSortOptionsProps = useSetProps(setSortOptions);

	const typeProps = useSelectProps(
		sortOptions?.type,
		setSortOptionsProps("type"),
		[
			["ASC", "ზრდადობით"],
			["DESC", "კლებადობით"],
		]
	);

	return (
		<div>
			<CheckboxWithLabel
				onChange={onSortExistenceChange}
				value={!!sortOptions}
				label="დასორტირდეს"
			/>
			{sortOptions && (
				<React.Fragment>
					<RadioNavigation
						{...typeProps}
						prefix=""
						direction="left"
					/>
					<SelectModelData
						options={columns}
						value={sortOptions.colId}
						onChange={setSortOptionsProps("colId")}
						placeholder="აირჩიეთ სვეტი"
					/>
				</React.Fragment>
			)}
		</div>
	);
});

export const GlobalFilters: React.FC<{}> = React.memo(({}) => {
	const setGlobalFilter = SaveStatsCont.useProperty("setProps")(
		"globalFilter"
	);
	const globalFilter = SaveStatsValueCont.useProperty("globalFilter");
	return (
		<div
			style={{
				margin: "20px 0",
				border: "1px solid #ccc",
				padding: 20,
				borderRadius: 10,
			}}
		>
			გლობალური ფილტრი
			<Filters value={globalFilter || {}} onChange={setGlobalFilter} />
		</div>
	);
});

export const StatColumns = SortableContainer(
	React.memo(() => {
		const setProps = SaveStatsCont.useProperty("setProps");
		const setStatColumns = setProps("statColumns");
		const setStatColumnsProps = useSetProps(setStatColumns);
		const statColumns = SaveStatsValueCont.useProperty("statColumns");
		const onDelete = useCallback(
			(index: number) => {
				setStatColumns(val => val.filter((x, i) => i !== index));
			},
			[setStatColumns]
		);
		const onDeletes = useFuncDistributor(onDelete, statColumns.length);
		return (
			<div>
				{statColumns.map((col, index) => {
					return (
						<SingleStatColumn
							key={`item${col._id}`}
							index={index}
							statCol={col}
							onDelete={onDeletes[index]}
							onChange={setStatColumnsProps(index)}
						/>
					);
				})}
				<Button
					variant="contained"
					color="primary"
					onClick={setProps("addStatCol")}
				>
					სვეტის დამატება
				</Button>
			</div>
		);
	})
);

const handleTypeChange = (
	statCol: IRStatsTableColumn,
	type: StatsTableColumnType
): IRStatsTableColumn => {
	if (statCol.type === type) return statCol;
	const commonProps: IRStatsTableColumnCommon = pickKeys(
		statCol,
		"_id",
		"isHidden",
		"name"
	);
	if (type === StatsTableColumnType.filter) {
		return { ...commonProps, type, filterOptions: {} };
	}
	if (type === StatsTableColumnType.transformation) {
		return {
			...commonProps,
			type,
			transformation:
				1 > 2
					? {
							type: TranformationType.value,
							val: getDefaultTransformationValue(),
					  }
					: {
							type: TranformationType.sum,
							addenda: [
								{
									type: TranformationType.value,
									val: getDefaultTransformationValue(),
								},
								{
									type: TranformationType.value,
									val: getDefaultTransformationValue(),
								},
							],
					  },
		};
	}
	return statCol;
};

const StatColIdContext = React.createContext<ObjectId>("");

const SingleStatColumn = SortableElement(
	({
		statCol,
		onDelete,
		onChange,
	}: {
		statCol: IRStatsTableColumn;
		onDelete: () => void;
		onChange: SetState<IRStatsTableColumn>;
	}) => {
		const setValueProps = useSetProps(onChange, {
			type: handleTypeChange,
		});
		const selectProps = useSelectProps(
			statCol.type,
			setValueProps("type"),
			[
				[StatsTableColumnType.filter, "გაფილტრვა"],
				[StatsTableColumnType.transformation, "ტრანსფორმაცია"],
			]
		);
		return (
			<StatColIdContext.Provider value={statCol._id}>
				<div className={TableColumnCSS}>
					<SortIcon />
					<div>
						<RadioNavigation {...selectProps} direction="left" />
						<InputWithLabel
							value={statCol.name}
							onChange={setValueProps("name")}
							label="სახელი"
						/>
						<CheckboxWithLabel
							value={!!statCol.isHidden}
							onChange={setValueProps("isHidden")}
							label="დამალული იყოს"
						/>
						{statCol.type === StatsTableColumnType.filter && (
							<Filters
								value={statCol.filterOptions}
								onChange={setValueProps("filterOptions")}
							/>
						)}
						{statCol.type ===
							StatsTableColumnType.transformation && (
							<TransformationChooser
								transformation={statCol.transformation}
								onChange={setValueProps("transformation")}
								getDefaultRawValue={
									getDefaultTransformationValue
								}
								RawValueComponent={RawValueComponent}
							/>
						)}
					</div>
					<CloseIcon onClick={onDelete} />
				</div>
			</StatColIdContext.Provider>
		);
	}
);

const getDefaultTransformationValue = (): StatsColTransformationValue => {
	return { statColId: "" };
};

type FOptions = IRStatsTableFilterColumn["filterOptions"];
export const Filters: React.FC<{
	value: FOptions;
	onChange: SetState<FOptions>;
}> = React.memo(({ onChange, value }) => {
	const columns = SaveStatsCont.useProperty("columns");
	const filteredColumnIds = Object.keys(value);
	const setProps = useSetProps(onChange, {
		addColumn: (state: FOptions, colId: ObjectId): FOptions => ({
			...state,
			[colId]: {},
		}),
		deleteColumn: (state: FOptions, colId: ObjectId): FOptions =>
			removeKeys(state, colId),
	});
	return (
		<div>
			{filteredColumnIds.map(colId => {
				const filter = value[colId];
				if (!filter) return null;
				const column = columns.find(e => e._id === colId);
				if (!column) return null;
				return (
					<ColumnFilter
						key={colId}
						column={column}
						filter={filter}
						onChange={setProps(colId)}
						onDelete={setProps("deleteColumn")}
					/>
				);
			})}
			<AddColumn onAdd={setProps("addColumn")} />
		</div>
	);
});

export const AddColumn: React.FC<{
	onAdd: (colId: ObjectId) => void;
}> = React.memo(({ onAdd }) => {
	const columns = SaveStatsCont.useProperty("columns");
	return (
		<SelectModelData
			placeholder={"აირჩეთ გასაფილტრი სვეტი"}
			options={columns}
			onChange={onAdd}
			value={null}
		/>
	);
});

export const ColumnFilter: React.FC<{
	column: IRColumn;
	filter: IFilterOptionWithoutDollar;
	onChange: SetState<IFilterOptionWithoutDollar>;
	onDelete: (colId: ObjectId) => void;
}> = React.memo(({ column, filter, onChange, onDelete }) => {
	const {
		value: isPopupOpen,
		setTrue: openPopup,
		setFalse: closePopup,
	} = useBoolean();
	const onConfirm = (colId: string, filterOption: IFilterOption) => {
		onChange(withDollarToWithout(filterOption));
		closePopup();
	};
	return (
		<div>
			{column.name}
			<Button onClick={openPopup}>ფილტრი</Button>
			<Button onClick={() => onDelete(column._id)}>წაშლა</Button>
			{isPopupOpen && (
				<Popup>
					<PopupContentWithClose onClose={closePopup}>
						<FilterOptionsComponent
							key={column._id}
							column={column}
							currentOpton={withoutDollarToWith(filter)}
							showRemovingOtherFiltersOption={false}
							onConfirm={onConfirm}
						/>
					</PopupContentWithClose>
				</Popup>
			)}
		</div>
	);
});

export const RawValueComponent: React.FC<{
	transformation: TransformationValue<StatsColTransformationValue>;
	onChange: SetState<TransformationValue<StatsColTransformationValue>>;
}> = React.memo(({ onChange, transformation }) => {
	const currentStatColId = useContext(StatColIdContext);
	const val = transformation.val;
	const setFns = useSetProps(onChange);
	const onValChange = setFns("val");
	const valChangeFns = useSetProps(onValChange);
	const valueType = val.statColId !== undefined ? "statColId" : "rawNum";
	const selectProps = useSelectProps<"statColId" | "rawNum">(
		valueType,
		(type: "statColId" | "rawNum") => {
			if (type === "statColId") onValChange({ statColId: "" });
			else {
				onValChange({ rawNum: 0 });
			}
		},
		[
			["statColId", "სვეტი"],
			["rawNum", "მნიშვნელობა"],
		]
	);
	const statColumns = SaveStatsValueCont.useSelector(
		x => selectStatCols(x).filter(e => e._id !== currentStatColId),
		deepEqual,
		[currentStatColId]
	);

	return (
		<div>
			<RadioNavigation {...selectProps} />
			{valueType === "statColId" && (
				<SelectModelData
					placeholder={"აირჩეთ სვეტი"}
					options={statColumns}
					onChange={valChangeFns("statColId")}
					value={val.statColId!}
				/>
			)}
			{valueType === "rawNum" && (
				<BasicInputDatum
					type="number"
					onChange={x => valChangeFns("rawNum")(x as number)}
					big={false}
					defaultValue={val.rawNum!}
				/>
			)}
		</div>
	);
});

const TableColumnCSS = css`
	border-radius: 5px;
	border: 1px solid #ccc;
	margin: 5px 0;
	padding: 5px;
	display: flex;
	width: 100%;
	align-items: center;
	position: relative;
	background: white;
	& > *:nth-child(2) {
		padding: 5px;
		background: transparent;
		width: 100%;
		border: none;
		outline: none;
		text-align: left;
	}
`;

const getDefaultStats = (projectId: ObjectId): IAPOSTCreateStatsTable => {
	return {
		projectId,
		name: "",
		usedTableColumnIds: [],
		groupBy: StatsTableGroupBy.resourceGroups,
		statColumns: [],
		globalFilter: {},
	};
};
