import React from "react";
import {
	IApiCallResponse,
	ResponseAdditionalProps,
} from "@app/api/api-calls/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { ColumnType } from "@app/api/columns/helper-schemas";
import { ColumnInfoType } from "../columns/add";
import { useDynamicRef, useValueChange, useFuncCall } from "@app/hooks/general";
import { InputWithLabel } from "../widgets/input";

/* const getDefaultData = (): IApiCallResponse => {
	return {
		type: ColumnType.object,
		props: [],
	};
}; */

const defaultAdditionalProps: ResponseAdditionalProps = {};

export const ResponseSchemaEdit: React.FC<{
	info: IApiCallResponse;
	onChange: (info: IApiCallResponse) => void;
	projectId: ObjectId;
}> = ({ info, onChange, projectId }) => {
	return (
		<div>
			<ColumnInfoType
				info={info}
				allow="all"
				onChange={onChange}
				projectId={projectId}
				hasNestedProps={true}
				additionalPropsComponent={AdditionalPropsComponent}
				defaultAdditionalProps={defaultAdditionalProps}
			/>
		</div>
	);
};

const AdditionalPropsComponent: React.FC<{
	info: IApiCallResponse;
	onChange: (info: IApiCallResponse) => void;
	projectId: ObjectId;
}> = ({ info, onChange, projectId }) => {
	const infoRef = useDynamicRef(info);
	const handleValueChange = useValueChange(onChange, infoRef);
	const handleKeyOfIdChange = useFuncCall(
		handleValueChange,
		"keyOfId" as const
	);
	if (info.type !== ColumnType.object) {
		return null;
	}
	// TODO: add other keys too
	return (
		<div>
			<InputWithLabel
				label="keyOfId"
				value={info.keyOfId}
				onChange={handleKeyOfIdChange}
			/>
		</div>
	);
};
