import {
	IADELETEStatsTable,
	IAGETManyStatsTables,
	IAGETStatistics,
	IAGETStatsTable,
	IAPOSTCreateStatsTable,
	IAPUTStatsTable,
	IRGETManyStatsTables,
	IRGETStatistics,
	IRGETStatsTable,
	IRPOSTCreateStatsTable,
	RGETManyStatsTablesSchema,
	RGETStatisticsSchema,
	RGETStatsTableSchema,
	RPOSTCreateStatsTableSchema,
	APUTStatsTableSchema,
	APOSTCreateStatsTableSchema,
} from "./validators";
import { Requests } from "..";

// tslint:disable:no-duplicate-string
const statsStatsTables = {
	add: (data: IAPOSTCreateStatsTable): Promise<IRPOSTCreateStatsTable> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/stats-tables/",
			data,
			null,
			{
				requestSchema: APOSTCreateStatsTableSchema,
				responseSchema: RPOSTCreateStatsTableSchema,
			}
		),
	update: (data: IAPUTStatsTable): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/stats-tables/:_id",
			data,
			null,
			{
				requestSchema: APUTStatsTableSchema,
			}
		),
	getById: (data: IAGETStatsTable): Promise<IRGETStatsTable> =>
		Requests.send(
			"GET",
			"/api/projects/:projectId/stats-tables/:statsTableId",
			data,
			null,
			{
				responseSchema: RGETStatsTableSchema,
			}
		),
	deleteById: (data: IADELETEStatsTable): Promise<void> =>
		Requests.send(
			"DELETE",
			"/api/projects/:projectId/stats-tables/:statsTableId",
			data
		),
	getReadableStatsTables: (
		data: IAGETManyStatsTables
	): Promise<IRGETManyStatsTables> =>
		Requests.send(
			"GET",
			"/api/projects/:projectId/stats-tables",
			data,
			null,
			{
				responseSchema: RGETManyStatsTablesSchema,
			}
		),
	getStatistics: (data: IAGETStatistics): Promise<IRGETStatistics> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/stats-tables/:statsTableId/stats",
			data,
			null,
			{
				responseSchema: RGETStatisticsSchema,
			}
		),
};
export default statsStatsTables;
