import {
	IADELETEFunction,
	IAGETManyFunctions,
	IAGETFunction,
	IAPOSTCreateFunction,
	IAPUTFunction,
	IRGETManyFunctions,
	IRGETFunction,
	IRPOSTCreateFunction,
	RGETManyFunctionsSchema,
	RGETFunctionSchema,
	RPOSTCreateFunctionSchema,
} from "./validators";
import { Requests } from "..";

// tslint:disable:no-duplicate-string
const functions = {
	add: (data: IAPOSTCreateFunction): Promise<IRPOSTCreateFunction> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/functions/",
			data,
			null,
			{
				responseSchema: RPOSTCreateFunctionSchema,
			}
		),
	update: (data: IAPUTFunction): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/functions/:_id",
			data,
			null
		),
	getById: (data: IAGETFunction): Promise<IRGETFunction> =>
		Requests.send(
			"GET",
			"/api/projects/:projectId/functions/:functionId",
			data,
			null,
			{
				responseSchema: RGETFunctionSchema,
			}
		),
	deleteById: (data: IADELETEFunction): Promise<void> =>
		Requests.send(
			"DELETE",
			"/api/projects/:projectId/functions/:functionId",
			data
		),
	getReadableFunctions: (
		data: IAGETManyFunctions
	): Promise<IRGETManyFunctions> =>
		Requests.send("GET", "/api/projects/:projectId/functions", data, null, {
			responseSchema: RGETManyFunctionsSchema,
		}),
};
export default functions;
