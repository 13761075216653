import api, { ICredentials, Requests } from "@app/api";
import { IAPOSTLogin, IRPOSTLogin } from "@app/api/auth/validators";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { loginAction } from "./user";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

export const renewCredentials = (credentials: ICredentials) => {
	localStorage.setItem(
		"credentials",
		JSON.stringify({
			userId: credentials.userId,
			accessToken: credentials.accessToken,
			refreshToken: credentials.refreshToken,
		})
	);
	Requests.renewConfigByCredentials(credentials);
};

export const dipatchLoginActions = (
	dispatch: ThunkDispatch<IRootState, null, IRootActions>,
	res: any
) => {
	renewCredentials({
		userId: res.userData.id,
		accessToken: res.accessToken,
		refreshToken: res.refreshToken,
	});
	dispatch(loginAction(res.userData));
};

export type ILogin = (data: IAPOSTLogin) => Promise<IRPOSTLogin>;
// tslint:disable-next-line:max-line-length
export const login = (
	data: IAPOSTLogin
): ThunkAction<ReturnType<ILogin>, IRootState, null, IRootActions> => (
	dispatch
): ReturnType<ILogin> => {
	return api.auth.login(data).then(res => {
		console.log(res);
		dipatchLoginActions(dispatch, res);
		return res;
	});
};
