import { useRef } from "react";

export type SetState<T> = (arg: T | ((prevState: T) => T)) => void;

export type HelperFn<T, Args extends readonly any[]> = (
	currentState: T,
	...args: Args
) => T;

type UnWrap<Fn> = Fn extends (currentState: infer T, ...args: infer R) => void
	? (...args: R) => void
	: never;

export interface SetProp<
	T,
	HelperFunctions extends Record<string, HelperFn<T, any>> = {},
	ForbiddenKeys extends string | number | symbol = never
> {
	<K extends Exclude<KeysOfUnion<T> | keyof HelperFunctions, ForbiddenKeys>>(
		key: K
	): K extends keyof HelperFunctions
		? UnWrap<HelperFunctions[K]>
		: SetState<
				T extends any[]
					? K extends keyof T
						? T[K]
						: never
					:
							| (T extends { [key in K]: any }
									? T[K]
									: T extends { [key in K]?: any }
									? T[K] | undefined
									: never)
							| (K extends keyof NonNullable<T>
									? never
									: undefined)
		  >;
}

type SanitizationFn<T> = (chnagedValue: T) => T;

interface UsePropFns {
	<T extends Record<any, any> | undefined | null>(
		setState: SetState<T>,
		sanitizationFN?: SanitizationFn<T>
	): SetProp<T>;
	<
		T extends Record<any, any> | undefined | null,
		AdditionaFns extends Record<string, HelperFn<T, any>>
	>(
		setState: SetState<T>,
		helperFns: AdditionaFns,
		sanitizationFN?: SanitizationFn<T>
	): SetProp<T, AdditionaFns>;
	<T extends Record<any, any> | undefined | null, K extends KeysOfUnion<T>>(
		setState: SetState<T>,
		forbiddenKeys: readonly K[],
		sanitizationFN?: SanitizationFn<T>
	): SetProp<T, {}, K>;
	<
		T extends Record<any, any> | undefined | null,
		AdditionaFns extends Record<string, HelperFn<T, any>>,
		K extends KeysOfUnion<T> | keyof AdditionaFns
	>(
		setState: SetState<T>,
		helperFns: AdditionaFns,
		forbiddenKeys: readonly K[],
		sanitizationFN?: SanitizationFn<T>
	): SetProp<T, AdditionaFns, K>;
}

const defaultSanitizationFn = <T>(val: T) => val;

export const useSetProps: UsePropFns = (...rest: any[]): any => {
	const { setState, helperFns, forbiddenKeys, sanitizationFN } = getArgs(
		rest
	);

	const forbiddenKeysRef = useRef<string[] | undefined>(forbiddenKeys);
	forbiddenKeysRef.current = forbiddenKeys;

	const sanitizationFnRef = useRef<SanitizationFn<any>>(
		sanitizationFN || defaultSanitizationFn
	);
	sanitizationFnRef.current = sanitizationFN || defaultSanitizationFn;

	const helperFnsRef = useRef(helperFns);
	helperFnsRef.current = helperFns;

	const memoizedFnsRef = useRef<any>();
	const result = useRef<SetProp<any>>();

	if (!memoizedFnsRef.current) {
		memoizedFnsRef.current = {};
		const fn: SetProp<any> = ((key: string) => {
			if (memoizedFnsRef.current[key]) {
				return memoizedFnsRef.current[key];
			}
			const fn = (...args: any[]) => {
				const isForbidden = forbiddenKeysRef.current
					? forbiddenKeysRef.current.indexOf(key) > -1
					: false;
				if (isForbidden) {
					throw new Error(
						`The key ${key} is set as forbidden and the value cannot be changed`
					);
				}
				const sanitizationFn = sanitizationFnRef.current;
				if (
					helperFnsRef.current &&
					typeof helperFnsRef.current[key] === "function"
				) {
					const additionalFn = helperFnsRef.current;
					setState((x: any) => {
						const finalVal = additionalFn[key](x, ...args);
						if (finalVal === x) return x;
						return sanitizationFn(finalVal);
					});
					return;
				}
				const val = args[0];
				const valGetter = typeof val === "function" ? val : () => val;
				setState((x: any) => {
					if (!x) return x;
					const oldVal = x[key];
					const newVal = valGetter(oldVal);
					if (newVal === oldVal) return x;
					const finalVal = Array.isArray(x) ? [...x] : { ...x };
					finalVal[key] = newVal;
					return sanitizationFn(finalVal);
				});
			};
			memoizedFnsRef.current[key] = fn;
			return fn;
		}) as any;
		result.current = fn;
	}
	return result.current!;
};

const getArgs = (args: any) => {
	const setState = args[0];
	let helperFns: Record<any, any> | undefined = undefined;
	let forbiddenKeys: any[] | undefined = undefined;
	let sanitizationFN: ((...args: any[]) => any) | undefined = undefined;
	for (let i = 1; i < args.length; i++) {
		if (Array.isArray(args[i])) {
			forbiddenKeys = args[i];
		} else if (typeof args[i] === "function") {
			sanitizationFN = args[i];
		} else if (args[i] && typeof args[i] === "object") {
			helperFns = args[1];
		}
	}
	return { setState, helperFns, forbiddenKeys, sanitizationFN };
};

type KeysOfUnion<T> = T extends any ? keyof T : never;
