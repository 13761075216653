import { RFunctionSchema, IRFunction } from "./helper-schemas";
import Joi from "@app/utils/joi";
import {
	insertStripKeys,
	updateStripKeys,
	markKeysOptional,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateFunctionSchema = RFunctionSchema.keys(insertStripKeys);

export type IAPOSTCreateFunction = IRFunction;

export const RPOSTCreateFunctionSchema = RFunctionSchema;
export type IRPOSTCreateFunction = IRFunction;

///

export const AGETFunctionSchema = Joi.object({
	projectId: Joi.objectId().required(),
	functionId: Joi.objectId().required(),
});
export interface IAGETFunction {
	projectId: ObjectId;
	functionId: ObjectId;
}

export const RGETFunctionSchema = RFunctionSchema;
export type IRGETFunction = IRFunction;

///

export const APUTFunctionSchema = RFunctionSchema.keys(updateStripKeys).fork(
	["name", "description", "info"],
	markKeysOptional
);
export type IAPUTFunction = ToUpdateKeys<
	IRFunction,
	"name" | "description" | "info"
>;

///

export const ADELETEFunctionSchema = AGETFunctionSchema;
export type IADELETEFunction = IAGETFunction;

///

const AGETManyFunctionsSchema = Joi.object({
	projectId: Joi.objectId().required(),
});
export interface IAGETManyFunctions {
	projectId: ObjectId;
}

export const RGETManyFunctionsSchema = Joi.array().items(RFunctionSchema);
export type IRGETManyFunctions = IRFunction[];

///
