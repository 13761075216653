import * as origianljoi from "joi";
import Joi from "@app/utils/joi";
import { ObjectId, OptionalKeysOtherThan } from "@app/utils/generics";

//EQUAL-CONTENT-START
type LooseOptionalKeys<T, K extends string> = Omit<T, K> &
	Partial<{ [key in K & keyof T]: T[key] }>;

export const updateStripKeys = {
	createdAt: Joi.any().strip(),
	updatedAt: Joi.any().strip(),
	author: Joi.any().strip(),
};

export type ToUpdateKeys<
	T,
	OptionalKeys extends string | never = never,
	OmitKeys extends string = never
> = Omit<
	LooseOptionalKeys<T, OptionalKeys>,
	"createdAt" | "updatedAt" | "author" | OmitKeys
>;

export type UpdateSchema<
	T extends { _id: ObjectId; organization?: ObjectId },
	RequiredKeys extends keyof T | never = never,
	OmitKeys extends keyof T = never
> = Omit<
	OptionalKeysOtherThan<T, RequiredKeys | "_id" | "organization">,
	"createdAt" | "updatedAt" | "author" | OmitKeys
>;

export const insertStripKeys = {
	_id: Joi.any().strip(),
	createdAt: Joi.any().strip(),
	updatedAt: Joi.any().strip(),
	author: Joi.any().strip(),
};
export type ToInsertKeys<
	T,
	OptionalKeys extends string | never = never,
	OmitKeys extends string = never
> = Omit<
	LooseOptionalKeys<T, OptionalKeys>,
	"_id" | "createdAt" | "updatedAt" | "author" | OmitKeys
>;

export const markKeysOptional = (schema: origianljoi.Schema) =>
	schema.optional();
export const markKeysForbidden = (schema: origianljoi.Schema) =>
	schema.forbidden();
export const markKeysStripped = (schema: origianljoi.Schema) =>
	schema.optional().strip();
