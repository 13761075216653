import api from "@app/api";
import {
	addTable,
	deleteTable,
	loadTableById,
	tableCRUDActions,
	updateTable,
} from "@app/actions/tables";
import {
	IAGETManyTables,
	IAPOSTCreateTable,
	IAPUTTable,
	IRPOSTCreateTable,
} from "@app/api/tables/validators";
import { IDeleteOneArgs, ISearchManyArgs, ISearchOneArgs } from "./interfaces";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IRTable } from "@app/api/tables/helper-schemas";
import { Model } from ".";
import { Store } from "redux";
import { User } from "@app/user";

type MyInstance = IRTable;

class TableModel extends Model<"tables"> {
	constructor(store: Store<IRootState, IRootActions>) {
		super("tables", store);
		this.supportsLoadingByIdsFromApi = false;
	}

	getReadableTables(args: IAGETManyTables): Promise<MyInstance[]> {
		return api.tables.getReadableTables(args).then(tables => {
			this.dispatch(tableCRUDActions.loadMany(tables));
			return tables;
		});
	}

	getReadableTablesSync(args: IAGETManyTables, user: User): MyInstance[] {
		const instances = this.getState().tables;
		const colIds = Object.keys(instances);
		const myTables: MyInstance[] = [];
		for (let i = 0; i < colIds.length; ++i) {
			const table = instances[colIds[i]]!.info;
			if (table.projectId !== args.projectId) continue;
			if (user.canReadTable(table._id, table.projectId))
				myTables.push(table);
		}
		return myTables.sort((a, b) => {
			return a._id < b._id ? -1 : 1;
		});
	}

	async create(table: IAPOSTCreateTable): Promise<IRPOSTCreateTable> {
		return addTable(table)(this.dispatch, this.getState, null);
	}
	async update(table: IAPUTTable): Promise<void> {
		return updateTable(table)(this.dispatch, this.getState, null);
	}

	public async deleteOne(info: IDeleteOneArgs["tables"]): Promise<void> {
		return deleteTable({ tableId: info.id, projectId: info.projectId })(
			this.dispatch,
			this.getState,
			null
		);
	}

	protected async loadOne(
		info: ISearchOneArgs["tables"]
	): Promise<MyInstance> {
		return loadTableById({ tableId: info.id, projectId: info.projectId })(
			this.dispatch,
			this.getState,
			null
		);
	}

	protected async loadManyByIds(
		infos: ISearchManyArgs["tables"]
	): Promise<MyInstance[]> {
		return [];
	}
}

export { TableModel };
