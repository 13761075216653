import Joi from "@app/utils/joi";
import { RAdminRoleSchema, IRAdminRole } from "../admin-roles/helper-schemas";
import { RProjectSchema, IRProject } from "./helper-schemas";
import {
	RUserMinimalInfoSchema,
	IRUserMinimalInfo,
} from "../users/helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateProjectSchema = RProjectSchema.keys(insertStripKeys);

export type IAPOSTCreateProject = ToInsertKeys<IRProject>;

export const RPOSTCreateProjectSchema = Joi.object().keys({
	project: RProjectSchema.required(),
	ownerAdminRole: RAdminRoleSchema.required(),
});
export interface IRPOSTCreateProject {
	project: IRProject;
	ownerAdminRole: IRAdminRole;
}

///

export const AGETProjectSchema = Joi.object({
	id: Joi.objectId().required(),
});
export interface IAGETProject {
	id: ObjectId;
}

export const RGETProjectSchema = RProjectSchema;
export type IRGETProject = IRProject;

///

export const APUTProjectSchema = RProjectSchema.keys(updateStripKeys).unknown();
export type IAPUTProject = ToUpdateKeys<IRProject>;

///

export const ADELETEProjectSchema = AGETProjectSchema;
export type IADELETEProject = IAGETProject;

///

export const RGETManyProjectsSchema = Joi.array().items(RGETProjectSchema);
export type IRGETManyProjects = IRGETProject[];

///

export const AGETAdminsMinimalInfoSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETAdminsMinimalInfo {
	_id: ObjectId;
}

export const RGETAdminsMinimalInfoSchema = Joi.array().items(
	RUserMinimalInfoSchema
);
export type IRGETAdminsMinimalInfo = IRUserMinimalInfo[];

//
