import api from "@app/api";
import createCRUDActions from "./crud";
import {
	DELETE_STATS_TABLE,
	LOAD_STATS_TABLE_BY_ID,
	LOAD_STATS_TABLES,
	UPDATE_STATS_TABLE,
} from "@app/action-types";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IRStatsTable } from "@app/api/stats-tables/helper-schemas";
import { ThunkAction } from "redux-thunk";
import {
	IAGETStatsTable,
	IAPOSTCreateStatsTable,
	IAPUTStatsTable,
	IRGETManyStatsTables,
	IRGETStatsTable,
	IRPOSTCreateStatsTable,
	IADELETEStatsTable,
	IAGETManyStatsTables,
} from "@app/api/stats-tables/validators";

export const addStatsTable = (
	args: IAPOSTCreateStatsTable
): ThunkAction<
	Promise<IRPOSTCreateStatsTable>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.statsTables.add(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const updateStatsTable = (
	args: IAPUTStatsTable
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.statsTables.update(args).then(() => {
		dispatch(crudActions.update(args));
	});
};

export const loadStatsTables = (
	args: IAGETManyStatsTables
): ThunkAction<
	Promise<IRGETManyStatsTables>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.statsTables.getReadableStatsTables(args).then(data => {
		dispatch(crudActions.loadMany(data));
		return data;
	});
};

export const loadStatsTableById = (
	args: IAGETStatsTable
): ThunkAction<
	Promise<IRGETStatsTable>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.statsTables.getById(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const deleteStatsTable = (
	args: IADELETEStatsTable
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.statsTables.deleteById(args).then(() => {
		dispatch(crudActions.delete(args.statsTableId));
	});
};

export type StatsTableActions =
	| ReturnType<typeof crudActions["delete"]>
	| ReturnType<typeof crudActions["loadMany"]>
	| ReturnType<typeof crudActions["loadOne"]>
	| ReturnType<typeof crudActions["update"]>;

export const statsTableCRUDActionTypes = {
	update: UPDATE_STATS_TABLE as typeof UPDATE_STATS_TABLE,
	loadOne: LOAD_STATS_TABLE_BY_ID as typeof LOAD_STATS_TABLE_BY_ID,
	loadMany: LOAD_STATS_TABLES as typeof LOAD_STATS_TABLES,
	delete: DELETE_STATS_TABLE as typeof DELETE_STATS_TABLE,
};

const crudActions = createCRUDActions<
	IRStatsTable,
	typeof statsTableCRUDActionTypes
>(statsTableCRUDActionTypes);
export { crudActions as statsTableCRUDActions };
