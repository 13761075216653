import * as React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FilterListIcon from "@material-ui/icons/FilterList";
import { ContextMenuTrigger } from "react-contextmenu";
import {
	IFilterOptions,
	ISortOptions,
} from "@app/api/resource-data/helper-schemas";
import { IRGETManyResourcesData } from "@app/api/resource-data/validators";
import { ColumnType } from "@app/api/columns/helper-schemas";
import { ITriggerAttributes } from "../right-click-context";

interface ITableHeadProps {
	cols: IRGETManyResourcesData["cols"];
	filterOptions?: IFilterOptions;
	sortOptions?: ISortOptions;
}

export class TableHead extends React.PureComponent<ITableHeadProps> {
	render() {
		return (
			<thead>
				<tr>
					{this.props.cols.map(col => (
						<th key={col._id}>
							<TableHeadCell
								col={col}
								isFiltered={
									!!(
										this.props.filterOptions &&
										this.props.filterOptions[col._id]
									)
								}
								sortDirection={
									(this.props.sortOptions &&
										this.props.sortOptions.colId ===
											col._id &&
										this.props.sortOptions.type) ||
									undefined
								}
							/>
						</th>
					))}
					<th>მფლობელი ჯგუფები</th>
				</tr>
			</thead>
		);
	}
}

interface ITableHeadCellProps {
	col: IRGETManyResourcesData["cols"][number];
	isFiltered: boolean;
	sortDirection?: ISortOptions["type"];
}
function collect(props) {
	return props;
}

const TableHeadCell: React.FC<ITableHeadCellProps> = props => {
	const col = props.col;

	const additional: ITriggerAttributes = {
		colInfo: col.info,
		projectId: col.projectId,
	};
	return (
		<ContextMenuTrigger
			key={col._id}
			id="multi"
			attributes={{ title: col._id }}
			collect={collect}
			{...(additional as any)}
		>
			{col.customName === undefined ? col.name : col.customName}
			{props.isFiltered && <FilterListIcon />}
			{props.sortDirection && props.sortDirection === "ASC" && (
				<ArrowDropUpIcon />
			)}
			{props.sortDirection && props.sortDirection === "DESC" && (
				<ArrowDropDownIcon />
			)}
		</ContextMenuTrigger>
	);
};
