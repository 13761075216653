import Joi from "@app/utils/joi";
import { RColumnSchema, IRColumn } from "./helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateColumnSchema = RColumnSchema.keys(insertStripKeys);

export type IAPOSTCreateColumn = ToInsertKeys<IRColumn>;

export const RPOSTCreateColumnSchema = RColumnSchema;
export type IRPOSTCreateColumn = IRColumn;

///

export const AGETColumnSchema = Joi.object({
	projectId: Joi.objectId().required(),
	columnId: Joi.objectId().required(),
});
export interface IAGETColumn {
	projectId: ObjectId;
	columnId: ObjectId;
}

export const RGETColumnSchema = RColumnSchema;
export type IRGETColumn = IRColumn;

///

export const APUTColumnSchema = RColumnSchema.keys(updateStripKeys);
export type IAPUTColumn = ToUpdateKeys<IRColumn>;

///

export const ADELETEColumnSchema = AGETColumnSchema;
export type IADELETEColumn = IAGETColumn;

///

const AGETManyColumnsSchema = Joi.object({
	projectId: Joi.objectId().required(),
});
export interface IAGETManyColumns {
	projectId: ObjectId;
}

export const RGETManyColumnsSchema = Joi.array().items(RColumnSchema);
export type IRGETManyColumns = IRColumn[];

///
