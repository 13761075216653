export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_AUTHORIZATION_SUCCESS = "USER_AUTHORIZATION_SUCCESS";
export const USERDATA_CHANGE = "USERDATA_CHANGE";
export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const USER_LOG_OUT = "USER_LOG_OUT";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const LOAD_PROJECT_BY_ID = "LOAD_PROJECT_BY_ID";
export const LOAD_PROJECTS = "LOAD_PROJECTS";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const UPDATE_COLUMN = "UPDATE_COLUMN";
export const LOAD_COLUMN_BY_ID = "LOAD_COLUMN_BY_ID";
export const LOAD_COLUMNS = "LOAD_COLUMNS";
export const DELETE_COLUMN = "DELETE_COLUMN";

export const UPDATE_TABLE = "UPDATE_TABLE";
export const LOAD_TABLE_BY_ID = "LOAD_TABLE_BY_ID";
export const LOAD_TABLES = "LOAD_TABLES";
export const DELETE_TABLE = "DELETE_TABLE";

export const UPDATE_APICALL = "UPDATE_APICALL";
export const LOAD_APICALL_BY_ID = "LOAD_APICALL_BY_ID";
export const LOAD_APICALLS = "LOAD_APICALLS";
export const DELETE_APICALL = "DELETE_APICALL";

export const UPDATE_STATS_TABLE = "UPDATE_STATS_TABLE";
export const LOAD_STATS_TABLE_BY_ID = "LOAD_STATS_TABLE_BY_ID";
export const LOAD_STATS_TABLES = "LOAD_STATS_TABLES";
export const DELETE_STATS_TABLE = "DELETE_STATS_TABLE";

export const UPDATE_FUNCTION = "UPDATE_FUNCTION";
export const LOAD_FUNCTION_BY_ID = "LOAD_FUNCTION_BY_ID";
export const LOAD_FUNCTIONS = "LOAD_FUNCTIONS";
export const DELETE_FUNCTION = "DELETE_FUNCTION";
