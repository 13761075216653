import Joi from "@app/utils/joi";
import { RAdminRoleSchema, IRAdminRole } from "../admin-roles/helper-schemas";

export const RUserSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	murtskuId: Joi.number()
		.integer()
		.allow(null)
		.required(),
	mobile: Joi.string()
		.allow(null)
		.required(),
	mail: Joi.string()
		.allow(null)
		.allow(""),
	username: Joi.string(),
	firstname: Joi.string().allow(null),
	lastname: Joi.string().allow(null),
	canAddProjects: Joi.boolean().required(),
	projectRoles: Joi.object()
		.pattern(/[\da-f]+/, RAdminRoleSchema)
		.allow(null)
		.required(),
});
export interface IRUser {
	id: number;
	murtskuId: number;
	mobile: string | null;
	mail?: string | null;
	username?: string;
	firstname?: string | null;
	lastname?: string | null;
	canAddProjects: boolean;
	projectRoles: { [key in string]?: IRAdminRole } | null;
}

export const UserEssentialsSchema = Joi.object().keys({
	userData: RUserSchema.required(),
	extraInfo: Joi.object(),
});
export interface IUserEssentials {
	userData: IRUser;
	extraInfo?: Record<any, any>;
}

export const RUserMinimalInfoSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	murtskuId: Joi.number()
		.integer()
		.allow(null)
		.required(),
	username: Joi.string(),
});
export interface IRUserMinimalInfo {
	id: number;
	murtskuId: number;
	username?: string;
}
