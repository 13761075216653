import Joi from "@app/utils/joi";
import { RResourceGroupSchema, IRResourceGroup } from "./helper-schemas";
import {
	insertStripKeys,
	markKeysStripped,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { IResIds, ResIdsSchema } from "../common-schemas";

export const APOSTCreateResourceGroupSchema = RResourceGroupSchema.keys(
	insertStripKeys
).fork("numOfResources", markKeysStripped);

export type IAPOSTCreateResourceGroup = ToInsertKeys<
	IRResourceGroup,
	never,
	"numOfResources"
>;

export const RPOSTCreateResourceGroupSchema = RResourceGroupSchema;
export type IRPOSTCreateResourceGroup = IRResourceGroup;

///

export const AGETResourceGroupSchema = Joi.object({
	projectId: Joi.objectId().required(),
	resourceGroupId: Joi.objectId().required(),
});
export interface IAGETResourceGroup {
	projectId: ObjectId;
	resourceGroupId: ObjectId;
}

export const RGETResourceGroupSchema = RResourceGroupSchema;
export type IRGETResourceGroup = IRResourceGroup;

///

export const APUTResourceGroupSchema = RResourceGroupSchema.keys(
	updateStripKeys
).fork("numOfResources", markKeysStripped);
export type IAPUTResourceGroup = ToUpdateKeys<
	IRResourceGroup,
	never,
	"numOfResources"
>;

///

export const ResourceModifyingMinimalSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	resourceIds: ResIdsSchema.required(),
});

interface ResourceModifyingMinimal {
	_id: ObjectId;
	projectId: ObjectId;
	resourceIds: IResIds;
}

///

export enum ResourceAdditionStrategy {
	straightforward,
	addIfHomeless,
	seize,
}

export const APUTAddResourceToGroupSchema = ResourceModifyingMinimalSchema.keys(
	{
		strategy: Joi.any()
			.valid(
				ResourceAdditionStrategy.straightforward,
				ResourceAdditionStrategy.addIfHomeless,
				ResourceAdditionStrategy.seize
			)
			.required(),
	}
);

export interface IAPUTAddResourceToGroup extends ResourceModifyingMinimal {
	strategy: ResourceAdditionStrategy;
}

///

export const ADELETEResourceToGroupSchema = ResourceModifyingMinimalSchema;
export type IADELETEResourceToGroup = ResourceModifyingMinimal;

///

export const APUTSetResourceToGroupSchema = ResourceModifyingMinimalSchema.keys(
	{
		removeFromOtherResourceGroups: Joi.boolean(),
	}
);
export type IAPUTSetResourceToGroup = ResourceModifyingMinimal & {
	removeFromOtherResourceGroups: boolean;
};

///

export const ADELETEResourceGroupSchema = AGETResourceGroupSchema;
export type IADELETEResourceGroup = IAGETResourceGroup;

///

const AGETManyResourceGroupsSchema = Joi.object({
	projectId: Joi.objectId().required(),
});
export interface IAGETManyResourceGroups {
	projectId: ObjectId;
}

export const RGETManyResourceGroupsSchema = Joi.array().items(
	RResourceGroupSchema
);
export type IRGETManyResourceGroups = IRResourceGroup[];

///
