import React, { useCallback, useEffect, useMemo, useState } from "react";
import Switch from "@material-ui/core/Switch";
import { CheckboxWithLabel } from "../widgets/input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ObjectId } from "@app/utils/generics";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Select from "react-select";
import DeleteIcon from "@material-ui/icons/Delete";
import { SetProp, SetState, useSetProps } from "react-prop-hooks";
import { useDynamicRef } from "@app/hooks/general";

export const CRUDOptions2 = React.memo(function CRUDOptions({
	value,
	onChange,
	options,
	showAllOption,
	children,
}: {
	value: number;
	onChange: (newValue: number) => void;
	options: { value: number; label: string }[];
	showAllOption?: boolean;
	children?: JSX.Element;
}) {
	const setOption = (val: number, checked: boolean) => {
		onChange(checked ? val | value : value & ~val);
	};

	const isAllSelected = value === -1;

	const showRestOptions = (showAllOption && !isAllSelected) || !showAllOption;

	return (
		<div>
			{showAllOption && (
				<FormControlLabel
					control={
						<Switch
							checked={isAllSelected}
							onChange={(_, checked) => {
								checked ? onChange(-1) : onChange(0);
							}}
						/>
					}
					label="სრული უფლება"
				/>
			)}
			{showRestOptions &&
				options.map((option, i) => {
					const checked = (option.value & value) === option.value;
					return (
						<span style={{ marginRight: 10 }} key={i}>
							<CheckboxWithLabel
								onChange={ch => setOption(option.value, ch)}
								value={checked}
								label={option.label}
							/>
						</span>
					);
				})}
			{children}
		</div>
	);
});

interface Option<T> {
	value: string;
	label: string;
	data?: T;
}

export interface RenderItemProps<T> {
	onDataChange: SetState<T | undefined>;
	data: T | undefined;
	getDataPropSetterFn: SetProp<T>;
}

interface IndividualSelectsProps<T> {
	records: { _id: ObjectId; name: string }[];
	renderItem: (props: RenderItemProps<T>) => JSX.Element;
	onChange: (data: Record<string, T>) => void;
	getDefaultData?: (id: ObjectId) => T;
	defaultData: { [key in any]?: T } | undefined;
}

export const IndividualSelects: <T extends any>(
	props: IndividualSelectsProps<T>
) => JSX.Element | null = React.memo(function IndividualSelects<T extends any>({
	records,
	renderItem,
	onChange,
	getDefaultData,
	defaultData,
}: IndividualSelectsProps<T | any>) {
	// TODO: get initial selections
	const [selections, setSelections] = useState<(Option<T> | null)[]>(() => {
		if (!defaultData) return [];
		const opt: Option<T>[] = [];
		for (const key in defaultData) {
			opt.push({
				value: key,
				label: records.find(e => e._id === key)?.name ?? "",
				data: defaultData[key],
			});
		}
		return opt;
	});
	const getSelectionSetters = useSetProps(setSelections);
	const options = useMemo(
		() => records.map(each => ({ value: each._id, label: each.name })),
		[records]
	);
	const onChangeRef = useDynamicRef(onChange);

	const handleAdd = useCallback(() => setSelections(x => [...x, null]), []);

	useEffect(() => {
		if (selections.length === 0) handleAdd();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const finalData: Record<string, T> = {};
		for (const opt of selections) {
			if (!opt || opt.data === undefined) continue;
			finalData[opt.value] = opt.data;
		}
		onChangeRef.current?.(finalData);
	}, [selections, onChangeRef]);

	return (
		<React.Fragment>
			<Button onClick={handleAdd}>
				<span style={{ marginTop: 7 }}>დამატება</span>
				<AddIcon />
			</Button>
			{selections.map((selection, i) => {
				return (
					<ItemSelect
						key={i}
						options={options}
						selectedOption={selection}
						onChange={getSelectionSetters(i)}
						onDelete={getSelectionSetters.getDeleteFn.byIndex(i)}
						getDefaultData={getDefaultData}
					>
						{childProps => renderItem(childProps)}
					</ItemSelect>
				);
			})}
		</React.Fragment>
	);
});

interface ItemSelectProps<T> {
	selectedOption: Option<T> | null;
	onChange: SetState<Option<T> | null>;
	onDelete: () => void;
	options: { value: ObjectId; label: string }[];
	children?: (props: RenderItemProps<T>) => JSX.Element;
	getDefaultData?: (id: ObjectId) => T;
}

const ItemSelect: <T extends any>(
	props: ItemSelectProps<T>
) => JSX.Element | null = React.memo(function ItemSelect<T extends any>({
	options,
	onChange,
	onDelete,
	selectedOption,
	children,
	getDefaultData,
}: ItemSelectProps<T | any>) {
	const getChangerFn = useSetProps(onChange);
	const onDataChange = getChangerFn("data");
	const getDataPropSetterFn = useSetProps(onDataChange as any) as SetProp<T>;
	return (
		<div>
			<div style={{ display: "flex" }}>
				<div style={{ flex: 1 }}>
					<Select
						options={options}
						onChange={(v: { value: ObjectId; label: string }) => {
							const data = getDefaultData?.(v.value) as T;
							onChange({ ...v, data });
						}}
						value={selectedOption ?? null}
					/>
				</div>
				<Button onClick={onDelete}>
					<DeleteIcon />
				</Button>
			</div>
			{!!selectedOption &&
				!!children &&
				children({
					onDataChange: onDataChange,
					data: selectedOption?.data,
					getDataPropSetterFn,
				})}
			<br />
			<br />
		</div>
	);
});
