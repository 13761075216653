/* eslint-disable no-restricted-globals */
import api from "@app/api";
import {
	ApiCallType,
	IAPICallRefreshResourceIds,
	IRAPICall,
} from "@app/api/api-calls/helper-schemas";
import { IRResourceGroup } from "@app/api/resource-groups/helper-schemas";
import { useBoolean } from "@app/hooks/general";
import { inject } from "@app/modules";
import { User } from "@app/user";
import { ObjectId, WithKnownKeyType } from "@app/utils/generics";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { History } from "history";
import * as React from "react";
import { match } from "react-router";
import { IRAdminRole } from "../../api/admin-roles/helper-schemas";
import { APICallActionViewOuter } from "../tables/data/action";
import { AdminTable, SVG } from "../widgets/admin-table";
import Loading from "../widgets/loading";
import Popup, { PopupContentWithClose } from "../widgets/popup";
import { ImportDatabase } from "./import";

interface IProps {
	history: History;
	match: match<{ projectId: string }>;
	user: User;
}

interface IState {
	resoucrGroups?: IRResourceGroup[];
	showImportPopup: boolean;
	apiCalls?: IRAPICall[];
	showRolesPopup: boolean;
	adminRoles?: IRAdminRole[];
	showAddRoles?: boolean;
}

export class ResourceGroupsList extends React.PureComponent<IProps, IState> {
	projectId = this.props.match.params.projectId;

	_APICalls = inject("APICall");

	state: IState = {
		showImportPopup: false,
		showRolesPopup: false,
		adminRoles: [],
		showAddRoles: false,
	};

	componentDidMount() {
		api.resourceGroups
			.getReadableResourceGroups({ projectId: this.projectId })
			.then(resoucrGroups => {
				this.setState({
					resoucrGroups,
				});
			});
		this._APICalls
			.getReadableAPICalls({ projectId: this.projectId })
			.then(data => {
				this.setState({ apiCalls: data });
			});
	}

	canDelete = (id: ObjectId) => {
		return this.props.user.canDeleteResourceGroup(id, this.projectId);
	};
	canView = (id: ObjectId) => {
		return false; // to hide view icon
		// return this.props.user.canReadResourceGroup(id, this.projectId);
	};
	canEdit = (id: ObjectId) => {
		return this.props.user.canDeleteAPICall(id, this.projectId);
	};

	onDelete = (id: ObjectId) => {
		if (!this.state.resoucrGroups) return;
		if (confirm("დარწმუნებული ხართ????")) {
			api.resourceGroups
				.deleteById({ resourceGroupId: id, projectId: this.projectId })
				.then(() => {
					this.setState({
						resoucrGroups: this.state.resoucrGroups!.filter(
							e => e._id !== id
						),
					});
				});
		}
	};

	onView = (id: ObjectId) => {
		this.props.history.push(
			`/projects/${this.projectId}/resource-groups/${id}`
		);
	};

	onEdit = (id: ObjectId) => {
		this.props.history.push(
			`/projects/${this.projectId}/resource-groups/${id}/edit`
		);
	};

	onResourceGroupAdd = () => {
		this.props.history.push(
			`/projects/${this.projectId}/resource-group/add`
		);
	};

	onAddResources = (id: ObjectId) => {
		this.props.history.push(
			`/projects/${this.projectId}/resource-groups/${id}/add-resources`
		);
	};

	iconsAfterComponent = (props: { row: IRResourceGroup; index: number }) => (
		<>
			{this.props.user.canWriteResourceGroup(
				props.row._id,
				this.projectId
			) ? (
				<AddIcon
					className={SVG}
					onClick={() => this.onAddResources(props.row._id)}
				/>
			) : null}
		</>
	);

	onShowImport = () => {
		this.setState({ showImportPopup: true });
	};
	onImportClose = () => {
		this.setState({ showImportPopup: false });
	};
	onGotoHelpingTools = () => {
		this.props.history.push(
			`/projects/${this.projectId}/resource-groups/tools`
		);
	};

	onGotoRoles = () => {
		this.props.history.push(`/projects/${this.projectId}/roles`);
	};

	render() {
		if (!this.state.resoucrGroups) {
			return <Loading />;
		}
		return (
			<div className="main main2">
				<br />
				{this.props.user.canWriteResourceGroup(
					null,
					this.projectId
				) && (
					<>
						<Button
							variant="contained"
							color="primary"
							onClick={this.onResourceGroupAdd}
						>
							მომხმარებელთა ჯგუფის დამატება
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={this.onShowImport}
						>
							იმპორტი
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={this.onGotoHelpingTools}
						>
							დამხმარე tools
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={this.onGotoRoles}
						>
							როლები
						</Button>
					</>
				)}
				<AdminTable
					canDelete={this.canDelete}
					canEdit={this.canEdit}
					canView={this.canView}
					onView={this.onView}
					onEdit={this.onEdit}
					onDelete={this.onDelete}
					rows={this.state.resoucrGroups}
					iconsAfterComponent={this.iconsAfterComponent}
				/>
				{this.state.showImportPopup && (
					<Popup>
						<PopupContentWithClose onClose={this.onImportClose}>
							<ImportDatabase
								user={this.props.user}
								projectId={this.projectId}
								onSuccess={this.onImportClose}
							/>
						</PopupContentWithClose>
					</Popup>
				)}
				{this.state.apiCalls && (
					<APICallsButtons apiCalls={this.state.apiCalls} />
				)}
			</div>
		);
	}
}

const APICallsButtons: React.FC<{ apiCalls: IRAPICall[] }> = React.memo(
	({ apiCalls }) => {
		return (
			<div>
				{apiCalls.map(apiCall => {
					if (apiCall.info.type !== ApiCallType.refreshResourceIds)
						return null;
					return (
						<APICallsButton
							key={apiCall._id}
							api={apiCall as any}
						/>
					);
				})}
			</div>
		);
	}
);

const APICallsButton: React.FC<{
	api: WithKnownKeyType<IRAPICall, "info", IAPICallRefreshResourceIds>;
}> = React.memo(props => {
	const {
		value: isPopupOpen,
		setFalse: closePopup,
		setTrue: openPopup,
	} = useBoolean();
	return (
		<>
			<Button variant="contained" color="primary" onClick={openPopup}>
				{props.api.name}
			</Button>
			{isPopupOpen && (
				<APICallActionViewOuter
					api={props.api as any}
					onClose={closePopup}
					onCall={closePopup}
				/>
			)}
		</>
	);
});
