/* eslint-disable no-restricted-globals */
import * as React from "react";
import Button from "@material-ui/core/Button";
import { AdminTable } from "../widgets/admin-table";
import { History } from "history";
import { inject } from "@app/modules";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";
import { User } from "@app/user";
import CopyIcon from "@material-ui/icons/FilterNone";

interface IProps {
	history: History;
	match: match<{ projectId: string }>;
	user: User;
}

export class FunctionsList extends React.PureComponent<IProps> {
	projectId = this.props.match.params.projectId;

	_Function = inject("Function");

	componentDidMount() {
		this._Function
			.getReadableFunctions({ projectId: this.projectId })
			.then();
	}

	canDelete = (id: ObjectId) => {
		return this.props.user.canDeleteFunction(id, this.projectId);
	};
	canEdit = (id: ObjectId) => {
		return this.props.user.canDeleteFunction(id, this.projectId);
	};

	onDelete = (id: ObjectId) => {
		if (confirm("დარწმუნებული ხართ????")) {
			this._Function.deleteOne({ id, projectId: this.projectId });
		}
	};

	onEdit = (id: ObjectId) => {
		this.props.history.push(
			`/projects/${this.projectId}/functions/${id}/edit`
		);
	};

	onFunctionAdd = () => {
		this.props.history.push(`/projects/${this.projectId}/functions/add`);
	};

	additionalComponents: React.ComponentType<{
		rowId: ObjectId;
		iconClassName: string;
	}> = ({ rowId, iconClassName }) => {
		return (
			<>
				<CopyIcon
					className={iconClassName}
					onClick={() => this.copy(rowId)}
				/>
			</>
		);
	};

	copy = (funcId: ObjectId) => {
		sessionStorage.setItem(
			"copy-functions",
			JSON.stringify({ funcId, projectId: this.projectId })
		);
	};

	render() {
		const tables = this._Function.getReadableFunctionsSync(
			{ projectId: this.projectId },
			this.props.user
		);
		return (
			<div className="main main2">
				<br />
				{this.props.user.canWriteFunction(null, this.projectId) && (
					<Button
						variant="contained"
						color="primary"
						onClick={this.onFunctionAdd}
					>
						ფუნქციის დამატება
					</Button>
				)}
				<AdminTable
					canDelete={this.canDelete}
					canEdit={this.canEdit}
					onEdit={this.onEdit}
					onDelete={this.onDelete}
					rows={tables}
					additionalComponents={this.additionalComponents}
				/>
			</div>
		);
	}
}
