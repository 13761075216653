import api from "@app/api";
import {
	addColumn,
	columnCRUDActions,
	deleteColumn,
	loadColumnById,
	updateColumn,
} from "@app/actions/columns";
import {
	IAGETManyColumns,
	IAPOSTCreateColumn,
	IAPUTColumn,
	IRPOSTCreateColumn,
} from "@app/api/columns/validators";
import { IDeleteOneArgs, ISearchManyArgs, ISearchOneArgs } from "./interfaces";
import { IRColumn } from "@app/api/columns/helper-schemas";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { Model } from ".";
import { Store } from "redux";
import { User } from "@app/user";

type MyInstance = IRColumn;

class ColumnModel extends Model<"columns"> {
	constructor(store: Store<IRootState, IRootActions>) {
		super("columns", store);
		this.supportsLoadingByIdsFromApi = false;
	}

	getReadableColumns(args: IAGETManyColumns): Promise<MyInstance[]> {
		return api.columns.getReadableColumns(args).then(columns => {
			this.dispatch(columnCRUDActions.loadMany(columns));
			return columns;
		});
	}

	loadManySync(columns: IRColumn[]) {
		this.dispatch(columnCRUDActions.loadMany(columns));
	}

	getReadableColumnsSync(args: IAGETManyColumns, user: User): MyInstance[] {
		const instances = this.getState().columns;
		const colIds = Object.keys(instances);
		const myCols: MyInstance[] = [];
		for (let i = 0; i < colIds.length; ++i) {
			const column = instances[colIds[i]]!.info;
			if (column.projectId !== args.projectId) continue;
			if (user.canReadColumn(column._id, column.projectId))
				myCols.push(column);
		}
		return myCols.sort((a, b) => {
			return a._id < b._id ? -1 : 1;
		});
	}

	async create(column: IAPOSTCreateColumn): Promise<IRPOSTCreateColumn> {
		return addColumn(column)(this.dispatch, this.getState, null);
	}
	async update(column: IAPUTColumn): Promise<void> {
		return updateColumn(column)(this.dispatch, this.getState, null);
	}

	public async deleteOne(info: IDeleteOneArgs["columns"]): Promise<void> {
		return deleteColumn({ columnId: info.id, projectId: info.projectId })(
			this.dispatch,
			this.getState,
			null
		);
	}

	protected async loadOne(
		info: ISearchOneArgs["columns"]
	): Promise<MyInstance> {
		return loadColumnById({ columnId: info.id, projectId: info.projectId })(
			this.dispatch,
			this.getState,
			null
		);
	}

	protected async loadManyByIds(
		infos: ISearchManyArgs["columns"]
	): Promise<MyInstance[]> {
		return [];
	}
}

export { ColumnModel };
