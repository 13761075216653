/* eslint-disable no-restricted-globals */
import * as React from "react";
import Button from "@material-ui/core/Button";
import { AdminTable } from "../widgets/admin-table";
import { History } from "history";
import { inject } from "@app/modules";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";
import { User } from "@app/user";

interface IProps {
	history: History;
	match: match<{ projectId: string }>;
	user: User;
}

export class ColumnsList extends React.PureComponent<IProps> {
	projectId = this.props.match.params.projectId;

	_Column = inject("Column");

	componentDidMount() {
		this._Column.getReadableColumns({ projectId: this.projectId }).then();
	}

	canDelete = (id: ObjectId) => {
		return this.props.user.canDeleteColumn(id, this.projectId);
	};
	canView = (id: ObjectId) => {
		return this.props.user.canReadColumn(id, this.projectId);
	};
	canEdit = (id: ObjectId) => {
		return this.props.user.canDeleteAPICall(id, this.projectId);
	};

	onDelete = (id: ObjectId) => {
		if (confirm("დარწმუნებული ხართ????")) {
			this._Column.deleteOne({ id, projectId: this.projectId });
		}
	};

	onView = (id: ObjectId) => {
		this.props.history.push(`/projects/${this.projectId}/columns/${id}`);
	};

	onEdit = (id: ObjectId) => {
		this.props.history.push(
			`/projects/${this.projectId}/columns/${id}/edit`
		);
	};

	onColumnAdd = () => {
		this.props.history.push(`/projects/${this.projectId}/column/add`);
	};

	render() {
		const columns = this._Column.getReadableColumnsSync(
			{ projectId: this.projectId },
			this.props.user
		);
		return (
			<div className="main main2">
				<br />
				{this.props.user.canWriteColumn(null, this.projectId) && (
					<Button
						variant="contained"
						color="primary"
						onClick={this.onColumnAdd}
					>
						სვეტის დამატება
					</Button>
				)}
				<AdminTable
					canDelete={this.canDelete}
					canEdit={this.canEdit}
					canView={this.canView}
					onView={this.onView}
					onEdit={this.onEdit}
					onDelete={this.onDelete}
					rows={columns}
				/>
			</div>
		);
	}
}
