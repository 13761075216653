import createCRUDReducer from "./crud";
import { IReduxStateInstance } from "./root-state";
import { IRFunction } from "@app/api/functions/helper-schemas";
import {
	FunctionActions,
	functionCRUDActionTypes,
} from "@app/actions/functions";

function getNewFunctionState(
	state: IStateFunctions = {},
	action: FunctionActions
): IStateFunctions {
	switch (action.type) {
		default:
			return state;
	}
}

const crudReducer = createCRUDReducer<IRFunction>(functionCRUDActionTypes);

export default function functions(
	state = {},
	action: FunctionActions
): IStateFunctions {
	const state1 = crudReducer(state, action);
	const newState = getNewFunctionState(state1, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("functions", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export type IStateFunctions = IReduxStateInstance<IRFunction>;
