import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const AdminRolePermissionSchema = Joi.object({
	general: Joi.number().required(),
	individuals: Joi.object().pattern(/[\da-f]+/, Joi.number()),
});
export interface IAdminRolePermission {
	general: number;
	individuals?: Record<string, number | undefined>;
}

export const ResourceGroupsPermissionSchema = Joi.object({
	general: Joi.number().required(),
	dataGeneral: Joi.number().required(),
	individuals: Joi.object().pattern(
		/[\da-f]+/,
		Joi.object({
			permission: Joi.number(),
			dataPermission: Joi.number(),
			dataIndividualColumns: Joi.object().pattern(
				/[\da-f]+/,
				Joi.number()
			),
		})
	),
});
export type IResourceGroupsPermission = {
	general: number;
	dataGeneral: number;
} & {
	individuals?: Record<
		string,
		{
			permission?: number;
			dataPermission?: number;
			dataIndividualColumns?: Record<string, number | undefined>;
		}
	>;
};

export const RAdminRoleSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string().allow(""),
	projectPermission: Joi.number()
		.integer()
		.required(),
	tablePermissions: AdminRolePermissionSchema,
	columnPermissions: AdminRolePermissionSchema,
	apiCallsPermissions: AdminRolePermissionSchema,
	resourceGroupPermissions: ResourceGroupsPermissionSchema,
	functionPermissions: AdminRolePermissionSchema,
	author: Joi.number().required(),
	adminIds: Joi.array(),
});

export interface IRAdminRole {
	_id: ObjectId;
	projectId: ObjectId;
	name: string;
	description?: string;
	projectPermission: number;
	tablePermissions?: IAdminRolePermission;
	columnPermissions?: IAdminRolePermission;
	apiCallsPermissions?: IAdminRolePermission;
	resourceGroupPermissions?: IResourceGroupsPermission;
	functionPermissions?: IAdminRolePermission;
	adminIds?: number[];
	author: number;
}

export interface ICreateAdminRole {
	projectId: ObjectId;
	name: string;
	description?: string;
	projectPermission: number;
	tablePermissions?: IAdminRolePermission;
	columnPermissions?: IAdminRolePermission;
	apiCallsPermissions?: IAdminRolePermission;
	resourceGroupPermissions?: IResourceGroupsPermission;
	functionPermissions?: IAdminRolePermission;
}
