import React from "react";
import { IAPICallAction } from "@app/api/api-calls/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { CallRequestEdit } from "./call-request";
import { useDynamicRef, useValueChange, useFuncCall } from "@app/hooks/general";
import { CheckboxWithLabel } from "../widgets/input";

export const APIActionEdit: React.FC<{
	info: IAPICallAction;
	onChange: (info: IAPICallAction) => void;
	projectId: ObjectId;
}> = ({ info, onChange, projectId }) => {
	const infoRef = useDynamicRef(info);
	const handleValueChange = useValueChange(onChange, infoRef);
	const handleRequestChange = useFuncCall(
		handleValueChange,
		"request" as const
	);
	const handleMultipleTimesCallingChange = useFuncCall(
		handleValueChange,
		"canBeCalledMultipleTimes" as const
	);

	return (
		<div>
			<CallRequestEdit
				info={info.request}
				onChange={handleRequestChange}
				projectId={projectId}
			/>
			<CheckboxWithLabel
				value={!!info.canBeCalledMultipleTimes}
				onChange={handleMultipleTimesCallingChange}
				label="შეიძლება განხორციელდეს რამდენჯერმე"
			/>
		</div>
	);
};
