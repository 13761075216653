import Joi from "@app/utils/joi";
import {
	FilterOptionsSchemaWithoutDollar,
	SortOptionsSchema,
	ISortOptions,
	IFilterOptionsWithoutDollar,
} from "../resource-data/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import {
	Transformation,
	createTransformationSchema,
} from "../transformation-schemas";

export enum StatsTableColumnType {
	filter = "filter",
	transformation = "transformation",
}

const RStatsTableColumnCommonSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	isHidden: Joi.boolean(),
});

export interface IRStatsTableColumnCommon {
	_id: ObjectId;
	name: string;
	isHidden?: boolean;
}

const RStatsTableFilterColumnSchema = RStatsTableColumnCommonSchema.keys({
	type: Joi.string()
		.valid(StatsTableColumnType.filter)
		.default(StatsTableColumnType.filter),
	filterOptions: FilterOptionsSchemaWithoutDollar.required(),
});
export interface IRStatsTableFilterColumn extends IRStatsTableColumnCommon {
	type: StatsTableColumnType.filter;
	filterOptions: IFilterOptionsWithoutDollar;
}

const transformationSchema = createTransformationSchema(
	Joi.alternatives(
		Joi.object({ statColId: Joi.objectId().required() }),
		Joi.object({ rawNum: Joi.number().required() })
	),
	"transformationAA"
);

export type StatsColTransformationValue =
	| { statColId: ObjectId; rawNum?: undefined }
	| { rawNum: number; statColId?: undefined };

const RStatsTableTransformationColumnSchema = RStatsTableColumnCommonSchema.keys(
	{
		type: Joi.any()
			.valid(StatsTableColumnType.transformation)
			.required(),
		transformation: transformationSchema.required(),
	}
);

export interface IRStatsTableTransformationColumn
	extends IRStatsTableColumnCommon {
	type: StatsTableColumnType.transformation;
	transformation: Transformation<StatsColTransformationValue>;
}

export const RStatsTableColumnSchema = Joi.alternatives(
	RStatsTableFilterColumnSchema,
	RStatsTableTransformationColumnSchema
);

export type IRStatsTableColumn =
	| IRStatsTableFilterColumn
	| IRStatsTableTransformationColumn;

export enum StatsTableGroupBy {
	admins = "ADMIN",
	resourceGroups = "RESGROUP",
}

export const RStatsTableSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string().allow(""),
	statColumns: Joi.array()
		.items(RStatsTableColumnSchema)
		.required(),
	usedTableColumnIds: Joi.array()
		.items(Joi.objectId())
		.required(),
	globalFilter: FilterOptionsSchemaWithoutDollar,
	sortOptions: SortOptionsSchema,
	groupBy: Joi.string()
		.valid(StatsTableGroupBy.admins, StatsTableGroupBy.resourceGroups)
		.required(),
	author: Joi.number().required(),
});
export interface IRStatsTable {
	_id: ObjectId;
	projectId: ObjectId;
	description?: string;
	statColumns: IRStatsTableColumn[];
	globalFilter?: IFilterOptionsWithoutDollar;
	sortOptions?: ISortOptions;
	name: string;
	usedTableColumnIds: ObjectId[];
	groupBy: StatsTableGroupBy;
	author: number;
}
