import { Dispatch, Store } from "redux";
import {
	IReduxStateInstance,
	IRootActions,
	IRootState,
} from "@app/reducers/root-state";
import { ReduxApiService } from "@app/services/redux-api";
import { ObjectId } from "@app/utils/generics";
import {
	Instance,
	ISearchManyArgs,
	ISearchOneArgs,
	NormalizedReduxStateReducerNames,
	IDeleteOneArgs,
} from "./interfaces";

abstract class Model<F extends NormalizedReduxStateReducerNames> {
	protected store: Store<IRootState, IRootActions>;
	protected fieldName: F;
	protected dispatch: Dispatch<IRootActions>;
	protected getState: () => IRootState;
	protected supportsLoadingByIdsFromApi = false;
	protected apiDataHolder: ReduxApiService;

	constructor(fieldName: F, store: Store<IRootState, IRootActions>) {
		this.fieldName = fieldName;
		this.store = store;
		this.dispatch = store.dispatch;
		this.getState = store.getState;
		this.apiDataHolder = new ReduxApiService(fieldName);
	}

	async findById(
		info: ISearchOneArgs[F],
		instances?: IReduxStateInstance<Instance<F>>
	): Promise<Instance<F>> {
		const instanceInfo = this.findByIdSync(info, instances);
		if (instanceInfo) return instanceInfo;
		let promise = this.apiDataHolder.getPromise(info.id);
		if (promise) return promise;
		promise = this.loadOne(info);
		this.apiDataHolder.setPromise(info.id, promise);
		return promise;
	}

	findByIdSync(
		info: ISearchOneArgs[F],
		instances?: IReduxStateInstance<Instance<F>>
	): Instance<F> | undefined {
		if (!info.id) return undefined;
		instances = !instances
			? this.store.getState()[this.fieldName]
			: instances;
		const instance = instances[info.id];
		if (!instance) {
			let promise = this.apiDataHolder.getPromise(info.id);
			if (promise) return undefined;
			promise = this.loadOne(info);
			this.apiDataHolder.setPromise(info.id, promise);
			return undefined;
		}
		return instance.info;
	}

	async findManyByIds(
		infos: ISearchManyArgs[F],
		instances?: IReduxStateInstance<Instance<F>>
	): Promise<Array<Instance<F>>> {
		instances = !instances
			? this.store.getState()[this.fieldName]
			: instances;
		const objs: Array<Instance<F>> = [];
		const notFoundIds: ObjectId[] = [];
		for (let i = 0; i < infos.ids.length; ++i) {
			const id = infos.ids[i];
			if (instances[id]) {
				objs.push(instances[id]!.info);
			} else {
				notFoundIds.push(id);
			}
		}
		if (notFoundIds.length === 0 || !this.supportsLoadingByIdsFromApi)
			return objs;
		return this.loadManyByIds(infos);
	}

	findManyByIdsSync(
		infos: ISearchManyArgs[F],
		instances?: IReduxStateInstance<Instance<F>>
	): Array<Instance<F>> {
		instances = !instances
			? this.store.getState()[this.fieldName]
			: instances;
		const objs: Array<Instance<F>> = [];
		const notFoundIds: ObjectId[] = [];
		for (let i = 0; i < infos.ids.length; ++i) {
			const id = infos.ids[i];
			if (instances[id]) {
				objs.push(instances[id]!.info);
			} else {
				notFoundIds.push(id);
			}
		}
		return objs;
	}

	public abstract deleteOne(info: IDeleteOneArgs[F]): Promise<void>;
	protected abstract loadOne(info: ISearchOneArgs[F]): Promise<Instance<F>>;
	protected abstract loadManyByIds(
		infos: ISearchManyArgs[F]
	): Promise<Array<Instance<F>>>;
}

export { Model };
