import api from "@app/api";
import { dipatchLoginActions } from "./auth";
import {
	IAPUTLoginByAccessToken,
	IRPOSTLogin,
	IRPUTAccessTokens,
} from "@app/api/auth/validators";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IStateUser } from "@app/reducers/user";
import {
	LOAD_USER_INFO,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
	USERDATA_CHANGE,
} from "@app/action-types";
import { setUserForAnalytics } from "@app/utils/events";
import { ThunkAction } from "redux-thunk";

type IUpdateUserDataAction = ReturnType<typeof updateUserDataAction>;
export const updateUserDataAction = (userData: IRPOSTLogin["userData"]) => {
	setUserForAnalytics(userData);
	return {
		type: USERDATA_CHANGE as typeof USERDATA_CHANGE,
		userData,
	};
};

interface ILoginAction {
	type: typeof USER_AUTHORIZATION_SUCCESS;
	userData: IRPOSTLogin["userData"];
}

export const loginAction = (
	userData: IRPOSTLogin["userData"]
): ILoginAction => {
	setUserForAnalytics(userData);
	return {
		type: USER_AUTHORIZATION_SUCCESS,
		userData,
	};
};

export type ILoginByToken = (
	data: IAPUTLoginByAccessToken
) => Promise<IRPUTAccessTokens>;
// tslint:disable-next-line:max-line-length
export const loginByToken = (
	data: IAPUTLoginByAccessToken
): ThunkAction<ReturnType<ILoginByToken>, IRootState, null, IRootActions> => (
	dispatch
): ReturnType<ILoginByToken> => {
	return api.auth.loginByToken(data).then(res => {
		dipatchLoginActions(dispatch, res);
		return res;
	});
};

export interface ILogoutAction {
	type: typeof USER_LOG_OUT;
}
export const logout = (): ILogoutAction => {
	try {
		const credentials = JSON.parse(localStorage.credentials);
		api.auth
			.logout({
				userId: credentials.userId,
				refreshToken: credentials.refreshToken,
			})
			.then()
			.catch();
	} finally {
		localStorage.removeItem("credentials");
		localStorage.removeItem("user");
	}
	window.scroll(0, 0);
	return {
		type: USER_LOG_OUT,
	};
};

interface ILoadUserAction {
	type: typeof LOAD_USER_INFO;
	user: IStateUser;
}
export const loadUser = (user: IStateUser): ILoadUserAction => {
	return {
		type: LOAD_USER_INFO,
		user,
	};
};

export type UserActions =
	| ILoginAction
	| ILoadUserAction
	| ILogoutAction
	| IUpdateUserDataAction;
