import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export enum ResourceIdType {
	number = "NUM",
	string = "STR",
}

export function getResourceIdType(type: ResourceIdType): string {
	switch (type) {
		case ResourceIdType.number:
			return "number";
		case ResourceIdType.string:
			return "string";
	}
}

export const RProjectSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	resourceIdType: Joi.string()
		.valid(ResourceIdType.number, ResourceIdType.string)
		.required(),
	description: Joi.string().allow(""),
	author: Joi.number()
		.integer()
		.required(),
});
export interface IRProject {
	_id: ObjectId;
	name: string;
	resourceIdType: ResourceIdType;
	author: number;
	description?: string | undefined;
}
