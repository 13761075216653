import * as React from "react";
import * as ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import handleActionOfOtherTab from "@app/handle-action-of-other-tab";
import reduxThunk, { ThunkMiddleware } from "redux-thunk";
import rootReducer from "@app/root-reducer";
import ZPL from "@app/app";
import { _console } from "@app/commonJavascript";
import { applyMiddleware, createStore } from "redux";
import { BrowserRouter, Route } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import { createModules } from "@app/modules";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { GlobalHistory } from "@app/global_history";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { loadUser } from "@app/actions/user";
import { Provider } from "react-redux";
import "@app/components/styles/commonStyles.min.css";
import "polyfills";

const store = createStore(
	rootReducer,
	composeWithDevTools(
		applyMiddleware(reduxThunk as ThunkMiddleware<IRootState, IRootActions>)
	)
);
createModules(store);
export { store };

window.addEventListener("storage", (e: StorageEvent) => {
	handleActionOfOtherTab(e, store.dispatch);
});

if (localStorage.user) {
	try {
		const user = JSON.parse(localStorage.user);
		store.dispatch(loadUser(user));
	} catch (e) {
		_console.error(e);
	}
}

// ReactGA.initialize("UA-133455344-1");

(async () => {
	const theme = createMuiTheme({
		palette: {
			primary: {
				light: "#63bed9",
				main: "#278ea7",
				dark: "#023e5a",
			},
			secondary: {
				light: "#ffd64a",
				main: "#ffa500",
				dark: "#c67600",
			},
		},
		typography: {
			useNextVariants: true,
			fontFamily: "BPGNinoMtavruli",
		} as any,
		overrides: {
			MuiButton: {
				root: {
					textTransform: "initial",
				},
			},
		},
	});
	ReactDOM.render(
		<MuiThemeProvider theme={theme}>
			<BrowserRouter>
				<>
					<GlobalHistory />
					<Provider store={store}>
						<>
							<Route component={ZPL} />
						</>
					</Provider>
				</>
			</BrowserRouter>
		</MuiThemeProvider>,
		document.getElementById("root")
	);
	serviceWorker.unregister();
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
