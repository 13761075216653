import api from "@app/api";
import {
	addAPICall,
	apiCallCRUDActions,
	deleteAPICall,
	loadAPICallById,
	updateAPICall,
} from "@app/actions/api-calls";
import {
	IAGETManyAPICalls,
	IAPOSTCreateAPICall,
	IAPUTAPICall,
	IRPOSTCreateAPICall,
} from "@app/api/api-calls/validators";
import { IDeleteOneArgs, ISearchManyArgs, ISearchOneArgs } from "./interfaces";
import { IRAPICall } from "@app/api/api-calls/helper-schemas";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { Model } from ".";
import { Store } from "redux";
import { User } from "@app/user";

type MyInstance = IRAPICall;

class APICallModel extends Model<"apiCalls"> {
	constructor(store: Store<IRootState, IRootActions>) {
		super("apiCalls", store);
		this.supportsLoadingByIdsFromApi = false;
	}

	getReadableAPICalls(args: IAGETManyAPICalls): Promise<MyInstance[]> {
		return api.apiCalls.getReadableAPICalls(args).then(apiCalls => {
			this.dispatch(apiCallCRUDActions.loadMany(apiCalls));
			return apiCalls;
		});
	}

	getReadableAPICallsSync(args: IAGETManyAPICalls, user: User): MyInstance[] {
		const instances = this.getState().apiCalls;
		const colIds = Object.keys(instances);
		const apiCalls: MyInstance[] = [];
		for (let i = 0; i < colIds.length; ++i) {
			const column = instances[colIds[i]]!.info;
			if (column.projectId !== args.projectId) continue;
			if (user.canReadAPICall(column._id, column.projectId))
				apiCalls.push(column);
		}
		return apiCalls.sort((a, b) => {
			return a._id < b._id ? -1 : 1;
		});
	}

	async create(column: IAPOSTCreateAPICall): Promise<IRPOSTCreateAPICall> {
		return addAPICall(column)(this.dispatch, this.getState, null);
	}
	async update(column: IAPUTAPICall): Promise<void> {
		return updateAPICall(column)(this.dispatch, this.getState, null);
	}

	public async deleteOne(info: IDeleteOneArgs["apiCalls"]): Promise<void> {
		return deleteAPICall({ APICallId: info.id, projectId: info.projectId })(
			this.dispatch,
			this.getState,
			null
		);
	}

	protected async loadOne(
		info: ISearchOneArgs["apiCalls"]
	): Promise<MyInstance> {
		return loadAPICallById({
			APICallId: info.id,
			projectId: info.projectId,
		})(this.dispatch, this.getState, null);
	}

	protected async loadManyByIds(
		infos: ISearchManyArgs["apiCalls"]
	): Promise<MyInstance[]> {
		return [];
	}
}

export { APICallModel };
