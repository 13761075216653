import "core-js/fn/set";
import "core-js/fn/map";
import "core-js/fn/promise";
import "core-js/fn/symbol";
import "core-js/fn/array/from";
import "core-js/fn/array/find-index";
import "core-js/fn/array/find";
import "core-js/fn/string/virtual/starts-with";
import "core-js/es6/number";

Number.isInteger =
	Number.isInteger ||
	(value =>
		typeof value === "number" &&
		Number.isFinite(value) &&
		Math.floor(value) === value);
