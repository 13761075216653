import { IRUser } from "@app/api/users/helper-schemas";
import {
	LOAD_USER_INFO,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
	USERDATA_CHANGE,
} from "@app/action-types";
import { UserActions } from "@app/actions/user";

function getNewUserState(
	state: IStateUser = {},
	action: UserActions
): IStateUser {
	switch (action.type) {
		case USER_AUTHORIZATION_SUCCESS:
		case USERDATA_CHANGE:
			return {
				...state,
				loggedIn: true,
				userId: action.userData.id,
				userData: action.userData,
			};

		case LOAD_USER_INFO:
			return {
				...state,
				...action.user,
			};
		case USER_LOG_OUT:
			return {};
		default:
			return state;
	}
}

export default function user(state = {}, action: UserActions): IStateUser {
	const newState = getNewUserState(state, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("user", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export interface IStateUser {
	loggedIn?: boolean;
	userId?: number;
	userData?: IRUser;
}
