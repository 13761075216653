import * as React from "react";
import api from "@app/api";
import Loading from "@app/components/widgets/loading";
import { History } from "history";
import { inject } from "@app/modules";
import { IRGETStatistics } from "@app/api/stats-tables/validators";
import {
	IRStatsTable,
	StatsTableGroupBy,
	IRStatsTableColumn,
	StatsTableColumnType,
	StatsColTransformationValue,
} from "@app/api/stats-tables/helper-schemas";
import { IRTable } from "@app/api/tables/helper-schemas";
import { match } from "react-router";
import { ObjectId, NotUndefined } from "@app/utils/generics";
import { tableCSS } from "../styles";
import { User } from "@app/user";
import Button from "@material-ui/core/Button";
import {
	getTimezoneOffsetInMinutes,
	arrayToObject,
	removeKeys,
	stateValueChange,
} from "@app/utils/common";
import { TranformationType } from "@app/api/transformation-schemas";
import { roundNumberDecimalPlaces } from "@app/commonJavascript";
import { ContextMenuTrigger } from "react-contextmenu";
import { MyMenu, MenuAction } from "../right-click-context";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
	ISortOptions,
	IFilterOptionsWithoutDollar,
	IFilterOptionWithoutDollar,
	IFilterOption,
} from "@app/api/resource-data/helper-schemas";
import { useSetProps, SetState } from "@app/hooks/state-management";
import { IRColumn } from "@app/api/columns/helper-schemas";
import { useBoolean } from "@app/hooks/general";
import { withDollarToWithout, withoutDollarToWith } from "@app/utils/filter";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { FilterOptionsComponent } from "../filter";
import { transposeOfArray, sumArr } from "@app/utils/array";

interface IProps {
	history: History;
	match: match<{ projectId: string; id: string }>;
	user: User;
}

interface IState {
	loadingInitialRequests: boolean;
	statsTables?: IRStatsTable[];
	table?: IRTable;
	columns?: IRColumn[];
	areAllTablesLoaded: boolean;
	statsGroups: {
		[statsTableId: string]: IRGETStatistics["groups"] | undefined;
	};
	viewMode: "normal" | "reverse";
}

class TableStatsPage extends React.PureComponent<IProps, IState> {
	state: IState = {
		loadingInitialRequests: true,
		areAllTablesLoaded: false,
		viewMode: "normal",
		statsGroups: {},
	};

	projectId = this.props.match.params.projectId;
	tableId = this.props.match.params.id;
	pathname = this.props.history.location.pathname;

	_isMounted = false;

	_Table = inject("Table");
	_Column = inject("Column");
	_Project = inject("Project");
	_APICall = inject("APICall");
	_StatsTable = inject("StatsTable");

	statTableRefs: React.RefObject<StatsTable>[] = [];

	componentDidMount() {
		this._isMounted = true;
		this._Column
			.getReadableColumns({ projectId: this.projectId })
			.then(columns => {
				this.setState({ columns });
			});
		this._Project.findById({ id: this.projectId }).then();
		this._Table
			.findById({ id: this.tableId, projectId: this.projectId })
			.then(table => {
				if (table.statsTableOrder) {
					for (const _ of table.statsTableOrder) {
						this.statTableRefs.push(React.createRef());
					}
					this.checkIfEverythingIsLoaded();
				}
				this.setState({ table });
			});
		this._StatsTable
			.getReadableStatsTables({ projectId: this.projectId })
			.then(data => {
				this.setState({
					loadingInitialRequests: false,
					statsTables: data,
				});
			});
	}

	checkIfEverythingIsLoaded = () => {
		const c = setInterval(() => {
			if (
				this.state.loadingInitialRequests ||
				!this.state.statsTables ||
				!this.state.statsTables ||
				!this.state.columns
			)
				return;
			if (!this.state.statsTables) return;
			for (const statsTableRef of this.statTableRefs) {
				if (statsTableRef.current === null) continue;
				if (!statsTableRef.current.state.groups) return;
			}
			this.setState({
				areAllTablesLoaded: true,
			});
			clearInterval(c);
		}, 100);
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	onGroupLoad = (
		statsTableId: ObjectId,
		groups: IRGETStatistics["groups"]
	) => {
		this.setState(({ statsGroups }) => {
			return { statsGroups: { ...statsGroups, [statsTableId]: groups } };
		});
	};

	switchView = () => {
		this.setState({
			viewMode: this.state.viewMode === "reverse" ? "normal" : "reverse",
		});
	};

	render() {
		const table = this.state.table;
		if (
			this.state.loadingInitialRequests ||
			!table ||
			!this.state.statsTables
		) {
			return <Loading />;
		}
		if (!table.statsTableOrder) {
			return (
				<div className="main main2">
					<div>ცარიელია</div>
				</div>
			);
		}
		return (
			<>
				<div className="main main2">
					<br />
					{this.state.areAllTablesLoaded ? (
						<Button
							variant="contained"
							color="primary"
							onClick={this.switchView}
						>
							შეტრიალება
						</Button>
					) : (
						<div>იტვირთება...</div>
					)}
					{this.state.viewMode === "normal" ? (
						table.statsTableOrder.map(
							({ id: statsTableId, customName }, index) => {
								const statTable = this.state.statsTables!.find(
									e => e._id === statsTableId
								);
								if (!statTable || !this.state.columns)
									return null;
								return (
									<StatsTable
										key={statTable._id}
										statsTable={statTable}
										customName={customName}
										tableId={this.tableId}
										ref={this.statTableRefs[index]}
										onGroupLoad={this.onGroupLoad}
										groups={
											this.state.statsGroups[
												statTable._id
											]
										}
										columns={this.state.columns}
									/>
								);
							}
						)
					) : (
						<ReversedTables
							statsTableOrder={table.statsTableOrder}
							statsTables={this.state.statsTables}
							allGroups={this.state.statsGroups}
						/>
					)}
					<br />
					<br />
					<br />
				</div>
			</>
		);
	}
}

interface IStatsTableProps {
	statsTable: IRStatsTable;
	columns: IRColumn[];
	customName?: string;
	tableId: ObjectId;
	groups?: IRGETStatistics["groups"];
	onGroupLoad: (
		statsTableId: ObjectId,
		groups: IRGETStatistics["groups"]
	) => void;
}

interface IStatsTableState {
	groups?: IRGETStatistics["groups"];
	sortOptions?: ISortOptions;
	globalFilter?: IRStatsTable["globalFilter"];
}

class StatsTable extends React.PureComponent<
	IStatsTableProps,
	IStatsTableState
> {
	_isMounted = false;

	state: IStatsTableState = {
		globalFilter: this.props.statsTable.globalFilter,
	};

	getGroupByColName = (groupBy: StatsTableGroupBy) => {
		if (groupBy === StatsTableGroupBy.admins) return "ადმინი";
		return "მომხმარებელთა ჯგუფი";
	};

	componentDidMount() {
		this._isMounted = true;
		if (this.props.groups) {
			this.setState({
				groups: this.props.groups,
			});
		} else {
			this.fetchStatsTable();
		}
	}

	fetchStatsTable = () => {
		this.setState({
			groups: undefined,
		});
		return api.statsTables
			.getStatistics({
				projectId: this.props.statsTable.projectId,
				statsTableId: this.props.statsTable._id,
				tableId: this.props.tableId,
				ignoreDateTimes: true,
				timeZoneOffsetInMinutes: getTimezoneOffsetInMinutes(),
				globalFilter: this.state.globalFilter,
			})
			.then(data => {
				if (!this._isMounted) return;
				return new Promise((resolve, reject) => {
					this.setState(
						{
							groups: data.groups,
						},
						resolve
					);
					this.props.onGroupLoad(
						this.props.statsTable._id,
						data.groups
					);
				});
			});
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	onMenuItemSelect = (
		e,
		data: { action: MenuAction },
		target: HTMLElement
	) => {
		const colId = target.title;
		if (data.action === MenuAction.sortASC) {
			const sortOptions: ISortOptions = { colId, type: "ASC" };
			this.setState({ sortOptions });
			this.sortGroups(sortOptions);
		}
		if (data.action === MenuAction.sortDESC) {
			const sortOptions: ISortOptions = { colId, type: "DESC" };
			this.setState({ sortOptions });
			this.sortGroups(sortOptions);
		}
	};

	sortGroups = (sort: ISortOptions) => {
		const colIndex = this.props.statsTable.statColumns.findIndex(
			e => e._id === sort.colId
		);
		if (colIndex === -1 || !this.state.groups) return;
		const coef = sort.type === "ASC" ? 1 : -1;
		const newGroups = [...this.state.groups].sort((group1, group2) => {
			const data1 = group1.data[colIndex];
			const data2 = group2.data[colIndex];
			if (typeof data1 !== typeof data2) {
				return typeof data1 !== "undefined" ? -coef : coef;
			}
			return coef * (data1 - data2);
		});
		this.setState({ groups: newGroups });
	};

	onGlobalFilterChange: SetState<IFilterOptionsWithoutDollar> = vl => {
		const fn = typeof vl !== "function" ? oldval => vl : vl;
		this.setState(
			({ globalFilter }) => {
				return {
					globalFilter: fn(
						globalFilter || this.props.statsTable.globalFilter || {}
					),
				};
			},
			() =>
				this.fetchStatsTable().then(() => {
					if (this.state.sortOptions) {
						this.sortGroups(this.state.sortOptions);
					}
				})
		);
	};

	render() {
		const { statsTable } = this.props;
		const sortOptions = this.state.sortOptions || statsTable.sortOptions;
		const globalFilter =
			this.state.globalFilter || this.props.statsTable.globalFilter;
		return (
			<div>
				<h2>
					{this.props.customName === undefined
						? statsTable.name
						: this.props.customName}
				</h2>
				{this.state.groups &&
					globalFilter &&
					Object.keys(globalFilter).length > 0 && (
						<GlobalFilter
							value={globalFilter}
							columns={this.props.columns}
							onChange={this.onGlobalFilterChange}
						/>
					)}
				{!this.state.groups ? (
					<Loading />
				) : (
					<table
						className={tableCSS}
						style={{ tableLayout: "fixed" }}
					>
						<thead>
							<tr>
								<th>
									{this.getGroupByColName(statsTable.groupBy)}
								</th>
								{statsTable.statColumns.map(col => {
									return (
										<Column
											key={col._id}
											col={col}
											sortDirection={
												sortOptions?.colId === col._id
													? sortOptions?.type
													: undefined
											}
										/>
									);
								})}
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>უხეში ჯამი</td>
								{transposeOfArray(
									this.state.groups.map(group => group.data)
								)
									.map((colData, index): number => {
										const statColumn =
											statsTable.statColumns[index];
										if (!statColumn) return 0;
										if (statColumn.isHidden) return 0;
										const datum = sumArr(colData);
										return datum;
									})
									.map((datum, index, data) => {
										const statColumn =
											statsTable.statColumns[index];
										if (!statColumn) return null;
										if (statColumn.isHidden) return null;
										const service = inject(
											"TransformationService"
										);
										if (
											statColumn.type ===
											StatsTableColumnType.transformation
										) {
											datum = service.calculate(
												statColumn.transformation,
												(
													val: StatsColTransformationValue
												) => {
													if (
														val.statColId !==
														undefined
													) {
														const statColIndex = statsTable.statColumns.findIndex(
															e =>
																e._id ===
																val.statColId
														);
														if (
															statColIndex ===
																-1 ||
															statColIndex >=
																data.length
														)
															return 0;
														return data[
															statColIndex
														];
													}
													if (
														val.rawNum !== undefined
													) {
														return val.rawNum;
													}
													return 0;
												}
											);
										}
										return (
											<td key={index}>
												{transformDatum(
													datum,
													statColumn
												)}
											</td>
										);
									})}
							</tr>
							{this.state.groups.map(group => (
								<tr key={group.id}>
									<td>{group.name}</td>
									{group.data.map((datum, index) => {
										const statCol =
											statsTable.statColumns[index];
										if (statCol.isHidden) return null;
										return (
											<td key={index}>
												{transformDatum(datum, statCol)}
											</td>
										);
									})}
								</tr>
							))}
						</tbody>
					</table>
				)}
				<MyMenu onSelect={this.onMenuItemSelect} hideFilter={true} />
			</div>
		);
	}
}

const GlobalFilter: React.FC<{
	value: IFilterOptionsWithoutDollar;
	onChange: SetState<IFilterOptionsWithoutDollar>;
	columns: IRColumn[];
}> = React.memo(({ value, columns, onChange }) => {
	const filteredColumnIds = Object.keys(value);
	const setProps = useSetProps(onChange);
	return (
		<div>
			{filteredColumnIds.map(colId => {
				const filter = value[colId];
				if (!filter) return null;
				const column = columns.find(e => e._id === colId);
				if (!column) return null;
				return (
					<ColumnFilter
						key={colId}
						column={column}
						filter={filter}
						onChange={setProps(colId)}
					/>
				);
			})}
		</div>
	);
});

const ColumnFilter: React.FC<{
	column: IRColumn;
	filter: IFilterOptionWithoutDollar;
	onChange: SetState<IFilterOptionWithoutDollar>;
}> = React.memo(({ column, filter, onChange }) => {
	const {
		value: isPopupOpen,
		setTrue: openPopup,
		setFalse: closePopup,
	} = useBoolean();
	const onConfirm = (colId: string, filterOption: IFilterOption) => {
		onChange(withDollarToWithout(filterOption));
		closePopup();
	};
	return (
		<div>
			{column.name}
			<Button onClick={openPopup}>ფილტრი</Button>
			{isPopupOpen && (
				<Popup>
					<PopupContentWithClose onClose={closePopup}>
						<FilterOptionsComponent
							key={column._id}
							column={column}
							currentOpton={withoutDollarToWith(filter)}
							showRemovingOtherFiltersOption={false}
							onConfirm={onConfirm}
						/>
					</PopupContentWithClose>
				</Popup>
			)}
		</div>
	);
});

const Column = React.memo(
	({
		col,
		sortDirection,
	}: {
		col: IRStatsTableColumn;
		sortDirection?: ISortOptions["type"];
	}) => {
		if (col.isHidden) return null;
		return (
			<th key={col._id}>
				<ContextMenuTrigger
					key={col._id}
					id="multi"
					attributes={{ title: col._id }}
					collect={collect}
				>
					{col.name}
					{sortDirection && sortDirection === "ASC" && (
						<ArrowDropUpIcon />
					)}
					{sortDirection && sortDirection === "DESC" && (
						<ArrowDropDownIcon />
					)}
				</ContextMenuTrigger>
			</th>
		);
	}
);

function collect(props) {
	return props;
}

const transformDatum = (
	datum: number,
	statCol: IRStatsTableColumn
): number | string => {
	if (statCol.type === StatsTableColumnType.transformation) {
		if (statCol.transformation.type === TranformationType.percentage) {
			return roundNumberDecimalPlaces(datum, 1) + "%";
		}
	}
	return datum;
};

interface IReversedTablesProps {
	statsTables: IRStatsTable[];
	allGroups: {
		[statsTableId: string]: IRGETStatistics["groups"] | undefined;
	};
	statsTableOrder: NotUndefined<IRTable["statsTableOrder"]>;
}

interface IReversedTablesState {
	uniqueGroupsInfo?: {
		[id: string]:
			| {
					[statsColName: string]:
						| {
								[statsTableId: string]: number | undefined;
						  }
						| undefined;
			  }
			| undefined;
	};
}

class ReversedTables extends React.PureComponent<
	IReversedTablesProps,
	IReversedTablesState
> {
	state: IReversedTablesState = {};

	groupIdToName: { [groupId: string]: string } = {};
	groupIdToStatsTableNames: {
		[groupId: string]: { statsTableId: ObjectId; name: string }[];
	} = {};

	componentDidMount() {
		const uniqueGroupsInfo: NotUndefined<IReversedTablesState["uniqueGroupsInfo"]> = {};
		for (const c of this.props.statsTableOrder) {
			const statsTableId = c.id;
			const groups = this.props.allGroups[statsTableId];
			const statTable = this.props.statsTables.find(
				e => e._id === statsTableId
			);
			if (!statTable) continue;
			const statTableName = c.customName || statTable.name;
			if (!groups) continue;
			for (const group of groups) {
				this.groupIdToName[group.id] = group.name;
				if (!this.groupIdToStatsTableNames[group.id]) {
					this.groupIdToStatsTableNames[group.id] = [];
				}
				this.groupIdToStatsTableNames[group.id].push({
					statsTableId,
					name: statTableName,
				});
				if (!uniqueGroupsInfo[group.id as string]) {
					uniqueGroupsInfo[group.id as string] = {};
				}
				for (let i = 0; i < group.data.length; ++i) {
					const datum = group.data[i];
					const statsColName = statTable.statColumns[i].name;
					if (!uniqueGroupsInfo[group.id as string]![statsColName]) {
						uniqueGroupsInfo[group.id as string]![
							statsColName
						] = {};
					}
					uniqueGroupsInfo[group.id as string]![statsColName]![
						statsTableId
					] = datum;
				}
			}
		}
		this.setState({
			uniqueGroupsInfo,
		});
	}

	render() {
		const uniqueGroupsInfo = this.state.uniqueGroupsInfo;
		if (!uniqueGroupsInfo) return null;
		const groupIds = Object.keys(uniqueGroupsInfo);
		const statTablesByIds = arrayToObject(this.props.statsTables, "_id");
		return (
			<div>
				{groupIds.map((groupId, index) => {
					const groupName = this.groupIdToName[groupId];
					const statsTableOrders = this.groupIdToStatsTableNames[
						groupId
					];
					const colNames = Object.keys(uniqueGroupsInfo[groupId]!);
					return (
						<div key={groupId}>
							<h2>{groupName}</h2>
							<table className={tableCSS}>
								<thead>
									<tr>
										<th>ჯგ</th>
										{statsTableOrders.map(e => (
											<th key={e.statsTableId}>
												{e.name}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{colNames.map(colName => {
										return (
											<tr key={colName}>
												<td>{colName}</td>
												{statsTableOrders.map(
													({
														statsTableId,
														name,
													}) => {
														const statTable =
															statTablesByIds[
																statsTableId
															];
														const uInfo = uniqueGroupsInfo[
															groupId
														]![colName];
														const statCol = statTable?.statColumns.find(
															e =>
																e.name ===
																colName
														);
														if (
															!statTable ||
															!uInfo ||
															!statCol
														)
															return (
																<td
																	key={
																		statsTableId
																	}
																/>
															);
														const datum =
															uInfo[
																statsTableId
															] || 0;
														return (
															<td
																key={
																	statsTableId
																}
															>
																{transformDatum(
																	datum,
																	statCol
																)}
															</td>
														);
													}
												)}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					);
				})}
			</div>
		);
	}
}

export { TableStatsPage };
