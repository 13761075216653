export function getIntersectionOfSets<T extends string | number>(
	set1: Set<T>,
	set2: Set<T>
) {
	const intersect = new Set<T>();
	for (const x of set1) {
		if (set2.has(x)) intersect.add(x);
	}
	return intersect;
}

export function intersectWith<T extends string | number>(
	this: Set<T>,
	set2: Set<T>
) {
	for (const x of this) {
		if (!set2.has(x)) this.delete(x);
	}
	return this;
}

export function getUnionOfSets<T extends string | number>(
	set1: Iterable<T>,
	set2: Iterable<T>
) {
	const union = new Set<T>(set1);
	for (const x of set2) {
		union.add(x);
	}
	return union;
}

export function unionWith<T extends string | number>(
	this: Set<T>,
	set2: Iterable<T>
) {
	for (const x of set2) {
		this.add(x);
	}
	return this;
}

export function getSubtractRightSetFromLeftSet<T extends string | number>(
	set1: Iterable<T>,
	set2: Iterable<T>
) {
	const resSet = new Set(set1);
	for (const x of set2) {
		resSet.delete(x);
	}
	return resSet;
}

export function subtractSet<T extends string | number>(
	this: Set<T>,
	set2: Iterable<T>
) {
	for (const x of set2) {
		this.delete(x);
	}
	return this;
}
