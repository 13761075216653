import { ApiCallType } from "@app/api/api-calls/helper-schemas";
import {
	ColumnType,
	IColumnData,
	IColumnInfo,
	IDateColumnInfo,
	IRColumn,
} from "@app/api/columns/helper-schemas";
import { ResourceIdType } from "@app/api/projects/helper-schemas";
import {
	FilterType,
	IFilterOption,
} from "@app/api/resource-data/helper-schemas";
import { IRGETManyResourcesData } from "@app/api/resource-data/validators";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import memoizeOne from "memoize-one";
import * as React from "react";
import Loading from "../widgets/loading";
import { RadioNavigation } from "../widgets/radio-navigation";
import { ChangeContainer } from "./data/edit";
import { getApiInfoColumnInfoSync, getEditComponent } from "./utils";

interface IProps {
	column: IRGETManyResourcesData["cols"][number];
	currentOpton?: IFilterOption;
	onConfirm: (
		colId: ObjectId,
		filterOption: IFilterOption,
		clearOtherFilters: boolean
	) => void;
	showRemovingOtherFiltersOption: boolean;
}

interface IState {
	filterOption?: IFilterOption;
	clearOtherFilters: boolean;
}

function objToFilterType(filterOption: IFilterOption): FilterType | null {
	if (filterOption.$eq !== undefined) {
		return FilterType.equal;
	}
	if (filterOption.$ne !== undefined) {
		return FilterType.notEqual;
	}
	if (filterOption.$gt !== undefined) {
		return FilterType.greaterThan;
	}
	if (filterOption.$gte !== undefined) {
		return FilterType.greaterThanOrEqual;
	}
	if (filterOption.$lt !== undefined) {
		return FilterType.lessThan;
	}
	if (filterOption.$lte !== undefined) {
		return FilterType.lessThanOrEqual;
	}
	return null;
}

export class FilterOptionsComponent extends React.PureComponent<
	IProps,
	IState
> {
	state: IState = {
		filterOption: this.props.currentOpton || {},
		clearOtherFilters: false,
	};

	_Project = inject("Project");
	_APICalls = inject("APICall");

	getOptions = memoizeOne((colInfo: IColumnInfo) => {
		if (colInfo.type === ColumnType.api) {
			const apiCall = this._APICalls.findByIdSync({
				id: colInfo.apiId,
				projectId: this.props.column.projectId,
			});
			if (apiCall && apiCall.info.type === ApiCallType.action) {
				const dateInfo: IDateColumnInfo = {
					type: ColumnType.date,
				};
				return this.getOptions(dateInfo);
			}
			const apiResponseSchema = getApiInfoColumnInfoSync(
				colInfo,
				this.props.column.projectId
			);
			if (!apiResponseSchema) return [];
			colInfo = apiResponseSchema;
		}
		const options = [
			{ value: FilterType.equal, label: "ტოლია" },
			{ value: FilterType.notEqual, label: "არაა ტოლი" },
		];
		if (
			colInfo.type === ColumnType.number ||
			colInfo.type === ColumnType.date
		) {
			options.push(
				{ value: FilterType.greaterThan, label: "მეტია" },
				{
					value: FilterType.greaterThanOrEqual,
					label: "მეტია ან ტოლია",
				},
				{ value: FilterType.lessThan, label: "ნაკლებია" },
				{
					value: FilterType.lessThanOrEqual,
					label: "ნაკლებია ან ტოლია",
				}
			);
		}
		return options;
	});

	getSelection = memoizeOne(
		(
			options: { value: FilterType; label: string }[],
			filterOption: IState["filterOption"]
		) => {
			if (!filterOption)
				return options.find(e => e.value === FilterType.equal);
			const chosenValue = objToFilterType(filterOption);
			return options.find(e => e.value === chosenValue);
		}
	);

	onSelectionChange = (sel: { value: FilterType; label: string }) => {
		let val: IColumnData = null;
		for (const key in this.state.filterOption) {
			if (this.state.filterOption[key] !== undefined) {
				val = this.state.filterOption[key];
			}
		}
		this.setState({
			filterOption: {
				[sel.value]: val,
			},
		});
	};

	onConfirm = () => {
		if (!this.state.filterOption) return;
		this.props.onConfirm(
			this.props.column._id,
			this.state.filterOption,
			this.state.clearOtherFilters
		);
	};

	onInputValueChange = (data: IColumnData, callParentDirectly = false) => {
		const { filterOption } = this.state;
		if (!filterOption) return;
		const chosenValue = objToFilterType(filterOption);
		if (!chosenValue) return;
		this.setState(
			{
				filterOption: {
					[chosenValue]: data,
				},
			},
			() => {
				if (callParentDirectly) {
					this.onConfirm();
				}
			}
		);
	};

	onDataClean = () => {
		this.onInputValueChange(null, true);
	};

	onClearingOtherFiltersChange = (checked: boolean) => {
		this.setState({
			clearOtherFilters: checked,
		});
	};

	editComponentProps = { onChange: this.onInputValueChange };

	componentDidMount() {
		const conInfo = this.getNormalizedColInfo(this.props.column);
		const options = this.getOptions(conInfo);
		const selectedValue = this.getSelection(
			options,
			this.state.filterOption
		);

		if (!selectedValue && options.length > 0) {
			this.onSelectionChange(options[0]);
		}
	}

	getNormalizedColInfo = (column: IRColumn): IRColumn["info"] => {
		if (column.info.type === ColumnType.resourceId) {
			// support editing resourceId
			const project = this._Project.findByIdSync({
				id: column.projectId,
			});
			if (project && project.resourceIdType === ResourceIdType.number) {
				return { type: ColumnType.number };
			} else if (
				project &&
				project.resourceIdType === ResourceIdType.string
			) {
				return { type: ColumnType.string };
			}
		}
		return column.info;
	};

	render() {
		const { column } = this.props;
		if (!column) return <Loading />;
		if (column.info.type === ColumnType.colReference) {
			return (
				<div>ჯერ არ გვაქვს ამ ტიპის მონაცემის გაფილტვრის საშუალება</div>
			);
		}
		const conInfo = this.getNormalizedColInfo(column);

		const options = this.getOptions(conInfo);
		const selectedValue = this.getSelection(
			options,
			this.state.filterOption
		);
		const editComponent =
			!this.state.filterOption || !selectedValue
				? null
				: getEditComponent(
						this.props.column.projectId,
						conInfo,
						this.state.filterOption[selectedValue.value],
						this.editComponentProps,
						true
				  );
		return (
			<div>
				{this.props.showRemovingOtherFiltersOption && (
					<>
						<FormControlLabel
							control={
								<Checkbox
									checked={!!this.state.clearOtherFilters}
									onChange={(e, checked) =>
										this.onClearingOtherFiltersChange(
											checked
										)
									}
								/>
							}
							label="სხვა ფილტრების მოშორება"
						/>
						<br />
					</>
				)}
				აირჩიეთ შედარების ხერხი: <br />
				<RadioNavigation
					options={options}
					value={selectedValue}
					onChange={this.onSelectionChange}
				/>
				{this.state.filterOption && selectedValue && (
					<>
						{editComponent && (
							<ChangeContainer
								onClean={this.onDataClean}
								onConfirm={this.onConfirm}
							>
								{editComponent}
							</ChangeContainer>
						)}
					</>
				)}
			</div>
		);
	}
}
