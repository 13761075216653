import React, { useCallback, useMemo } from "react";
import {
	IAPIInfo,
	IAPICallRequest,
	HTTPMethod,
} from "@app/api/api-calls/helper-schemas";
import { useDynamicRef, useValueChange, useFuncCall } from "@app/hooks/general";
import { RadioNavigation } from "../widgets/radio-navigation";
import { removeKeys } from "@app/utils/common";
import { CallRequestEdit } from "./call-request";
import { ObjectId } from "@app/utils/generics";
import { ResponseSchemaEdit } from "./response-schema";

enum GetType {
	one,
	many,
}

const getDefaultRequest = (): IAPICallRequest => {
	return {
		url: "",
		method: HTTPMethod.POST,
	};
};

export const APIInfoEdit: React.FC<{
	info: IAPIInfo;
	onChange: (info: IAPIInfo) => void;
	projectId: ObjectId;
}> = ({ info, onChange, projectId }) => {
	const infoRef = useDynamicRef(info);
	const handleValueChange = useValueChange(onChange, infoRef);
	const handleResponseChange = useFuncCall(
		handleValueChange,
		"responseSchema" as const
	);
	const handleGetOneChange = useFuncCall(
		handleValueChange,
		"getOne" as const
	);
	const handleGetManyChange = useFuncCall(
		handleValueChange,
		"getMany" as const
	);

	const onRequestTypeChange = useCallback(
		(type: GetType) => {
			if (type === GetType.one && infoRef.current.getMany) {
				onChange({
					...removeKeys(infoRef.current, "getMany"),
					getOne: infoRef.current.getMany,
				});
			} else if (type === GetType.many && infoRef.current.getOne) {
				onChange({
					...removeKeys(infoRef.current, "getOne"),
					getMany: infoRef.current.getOne,
				});
			} else {
				onChange({
					...infoRef.current,
					[type === GetType.one
						? "getOne"
						: "getMany"]: getDefaultRequest(),
				});
			}
		},
		[infoRef, onChange]
	);

	return (
		<div>
			<GetTypeSwitcher
				hasGetOne={!!info.getOne}
				hasGetMany={!!info.getMany}
				onChange={onRequestTypeChange}
			/>
			{(info.getOne || info.getMany) && (
				<CallRequestEdit
					info={info.getOne ? info.getOne : info.getMany!}
					onChange={
						info.getOne ? handleGetOneChange : handleGetManyChange
					}
					projectId={projectId}
				/>
			)}
			<div style={{ padding: 10 }}>
				<h2>Response</h2>
				<ResponseSchemaEdit
					info={info.responseSchema}
					projectId={projectId}
					onChange={handleResponseChange}
				/>
			</div>
		</div>
	);
};

const GetTypeSwitcher: React.FC<{
	hasGetOne: boolean;
	hasGetMany: boolean;
	onChange: (type: GetType) => void;
}> = React.memo(({ onChange, hasGetOne, hasGetMany }) => {
	const options = useMemo(
		(): { value: GetType; label: string }[] => [
			{ value: GetType.one, label: "ერთის წამოღება" },
			{ value: GetType.many, label: "ბევრის წამოღება" },
		],
		[]
	);
	const getType = hasGetOne
		? GetType.one
		: hasGetMany
		? GetType.many
		: undefined;
	const selectedGetTypeOption = useMemo(
		() => options.find(e => e.value === getType),
		[getType, options]
	);
	return (
		<div>
			<RadioNavigation
				options={options}
				value={selectedGetTypeOption}
				onChange={sel => onChange(sel.value)}
			/>
		</div>
	);
});
