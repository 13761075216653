import api from "@app/api";
import {
	addProject,
	deleteProject,
	loadProjectById,
	projectCRUDActions,
	updateProject,
} from "@app/actions/projects";
import {
	IAPOSTCreateProject,
	IAPUTProject,
	IRPOSTCreateProject,
} from "@app/api/projects/validators";
import { IDeleteOneArgs, ISearchManyArgs, ISearchOneArgs } from "./interfaces";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IRProject } from "@app/api/projects/helper-schemas";
import { Model } from ".";
import { Store } from "redux";

type MyInstance = IRProject;

class ProjectModel extends Model<"projects"> {
	constructor(store: Store<IRootState, IRootActions>) {
		super("projects", store);
		this.supportsLoadingByIdsFromApi = false;
	}

	loadMyProjects(): Promise<MyInstance[]> {
		return api.projects.getMyProjects().then(projects => {
			this.dispatch(projectCRUDActions.loadMany(projects));
			return projects;
		});
	}

	async create(project: IAPOSTCreateProject): Promise<IRPOSTCreateProject> {
		return addProject(project)(this.dispatch, this.getState, null);
	}
	async update(project: IAPUTProject): Promise<void> {
		return updateProject(project)(this.dispatch, this.getState, null);
	}

	public async deleteOne(info: IDeleteOneArgs["projects"]): Promise<void> {
		return deleteProject(info)(this.dispatch, this.getState, null);
	}

	protected async loadOne(
		info: ISearchOneArgs["projects"]
	): Promise<MyInstance> {
		return loadProjectById(info)(this.dispatch, this.getState, null);
	}

	protected async loadManyByIds(
		infos: ISearchManyArgs["projects"]
	): Promise<MyInstance[]> {
		return [];
	}
}

export { ProjectModel };
