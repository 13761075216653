import * as React from "react";
import Header from "@app/components/Header";
import LoginPopup from "@app/components/login-popup";
import memoizeOne from "memoize-one";
import { connect } from "react-redux";
import { History, Path } from "history";
import { IRootState } from "@app/reducers/root-state";
import { IRUser } from "@app/api/users/helper-schemas";
import { Redirect, Route } from "react-router-dom";
import { removeKeys } from "@app/utils/common";
import { User } from "@app/user";
import { ProjectWatcher } from "@app/project-watcher";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
	excludedParamsForKey?: string | string[];
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

const getUser = memoizeOne((userData: IRUser) => {
	return new User(userData);
});

const paramsToKey = (
	params,
	excludedParamsForKey?: IWonProps["excludedParamsForKey"]
) => {
	const exclKeys = Array.isArray(excludedParamsForKey)
		? excludedParamsForKey
		: typeof excludedParamsForKey !== "undefined"
		? [excludedParamsForKey]
		: undefined;
	return JSON.stringify(exclKeys ? removeKeys(params, ...exclKeys) : params);
};

const UserRoute = ({
	component: Component,
	isAuthenticated,
	location,
	user,
	excludedParamsForKey,
	...rest
}: IProps) => {
	return (
		<Route
			location={location}
			{...rest}
			render={props =>
				user.userData ? (
					<ProjectWatcher match={props.match}>
						<div>
							<div className="user-page">
								<Header {...rest} {...props} />
								<div className="user-page-without-header">
									<Component
										key={paramsToKey(
											props.match.params,
											excludedParamsForKey
										)}
										{...props.match.params}
										{...rest}
										{...props}
										user={getUser(user.userData)}
									/>
								</div>
							</div>
						</div>
					</ProjectWatcher>
				) : !isAuthenticated ? (
					<LoginPopup onClose={succ => window.location.reload()} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
};

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	user: state.user,
	laa: state,
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	UserRoute
);
