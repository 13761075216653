/* eslint-disable no-restricted-globals */
import * as React from "react";
import Button from "@material-ui/core/Button";
import { AdminTable } from "../widgets/admin-table";
import { History } from "history";
import { inject } from "@app/modules";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";
import { User } from "@app/user";
import AnalyticsIcon from "@material-ui/icons/Equalizer";
import { useGotoProjectPage } from "@app/hooks/general";

interface IProps {
	history: History;
	match: match<{ projectId: string }>;
	user: User;
}

export class TablesList extends React.PureComponent<IProps> {
	projectId = this.props.match.params.projectId;

	_Table = inject("Table");

	componentDidMount() {
		this._Table.getReadableTables({ projectId: this.projectId }).then();
	}

	canDelete = (id: ObjectId) => {
		return this.props.user.canDeleteTable(id, this.projectId);
	};
	canView = (id: ObjectId) => {
		return this.props.user.canReadTable(id, this.projectId);
	};
	canEdit = (id: ObjectId) => {
		return this.props.user.canDeleteAPICall(id, this.projectId);
	};

	onDelete = (id: ObjectId) => {
		if (confirm("დარწმუნებული ხართ????")) {
			this._Table.deleteOne({ id, projectId: this.projectId });
		}
	};

	onView = (id: ObjectId) => {
		this.props.history.push(`/projects/${this.projectId}/tables/${id}`);
	};

	onEdit = (id: ObjectId) => {
		this.props.history.push(
			`/projects/${this.projectId}/tables/${id}/edit`
		);
	};

	onTableAdd = () => {
		this.props.history.push(`/projects/${this.projectId}/table/add`);
	};
	gotoStatsTables = () => {
		this.props.history.push(`/projects/${this.projectId}/stats`);
	};

	render() {
		const tables = this._Table.getReadableTablesSync(
			{ projectId: this.projectId },
			this.props.user
		);
		return (
			<div className="main main2">
				<br />
				{this.props.user.canWriteTable(null, this.projectId) && (
					<Button
						variant="contained"
						color="primary"
						onClick={this.onTableAdd}
					>
						ცხრილის დამატება
					</Button>
				)}
				{this.props.user.canWriteTable(null, this.projectId) && (
					<Button
						variant="contained"
						color="primary"
						onClick={this.gotoStatsTables}
					>
						სტატისტიკები
					</Button>
				)}
				<AdminTable
					canDelete={this.canDelete}
					canEdit={this.canEdit}
					canView={this.canView}
					onView={this.onView}
					onEdit={this.onEdit}
					onDelete={this.onDelete}
					rows={tables}
					additionalComponents={AdditionalComp}
				/>
			</div>
		);
	}
}

const AdditionalComp = ({
	rowId,
	iconClassName,
}: {
	rowId: ObjectId;
	iconClassName: string;
}) => {
	const gotoStatsList = useGotoProjectPage(`tables/${rowId}/stats/list`);
	return <AnalyticsIcon className={iconClassName} onClick={gotoStatsList} />;
};
