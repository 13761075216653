import { Schema } from "joi";
import Joi from "@app/utils/joi";

export enum TranformationType {
	value = "val",
	sum = "sum",
	fraction = "frac",
	percentage = "perc",
	multiplication = "mult",
	subtraction = "subtr",
}

const createSumTransformationSchema = (id: string) => {
	return Joi.object({
		type: Joi.any()
			.valid(TranformationType.sum)
			.required(),
		addenda: Joi.array()
			.items(Joi.link("#" + id).required())
			.required(),
	});
};
export interface SumTransformation<T> {
	type: TranformationType.sum;
	addenda: Transformation<T>[];
}

const createSubtractionTransformationSchema = (id: string) => {
	return Joi.object({
		type: Joi.any()
			.valid(TranformationType.fraction)
			.required(),
		dividend: Joi.link("#" + id).required(),
		divisor: Joi.link("#" + id).required(),
	});
};
export interface FractionTransformation<T> {
	type: TranformationType.fraction;
	dividend: Transformation<T>;
	divisor: Transformation<T>;
}

const createPercentageTransformationSchema = (id: string) => {
	return Joi.object({
		type: Joi.any()
			.valid(TranformationType.percentage)
			.required(),
		value: Joi.link("#" + id).required(),
	});
};
export interface PercentageTransformation<T> {
	type: TranformationType.percentage;
	value: Transformation<T>;
}

const createMultiplicationTransformationSchema = (id: string) => {
	return Joi.object({
		type: Joi.any()
			.valid(TranformationType.multiplication)
			.required(),
		factors: Joi.array()
			.items(Joi.link("#" + id).required())
			.required(),
	});
};
export interface MultiplicationTransformation<T> {
	type: TranformationType.multiplication;
	factors: Transformation<T>[];
}

const createFractionTransformationSchema = (id: string) => {
	return Joi.object({
		type: Joi.any()
			.valid(TranformationType.subtraction)
			.required(),
		minuend: Joi.link("#" + id).required(),
		subtrahend: Joi.link("#" + id).required(),
	});
};
export interface SubtractionTransformation<T> {
	type: TranformationType.subtraction;
	minuend: Transformation<T>;
	subtrahend: Transformation<T>;
}

const createTransformationValueSchema = (valSchema: Schema) => {
	return Joi.object({
		type: Joi.any()
			.valid(TranformationType.value)
			.required(),
		val: valSchema.required(),
	});
};

export interface TransformationValue<T> {
	type: TranformationType.value;
	val: T;
}

export type Transformation<T> =
	| TransformationValue<T>
	| SumTransformation<T>
	| FractionTransformation<T>
	| PercentageTransformation<T>
	| MultiplicationTransformation<T>
	| SubtractionTransformation<T>;

export const createTransformationSchema = (valSchema: Schema, id: string) => {
	const schema = Joi.alternatives(
		createTransformationValueSchema(valSchema),
		createSumTransformationSchema(id),
		createFractionTransformationSchema(id),
		createPercentageTransformationSchema(id),
		createMultiplicationTransformationSchema(id),
		createSubtractionTransformationSchema(id)
	).id(id);
	return schema;
};
