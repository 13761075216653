import * as React from "react";
import LoginByTokenPage from "@app/components/loginByTokenPage";
import UserRoute from "./routes/user-route";
import { AddResourcesToResourceGroup } from "./components/resource-groups/add-resource";
import { ColumnPage } from "./components/columns";
import { ColumnsList } from "./components/columns/list";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "./reducers/root-state";
import { MyProjects } from "./components/users/my-projects";
import { ProjectPage } from "./components/projects";
import { ResourceGroupsList } from "./components/resource-groups/list";
import { Route } from "react-router-dom";
import { SaveColumn } from "./components/columns/add";
import { SaveProject } from "./components/projects/add";
import { SaveResourceGroup } from "./components/resource-groups/add";
import { SaveTable } from "./components/tables/add";
import { TablePage } from "./components/tables";
import { TablesList } from "./components/tables/list";
import { TableStatsPage } from "./components/tables/stats";
import "moment/locale/ka";
import { APICallsList } from "./components/api-calls/list";
import { SaveAPICall } from "./components/api-calls/add";
import { FunctionsList } from "./components/functions/list";
import { SaveFunction } from "./components/functions/add";
import { StatsList } from "./components/stats/list";
import { SaveStats } from "./components/stats/save";
import { TableStatsOrder } from "./components/tables/stats/order";
import { IdsTools } from "./components/resource-groups/tools";
import { RolesList } from "./components/roles/list";

const App = ({
	location,
	isAuthenticated,
	isAdmin,
}: {
	location: History["location"];
	isAuthenticated: boolean;
	isAdmin: boolean;
}) => (
	<React.Fragment>
		<UserRoute
			location={location}
			path="/projects"
			exact={true}
			component={MyProjects}
		/>
		<UserRoute
			location={location}
			path="/project/add"
			exact={true}
			component={SaveProject}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/edit"
			exact={true}
			component={SaveProject}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId"
			exact={true}
			component={ProjectPage}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/column/add"
			exact={true}
			component={SaveColumn}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/columns"
			exact={true}
			component={ColumnsList}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/columns/:id/edit"
			exact={true}
			component={SaveColumn}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/columns/:id"
			exact={true}
			component={ColumnPage}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/table/add"
			exact={true}
			component={SaveTable}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/tables"
			exact={true}
			component={TablesList}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/tables/:id/edit"
			exact={true}
			component={SaveTable}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/tables/:id"
			exact={true}
			component={TablePage}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/tables/:id/stats/list"
			exact={true}
			component={TableStatsOrder}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/stats"
			exact={true}
			component={StatsList}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/stats/add"
			exact={true}
			component={SaveStats}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/stats/:id/edit"
			exact={true}
			component={SaveStats}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/api-calls"
			exact={true}
			component={APICallsList}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/api-calls/add"
			exact={true}
			component={SaveAPICall}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/api-calls/:id/edit"
			exact={true}
			component={SaveAPICall}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/functions"
			exact={true}
			component={FunctionsList}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/functions/add"
			exact={true}
			component={SaveFunction}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/functions/:id/edit"
			exact={true}
			component={SaveFunction}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/tables/:id/stats"
			exact={true}
			component={TableStatsPage}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/resource-group/add"
			exact={true}
			component={SaveResourceGroup}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/resource-groups"
			exact={true}
			component={ResourceGroupsList}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/resource-groups/tools"
			exact={true}
			component={IdsTools}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/roles"
			exact={true}
			component={RolesList}
		/>
		<Route
			location={location}
			path="/tools"
			exact={true}
			component={IdsTools}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/resource-groups/:id/edit"
			exact={true}
			component={SaveResourceGroup}
		/>
		<UserRoute
			location={location}
			path="/projects/:projectId/resource-groups/:id/add-resources"
			exact={true}
			component={AddResourcesToResourceGroup}
		/>
		<Route
			location={location}
			path="/loginByToken/:murtskuUserId/:token"
			exact={true}
			component={LoginByTokenPage}
		/>
	</React.Fragment>
);

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	// isAdmin: !!state.user.userData && !!state.user.userData.permissions
	// isStudent: state.user.userType === STUDENT_UTYPE,
	// isTeacher: state.user.userType === TEACHER_UTYPE,
	// isAdmin: state.user.userType === ADMIN_UTYPE,
});

export default connect(mapStateToProps)(App);
