import * as React from "react";
import { css } from "emotion";
import { IRTable } from "@app/api/tables/helper-schemas";
import { ObjectId } from "@app/utils/generics";

interface ITableWindowProps {
	table: IRTable;
	onClick: (tableId: ObjectId) => void;
}

export const TableWindow: React.FC<ITableWindowProps> = React.memo(props => (
	<div
		onClick={() => props.onClick(props.table._id)}
		className={TableWindowCSS}
	>
		<div>{props.table.name}</div>
	</div>
));

const TableWindowCSS = css`
	display: block;
	padding: 15px 20px;
	margin: 10px 0;
	border-radius: 10px;
	border: 1px solid #ccc;
	cursor: pointer;
`;
