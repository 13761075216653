/* eslint-disable no-restricted-globals */
import api from "@app/api";
import {
	CAN_DELETE_APICALLS,
	CAN_DELETE_COLUMNS,
	CAN_DELETE_OTHERS_USERS_FIELD_DATA,
	CAN_DELETE_OWN_USERS_FIELD_DATA,
	CAN_DELETE_PROJECT,
	CAN_DELETE_RESOURCE_GROUPS,
	CAN_DELETE_TABLES,
	CAN_EXECUTE_APICALLS,
	CAN_MANAGE_ADMINS_IN_PROJECT,
	CAN_MANAGE_USERS_IN_PROJECT,
	CAN_READ_APICALLS,
	CAN_READ_COLUMNS,
	CAN_READ_HIDDEN_DATAVALUES_OF_APICALLS,
	CAN_READ_OTHERS_USERS_FIELD_DATA,
	CAN_READ_OWN_USERS_FIELD_DATA,
	CAN_READ_PROJECT,
	CAN_READ_RESOURCE_GROUPS,
	CAN_READ_TABLES,
	CAN_WRITE_APICALLS,
	CAN_WRITE_COLUMNS,
	CAN_WRITE_OTHERS_USERS_FIELD_DATA,
	CAN_WRITE_OWN_USERS_FIELD_DATA,
	CAN_WRITE_PROJECT,
	CAN_WRITE_RESOURCE_GROUPS,
	CAN_WRITE_TABLES,
} from "@app/api/admin-roles/consts";
import { ObjectId, OptionalKeys } from "@app/utils/generics";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useSetProps } from "react-prop-hooks";
import Select from "react-select";
import {
	IRAdminRole,
	IResourceGroupsPermission,
} from "../../api/admin-roles/helper-schemas";
import { IRGETManyAPICalls } from "../../api/api-calls/validators";
import { IRGETManyColumns } from "../../api/columns/validators";
import { IRTable } from "../../api/tables/helper-schemas";
import { CheckboxWithLabel } from "../widgets/input";
import Loading from "../widgets/loading";
import Popup, { PopupContentWithClose } from "../widgets/popup";
import { CRUDOptions2, IndividualSelects } from "./form";

interface IIterableObject {
	id: number;
	_id: string;
	name: string;
	read: boolean;
	write: boolean;
	delete: boolean;
}

export const RolesList = React.memo(function RolesList({
	projectId,
}: {
	projectId: ObjectId;
}) {
	const [roles, setRoles] = useState<IRAdminRole[]>();
	const [showAdd, setShowAdd] = useState(false);
	useEffect(() => {
		api.adminRoles.getAdminRoles({ projectId }).then(setRoles);
	}, [projectId]);

	const onReleDelete = () => {
		location.reload();
	};

	if (!roles) return <Loading />;

	return (
		<div className="main main2">
			<Button onClick={() => setShowAdd(true)}>
				<AddIcon color={"primary"} />{" "}
				<span style={{ marginTop: 5 }}>როლის დამატება</span>
			</Button>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>როლი</TableCell>
						<TableCell align="right">ქმედებები</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{roles.map(x => {
						return (
							<RolesTable
								onRolesDelete={onReleDelete}
								id={x._id}
								key={x._id}
								projectId={projectId}
								name={x.name}
								permission={x.projectPermission}
							/>
						);
					})}
				</TableBody>
			</Table>
			{showAdd && (
				<AddAdminRoles
					onClose={() => setShowAdd(false)}
					projectId={projectId}
				/>
			)}
		</div>
	);
});

const RolesTable: React.FC<{
	name: string;
	id: string;
	permission: number;
	projectId: ObjectId;
	onRolesDelete: () => void;
}> = React.memo(({ projectId, name, id, permission, onRolesDelete }) => {
	const [showEdit, setEdit] = useState(false);

	const handleRoleDelete = () => {
		api.adminRoles
			.deleteById({ projectId, _id: id })
			.then(x => console.log("done"))
			.catch(e => console.log(e));
		onRolesDelete();
	};
	const onRolesClose = () => {
		setEdit(prevState => !prevState);
	};
	const onEditClick = () => {
		setEdit(prevState => !prevState);
	};
	return (
		<React.Fragment>
			<TableRow>
				<TableCell>{name}</TableCell>
				<TableCell>
					<Button onClick={onEditClick}>
						<EditIcon />
					</Button>
					<Button
						disabled={permission === -1}
						onClick={handleRoleDelete}
					>
						<DeleteIcon />
					</Button>
				</TableCell>
			</TableRow>
			{showEdit && (
				<AddAdminRoles
					onClose={onRolesClose}
					projectId={projectId}
					roleId={id}
				/>
			)}
		</React.Fragment>
	);
});

type ISaveAdminRole = OptionalKeys<Omit<IRAdminRole, "author">, "_id">;

const AddAdminRoles: React.FC<{
	onClose: () => void;
	projectId: string;
	roleId?: string;
}> = React.memo(({ projectId, onClose, roleId }) => {
	const [role, setRole] = useState<ISaveAdminRole | null>(null);
	const getRoleSetter = useSetProps(setRole);
	const [tables, setTables] = useState<IRTable[]>();
	const [columns, setColums] = useState<IRGETManyColumns>();
	const [apis, setApis] = useState<IRGETManyAPICalls>();

	const [allAdmins, setAllAdmins] = useState<any[]>();
	const [admins, setAdmins] = useState<{ value: number; label: string }[]>(
		[]
	);

	useEffect(() => {
		const fn = async () => {
			const [apiCalls, columns, tables, allAdmins] = await Promise.all([
				api.apiCalls.getReadableAPICalls({ projectId }),
				api.columns.getReadableColumns({ projectId }),
				api.tables.getReadableTables({ projectId }),
				api.users.getAll(),
			]);

			setApis(apiCalls);
			setColums(columns);
			setTables(tables);
			setAllAdmins(allAdmins);

			if (!roleId) {
				// Default permissions
				setRole({
					name: "",
					projectId,
					projectPermission: CAN_READ_PROJECT,
					tablePermissions: { general: CAN_READ_TABLES },
					columnPermissions: { general: CAN_READ_COLUMNS },
					apiCallsPermissions: {
						general: CAN_READ_APICALLS | CAN_EXECUTE_APICALLS,
					},
					resourceGroupPermissions: {
						general: CAN_READ_RESOURCE_GROUPS,
						dataGeneral:
							CAN_READ_OWN_USERS_FIELD_DATA |
							CAN_WRITE_OWN_USERS_FIELD_DATA |
							CAN_DELETE_OWN_USERS_FIELD_DATA |
							CAN_READ_OTHERS_USERS_FIELD_DATA,
					},
				});
				return;
			}
			const role = await api.adminRoles.getById({
				projectId,
				_id: roleId,
			});
			setRole(role);
			setAdmins(
				role.adminIds
					? role.adminIds.map(adminId => {
							return {
								value: adminId,
								label: allAdmins.filter(
									e => e.id === adminId
								)[0].username,
							};
					  })
					: []
			);
		};
		fn();
	}, [projectId, roleId]);

	const handleSave = async () => {
		if (!role) return;

		const newRole: typeof role = {
			...role,
			tablePermissions: normalizePermObject(role?.tablePermissions),
			columnPermissions: normalizePermObject(role?.columnPermissions),
			apiCallsPermissions: normalizePermObject(role?.apiCallsPermissions),
			resourceGroupPermissions: normalizePermObject(
				role?.resourceGroupPermissions
			),
			functionPermissions: normalizePermObject(role?.functionPermissions),
			adminIds: admins.map(e => e.value),
		};
		if (!roleId)
			await api.adminRoles
				.create(newRole)
				.then(x => {
					alert("წარმატებით დაემატა");
					onClose();
					location.reload();
				})
				.catch(err => {
					alert("შეცდომა");
				});
		else
			await api.adminRoles
				.edit({
					_id: roleId,
					...newRole,
				})
				.then(x => {
					alert("Updated");
					onClose();
				})
				.catch(err => {
					alert("Error");
				});
	};

	const getTablePermissionSetters = useSetProps(
		getRoleSetter("tablePermissions")
	);
	const getColumnPermissionSetters = useSetProps(
		getRoleSetter("columnPermissions")
	);
	const getApiCallsPermissionSetters = useSetProps(
		getRoleSetter("apiCallsPermissions")
	);
	const getResourceGroupPermissionSetters = useSetProps(
		getRoleSetter("resourceGroupPermissions")
	);

	const projectPermissionOptions = useMemo(
		() => [
			{ value: CAN_READ_PROJECT, label: "ნახვა" },
			{ value: CAN_WRITE_PROJECT, label: "შეცვლა" },
			{ value: CAN_DELETE_PROJECT, label: "წაშლა" },
			{
				value: CAN_MANAGE_ADMINS_IN_PROJECT,
				label: "ადმინების განკარგვა",
			},
			{
				value: CAN_MANAGE_USERS_IN_PROJECT,
				label: "მომხმარებლების განკარგვა",
			},
		],
		[]
	);

	const tablePermissionOptions = useMemo(
		() => [
			{ value: CAN_READ_TABLES, label: "ნახვა" },
			{ value: CAN_WRITE_TABLES, label: "შეცვლა" },
			{ value: CAN_DELETE_TABLES, label: "წაშლა" },
		],
		[]
	);

	const columnPermissionOptions = useMemo(
		() => [
			{ value: CAN_READ_COLUMNS, label: "ნახვა" },
			{ value: CAN_WRITE_COLUMNS, label: "შეცვლა" },
			{ value: CAN_DELETE_COLUMNS, label: "წაშლა" },
		],
		[]
	);

	const apiCallsPermissionOptions = useMemo(
		() => [
			{ value: CAN_READ_APICALLS, label: "ნახვა" },
			{ value: CAN_WRITE_APICALLS, label: "შეცვლა" },
			{ value: CAN_DELETE_APICALLS, label: "წაშლა" },
			{ value: CAN_EXECUTE_APICALLS, label: "აღსრულება" },
			{
				value: CAN_READ_HIDDEN_DATAVALUES_OF_APICALLS,
				label: "საიდუმლო ცვლადების წაკითხვა",
			},
		],
		[]
	);

	const resourceGroupPermissionOptions = useMemo(
		() => [
			{ value: CAN_READ_RESOURCE_GROUPS, label: "ნახვა" },
			{ value: CAN_WRITE_RESOURCE_GROUPS, label: "შეცვლა" },
			{ value: CAN_DELETE_RESOURCE_GROUPS, label: "წაშლა" },
		],
		[]
	);

	const resourceDataPermissionOptions = useMemo(
		() => [
			{ value: CAN_READ_OWN_USERS_FIELD_DATA, label: "საკუთ. ნახვა" },
			{ value: CAN_WRITE_OWN_USERS_FIELD_DATA, label: "საკუთ. შეცვლა" },
			{ value: CAN_DELETE_OWN_USERS_FIELD_DATA, label: "საკუთ. წაშლა" },
			{ value: CAN_READ_OTHERS_USERS_FIELD_DATA, label: "სხვ. ნახვა" },
			{ value: CAN_WRITE_OTHERS_USERS_FIELD_DATA, label: "სხვ. შეცვლა" },
			{ value: CAN_DELETE_OTHERS_USERS_FIELD_DATA, label: "სხვ. წაშლა" },
		],
		[]
	);

	type IndividualResourcePerm = NonNullable<
		NonNullable<IResourceGroupsPermission["individuals"]>[string]
	>;

	if (!role || !tables || !allAdmins || !columns || !apis) {
		return (
			<Popup onClose={onClose}>
				<Loading />
			</Popup>
		);
	}

	return (
		<Popup onClose={() => {}}>
			<PopupContentWithClose
				onClose={onClose}
				style={{
					width: 1200,
					textAlign: "left",
				}}
			>
				<Input
					type={"text"}
					value={role.name}
					onChange={e => getRoleSetter("name")(e.target.value)}
					placeholder={"სახელი"}
					style={{ width: 400, marginRight: 20 }}
					required={true}
				/>
				<br />
				<br />
				<span>პროექტი</span>
				<CRUDOptions2
					value={role.projectPermission}
					onChange={getRoleSetter("projectPermission")}
					options={projectPermissionOptions}
					showAllOption={true}
				/>
				{role.projectPermission !== -1 && (
					<div>
						<hr />
						ცხრილები:
						<CRUDOptions2
							value={role.tablePermissions?.general || 0}
							onChange={getTablePermissionSetters("general")}
							options={tablePermissionOptions}
						>
							<CheckboxWithLabel
								onChange={ch =>
									getTablePermissionSetters("individuals")(
										ch ? {} : undefined
									)
								}
								value={!!role.tablePermissions?.individuals}
								label="უფლებების სათითაოდ განსაზღვრა"
							/>
						</CRUDOptions2>
						{role.tablePermissions?.individuals && (
							<IndividualSelects<number>
								records={tables}
								onChange={getTablePermissionSetters(
									"individuals"
								)}
								renderItem={({ onDataChange, data }) => (
									<CRUDOptions2
										value={data || 0}
										onChange={onDataChange}
										options={tablePermissionOptions}
									/>
								)}
								defaultData={role.tablePermissions.individuals}
							/>
						)}
						<hr />
						სვეტები
						<br />
						<CRUDOptions2
							value={role.columnPermissions?.general || 0}
							onChange={getColumnPermissionSetters("general")}
							options={columnPermissionOptions}
						>
							<CheckboxWithLabel
								onChange={ch =>
									getColumnPermissionSetters("individuals")(
										ch ? {} : undefined
									)
								}
								value={!!role.columnPermissions?.individuals}
								label="უფლებების სათითაოდ განსაზღვრა"
							/>
						</CRUDOptions2>
						{role.columnPermissions?.individuals && (
							<IndividualSelects<number>
								records={columns}
								onChange={getColumnPermissionSetters(
									"individuals"
								)}
								renderItem={({ onDataChange, data }) => (
									<CRUDOptions2
										value={data || 0}
										onChange={onDataChange}
										options={columnPermissionOptions}
									/>
								)}
								defaultData={role.columnPermissions.individuals}
							/>
						)}
						<hr />
						API calls
						<br />
						<CRUDOptions2
							value={role.apiCallsPermissions?.general || 0}
							onChange={getApiCallsPermissionSetters("general")}
							options={apiCallsPermissionOptions}
						>
							<CheckboxWithLabel
								onChange={ch =>
									getApiCallsPermissionSetters("individuals")(
										ch ? {} : undefined
									)
								}
								value={!!role.apiCallsPermissions?.individuals}
								label="უფლებების სათითაოდ განსაზღვრა"
							/>
						</CRUDOptions2>
						{role.apiCallsPermissions?.individuals && (
							<IndividualSelects<number>
								records={apis}
								onChange={getApiCallsPermissionSetters(
									"individuals"
								)}
								renderItem={({ onDataChange, data }) => (
									<CRUDOptions2
										value={data || 0}
										onChange={onDataChange}
										options={apiCallsPermissionOptions}
									/>
								)}
								defaultData={
									role.apiCallsPermissions.individuals
								}
							/>
						)}
						<hr />
						მომხმარებელთა ჯგუფები
						<CRUDOptions2
							value={role.resourceGroupPermissions?.general || 0}
							onChange={getResourceGroupPermissionSetters(
								"general"
							)}
							options={resourceGroupPermissionOptions}
						>
							<CheckboxWithLabel
								onChange={ch =>
									getResourceGroupPermissionSetters(
										"individuals"
									)(ch ? {} : undefined)
								}
								value={
									!!role.resourceGroupPermissions?.individuals
								}
								label="უფლებების სათითაოდ განსაზღვრა"
							/>
						</CRUDOptions2>
						მოსწავლეების ინფორმაცია:
						<CRUDOptions2
							value={
								role.resourceGroupPermissions?.dataGeneral || 0
							}
							onChange={getResourceGroupPermissionSetters(
								"dataGeneral"
							)}
							options={resourceDataPermissionOptions}
						/>
						{role.resourceGroupPermissions?.individuals && (
							<IndividualSelects<IndividualResourcePerm>
								records={tables}
								onChange={getResourceGroupPermissionSetters(
									"individuals"
								)}
								renderItem={({ data, getDataPropSetterFn }) => (
									<div>
										<br />
										მომხმარებელთა ჯგუფები
										<CRUDOptions2
											value={data?.permission || 0}
											onChange={getDataPropSetterFn(
												"permission"
											)}
											options={
												resourceGroupPermissionOptions
											}
										/>
										მოსწავლეების ინფორმაცია:
										<CRUDOptions2
											value={data?.dataPermission || 0}
											onChange={getDataPropSetterFn(
												"dataPermission"
											)}
											options={
												resourceDataPermissionOptions
											}
										/>
									</div>
								)}
								defaultData={
									role.resourceGroupPermissions.individuals
								}
								getDefaultData={() => ({})}
							/>
						)}
						<br />
						<hr />
						მომხმარებლები
						<br />
						<br />
						{allAdmins.length > 0 && (
							<Select
								isMulti={true}
								onChange={setAdmins as any}
								options={allAdmins.map(x => ({
									value: x.id as number,
									label: x.username as string,
								}))}
								value={admins}
							/>
						)}
					</div>
				)}
				<br />
				<Button
					variant="contained"
					color="primary"
					type={"submit"}
					onClick={handleSave}
				>
					შენახვა
				</Button>
			</PopupContentWithClose>
		</Popup>
	);
});

const normalizePermObject = <T extends Record<any, any>>(
	obj: T | undefined
): T | undefined => {
	if (obj === undefined) return undefined;
	if (typeof obj !== "object" || obj === null) return obj;
	const newObj = {} as T;
	const keys = Object.keys(obj);
	for (const key of keys) {
		const value = obj[key];
		if (value === undefined) continue;
		if (typeof value === "object" && value !== null) {
			const newValue = normalizePermObject(value);
			if (newValue !== undefined) {
				newObj[(key as unknown) as keyof T] = newValue;
			}
		} else {
			newObj[(key as unknown) as keyof T] = value;
		}
	}
	if (Object.keys(newObj).length === 0) return undefined;
	return newObj;
};
