import { Requests } from "../index";
import { ICreateAdminRole, IRAdminRole } from "./helper-schemas";

const adminRoles = {
	getAdminRoles: (data: { projectId: string }): Promise<IRAdminRole[]> =>
		Requests.send("GET", "/api/projects/:projectId/admin-roles/", data),
	getById: (data: { projectId: string; _id: string }): Promise<IRAdminRole> =>
		Requests.send("GET", "/api/projects/:projectId/admin-roles/:_id", data),
	deleteById: (data: { projectId: string; _id: string }): Promise<any> =>
		Requests.send(
			"DELETE",
			"/api/projects/:projectId/admin-roles/:_id",
			data
		),
	create: (data: any): Promise<any> =>
		Requests.send("POST", "/api/projects/:projectId/admin-roles/", data),
	edit: (data: any) =>
		Requests.send("PUT", "/api/projects/:projectId/admin-roles/:_id", data),
};

export default adminRoles;
