import * as React from "react";
import api from "@app/api";
import Button from "@material-ui/core/Button";
import Loading from "../widgets/loading";
import memoizeOne from "memoize-one";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import { History } from "history";
import { inject } from "@app/modules";
import { IRResourceGroup } from "@app/api/resource-groups/helper-schemas";
import { IRTable } from "@app/api/tables/helper-schemas";
import { IRUserMinimalInfo } from "@app/api/users/helper-schemas";
import { match } from "react-router";
import { NotUndefined, OptionalKeys } from "@app/utils/generics";
import { removeKeys } from "@app/utils/common";
import { User } from "@app/user";

interface IProps {
	match: match<{ projectId: string; id?: string }>;
	history: History;
	user: User;
}

interface IState {
	resourceGroup?: OptionalKeys<
		IRResourceGroup,
		"_id" | "author" | "numOfResources"
	>;
	admins?: IRUserMinimalInfo[];
	tables?: IRTable[];
}

export class SaveResourceGroup extends React.Component<IProps, IState> {
	id = this.props.match.params.id;
	projectId = this.props.match.params.projectId;

	state: IState = {};

	_Table = inject("Table");

	getAdminOptions = memoizeOne((admins: NotUndefined<IState["admins"]>) => {
		return admins.map(e => ({
			value: e.id,
			label: e.username || "",
		}));
	});

	getTableOptions = memoizeOne((tables: NotUndefined<IState["tables"]>) => {
		return tables.map(e => ({
			value: e._id,
			label: e.name,
		}));
	});

	getSelectedAdmins = memoizeOne(
		(options: { value: number; label: string }[], ids: number[]) => {
			return this.getSelected(options, ids);
		}
	);
	getSelectedTables = memoizeOne(
		(options: { value: string; label: string }[], ids?: string[]) => {
			return this.getSelected(options, ids || []);
		}
	);

	getSelected = <T extends any>(
		options: { value: T; label: string }[],
		ids: T[]
	) => {
		const selectedOptions: { value: T; label: string }[] = [];
		for (let i = 0; i < ids.length; ++i) {
			const id = ids[i];
			const result = options.find(e => id === e.value);
			if (!result) continue;
			selectedOptions.push(result);
		}
		return selectedOptions;
	};

	componentDidMount() {
		this._Table
			.getReadableTables({ projectId: this.projectId })
			.then(tables => {
				this.setState({ tables });
			});
		api.projects.getAdminsMinInfo({ _id: this.projectId }).then(admins => {
			this.setState({
				admins,
			});
		});
		if (!this.id) {
			if (!this.props.user.canWriteResourceGroup(null, this.projectId)) {
				this.props.history.push(
					`/projects/${this.projectId}/resource-groups`
				);
				return;
			}
			this.setState({
				resourceGroup: {
					name: "",
					description: "",
					projectId: this.projectId,
					adminIds: [],
					tableIds: [],
				},
			});
		} else {
			api.resourceGroups
				.getById({
					resourceGroupId: this.id,
					projectId: this.projectId,
				})
				.then(resourceGroup => {
					this.projectId = resourceGroup.projectId;
					if (
						!this.props.user.canWriteResourceGroup(
							resourceGroup._id,
							resourceGroup.projectId
						)
					) {
						this.props.history.push(
							`/projects/${this.projectId}/resource-groups`
						);
						return;
					}
					this.setState({ resourceGroup });
				});
		}
	}

	onSave = () => {
		const { resourceGroup } = this.state;
		if (!resourceGroup) {
			return;
		}
		if (!this.id) {
			api.resourceGroups
				.add(resourceGroup as IRResourceGroup)
				.then(col => {
					this.props.history.push(
						`/projects/${this.projectId}/resource-groups`
					);
				});
		} else {
			api.resourceGroups
				.update(removeKeys(
					resourceGroup,
					"author",
					"numOfResources"
				) as IRResourceGroup)
				.then(() => {
					this.props.history.push(
						`/projects/${this.projectId}/resource-groups`
					);
				});
		}
	};

	handleInputChange = (
		key: keyof NotUndefined<IState["resourceGroup"]>
	): React.ChangeEventHandler<HTMLInputElement> => e => {
		this.setState({
			resourceGroup: {
				...this.state.resourceGroup!,
				[key]: e.target.value,
			},
		});
	};

	onAdminIdsChange = (sel: { value: number; label: string }[]) => {
		this.setState({
			resourceGroup: {
				...this.state.resourceGroup!,
				adminIds: (sel || []).map(e => e.value),
			},
		});
	};

	onTableIdsChange = (sel: { value: string; label: string }[]) => {
		this.setState({
			resourceGroup: {
				...this.state.resourceGroup!,
				tableIds: sel.map(e => e.value),
			},
		});
	};

	// tslint:disable-next-line:member-ordering
	onNameChange = this.handleInputChange("name");
	// tslint:disable-next-line:member-ordering
	onDescriptionChange = this.handleInputChange("description");

	render() {
		if (
			!this.state.resourceGroup ||
			!this.state.admins ||
			!this.state.tables
		) {
			return <Loading />;
		}
		const { resourceGroup } = this.state;
		const adminOptions = this.getAdminOptions(this.state.admins);
		const tableOptions = this.getTableOptions(this.state.tables);
		const selectedAdmins = this.getSelectedAdmins(
			adminOptions,
			resourceGroup.adminIds
		);
		const selectedTables = this.getSelectedTables(
			tableOptions,
			resourceGroup.tableIds
		);
		return (
			<div className="main main2">
				<div>
					<TextField
						label="სახელი"
						value={resourceGroup.name}
						onChange={this.onNameChange}
						margin="dense"
						variant="outlined"
					/>
				</div>
				<div>
					<TextField
						label="აღწერა"
						value={resourceGroup.description || ""}
						onChange={this.onDescriptionChange}
						margin="dense"
						variant="outlined"
					/>
				</div>
				აირჩიეთ, რომელ ცხრილებში უნდა გამოჩნდნენ ეს მომხმარებლები:
				<br />
				<Select
					isMulti={true}
					options={tableOptions}
					value={selectedTables}
					placeholder={"აირჩიეთ"}
					onChange={this.onTableIdsChange}
				/>
				<br />
				<br /> <br />
				აირჩიეთ მომხმარებლები, რომელთაც უნდა ჰქონდეთ მიბმული ამ ჯგუფის
				მომხმარებლები:
				<br />
				<Select
					isMulti={true}
					options={adminOptions}
					value={selectedAdmins}
					placeholder={"აირჩიეთ"}
					onChange={this.onAdminIdsChange}
					isClearable={true}
				/>
				<br />
				<Button
					variant="contained"
					color="primary"
					onClick={this.onSave}
				>
					შენახვა
				</Button>
			</div>
		);
	}
}
