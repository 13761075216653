/* eslint-disable no-restricted-globals */
import * as React from "react";
import Button from "@material-ui/core/Button";
import { AdminTable } from "../widgets/admin-table";
import { History } from "history";
import { inject } from "@app/modules";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";
import { User } from "@app/user";

interface IProps {
	history: History;
	match: match<{ projectId: string }>;
	user: User;
}

export class StatsList extends React.PureComponent<IProps> {
	projectId = this.props.match.params.projectId;

	_Stats = inject("StatsTable");

	componentDidMount() {
		this._Stats
			.getReadableStatsTables({ projectId: this.projectId })
			.then(() => this.forceUpdate());
	}

	canDelete = (id: ObjectId) => {
		return this.props.user.canDeleteTable(id, this.projectId);
	};
	canView = (id: ObjectId) => {
		return this.props.user.canReadTable(id, this.projectId);
	};
	canEdit = (id: ObjectId) => {
		return this.props.user.canDeleteAPICall(id, this.projectId);
	};

	onDelete = (id: ObjectId) => {
		if (confirm("დარწმუნებული ხართ????")) {
			this._Stats.deleteOne({ id, projectId: this.projectId });
		}
	};

	onView = (id: ObjectId) => {
		this.props.history.push(`/projects/${this.projectId}/stats/${id}`);
	};

	onEdit = (id: ObjectId) => {
		this.props.history.push(`/projects/${this.projectId}/stats/${id}/edit`);
	};

	onTableAdd = () => {
		this.props.history.push(`/projects/${this.projectId}/stats/add`);
	};

	render() {
		const stats = this._Stats.getReadableStatsTablesSync(
			{ projectId: this.projectId },
			this.props.user
		);
		return (
			<div className="main main2">
				<br />
				{this.props.user.canWriteTable(null, this.projectId) && (
					<Button
						variant="contained"
						color="primary"
						onClick={this.onTableAdd}
					>
						სტატისტიკის დამატება
					</Button>
				)}
				<AdminTable
					canDelete={this.canDelete}
					canEdit={this.canEdit}
					canView={this.canView}
					onView={this.onView}
					onEdit={this.onEdit}
					onDelete={this.onDelete}
					rows={stats}
				/>
			</div>
		);
	}
}
