import * as React from "react";
import memoizeOne from "memoize-one";
import SvgError from "../styles/icons/error";
import SvgForbidden from "../styles/icons/forbidden";
import SvgLoading from "../../styles/img/loading";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import { APICallActionViewOuter } from "./action";
import {
	ApiCallType,
	IAPICallAction,
	IRAPICall,
} from "@app/api/api-calls/helper-schemas";
import { ChangeContainer } from "./edit";
import {
	ColumnType,
	IColumnData,
	IEnumColumnInfo,
	IRColumn,
	ISpecialValue,
	SpecialValue,
	IColumnInfo,
} from "@app/api/columns/helper-schemas";
import { css, cx } from "emotion";
import { Diff, ObjectId, WithKnownKeyType } from "@app/utils/generics";
import { getDefaultValue, getEditComponent } from "../utils";
import {
	IFilterOptions,
	ISortOptions,
} from "@app/api/resource-data/helper-schemas";
import { inject } from "@app/modules";
import {
	IRGETManyResourcesData,
	IRPUTResourceData,
} from "@app/api/resource-data/validators";
import { IRTable } from "@app/api/tables/helper-schemas";
import { MenuAction, MyMenu } from "../right-click-context";
import { TableHead } from "./thead";
import { toGeorgianDate } from "@app/commonJavascript";
import "react-datepicker/dist/react-datepicker.css";
import { tableCSS } from "../styles";
import { IRResourceGroup } from "@app/api/resource-groups/helper-schemas";

interface IProps {
	table: IRTable;
	data: IRGETManyResourcesData;
	onDatumChange: (
		resourceId: number | string,
		colId: ObjectId,
		newValue: IColumnData
	) => Promise<IRPUTResourceData>;
	onAction: (colId: ObjectId, action: MenuAction) => void;
	projectId: ObjectId;
	filterOptions?: IFilterOptions;
	sortOptions?: ISortOptions;
	resourceGroups: IRResourceGroup[] | undefined;
	onAPICall: (args: {
		resourceId: number | string;
		colId: ObjectId;
		newValue: IColumnData;
	}) => void;
}

interface IState {
	apiActionPopup?: {
		resourceId: string | number;
		columnId: ObjectId;
		api: WithKnownKeyType<IRAPICall, "info", IAPICallAction>;
	};
}

export class TableData extends React.PureComponent<IProps, IState> {
	state: IState = {};

	_APICall = inject("APICall");

	onMenuItemSelect = (
		e,
		data: { action: MenuAction },
		target: HTMLElement
	) => {
		const colId = target.title;
		this.props.onAction(colId, data.action);
	};

	onActionPopupClose = () => {
		this.setState({ apiActionPopup: undefined });
	};

	onActionPopup = (info: IState["apiActionPopup"]) => {
		console.log(info);
		this.setState({ apiActionPopup: info });
	};

	getImplicitColInfos = memoizeOne((cols: IRColumn[]): IColumnInfo[] => {
		const types: IColumnInfo[] = [];
		for (const col of cols) {
			let hasHandled = false;
			const colInfo = col.info;
			if (colInfo.type === ColumnType.api) {
				const apiCall = this._APICall.findByIdSync({
					id: colInfo.apiId,
					projectId: this.props.projectId,
				});
				if (apiCall) {
					if (apiCall.info.type === ApiCallType.columnInfo) {
						if (!colInfo.key) {
							types.push(apiCall.info.responseSchema);
							hasHandled = true;
						} else if (
							apiCall.info.responseSchema.type ===
							ColumnType.object
						) {
							const prop = apiCall.info.responseSchema.props.find(
								e => e.key === colInfo.key
							);
							if (prop) {
								types.push(prop.info);
								hasHandled = true;
							}
						}
					}
				}
			}
			if (!hasHandled) {
				types.push(colInfo);
			}
		}
		return types;
	});

	getResourceGroupsObj = memoizeOne(
		(resourceGroups: IRResourceGroup[] | undefined) => {
			if (!resourceGroups) return {};
			const obj: Record<string, IRResourceGroup | undefined> = {};
			for (const regGroup of resourceGroups) {
				obj[regGroup._id] = regGroup;
			}
			return obj;
		}
	);

	render() {
		const resourceGroupsObj = this.getResourceGroupsObj(
			this.props.resourceGroups
		);
		return (
			<div>
				<table className={tableCSS}>
					<TableHead
						cols={this.props.data.cols}
						filterOptions={this.props.filterOptions}
						sortOptions={this.props.sortOptions}
					/>
					<tbody>
						{this.props.data.resources.map(resource => (
							<TableResourceData
								key={resource.id}
								cols={this.props.data.cols}
								implicitColInfos={this.getImplicitColInfos(
									this.props.data.cols
								)}
								resourceId={resource.id}
								data={resource.data}
								onDatumChange={this.props.onDatumChange}
								projectId={this.props.projectId}
								onActionPopup={this.onActionPopup}
								myResourceGroups={resource.resGroups}
								resourceGroupsObj={resourceGroupsObj}
							/>
						))}
					</tbody>
				</table>
				<MyMenu onSelect={this.onMenuItemSelect} />
				{this.state.apiActionPopup && (
					<APICallActionViewOuter
						{...this.state.apiActionPopup}
						onClose={this.onActionPopupClose}
						onCall={this.props.onAPICall}
					/>
				)}
			</div>
		);
	}
}

interface ITableResourceDataProps {
	resourceId: number | string;
	data: IColumnData[];
	cols: Pick<IRColumn, "_id" | "info">[];
	implicitColInfos: IColumnInfo[];
	projectId: ObjectId;
	myResourceGroups: ObjectId[];
	onDatumChange: (
		resourceId: number | string,
		colId: ObjectId,
		newValue: IColumnData
	) => Promise<IRPUTResourceData>;
	onActionPopup: (info: IState["apiActionPopup"]) => void;
	resourceGroupsObj: Record<string, IRResourceGroup | undefined>;
}

const TableResourceData: React.FC<ITableResourceDataProps> = React.memo(
	props => {
		return (
			<tr>
				{props.cols.map((col, index) => {
					return (
						<td key={col._id}>
							<TableResourceSingleDatum
								key={col._id}
								resourceId={props.resourceId}
								datum={props.data[index]}
								col={col}
								implicitColInfo={props.implicitColInfos[index]}
								onDatumChange={props.onDatumChange}
								projectId={props.projectId}
								onActionPopup={props.onActionPopup}
							/>
						</td>
					);
				})}
				<td>
					{props.myResourceGroups
						.map(e =>
							props.resourceGroupsObj[e]
								? props.resourceGroupsObj[e]!.name
								: null
						)
						.filter(e => !!e)
						.join(", ")}
				</td>
			</tr>
		);
	}
);

interface ITableResourceSingleDatumProps {
	resourceId: number | string;
	projectId: ObjectId;
	datum: IColumnData;
	col: Pick<IRColumn, "_id" | "info">;
	implicitColInfo: IColumnInfo;
	onDatumChange: (
		resourceId: number | string,
		colId: ObjectId,
		newValue: IColumnData
	) => Promise<IRPUTResourceData>;
	readonly?: boolean;
	onActionPopup: (info: IState["apiActionPopup"]) => void;
}

type cellmodes = "view" | "edit" | "loading" | "updateError";

interface ITableResourceSingleDatumState {
	mode: cellmodes;
	value?: IColumnData;
}

export class TableResourceSingleDatum extends React.PureComponent<
	ITableResourceSingleDatumProps,
	ITableResourceSingleDatumState
> {
	state: ITableResourceSingleDatumState = {
		mode: "view",
	};

	_APICall = inject("APICall");
	_Column = inject("Column");

	getDefaultValue = memoizeOne((colType: ColumnType, value: IColumnData) => {
		return getDefaultValue(colType, value);
	});

	toEditMode = e => {
		if (this.props.readonly || this.state.mode === "loading") return;
		const colType = this.props.col.info.type;
		if (colType === ColumnType.api || colType === ColumnType.resourceId)
			return;
		// TODO: check if can change the value
		this.setState({
			mode: "edit",
		});
	};

	onChange = (vewVal: IColumnData, confirmChange = false) => {
		this.setState({ value: vewVal }, () => {
			if (confirmChange) this.onConfirm();
		});
	};

	// tslint:disable-next-line:member-ordering
	editComponentProps = {
		onChange: this.onChange,
		insertDefaultDataIfEmpty: true,
	};

	onClean = () => {
		this.onChange(null, true);
	};

	onConfirm = () => {
		this.setState({ mode: "loading" });
		this.props
			.onDatumChange(
				this.props.resourceId,
				this.props.col._id,
				this.state.value
			)
			.then(() => {
				this.setState({
					mode: "view",
					value: undefined,
				});
			})
			.catch(() => {
				this.setState({ mode: "updateError" });
			});
	};

	onReject = () => {
		this.setState({ mode: "view", value: undefined });
	};

	editComponent = () => {
		const component = getEditComponent(
			this.props.projectId,
			this.props.col.info,
			this.props.datum,
			this.editComponentProps
		);
		if (component === null)
			return this.viewComponent(
				this.props.datum!,
				this.props.implicitColInfo
			);
		return component;
	};

	viewComponent = (
		datum: Diff<IColumnData, ISpecialValue | undefined>,
		colInfo: IColumnInfo,
		extraProps?: Record<any, any>
	) => {
		let extra: any;
		switch (colInfo.type) {
			case ColumnType.number:
			case ColumnType.string:
			case ColumnType.resourceId:
				return datum + "";
			case ColumnType.enum:
				extra = colInfo.enumerables.find(e => e.id === datum);
				if (!extra) return null;
				return (extra as IEnumColumnInfo["enumerables"][number]).val;
			case ColumnType.boolean:
				return datum ? "კი" : "არა";
			case ColumnType.date:
				return toGeorgianDate(datum as any);
			case ColumnType.list:
				if (Array.isArray(datum)) {
					return (
						<div className={ListContainer}>
							{datum.map((data, index) => {
								return (
									<div key={index}>
										{this.viewComponent(
											data,
											colInfo.itemsInfo,
											{ direction: "horizontal" }
										)}
									</div>
								);
							})}
						</div>
					);
				} else {
					return JSON.stringify(datum);
				}
			case ColumnType.colReference:
				const column = colInfo.colId
					? this._Column.findByIdSync({
							id: colInfo.colId,
							projectId: this.props.projectId,
					  })
					: null;
				if (column) {
					return this.viewComponent(datum, column.info);
				}
				return datum + "";
			case ColumnType.object:
				if (typeof datum !== "object") return JSON.stringify(datum);
				const direction: "vertical" | "horizontal" =
					(extraProps && extraProps.direction) || "vertical";
				return (
					<div
						className={cx(
							objectViewContainer,
							direction === "vertical" &&
								objectViewVerticalContainer,
							direction === "horizontal" &&
								objectViewHorizontalContainer
						)}
					>
						{colInfo.props.map(prop => {
							return (
								<div key={prop.key} className={objectRow}>
									<span>{prop.name}:</span>
									{this.viewComponent(
										datum[prop.key],
										prop.info
									)}
								</div>
							);
						})}
					</div>
				);
			default:
				return datum + "";
		}
	};

	mainComponent = () => {
		if (this.state.mode === "view") {
			if (this.props.col.info.type === ColumnType.api) {
				const api = this._APICall.findByIdSync({
					id: this.props.col.info.apiId,
					projectId: this.props.projectId,
				});
				if (
					api &&
					api.info.type === ApiCallType.action &&
					this.props.resourceId !== undefined
				) {
					return this.props.datum &&
						!api.info.canBeCalledMultipleTimes ? (
						<CheckIcon />
					) : (
						<button
							onClick={() =>
								this.props.onActionPopup({
									api: api as WithKnownKeyType<
										IRAPICall,
										"info",
										IAPICallAction
									>,
									resourceId: this.props.resourceId,
									columnId: this.props.col._id,
								})
							}
						>
							Action{this.props.datum && " ✓"}
						</button>
					);
				}
			}
			switch (this.props.datum) {
				case undefined:
				case SpecialValue.EMPTY:
					return null;
				case SpecialValue.PERMISSIONERROR:
					return <ForbiddenData />;
				case SpecialValue.PARSEERROR:
					return <ErrorData />;
				default:
					return this.viewComponent(
						this.props.datum,
						this.props.implicitColInfo
					);
			}
		}
		if (this.state.mode === "edit") {
			return (
				<ChangeContainer
					onConfirm={this.onConfirm}
					onReject={this.onReject}
					onClean={this.onClean}
				>
					{this.editComponent()}
				</ChangeContainer>
			);
		}
		if (this.state.mode === "loading") {
			return <SvgLoading style={{ width: 20, height: 20 }} />;
		}
		return <ErrorData />;
	};

	render() {
		return (
			<div onDoubleClick={this.toEditMode} className={datumContainerCSS}>
				{this.mainComponent()}
			</div>
		);
	}
}

const ForbiddenData = props => (
	<Tooltip title="არ გაქვთ ნახვის უფლება">
		<SvgForbidden style={SVGStyles} />
	</Tooltip>
);

const ErrorData = props => (
	<Tooltip title="დაფიქსირდა შეცდომა">
		<SvgError style={SVGStyles} />
	</Tooltip>
);

const SVGStyles = {
	width: 20,
	height: 20,
};

const datumContainerCSS = css`
	width: 100%;
	height: 100%;
	min-height: 30px;
	padding: 5px;
`;

const ListContainer = css`
	& > div {
		padding: 5px;
		border: none;
		border-bottom: 1px solid #ccc;
	}
	& > div:last-of-type {
		border-bottom: none;
	}
`;

const objectRow = css`
	display: flex;
`;

const objectViewContainer = css`
	display: flex;
`;
const objectViewVerticalContainer = css`
	flex-direction: column;
	& > .${objectRow} {
		flex-direction: row;
		margin: 5px 0;
	}
`;
const objectViewHorizontalContainer = css`
	flex-direction: row;
	& > .${objectRow} {
		flex-direction: column;
		margin: 0 5px;
	}
`;
