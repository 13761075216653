import Joi from "@app/utils/joi";
import { RStatsTableSchema, IRStatsTable } from "./helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";
import {
	FilterOptionsSchemaWithoutDollar,
	IFilterOptionsWithoutDollar,
} from "../resource-data/helper-schemas";

export const APOSTCreateStatsTableSchema = RStatsTableSchema.keys(
	insertStripKeys
);

export type IAPOSTCreateStatsTable = ToInsertKeys<IRStatsTable>;

export const RPOSTCreateStatsTableSchema = RStatsTableSchema;
export type IRPOSTCreateStatsTable = IRStatsTable;

///

export const AGETStatsTableSchema = Joi.object({
	projectId: Joi.objectId().required(),
	statsTableId: Joi.objectId().required(),
});
export interface IAGETStatsTable {
	projectId: ObjectId;
	statsTableId: ObjectId;
}

export const RGETStatsTableSchema = RStatsTableSchema;
export type IRGETStatsTable = IRStatsTable;

///

export const APUTStatsTableSchema = RStatsTableSchema.keys(updateStripKeys);
export type IAPUTStatsTable = ToUpdateKeys<IRStatsTable>;

///

export const ADELETEStatsTableSchema = AGETStatsTableSchema;
export type IADELETEStatsTable = IAGETStatsTable;

///

const AGETManyStatsTablesSchema = Joi.object({
	projectId: Joi.objectId().required(),
});
export interface IAGETManyStatsTables {
	projectId: ObjectId;
}

export const RGETManyStatsTablesSchema = Joi.array().items(RStatsTableSchema);
export type IRGETManyStatsTables = IRStatsTable[];

///

export const AGETStatisticsSchema = Joi.object({
	projectId: Joi.objectId().required(),
	statsTableId: Joi.objectId().required(),
	tableId: Joi.objectId().required(),
	timeZoneOffsetInMinutes: Joi.number()
		.integer()
		.required(),
	ignoreDateTimes: Joi.boolean().required(),
	globalFilter: FilterOptionsSchemaWithoutDollar,
});
export interface IAGETStatistics {
	projectId: ObjectId;
	statsTableId: ObjectId;
	tableId: ObjectId;
	timeZoneOffsetInMinutes: number;
	ignoreDateTimes: boolean;
	globalFilter?: IFilterOptionsWithoutDollar;
}

export const RGETStatisticsSchema = Joi.object({
	groups: Joi.array()
		.items(
			Joi.object({
				id: Joi.alternatives([Joi.number(), Joi.objectId()]).required(),
				name: Joi.string().required(),
				data: Joi.array()
					.items(Joi.number())
					.required(),
			})
		)
		.required(),
});
export interface IRGETStatistics {
	groups: {
		id: ObjectId | number;
		name: string;
		data: number[];
	}[];
}
