import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { addLoader } from "@app/commonJavascript";

export const PromiseConcurrent = async <K>(
	promises: readonly ((() => Promise<K>) | null)[],
	concurrentcy = Infinity
): Promise<K[]> => {
	if (promises.length === 0) return [];
	return new Promise<K[]>((resolve, reject) => {
		const result: K[] = [];
		let numOfConcurrentPromises = 0;
		let lastIndex = -1;

		const callNext = async () => {
			numOfConcurrentPromises++;
			lastIndex++;
			const myIndex = lastIndex;
			if (!promises[myIndex]) {
				finishPromise(promises[myIndex] as any, myIndex);
				return;
			}
			const promise = promises[myIndex]!() as Promise<K>;
			const data = await promise;
			finishPromise(data, myIndex);
		};
		const finishPromise = (data: K, index) => {
			result[index] = data;
			numOfConcurrentPromises--;
			if (
				lastIndex === promises.length - 1 &&
				numOfConcurrentPromises === 0
			) {
				resolve(result);
				return;
			}
			if (
				numOfConcurrentPromises < concurrentcy &&
				lastIndex < promises.length - 1
			) {
				callNext();
			}
		};
		const n = Math.min(concurrentcy, promises.length);
		for (let i = 0; i < n; i++) {
			callNext();
		}
	});
};

export const wrapInLoading = (promise: Promise<unknown>): Promise<void> => {
	const removeLoader = addLoader();
	return promise
		.then(() => {
			removeLoader();
		})
		.catch(() => {
			removeLoader();

			openConfirmationPopup({
				text: "დაფიქსირდა შეცდომა",
			});
		});
};
