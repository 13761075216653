import React from "react";
import styles from "./styles/radio-navigation.module.css";

interface IRadioNavigationProps<T extends string | number> {
	options: { value: T; label: string }[];
	value: { value: T; label: string } | undefined;
	onChange: (value: { value: T; label: string }) => void;
	prefix?: JSX.Element | string | number | null;
	suffix?: JSX.Element | string | number | null;
	direction?: "left" | "center";
}

export class RadioNavigation<
	T extends string | number
> extends React.PureComponent<IRadioNavigationProps<T>> {
	render() {
		return (
			<div
				className={
					styles.navigation +
					" " +
					(this.props.direction === "left"
						? styles.leftNavigation
						: "")
				}
			>
				{this.props.prefix}
				{this.props.options.map(option => {
					const classNames = [styles.tab];
					if (option === this.props.value) {
						classNames.push(styles.isSelected);
					}
					return (
						<div
							key={option.value}
							className={classNames.join(" ")}
							onClick={() => this.props.onChange(option)}
						>
							{option.label}
						</div>
					);
				})}
				{this.props.suffix}
			</div>
		);
	}
}
