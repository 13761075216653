import createCRUDReducer from "./crud";
import { IReduxStateInstance } from "./root-state";
import { IRTable } from "@app/api/tables/helper-schemas";
import { TableActions, tableCRUDActionTypes } from "@app/actions/tables";

function getNewTableState(
	state: IStateTables = {},
	action: TableActions
): IStateTables {
	switch (action.type) {
		default:
			return state;
	}
}

const crudReducer = createCRUDReducer<IRTable>(tableCRUDActionTypes);

export default function tables(state = {}, action: TableActions): IStateTables {
	const state1 = crudReducer(state, action);
	const newState = getNewTableState(state1, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("tables", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export type IStateTables = IReduxStateInstance<IRTable>;
