import React, { useCallback, useMemo } from "react";
import {
	IOneToOneMapLogic,
	IOneToOneMapPair,
} from "@app/api/functions/helper-schemas";
import { useDynamicRef, useValueChange, useFuncCall } from "@app/hooks/general";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { IColumnInfo, IColumnData } from "@app/api/columns/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { getEditComponent } from "../tables/utils";
import CloseIcon from "@material-ui/icons/Close";

export const OneToOneMapping: React.FC<{
	logic: IOneToOneMapLogic;
	inputSchema: IColumnInfo;
	outputSchema: IColumnInfo;
	onChange: (info: IOneToOneMapLogic) => void;
	projectId: ObjectId;
}> = React.memo(({ logic, onChange, inputSchema, outputSchema, projectId }) => {
	const logicRef = useDynamicRef(logic);
	const handleChange = useValueChange(onChange, logicRef);
	const handlePairsChange = useFuncCall(handleChange, "pairs" as const);
	const handleDefaultValueChange = useFuncCall(
		handleChange,
		"defaultValue" as const
	);

	const addPair = useCallback(() => {
		handlePairsChange([...logicRef.current.pairs, { in: null, out: null }]);
	}, [handlePairsChange, logicRef]);

	const handlePairDelete = useCallback(
		(index: number) => {
			handlePairsChange(
				logicRef.current.pairs.filter((e, i) => i !== index)
			);
		},
		[handlePairsChange, logicRef]
	);
	const handlePairChange = useCallback(
		(index: number, pair: IOneToOneMapPair) => {
			handlePairsChange(
				logicRef.current.pairs.map((e, i) => (i !== index ? e : pair))
			);
		},
		[handlePairsChange, logicRef]
	);

	return (
		<div>
			{logic.pairs.map((pair, index) => {
				return (
					<Pair
						key={index}
						pair={pair}
						index={index}
						onDelete={handlePairDelete}
						onChange={handlePairChange}
						inputSchema={inputSchema}
						outputSchema={outputSchema}
						projectId={projectId}
					/>
				);
			})}
			<Button variant="outlined" onClick={addPair}>
				<div className="middle">
					<AddIcon />
					<span>წყვილის დამატება</span>
				</div>
			</Button>
			<DefaultValue
				value={logic.defaultValue!}
				outputSchema={outputSchema}
				projectId={projectId}
				onChange={handleDefaultValueChange}
			/>
		</div>
	);
});

const Pair: React.FC<{
	pair: IOneToOneMapPair;
	index: number;
	onDelete: (index: number) => void;
	onChange: (index: number, pair: IOneToOneMapPair) => void;
	inputSchema: IColumnInfo;
	outputSchema: IColumnInfo;
	projectId: ObjectId;
}> = React.memo(
	({
		pair,
		index,
		onDelete,
		onChange,
		inputSchema,
		outputSchema,
		projectId,
	}) => {
		const pairRef = useDynamicRef(pair);
		const handleInputChange = useCallback(
			(val: IColumnData) => {
				onChange(index, { ...pairRef.current, in: val });
			},
			[index, onChange, pairRef]
		);
		const inputEditComponent = getEditComponent(
			projectId,
			inputSchema,
			pair.in,
			useMemo(
				() => ({
					onChange: handleInputChange,
					alwaysCallParentOnChange: true,
				}),
				[handleInputChange]
			)
		);

		const handleOutputChange = useCallback(
			(val: IColumnData) => {
				console.log(val);
				onChange(index, { ...pairRef.current, out: val });
			},
			[index, onChange, pairRef]
		);
		const outputEditComponent = getEditComponent(
			projectId,
			outputSchema,
			pair.out,
			useMemo(
				() => ({
					onChange: handleOutputChange,
					alwaysCallParentOnChange: true,
				}),
				[handleOutputChange]
			)
		);

		return (
			<div>
				<CloseIcon onClick={() => onDelete(index)} />
				in: <div>{inputEditComponent}</div>
				out: <div>{outputEditComponent}</div>
				<hr />
			</div>
		);
	}
);

const DefaultValue: React.FC<{
	value: IColumnData;
	onChange: (value: IColumnData) => void;
	outputSchema: IColumnInfo;
	projectId: ObjectId;
}> = React.memo(({ value, onChange, outputSchema, projectId }) => {
	const valueEditComponent = getEditComponent(
		projectId,
		outputSchema,
		value,
		useMemo(
			() => ({
				onChange,
				alwaysCallParentOnChange: true,
			}),
			[onChange]
		)
	);

	return <div>default value: {valueEditComponent}</div>;
});
