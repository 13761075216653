import * as React from "react";

const SvgClean = props => (
	<svg viewBox="0 0 860 860" fill={"currentColor"} {...props}>
		<path d="M726.5 77.3L555.1 409.5c1 .3 2 .6 2.9.9 7.2 2.3 14 5.2 20.4 8.5 7.6 4 14.6 8.8 20.8 14.2l171.7-332.9c6.3-12.3 1.5-27.3-10.8-33.7-12.2-6.3-27.3-1.4-33.6 10.8zM160.4 693.4c27.2 24.6 60.7 46 99.4 63.6 65.4 29.8 122.9 38.6 125.4 39h.2c.8.1 1.5.2 2.3.2h.6c68.8-2.2 122.4-25.2 159.4-68.1 48.6-56.5 66.2-141.4 62.7-214.4-1-20.9-8.4-42.2-23-57.5-1.1-1.1-2.2-2.2-3.4-3.3-5.1-4.7-10.9-8.6-17-11.8-5.3-2.8-10.9-5.1-16.5-6.9-2.4-.8-4.7-1.3-7.1-1.8-3.3-.6-6.6-.9-9.8-.9-24.1 0-47.2 15.2-64.9 30.6-18.2 16-36.8 33.4-57.4 47.1-22.6 15-47.4 22.5-73.2 29.9-33.4 9.5-67.6 15.6-102.1 19.4-30.6 3.4-61.6 5.4-92.6 5.4-12.3 0-24.5-.3-36.8-1-.7 0-1.4-.1-2.1-.1h-1.2c-5.3 0-10.2 2.4-13.5 6.6-3.5 4.5-4.4 10.5-2.6 15.9 14 40.6 38.6 76.9 73.2 108.1zm-23.8-95.5h5.4c25 0 59.9-1.1 99-5.6 82.7-9.4 150.2-29.5 201.2-60 1.5-.9 3.1-1.3 4.7-1.3 1.5 0 3.1.4 4.5 1.2 29.7 16.3 75.8 40.5 108.7 52.2 4.1 1.5 6.6 5.5 6.1 9.8-3.9 34.3-15.1 78-44.3 111.9-30 34.7-74.2 53.6-131.6 56h-.5c-.7 0-1.5-.1-2.2-.2-5.8-1-20-3.8-39.2-9 5.5-2.4 11.1-5.3 16.8-8.5 5.1-2.9 10.3-6 15.4-9.3 15.8-10.2 31-21.7 42.9-31.2 2.7-2.2.2-6.5-3.1-5.1-26 10.9-59.2 18.2-88.9 23-9.3 1.5-18.3 2.8-26.6 3.8-9.7 1.2-18.5 2.2-25.8 2.9-1.9-.8-3.8-1.7-5.8-2.6-16.5-7.6-31.9-15.9-46.2-24.8 6.2-.6 12.8-1.6 19.4-3 6-1.2 12.1-2.7 18.1-4.3 17.7-4.8 34.5-10.9 45.9-15.3 2.2-.8 1.4-4.1-.9-3.9-20.9 2-49.9 2.2-74.7 1.8-8.1-.1-15.8-.3-22.5-.5-8.9-.3-16.3-.5-21.1-.7-2.8-2.4-5.6-4.8-8.3-7.2-3.5-3.2-6.9-6.4-10.1-9.7 5-.5 10.2-1.3 15.5-2.3 5-.9 10-2.1 15-3.3 19.7-5 39-11.9 51.7-16.8 2.2-.8 1.4-4.1-.9-3.9-19.6 1.9-46.4 2.2-70.1 1.9-5.8-.1-11.5-.2-16.8-.3-5.9-.1-11.4-.3-16.3-.4-6.9-9.2-13-18.6-18.2-28.5-1.9-3.2.3-6.8 3.8-6.8z" />
		<circle cx={685} cy={683.5} r={40.1} />
		<circle cx={598.5} cy={775.4} r={20.3} />
		<circle cx={708.4} cy={770.7} r={20.3} />
	</svg>
);

export default SvgClean;
