import { Omit } from "./generics";
import ObjectID from "bson-objectid";

export function removeKeys<T, K extends keyof T>(
	obj: T,
	...keys: K[]
): Omit<T, K> {
	const obj2 = { ...obj };
	for (let i = 0; i < keys.length; ++i) {
		delete obj2[keys[i]];
	}
	return obj2;
}

export function pickKeys<T extends {}, K extends keyof T>(
	obj: T,
	...keys: K[]
): Pick<T, K> {
	const obj2 = {} as Pick<T, K>;
	for (let i = 0; i < keys.length; ++i) {
		if (obj.hasOwnProperty(keys[i]) || obj[keys[i]] !== undefined) {
			obj2[keys[i]] = obj[keys[i]];
		}
	}
	return obj2;
}

export function getUniqueId(currentIds: number[], numOfIds = 1): number[] {
	const ids = [...currentIds];
	const newIds: number[] = [];
	for (let i = 0; i < numOfIds; ++i) {
		let uid = 0;
		do {
			uid = Math.floor(Math.random() * 1e9);
		} while (ids.indexOf(uid) !== -1);
		newIds.push(uid);
		ids.push(uid);
	}
	return newIds;
}

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
export function jsonDateParser(key: any, value: any) {
	if (typeof value === "string") {
		const a = reISO.exec(value);
		if (a) return new Date(value);
	}
	return value;
}

export function getTimezoneOffsetInMinutes() {
	return new Date().getTimezoneOffset();
}

export function arrayToObject<T extends {}, K extends keyof T>(
	arr: readonly T[],
	mainKey: K,
	allowMultiple?: false
): { [key: string]: T | undefined };
export function arrayToObject<T extends {}, K extends keyof T>(
	arr: readonly T[],
	mainKey: K,
	allowMultiple: true
): { [key: string]: T[] | undefined };

export function arrayToObject<T extends {}, K extends keyof T>(
	arr: readonly T[],
	mainKey: K,
	allowMultiple: true
): { [key: string]: T[] | undefined };

export function arrayToObject<T extends {}, V>(
	arr: readonly T[],
	fn: (
		item: T,
		index: number,
		orgininalArr: T[]
	) => { key: string | number; value: V } | null,
	allowMultiple?: false
): { [key: string]: V | undefined };
export function arrayToObject<T extends {}, V>(
	arr: readonly T[],
	fn: (item: T) => { key: string | number; value: V } | null,
	allowMultiple: true
): { [key: string]: V[] | undefined };

export function arrayToObject(
	arr: readonly any[],
	mainKey: any,
	allowMultiple = false
): { [key: string]: any } {
	const obj: { [key: string]: any } = {};
	if (!allowMultiple) {
		for (let i = 0; i < arr.length; ++i) {
			let key = arr[i][mainKey as string];
			let value = arr[i];
			if (typeof mainKey === "function") {
				const temp = mainKey(arr[i], i, arr);
				if (!temp) continue;
				key = temp.key;
				value = temp.value;
			}
			obj[key] = value;
		}
	} else {
		for (let i = 0; i < arr.length; ++i) {
			let key = arr[i][mainKey as string];
			let value = arr[i];
			if (typeof mainKey === "function") {
				const temp = mainKey(arr[i], i, arr);
				if (!temp) continue;
				key = temp.key;
				value = temp.value;
			}
			if (!obj[key]) {
				obj[key] = [];
			}
			(obj[key] as any[]).push(value);
		}
	}
	return obj;
}

export const generateObjectId = () => new ObjectID().toHexString();

export function stateValueChange<S extends {}, K extends keyof S>(
	that: React.Component<any, S>,
	key: K,
	callback?: () => void
): (value: ((oldValue: S[K]) => S[K]) | S[K]) => void {
	return (func: ((oldVal: S[K]) => S[K]) | S[K]) => {
		return that.setState(
			({ [key]: val }) =>
				({
					[key]:
						typeof func === "function" ? (func as any)(val) : func,
				} as any),
			callback
		);
	};
}
