import { ObjectId } from "@app/utils/generics";
import React, { useCallback, useMemo, useEffect } from "react";
import Select from "react-select";

export const SelectModelData: React.FC<{
	options: { name: string; _id: ObjectId }[] | null | undefined;
	value: ObjectId | null | undefined;
	onChange: (newValue: ObjectId) => void;
	placeholder?: string;
	valueToPassIfEmpty?: any;
}> = React.memo(props => {
	const options: { label: string; value: string }[] = useMemo(() => {
		if (!props.options) return [];
		return props.options.map(e => ({ label: e.name, value: e._id }));
	}, [props.options]);

	const checked = useMemo(() => {
		return options.find(e => props.value === e.value);
	}, [options, props.value]);

	const { onChange, valueToPassIfEmpty } = props;

	const hasBeenPassedEmptyPlaceholder = "valueToPassIfEmpty" in props;
	useEffect(() => {
		if (!hasBeenPassedEmptyPlaceholder) return;
		if (!checked) {
			onChange(valueToPassIfEmpty);
		}
	}, [checked, hasBeenPassedEmptyPlaceholder, onChange, valueToPassIfEmpty]);

	const handleChange = useCallback(
		(selection: { label: string; value: string }) => {
			onChange(selection.value);
		},
		[onChange]
	);

	return (
		<Select
			options={options}
			value={checked || null}
			onChange={handleChange}
			placeholder={props.placeholder}
		/>
	);
});

export const SelectMultipleModelData: React.FC<{
	value: ObjectId[] | null | undefined;
	onChange: (newValue: ObjectId[]) => void;
	options: { name: string; _id: ObjectId }[] | null | undefined;
	placeholder?: string;
}> = React.memo(props => {
	const options: { label: string; value: string }[] = useMemo(() => {
		if (!props.options) return [];
		return props.options.map(e => ({ label: e.name, value: e._id }));
	}, [props.options]);

	const checked = useMemo(() => {
		if (!props.value) return [];
		return options.filter(e => props.value!.indexOf(e.value) > -1);
	}, [options, props.value]);

	const { onChange } = props;

	const handleChange = useCallback(
		(selections: { label: string; value: string }[]) => {
			onChange(selections.map(e => e.value));
		},
		[onChange]
	);

	return (
		<Select
			options={options}
			value={checked}
			isMulti={true}
			onChange={handleChange}
			placeholder={props.placeholder}
		/>
	);
});
