import * as React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AnyComponent, ObjectId } from "@app/utils/generics";
import { css } from "emotion";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

interface IRowInfo {
	_id: ObjectId;
	name: string;
	description?: string | null;
}

interface IProps {
	rows: IRowInfo[];
	canView?: (id: ObjectId) => boolean;
	canEdit?: (id: ObjectId) => boolean;
	canDelete?: (id: ObjectId) => boolean;
	onEdit?: (id: ObjectId) => void;
	onDelete?: (id: ObjectId) => void;
	onView?: (id: ObjectId) => void;
	iconsAfterComponent?: AnyComponent<{ row: IRowInfo; index: number }>;
	additionalComponents?: React.ComponentType<{
		rowId: ObjectId;
		iconClassName: string;
	}>;
}

export const AdminTable: React.FC<IProps> = props => {
	const IconsAfterComponent = props.iconsAfterComponent;
	return (
		<Paper className={TestTypesTableContainer}>
			<Table>
				<TableHead className={TestTypesTableHead}>
					<TableRow className={TestTypesTableRow}>
						<TableCell>სახელი</TableCell>
						<TableCell>განმარტება</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{props.rows.map((row, index) => (
						<TableRow key={row._id} className={TestTypesTableRow}>
							<TableCell>{row.name}</TableCell>
							<TableCell>{row.description}</TableCell>
							<TableCell>
								{props.onView &&
									props.canView &&
									props.canView(row._id) && (
										<VisibilityIcon
											className={SVG}
											onClick={() =>
												props.onView!(row._id)
											}
										/>
									)}
								{props.onEdit &&
									props.canEdit &&
									props.canEdit(row._id) && (
										<EditIcon
											className={SVG}
											onClick={() =>
												props.onEdit!(row._id)
											}
										/>
									)}
								{props.additionalComponents && (
									<props.additionalComponents
										rowId={row._id}
										iconClassName={SVG}
									/>
								)}
								{props.onDelete &&
									props.canDelete &&
									props.canDelete(row._id) && (
										<DeleteIcon
											className={SVG}
											onClick={() =>
												props.onDelete!(row._id)
											}
										/>
									)}
								{IconsAfterComponent && (
									<IconsAfterComponent
										row={row}
										index={index}
									/>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Paper>
	);
};

const TestTypesTableHead = css`
	font-size: 30px;
	font-family: BPGNinoMtavruli;
`;

const TestTypesTableRow = css`
	font-size: 25px;
	font-family: BPGNinoMtavruli;
`;

const TestTypesTableContainer = css`
	margin: 20px;
`;

export const SVG = css`
	color: red;
	margin: 0px 5px;
	cursor: pointer;
	color: grey;
	&:hover {
		color: #5f5a5a;
	}
`;
