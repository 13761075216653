import Joi from "@app/utils/joi";
import { RTableSchema, IRTable } from "./helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateTableSchema = RTableSchema.keys(insertStripKeys);

export type IAPOSTCreateTable = ToInsertKeys<IRTable>;

export const RPOSTCreateTableSchema = RTableSchema;
export type IRPOSTCreateTable = IRTable;

///

export const AGETTableSchema = Joi.object({
	projectId: Joi.objectId().required(),
	tableId: Joi.objectId().required(),
});
export interface IAGETTable {
	projectId: ObjectId;
	tableId: ObjectId;
}

export const RGETTableSchema = RTableSchema;
export type IRGETTable = IRTable;

///

export const APUTTableSchema = RTableSchema.keys(updateStripKeys);
export type IAPUTTable = ToUpdateKeys<IRTable>;

///

export const ADELETETableSchema = AGETTableSchema;
export type IADELETETable = IAGETTable;

///

const AGETManyTablesSchema = Joi.object({
	projectId: Joi.objectId().required(),
});
export interface IAGETManyTables {
	projectId: ObjectId;
}

export const RGETManyTablesSchema = Joi.array().items(RTableSchema);
export type IRGETManyTables = IRTable[];

///
