import React from "react";
import {
	IFunctionInfo,
	FunctionLogicType,
} from "@app/api/functions/helper-schemas";
import { useDynamicRef, useValueChange, useFuncCall } from "@app/hooks/general";
import { OneToOneMapping } from "./one-to-one";
import { ObjectId } from "@app/utils/generics";
import { ColumnInfoType } from "../columns/add";

export const FunctionInfo: React.FC<{
	info: IFunctionInfo;
	onChange: (info: IFunctionInfo) => void;
	projectId: ObjectId;
}> = React.memo(({ info, onChange, projectId }) => {
	const infoRef = useDynamicRef(info);
	const handleValueChange = useValueChange(onChange, infoRef);
	const onLogicChange = useFuncCall(handleValueChange, "logic" as const);
	const onInputSchemaChange = useFuncCall(
		handleValueChange,
		"inputSchema" as const
	);
	const onOutputSchemaChange = useFuncCall(
		handleValueChange,
		"outputSchema" as const
	);

	return (
		<div>
			inputSchema
			<ColumnInfoType
				info={info.inputSchema!}
				allow="all"
				onChange={onInputSchemaChange}
				projectId={projectId}
			/>
			<hr />
			outputSchema
			<ColumnInfoType
				info={info.outputSchema!}
				allow="all"
				onChange={onOutputSchemaChange}
				projectId={projectId}
			/>
			{info.logic.type === FunctionLogicType.discrete && (
				<OneToOneMapping
					logic={info.logic}
					onChange={onLogicChange}
					inputSchema={info.inputSchema!}
					outputSchema={info.outputSchema!}
					projectId={projectId}
				/>
			)}
		</div>
	);
});
