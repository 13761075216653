import * as React from "react";
import {
	ContextMenu,
	MenuItem,
	connectMenu,
	ConnectMenuProps,
} from "react-contextmenu";
import "./styles/context-menu.min.css";
import {
	IColumnInfo,
	ColumnType,
	IAPIColumnInfo,
} from "@app/api/columns/helper-schemas";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { ApiCallType } from "@app/api/api-calls/helper-schemas";

export interface ITriggerAttributes {
	colInfo: IColumnInfo;
	projectId: ObjectId;
}

interface IMyMenuProps extends ConnectMenuProps {
	onSelect: Function;
	trigger: null | ITriggerAttributes;
	hideFilter?: boolean;
}

export enum MenuAction {
	filter,
	sortASC,
	sortDESC,
	manyActions,
}

const MENU_TYPE = "multi";

const MyMenu: React.FC<IMyMenuProps> = (props: IMyMenuProps) => {
	return (
		<ContextMenu id={props.id}>
			{!props.hideFilter && (
				<MenuItem
					onClick={props.onSelect}
					data={{ action: MenuAction.filter }}
				>
					გაფილტრვა
				</MenuItem>
			)}
			<MenuItem
				onClick={props.onSelect}
				data={{ action: MenuAction.sortASC }}
			>
				ზრდადობით დალაგება
			</MenuItem>
			<MenuItem
				onClick={props.onSelect}
				data={{ action: MenuAction.sortDESC }}
			>
				კლებადობით დალაგება
			</MenuItem>
			{props.trigger?.colInfo?.type === ColumnType.api && (
				<APICallMenuItems
					onSelect={props.onSelect}
					colInfo={props.trigger.colInfo}
					projectId={props.trigger.projectId}
				/>
			)}
		</ContextMenu>
	);
};

const APICallMenuItems: React.FC<{
	colInfo: IAPIColumnInfo;
	onSelect: Function;
	projectId: ObjectId;
}> = props => {
	const APICall = inject("APICall");
	const api = APICall.findByIdSync({
		id: props.colInfo.apiId,
		projectId: props.projectId,
	});
	if (!api) return null;
	if (api.info.type !== ApiCallType.action) return null;
	return (
		<MenuItem
			onClick={props.onSelect}
			data={{ action: MenuAction.manyActions }}
		>
			ბევრის ერთად
		</MenuItem>
	);
};

const ConnectedMenu = connectMenu<Omit<IMyMenuProps, keyof ConnectMenuProps>>(
	MENU_TYPE
)(MyMenu);

export { ConnectedMenu as MyMenu };
