import createCRUDReducer from "./crud";
import { APICallActions, apiCallCRUDActionTypes } from "@app/actions/api-calls";
import { IRAPICall } from "@app/api/api-calls/helper-schemas";
import { IReduxStateInstance } from "./root-state";

function getNewAPICallState(
	state: IStateAPICalls = {},
	action: APICallActions
): IStateAPICalls {
	switch (action.type) {
		default:
			return state;
	}
}

const crudReducer = createCRUDReducer<IRAPICall>(apiCallCRUDActionTypes);

export default function apiCalls(
	state = {},
	action: APICallActions
): IStateAPICalls {
	const state1 = crudReducer(state, action);
	const newState = getNewAPICallState(state1, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("api-calls", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export type IStateAPICalls = IReduxStateInstance<IRAPICall>;
