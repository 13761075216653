import * as React from "react";
import * as ReactDOM from "react-dom";

export function addLoader() {
	const loaderDiv = document.createElement("div");
	loaderDiv.setAttribute("class", "loaderDiv");
	ReactDOM.render(
		<div className="loaderChild" />,
		document.body.appendChild(loaderDiv)
	);
	return () => {
		if (
			typeof loaderDiv.parentNode !== "undefined" &&
			loaderDiv.parentNode !== null
		) {
			loaderDiv.parentNode.removeChild(loaderDiv);
		}
	};
}

function toGeorgianOrdinalNumber(num: number, mode?: 0 | 1 | 2): string {
	// რიგითობაში
	if (num === 1) {
		if (typeof mode === "undefined") return "I";
		if (mode === 0) return "1-ლი";
		if (mode === 1) return "1-ელ";
		return "1-ელმა";
	}
	if (
		num <= 20 ||
		(num <= 100 && num % 20 === 0) ||
		(num <= 1000 && num % 100 === 0)
	)
		return "მე-" + num;
	return num + "-ე";
}

export function romanize(num: number): string {
	const lookup = {
		M: 1000,
		CM: 900,
		D: 500,
		CD: 400,
		C: 100,
		XC: 90,
		L: 50,
		XL: 40,
		X: 10,
		IX: 9,
		V: 5,
		IV: 4,
		I: 1,
	};
	let roman = "";
	for (const i in lookup) {
		if (lookup.hasOwnProperty(i)) {
			while (num >= lookup[i]) {
				roman += i;
				num -= lookup[i];
			}
		}
	}
	return roman;
}

export function toDecadeNames(decade: number, century: number): string {
	if (decade === 0) return toDecadeNames(century * 100, century + 1);
	return decade + "იანი";
}

export { toGeorgianOrdinalNumber };

const _console: { log: Function; error: Function } = {
	log: (...args) => {
		// tslint:disable-next-line
		console.log(...args);
	},
	error: (...args) => {
		// tslint:disable-next-line
		console.error(...args);
	},
};
export { _console };

const weekDaysGeo: string[] = [
	"ორშაბათი",
	"სამშაბათი",
	"ოთხშაბათი",
	"ხუთშაბათი",
	"პარასკევი",
	"შაბათი",
	"კვირა",
];
const weekDaysAbbrGeo: string[] = [
	"ორშ",
	"სამ",
	"ოთხ",
	"ხუთ",
	"პარ",
	"შაბ",
	"კვ",
];
// tslint:disable-next-line
const weekDaysGeoNatesaobiti: string[] = [
	"ორშაბათის",
	"სამშაბათის",
	"ოთხშაბათის",
	"ხუთშაბათის",
	"პარასკევის",
	"შაბათის",
	"კვირის",
];
// tslint:disable-next-line
const monthNamesGeo = [
	"იანვარი",
	"თებერვალი",
	"მარტი",
	"აპრილი",
	"მაისი",
	"ივნისი",
	"ივლისი",
	"აგვისტო",
	"სექტემბერი",
	"ოქტომბერი",
	"ნოემბერი",
	"დეკემბერი",
];
export { weekDaysGeo, weekDaysAbbrGeo, weekDaysGeoNatesaobiti, monthNamesGeo };

const funcToPromise = (func: Function) =>
	new Promise((resolve, reject) => {
		try {
			resolve(func());
		} catch (e) {
			reject();
		}
	});
export { funcToPromise };

export const animateHTMLElement = (
	element: HTMLElement,
	propertyName: string,
	endPosition: number,
	duration: number
) => {
	const startPosition = element[propertyName];
	const startTime = performance.now();
	const animate = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		element.scrollTo(
			0,
			percentage * (endPosition - startPosition) + startPosition
		);
		if (percentage < 1) window.requestAnimationFrame(animate);
	};
	window.requestAnimationFrame(animate);
};

const animateWindowScroll = (whereToScrollTop: number, duration: number) => {
	const scrollTop = window.pageYOffset || document.documentElement!.scrollTop;
	const startTime = performance.now();
	const animateScroll = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		window.scrollTo(
			0,
			percentage * (whereToScrollTop - scrollTop) + scrollTop
		);
		if (percentage < 1) window.requestAnimationFrame(animateScroll);
	};
	window.requestAnimationFrame(animateScroll);
};
export { animateWindowScroll };

const getQueryStringParams = (query: string): { [key: string]: string } =>
	query
		? (/^[?#]/.test(query) ? query.slice(1) : query)
				.split("&")
				.reduce((params, param) => {
					const [key, value] = param.split("=");
					params[key] = value
						? decodeURIComponent(value.replace(/\+/g, " "))
						: "";
					return params;
				}, {})
		: {};
export { getQueryStringParams };

export const shortenName = (name: string, maxLength = 75): string => {
	return name.length <= maxLength
		? name
		: name.substr(0, maxLength - 2).trim() + "..";
};

export function getHTMLElementCoords(elem: HTMLElement) {
	// crossbrowser version
	const box = elem.getBoundingClientRect();

	const body = document.body;
	const docEl = document.documentElement;

	const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
	const scrollLeft =
		window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

	const clientTop = docEl.clientTop || body.clientTop || 0;
	const clientLeft = docEl.clientLeft || body.clientLeft || 0;

	const top = box.top + scrollTop - clientTop;
	const left = box.left + scrollLeft - clientLeft;

	return { top, left };
}
(window as any).getHTMLElementCoords = getHTMLElementCoords;

export function extractVideoIdFromYoutubeLink(link: string): string {
	if (link.indexOf("youtu.be") >= 0) {
		const endIndex = link.indexOf("?");
		return link.substring(
			link.lastIndexOf("/") + 1,
			endIndex > -1 ? endIndex : undefined
		);
	}
	if (link.indexOf("youtube") >= 0) {
		const endIndex = link.indexOf("&");
		return link.substring(
			link.indexOf("=") + 1,
			endIndex > -1 ? endIndex : undefined
		);
	}
	return "";
}

export function getVideoThumbNailUrl(videoLink: string): string {
	const youtubeVideoId = extractVideoIdFromYoutubeLink(videoLink);
	return `https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`;
}

export const getGalleryMediaUrl = (
	type: "PHOTO" | "VIDEO",
	url: string
): string => {
	if (type === "PHOTO") {
		return `/photos/gallery/${url}`;
	}
	return getVideoThumbNailUrl(url);
};

export const getNewsOrEventMediaUrl = (
	type: "PHOTO" | "VIDEO",
	url: string
): string => {
	if (type === "PHOTO") {
		return `/photos/etc/${url}`;
	}
	return getVideoThumbNailUrl(url);
};

export const round = (num: number, precision = 0): number => {
	if (precision < 0) return -1;
	const numString = String(num);
	const decimalPointIndex = numString.indexOf(".");
	if (decimalPointIndex < 0) return num;
	if (numString.length - decimalPointIndex - 1 <= precision) return num;
	const leastSignificantDigit = Number(
		numString.charAt(decimalPointIndex + precision + 1)
	);
	const lastDigitPosition =
		precision > 0 ? decimalPointIndex + precision : decimalPointIndex - 1;
	let lastDigit = Number(numString.charAt(lastDigitPosition));
	if (leastSignificantDigit >= 5) lastDigit++;
	const newString = numString.substring(0, lastDigitPosition) + lastDigit;
	return Number(newString);
};

export function roundNumberDecimalPlaces(value: number, decimals: number) {
	return Number(Math.round(+(+(value + "e" + decimals) + "e-" + decimals)));
}

function isValidDateString(str: string): boolean {
	return !Number.isNaN(Date.parse(str));
}

export function parseDate(str: string): Date {
	if (isValidDateString(str)) {
		return new Date(str);
	}
	throw new Error(`"${str}" cannot be parsed to date`);
}

export function toGeorgianDate(d: string | Date | null): string {
	if (!(d instanceof Date)) {
		try {
			d = parseDate(d as string);
		} catch (e) {
			return "";
		}
	}
	const monthIndex = d.getMonth();
	const monthName = monthNamesGeo[monthIndex];
	return `${d.getDate()} ${monthName}, ${d.getFullYear()}`;
}

export function toGeorgianDateAndTime(d: string | Date | null): string {
	if (!(d instanceof Date)) {
		try {
			d = parseDate(d as string);
		} catch (e) {
			return "";
		}
	}
	const monthIndex = d.getMonth();
	const monthName = monthNamesGeo[monthIndex];
	return `${d.getDate()} ${monthName}, ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
}
