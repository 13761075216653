import { apiCallCRUDActions } from "@app/actions/api-calls";
import {
	IAPICallAction,
	IRAPICall,
	IRequestPropertiesData,
	IAPICallRequestData,
} from "@app/api/api-calls/helper-schemas";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { ToDispatchType } from "@app/reducers/root-state";
import { ObjectId, WithKnownKeyType } from "@app/utils/generics";
import * as React from "react";
import { connect } from "react-redux";
import api from "@app/api";
import { Line } from "rc-progress";
import { PromiseConcurrent } from "@app/utils/promise";

interface IOwnProps {
	api: WithKnownKeyType<IRAPICall, "info", IAPICallAction>;
	resourceIds: number[] | string[];
	columnId: ObjectId;
	onClose: () => void;
}

enum Mode {
	chooseNumOfResources,
	display,
	actionStarted,
}

interface IState {
	mode: Mode;
	resourceIds: number[] | string[];
	chosenNumOfResources: number;
	chosenIds: number[] | string[];
	infos: ({
		resourceId: number | string;
		requestType: IRequestPropertiesData;
		requestData: IAPICallRequestData;
	} | null)[];
	actionFilledFor: number;
	actionSentTo: number;
	actionSuccessfullySentTo: number;
}

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;
type IProps = IDispatchProps & IOwnProps;

function shuffleArray<T>(a: T[]): T[] {
	a = [...a];
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const x = a[i];
		a[i] = a[j];
		a[j] = x;
	}
	return a;
}

class APICallManyActionsViewOuter extends React.PureComponent<IProps, IState> {
	state: IState = {
		resourceIds: this.props.resourceIds,
		chosenNumOfResources: this.props.resourceIds.length,
		mode: Mode.chooseNumOfResources,
		chosenIds: [],
		infos: [],
		actionSentTo: 0,
		actionFilledFor: 0,
		actionSuccessfullySentTo: 0,
	};

	_isMounted = false;

	componentDidMount = () => {
		this._isMounted = true;
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	onPopupClose = () => {
		this.props.onClose();
	};

	onChosenNumOfResourcesChange = e => {
		this.setState({
			chosenNumOfResources: +e.target.value,
		});
	};

	onSubmitChosenNumOfResources = () => {
		if (this.state.chosenNumOfResources <= 0) {
			alert("აირჩიეთ მინიმუმ 1");
			return;
		}
		if (this.state.chosenNumOfResources > this.state.resourceIds.length) {
			alert("აირჩიეთ მაქსიმუმ " + this.state.resourceIds.length);
			return;
		}
		const chosenIds = shuffleArray(this.state.resourceIds as number[]) as
			| number[]
			| string[];
		chosenIds.length = this.state.chosenNumOfResources;
		this.setState(
			{
				mode: Mode.display,
				chosenIds: chosenIds,
			},
			this.sendParallelRequests
		);
	};

	sendParallelRequests = () => {
		PromiseConcurrent(
			(this.state.chosenIds as number[]).map(resourceId => {
				return () => {
					return api.apiCalls
						.getActionFillableForm({
							_id: this.props.api._id,
							projectId: this.props.api.projectId,
							resIds: resourceId,
						})
						.then(e => {
							if (!this._isMounted) return;
							this.setState(({ infos, actionFilledFor }) => ({
								infos: [
									...infos,
									{
										resourceId: resourceId,
										requestData: e.requestData,
										requestType: e.requestType,
									},
								],
								actionFilledFor: actionFilledFor + 1,
							}));
						})
						.catch(e => {
							if (!this._isMounted) return;
							this.setState(({ infos }) => ({
								infos: [...infos, null],
							}));
						});
				};
			}),
			5
		);
	};

	onStartAction = () => {
		this.setState({
			mode: Mode.actionStarted,
		});
		PromiseConcurrent(
			this.state.infos.map(info => {
				if (!info) return null;
				return () => {
					return api.apiCalls
						.callAction({
							_id: this.props.api._id,
							projectId: this.props.api.projectId,
							resIds: info.resourceId,
							columnId: this.props.columnId,
							requestData: info.requestData,
						})
						.then(e => {
							if (!this._isMounted) return;
							this.setState(
								({
									actionSentTo,
									actionSuccessfullySentTo,
								}) => ({
									actionSentTo: actionSentTo + 1,
									actionSuccessfullySentTo:
										actionSuccessfullySentTo + 1,
								})
							);
						})
						.catch(e => {
							if (!this._isMounted) return;
							this.setState(({ actionSentTo }) => ({
								actionSentTo: actionSentTo + 1,
							}));
						});
				};
			}),
			5
		);
	};

	render() {
		return (
			<Popup>
				<PopupContentWithClose onClose={this.onPopupClose}>
					{this.state.mode === Mode.chooseNumOfResources && (
						<div>
							სულ: {this.state.resourceIds.length} მომხმარებელი
							<br />
							<TextField
								label="მომხმარებელთა სასურველი რაოდენობა:"
								defaultValue={this.state.chosenNumOfResources}
								onChange={this.onChosenNumOfResourcesChange}
								margin="dense"
								type="number"
								variant="outlined"
								style={{
									width: 500,
								}}
							/>
							<br />
							<Button
								variant="contained"
								color="primary"
								onClick={this.onSubmitChosenNumOfResources}
							>
								დასტური
							</Button>
						</div>
					)}
					{this.state.mode === Mode.display && (
						<div>
							{this.state.infos.length <
								this.state.chosenIds.length && (
								<div>
									მიმდინარეობს მონაცემების დამუშავება <br />
									დამუშავდა{" "}
									{(
										(this.state.infos.length /
											this.state.chosenIds.length) *
										100
									).toFixed(2)}
									%
									<Line
										percent={
											(this.state.infos.length /
												this.state.chosenIds.length) *
											100
										}
										strokeWidth={4}
										strokeColor="#4189cb"
									/>
								</div>
							)}
							{this.state.infos.length ===
								this.state.chosenIds.length && (
								<div>
									მონაცემები დამუშავდა{" "}
									{this.state.actionFilledFor}{" "}
									მომხმარებლისთვის <br />
									<Button
										variant="contained"
										color="primary"
										onClick={this.onStartAction}
									>
										ქმედების გაშვება
									</Button>
								</div>
							)}
						</div>
					)}
					{this.state.mode === Mode.actionStarted && (
						<div>
							{this.state.actionSentTo <
								this.state.actionFilledFor && (
								<div>
									ქმედება ხორციელდება <br />
									<Line
										percent={
											(this.state.actionSentTo /
												this.state.actionFilledFor) *
											100
										}
										strokeWidth={4}
										strokeColor="#4189cb"
									/>
								</div>
							)}
							{this.state.actionSentTo ===
								this.state.actionFilledFor && (
								<div>
									ქმედება წარმატებით განხორციელდა{" "}
									{this.state.actionSuccessfullySentTo}{" "}
									მომხმარებლისთვის!
								</div>
							)}
						</div>
					)}
				</PopupContentWithClose>
			</Popup>
		);
	}
}

const mapDispatchToProps = {
	loadManyAPICalls: apiCallCRUDActions.loadMany,
};

const connected = connect<null, IDispatchProps, IOwnProps>(
	null,
	mapDispatchToProps
)(APICallManyActionsViewOuter);
export { connected as APICallManyActionsViewOuter };
