import { Requests } from "..";
import {
	IAGETManyResourcesData,
	IAPUTResourceData,
	IRGETManyResourcesData,
	IRPUTResourceData,
	RGETManyResourcesDataSchema,
	IAPOSTImportData,
	IRPOSTImportData,
	IAPUTReloadData,
	IAGETManyResourceIds,
	IRGETManyResourceIds,
	RGETManyResourceIdsSchema,
} from "./validators";

// tslint:disable:no-duplicate-string
const resourceData = {
	getCachedData: (
		data: IAGETManyResourcesData
	): Promise<IRGETManyResourcesData> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/resource-data/filter",
			data,
			null,
			{
				responseSchema: RGETManyResourcesDataSchema,
			}
		),
	getResourceIds: (
		data: IAGETManyResourceIds
	): Promise<IRGETManyResourceIds> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/resource-data/filter-resource-ids",
			data,
			null,
			{
				responseSchema: RGETManyResourceIdsSchema,
			}
		),
	exportCachedData: (data: IAGETManyResourcesData): Promise<any> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/resource-data/export",
			data,
			{
				responseType: "blob",
			}
		),
	updateData: (data: IAPUTResourceData): Promise<IRPUTResourceData> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/resource-data/values",
			data
		),
	importFile: (data: IAPOSTImportData): Promise<IRPOSTImportData> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/resource-data/import",
			data
		),
	reload: (data: IAPUTReloadData): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/resource-data/reload",
			data
		),
};
export default resourceData;
