import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import { Schema } from "joi";

export enum ColumnType {
	resourceId = "RESID",
	enum = "ENUM",
	boolean = "BOOL",
	string = "STR",
	number = "NUM",
	date = "DATE",
	object = "OBJ",
	list = "LIST",
	api = "API",
	colReference = "COLREF",
	function = "func",
}

export const ColumnTypes = [
	ColumnType.resourceId,
	ColumnType.enum,
	ColumnType.boolean,
	ColumnType.string,
	ColumnType.number,
	ColumnType.date,
	ColumnType.object,
	ColumnType.list,
	ColumnType.api,
	ColumnType.colReference,
	ColumnType.function,
];

export const ResourceIdColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.resourceId)
		.required(),
});
export interface IResourceIdColumnInfo {
	type: ColumnType.resourceId;
}

export const ColReferenceColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.colReference)
		.required(),
	colId: Joi.objectId().required(),
});
export interface IColReferenceColumnInfo {
	type: ColumnType.colReference;
	colId: ObjectId;
}

export const BooleanColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.boolean)
		.required(),
});
export interface IBooleanColumnInfo {
	type: ColumnType.boolean;
}

export const EnumColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.enum)
		.required(),
	enumerables: Joi.array()
		.items(
			Joi.object({
				id: Joi.number()
					.integer()
					.required(),
				val: Joi.string().required(),
			})
		)
		.required(),
});
export interface IEnumColumnInfo {
	type: ColumnType.enum;
	enumerables: {
		id: number;
		val: string;
	}[];
}

export const StringColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.string)
		.required(),
	textarea: Joi.boolean(),
});
export interface IStringColumnInfo {
	type: ColumnType.string;
	textarea?: boolean;
}

export const NumberColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.number)
		.required(),
});
export type INumberColumnInfo = {
	type: ColumnType.number;
} & {};

export const DateColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.date)
		.required(),
});
export type IDateColumnInfo = {
	type: ColumnType.date;
} & {};

export const PrimitiveColumnInfoSchema = Joi.alternatives([
	BooleanColumnInfoSchema,
	NumberColumnInfoSchema,
	StringColumnInfoSchema,
	DateColumnInfoSchema,
	EnumColumnInfoSchema,
]);

export const ObjectColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.object)
		.required(),
	props: Joi.array()
		.items(
			Joi.object({
				key: Joi.string().required(),
				name: Joi.string().required(),
				info: Joi.link("#ColumnInfoSchema").required(),
			})
		)
		.required(),
});
export interface IObjectColumnInfo<T extends Record<any, any> = {}> {
	type: ColumnType.object;
	props: {
		key: string;
		name: string;
		info: IColumnInfo<T>;
	}[];
}

export const SimpleObjectColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.object)
		.required(),
	props: Joi.array()
		.items(
			Joi.object({
				key: Joi.string().required(),
				name: Joi.string().required(),
				info: Joi.link("#ColumnInfoSchema").required(),
			})
		)
		.required(),
});
export interface ISimpleObjectColumnInfo {
	type: ColumnType.object;
	props: {
		key: string;
		name: string;
		info: ISimpleColumnInfo;
	}[];
}

export const ListColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.list)
		.required(),
	itemsInfo: Joi.link("#ColumnInfoSchema").required(),
});
export interface IListColumnInfo<T extends Record<any, any> = {}> {
	type: ColumnType.list;
	itemsInfo: IColumnInfo<T>;
}

const SimpleListColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.list)
		.required(),
	itemsInfo: Joi.link("#SimpleColumnInfoSchema").required(),
});
interface ISimpleListColumnInfo {
	type: ColumnType.list;
	itemsInfo: ISimpleColumnInfo;
}

export const APIColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.api)
		.required(),
	apiId: Joi.objectId().required(),
	key: Joi.string(),
});
export interface IAPIColumnInfo {
	type: ColumnType.api;
	apiId: ObjectId;
	key?: string | undefined;
}

export const FunctionColumnInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ColumnType.function)
		.required(),
	funcId: Joi.objectId().required(),
	inputInfo: Joi.link("#ColumnInfoSchema").required(),
});
export interface IFunctionColumnInfo<T extends Record<any, any> = {}> {
	type: ColumnType.function;
	funcId: ObjectId;
	inputInfo: IColumnInfo<T>;
}

export const SimpleColumnInfoSchema = Joi.alternatives([
	BooleanColumnInfoSchema,
	NumberColumnInfoSchema,
	StringColumnInfoSchema,
	DateColumnInfoSchema,
	SimpleListColumnInfoSchema,
	SimpleObjectColumnInfoSchema,
	ResourceIdColumnInfoSchema,
	EnumColumnInfoSchema,
]).id("SimpleColumnInfoSchema");
export type ISimpleColumnInfo =
	| IBooleanColumnInfo
	| INumberColumnInfo
	| IStringColumnInfo
	| IDateColumnInfo
	| ISimpleListColumnInfo
	| ISimpleObjectColumnInfo
	| IResourceIdColumnInfo
	| IEnumColumnInfo;

export const ColumnInfoSchema = Joi.alternatives([
	BooleanColumnInfoSchema,
	NumberColumnInfoSchema,
	StringColumnInfoSchema,
	DateColumnInfoSchema,
	ObjectColumnInfoSchema,
	ListColumnInfoSchema,
	ResourceIdColumnInfoSchema,
	APIColumnInfoSchema,
	EnumColumnInfoSchema,
	ColReferenceColumnInfoSchema,
	FunctionColumnInfoSchema,
]).id("ColumnInfoSchema");
export type IColumnInfo<T extends Record<any, any> = {}> = (
	| IBooleanColumnInfo
	| INumberColumnInfo
	| IStringColumnInfo
	| IDateColumnInfo
	| IListColumnInfo<T>
	| IObjectColumnInfo<T>
	| IResourceIdColumnInfo
	| IEnumColumnInfo
	| IAPIColumnInfo
	| IColReferenceColumnInfo
	| IFunctionColumnInfo<T>
) &
	T;

export function getCustomColumnInfoSchema<T extends { [key: string]: Schema }>(
	k: T
): Schema {
	const schema = Joi.alternatives([
		BooleanColumnInfoSchema.keys(k),
		NumberColumnInfoSchema.keys(k),
		StringColumnInfoSchema.keys(k),
		DateColumnInfoSchema.keys(k),
		Joi.object({
			// ObjectColumnInfoSchema
			type: Joi.string()
				.valid(ColumnType.object)
				.required(),
			props: Joi.array()
				.items(
					Joi.object({
						key: Joi.string().required(),
						name: Joi.string().required(),
						info: Joi.link("#Ischema").required(),
					})
				)
				.required(),
		}).keys(k),
		Joi.object({
			// ListColumnInfoSchema
			type: Joi.string()
				.valid(ColumnType.list)
				.required(),
			itemsInfo: Joi.link("#Ischema").required(),
		}).keys(k),
		Joi.object({
			type: Joi.string()
				.valid(ColumnType.function)
				.required(),
			funcId: Joi.objectId().required(),
			inputInfo: Joi.link("#Ischema").required(),
		}).keys(k),
		ResourceIdColumnInfoSchema.keys(k),
		APIColumnInfoSchema.keys(k),
		EnumColumnInfoSchema.keys(k),
		ColReferenceColumnInfoSchema.keys(k),
	]).id("Ischema");
	return schema as any;
}
// Easy to check with this code
// const bb = getCustomColumnInfoSchema({ hey: Joi.boolean().required() });
// const cc = (6 as any) as SchemaToType<typeof bb>;
// if (cc.type === ColumnType.list) {
// cc.itemsInfo.
// }

export const SpecialValue = {
	PARSEERROR: "MRTSKPRSERROR",
	EMPTY: null,
	PERMISSIONERROR: "MRTSKPERMERROR",
} as const;
export type ISpecialValue = typeof SpecialValue[keyof typeof SpecialValue];
export const SPECIAL_VALUES = Object.keys(SpecialValue).map(
	e => SpecialValue[e]
) as ISpecialValue[];

export type IResourceIdDataField = string | number;

type ToVal<T> = T | ISpecialValue | undefined;

const EnumDataSchema = Joi.number()
	.integer()
	.allow(...SPECIAL_VALUES);
export type IEnumDataField = ToVal<number>;

const BooleanDataSchema = Joi.boolean().allow(...SPECIAL_VALUES);
export type IBooleanDataField = ToVal<boolean>;
const StringDataSchema = Joi.string().allow("", ...SPECIAL_VALUES);
export type IStringDataField = ToVal<string>;
const NumberDataSchema = Joi.number().allow(...SPECIAL_VALUES);
export type INumberDataField = ToVal<number>;
const DateDataSchema = Joi.date()
	.iso()
	.allow(...SPECIAL_VALUES);
export type IDateDataField = ToVal<Date>;

export type IPrimitiveDataTypes = ToVal<
	string | number | boolean | IEnumColumnInfo | Date | null
>;

const ObjectDataSchema = Joi.object().allow(...SPECIAL_VALUES);
export type IObjectData =
	| { [key: string]: IPrimitiveDataTypes | undefined }
	| ISpecialValue
	| undefined;

const ListDataSchema = Joi.array()
	.items(Joi.link("#ColumnDataSchema"))
	.allow(...SPECIAL_VALUES);
type IListData = any[] | ISpecialValue | undefined;

export const ColumnDataSchema = Joi.alternatives([
	NumberDataSchema.strict(),
	EnumDataSchema.strict(),
	BooleanDataSchema,
	DateDataSchema,
	StringDataSchema,
	ObjectDataSchema,
	ListDataSchema,
])
	.allow(...SPECIAL_VALUES)
	.id("ColumnDataSchema");
export type IColumnData =
	| IBooleanDataField
	| IStringDataField
	| INumberDataField
	| IDateDataField
	| IObjectData
	| IListData
	| IEnumDataField
	| IResourceIdDataField
	| ISpecialValue
	| undefined;

export const RColumnSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	name: Joi.string().required(),
	info: ColumnInfoSchema.required(),
	description: Joi.string().allow(""),
	author: Joi.number().required(),
});
export interface IRColumn {
	_id: ObjectId;
	projectId: ObjectId;
	name: string;
	description?: string | undefined;
	info: IColumnInfo;
	author: number;
}
