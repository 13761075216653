import { IRootActions, IRootState } from "@app/reducers/root-state";
import { Store } from "redux";

interface IDataHolder {
	[id: string]: Promise<any> | undefined;
}
const apis = {};

class ReduxApiService {

	protected data: IDataHolder;
	constructor(fieldName: keyof IRootState) {
		this.data = {};
	}

	existsPromise(docId: string | number) {
		if (this.data[docId]) return true;
		return false;
	}

	getPromise(docId: string | number): Promise<any> | undefined {
		return this.data[docId];
	}

	setPromise(docId: string | number, promise: Promise<any>) {
		this.data[docId] = promise;
		promise.then((data) => {
			setTimeout(() => this.clearPromise(docId), 0);
			return data;
		});
	}

	protected clearPromise(docId: string | number) {
		delete this.data[docId];
	}

}
export { ReduxApiService };
