import { APICallModel } from "./models/api-calls";
import { ColumnModel } from "./models/column";
import { IRootActions, IRootState } from "./reducers/root-state";
import { ProjectModel } from "./models/project";
import { StatsTableModel } from "./models/stats-table";
import { Store } from "redux";
import { TableModel } from "./models/table";
import { FunctionModel } from "./models/function";
import { TransformationService } from "./services/transformation";

export const Injectables = {
	Project: {} as ProjectModel,
	Column: {} as ColumnModel,
	Table: {} as TableModel,
	APICall: {} as APICallModel,
	StatsTable: {} as StatsTableModel,
	Function: {} as FunctionModel,
	TransformationService: {} as TransformationService,
};

export function createModules(store: Store<IRootState, IRootActions>) {
	Injectables.Project = new ProjectModel(store);
	Injectables.Column = new ColumnModel(store);
	Injectables.Table = new TableModel(store);
	Injectables.APICall = new APICallModel(store);
	Injectables.StatsTable = new StatsTableModel(store);
	Injectables.Function = new FunctionModel(store);
	Injectables.TransformationService = new TransformationService();
}

export function inject<T extends keyof typeof Injectables>(
	key: T
): typeof Injectables[T] {
	return Injectables[key];
}
