import * as React from "react";
import * as ReactGA from "react-ga";
import { History } from "history";
import { withRouter } from "react-router";

let globalHistory: any = null;

interface IProps {
	history: History;
}

class Spy extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
		globalHistory = props.history;
		ReactGA.pageview(window.location.pathname + window.location.search);

		props.history.listen(() => {
			window.scroll(0, 0);
			ReactGA.pageview(window.location.pathname + window.location.search);
		});
	}

	render() {
	  return null;
	}
}

export const GlobalHistory = withRouter(Spy as any);

// export react-router history
export default function getHistory(): History | null {
	return globalHistory;
}
