import api from "@app/api";
import createCRUDActions from "./crud";
import {
	DELETE_TABLE,
	LOAD_TABLE_BY_ID,
	LOAD_TABLES,
	UPDATE_TABLE,
} from "@app/action-types";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IRTable } from "@app/api/tables/helper-schemas";
import { ThunkAction } from "redux-thunk";
import {
	IAGETTable,
	IAPOSTCreateTable,
	IAPUTTable,
	IRGETManyTables,
	IRGETTable,
	IRPOSTCreateTable,
	IADELETETable,
	IAGETManyTables,
} from "@app/api/tables/validators";

export const addTable = (
	args: IAPOSTCreateTable
): ThunkAction<
	Promise<IRPOSTCreateTable>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.tables.add(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const updateTable = (
	args: IAPUTTable
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.tables.update(args).then(() => {
		dispatch(crudActions.update(args));
	});
};

export const loadTables = (
	args: IAGETManyTables
): ThunkAction<
	Promise<IRGETManyTables>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.tables.getReadableTables(args).then(data => {
		dispatch(crudActions.loadMany(data));
		return data;
	});
};

export const loadTableById = (
	args: IAGETTable
): ThunkAction<
	Promise<IRGETTable>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.tables.getById(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const deleteTable = (
	args: IADELETETable
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.tables.deleteById(args).then(() => {
		dispatch(crudActions.delete(args.tableId));
	});
};

export type TableActions =
	| ReturnType<typeof crudActions["delete"]>
	| ReturnType<typeof crudActions["loadMany"]>
	| ReturnType<typeof crudActions["loadOne"]>
	| ReturnType<typeof crudActions["update"]>;

export const tableCRUDActionTypes = {
	update: UPDATE_TABLE as typeof UPDATE_TABLE,
	loadOne: LOAD_TABLE_BY_ID as typeof LOAD_TABLE_BY_ID,
	loadMany: LOAD_TABLES as typeof LOAD_TABLES,
	delete: DELETE_TABLE as typeof DELETE_TABLE,
};

const crudActions = createCRUDActions<IRTable, typeof tableCRUDActionTypes>(
	tableCRUDActionTypes
);
export { crudActions as tableCRUDActions };
