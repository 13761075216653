import {
	IADELETEProject,
	IAGETAdminsMinimalInfo,
	IAGETProject,
	IAPOSTCreateProject,
	IAPUTProject,
	IRGETAdminsMinimalInfo,
	IRGETManyProjects,
	IRGETProject,
	IRPOSTCreateProject,
	RGETAdminsMinimalInfoSchema,
	RGETManyProjectsSchema,
	RGETProjectSchema,
	RPOSTCreateProjectSchema,
} from "./validators";
import { Requests } from "..";

// tslint:disable:no-duplicate-string
const projects = {
	add: (data: IAPOSTCreateProject): Promise<IRPOSTCreateProject> =>
		Requests.send("POST", "/api/projects", data, null, {
			responseSchema: RPOSTCreateProjectSchema,
		}),
	update: (data: IAPUTProject): Promise<void> =>
		Requests.send("PUT", "/api/projects/:_id", data, null),
	getById: (data: IAGETProject): Promise<IRGETProject> =>
		Requests.send("GET", "/api/projects/:id", data, null, {
			responseSchema: RGETProjectSchema,
		}),
	deleteById: (data: IADELETEProject): Promise<void> =>
		Requests.send("DELETE", "/api/projects/:_id", data),
	getMyProjects: (): Promise<IRGETManyProjects> =>
		Requests.send("GET", "/api/projects/", undefined, null, {
			responseSchema: RGETManyProjectsSchema,
		}),
	getAdminsMinInfo: (
		data: IAGETAdminsMinimalInfo
	): Promise<IRGETAdminsMinimalInfo> =>
		Requests.send("GET", "/api/projects/:_id/admins", data, null, {
			responseSchema: RGETAdminsMinimalInfoSchema,
		}),
};
export default projects;
