import * as React from "react";
import { ApiCallType } from "@app/api/api-calls/helper-schemas";
import {
	BasicInputDatum,
	BooleanDatum,
	DateDatum,
	EnumDatum,
	ListDatum,
	ObjectDatum,
} from "./data/edit";
import {
	ColumnType,
	IAPIColumnInfo,
	IColumnData,
	IColumnInfo,
	SPECIAL_VALUES,
	IDateColumnInfo,
} from "@app/api/columns/helper-schemas";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { parseDate } from "@app/commonJavascript";

export const getDefaultValue = (colType: ColumnType, value: IColumnData) => {
	if (value === undefined || value === null) return null;
	switch (colType) {
		case ColumnType.number:
			if (typeof value === "number") return value;
			return 0;
		case ColumnType.string:
			if (
				SPECIAL_VALUES.indexOf(value as any) > -1 ||
				typeof value !== "string"
			)
				return "";
			return value;
		case ColumnType.boolean:
			if (SPECIAL_VALUES.indexOf(value as any) > -1) return false;
			return !!value;
		case ColumnType.date:
			if (value instanceof Date) return value;
			try {
				return parseDate(value as any);
			} catch (e) {
				return null;
			}
		case ColumnType.enum:
			if (typeof value === "number") return value;
			return null;
		case ColumnType.api:
		case ColumnType.colReference:
			return value;
		case ColumnType.list:
			return Array.isArray(value) ? value : null;
		case ColumnType.object:
			return typeof value === "object" ? value : null;
		default:
			return null;
	}
};

type IEditComponentProps = {
	onChange: (vewVal: IColumnData, confirmChange: boolean) => void;
} & Record<any, any>;

export const getEditComponent = (
	projectId: ObjectId,
	colInfo: IColumnInfo,
	datum: IColumnData,
	props: IEditComponentProps,
	showAdditionalFeatures = false,
	key: string | number = 1
) => {
	const APICalls = inject("APICall");
	const ColumnModel = inject("Column");
	const defValue = getDefaultValue(colInfo.type, datum);
	switch (colInfo.type) {
		case ColumnType.number:
			return (
				<BasicInputDatum
					key={key}
					type="number"
					big={false}
					defaultValue={defValue as any}
					{...props}
				/>
			);
		case ColumnType.string:
			return (
				<BasicInputDatum
					key={key}
					type="text"
					big={!!colInfo.textarea}
					defaultValue={defValue as any}
					{...props}
				/>
			);
		case ColumnType.enum:
			return (
				<EnumDatum
					key={key}
					enums={colInfo.enumerables}
					defaultValue={defValue as any}
					{...props}
				/>
			);
		case ColumnType.boolean:
			return (
				<BooleanDatum
					key={key}
					defaultValue={defValue as any}
					{...props}
				/>
			);
		case ColumnType.date:
			const isCustomDate =
				typeof datum === "object" && datum && (datum as any).type;
			return (
				<>
					{showAdditionalFeatures && "ზუსტი თარიღი: "}
					<DateDatum
						key={key}
						defaultValue={isCustomDate ? null : (defValue as any)}
						{...props}
					/>
					{showAdditionalFeatures && (
						<div key={key}>
							<br />
							<br />
							ან დღევანდელ რიცხვს დამატებული x დღე: <br />
							<BasicInputDatum
								type="number"
								big={false}
								defaultValue={
									isCustomDate ? (datum as any).inc : null
								}
								{...props}
								onChange={(newVal, confirm) =>
									props.onChange(
										{ type: "currentDate", inc: newVal },
										confirm
									)
								}
							/>
						</div>
					)}
				</>
			);
		case ColumnType.list:
			return (
				<ListDatum
					key={key}
					defaultValue={defValue as any}
					colInfo={colInfo}
					projectId={projectId}
					{...props}
				/>
			);
		case ColumnType.object:
			return (
				<ObjectDatum
					key={key}
					defaultValue={defValue as any}
					colInfo={colInfo}
					projectId={projectId}
					{...props}
				/>
			);
		case ColumnType.api:
			const apiCall = APICalls.findByIdSync({
				id: colInfo.apiId,
				projectId,
			});
			if (apiCall && apiCall.info.type === ApiCallType.action) {
				const dateInfo: IDateColumnInfo = {
					type: ColumnType.date,
				};
				return getEditComponent(
					projectId,
					dateInfo,
					datum,
					props,
					showAdditionalFeatures,
					key
				);
			}
			const apiDocInfo = getApiInfoColumnInfoSync(
				colInfo as IAPIColumnInfo,
				projectId
			);
			if (!apiDocInfo) return null;
			let innerColumnInfo: IColumnInfo = apiDocInfo;
			if (colInfo.key) {
				if (apiDocInfo.type === ColumnType.object) {
					const prop = apiDocInfo.props.find(
						e => e.key === colInfo.key
					);
					if (!prop) {
						return null;
					}
					innerColumnInfo = prop.info;
				}
			}
			return getEditComponent(
				projectId,
				innerColumnInfo,
				datum,
				props,
				showAdditionalFeatures,
				key
			);
		case ColumnType.colReference:
			const column = ColumnModel.findByIdSync({
				id: colInfo.colId,
				projectId,
			});
			if (column) {
				return getEditComponent(
					projectId,
					column.info,
					datum,
					props,
					showAdditionalFeatures,
					key
				);
			}
			return null;
		case ColumnType.function:
			return getEditComponent(
				projectId,
				colInfo.inputInfo,
				datum,
				props,
				showAdditionalFeatures,
				key
			);
		default:
			return null;
	}
};

export function getApiInfoColumnInfoSync(
	colInfo: IAPIColumnInfo,
	projectId: ObjectId
): IColumnInfo | null {
	const APICalls = inject("APICall");
	const apiCall = APICalls.findByIdSync({ id: colInfo.apiId, projectId });
	if (!apiCall) return null;
	if (apiCall.info.type === ApiCallType.columnInfo) {
		const apiDocInfo = apiCall.info;
		if (
			colInfo.key !== undefined &&
			apiDocInfo.responseSchema.type === ColumnType.object
		) {
			const c = apiDocInfo.responseSchema.props.find(
				e => e.key === colInfo.key
			);
			if (c) return c.info;
		}
		return apiDocInfo.responseSchema;
	}
	return null;
}
