import * as React from "react";
import Button from "@material-ui/core/Button";
import Loading from "../widgets/loading";
import { History } from "history";
import { inject } from "@app/modules";
import { IRColumn } from "@app/api/columns/helper-schemas";
import { match } from "react-router";
import { OptionalKeys } from "@app/utils/generics";
import { removeKeys } from "@app/utils/common";
import { TableColumOrder } from "./col-order";
import { User } from "@app/user";
import { IRTable } from "@app/api/tables/helper-schemas";
import { InputWithLabel } from "../widgets/input";

interface IProps {
	match: match<{ projectId: string; id?: string }>;
	history: History;
	user: User;
}

interface IState {
	table?: OptionalKeys<IRTable, "_id" | "author">;
	columns?: IRColumn[];
}

export type IColOrderValue = IRTable["columnOrder"][number];

export class SaveTable extends React.Component<IProps, IState> {
	id = this.props.match.params.id;
	projectId = this.props.match.params.projectId;

	state: IState = {};

	_Table = inject("Table");
	_Column = inject("Column");

	componentDidMount() {
		this._Column
			.getReadableColumns({ projectId: this.projectId })
			.then(columns => {
				this.setState({ columns });
			});
		if (!this.id) {
			if (!this.props.user.canWriteTable(null, this.projectId)) {
				this.props.history.push(`/projects/${this.projectId}/tables`);
				return;
			}
			this.setState({
				table: {
					name: "",
					projectId: this.projectId,
					columnOrder: [],
					description: "",
				},
			});
		} else {
			this._Table
				.findById({ id: this.id, projectId: this.projectId })
				.then(table => {
					this.projectId = table.projectId;
					if (
						!this.props.user.canWriteTable(
							table._id,
							table.projectId
						)
					) {
						this.props.history.push(
							`/projects/${this.projectId}/tables`
						);
						return;
					}
					this.setState({ table });
				});
		}
	}

	onSave = () => {
		const { table } = this.state;
		if (!table) {
			return;
		}
		if (!this.id) {
			this._Table.create(table as IRTable).then(col => {
				this.props.history.push(`/projects/${this.projectId}/tables`);
			});
		} else {
			this._Table
				.update(removeKeys(table, "author") as IRTable)
				.then(() => {
					this.props.history.push(
						`/projects/${this.projectId}/tables`
					);
				});
		}
	};

	handleValueChange = <K extends keyof IRTable>(key: K) => (
		value: IRTable[K]
	) => {
		this.setState({
			table: {
				...this.state.table!,
				[key]: value,
			},
		});
	};

	onNameChange = this.handleValueChange("name");
	onDescriptionChange = this.handleValueChange("description");
	onColsChange = this.handleValueChange("columnOrder");

	render() {
		if (!this.state.table || !this.state.columns) {
			return <Loading />;
		}
		const { table } = this.state;
		return (
			<div className="main main2">
				<div>
					<InputWithLabel
						label="სახელი"
						value={table.name}
						onChange={this.onNameChange}
					/>
				</div>
				<div>
					<InputWithLabel
						label="აღწერა"
						value={table.description || ""}
						onChange={this.onDescriptionChange}
					/>
				</div>
				<TableColumOrder
					allowRenaming={true}
					order={table.columnOrder}
					resources={this.state.columns}
					onChange={this.onColsChange}
				/>
				<br />
				<br />
				<Button
					variant="contained"
					color="primary"
					onClick={this.onSave}
				>
					შენახვა
				</Button>
			</div>
		);
	}
}
