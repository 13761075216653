import createCRUDReducer from "./crud";
import { IReduxStateInstance } from "./root-state";
import { IRStatsTable } from "@app/api/stats-tables/helper-schemas";
import {
	StatsTableActions,
	statsTableCRUDActionTypes,
} from "@app/actions/stats-tables";

function getNewStatsTableState(
	state: IStateStatsTables = {},
	action: StatsTableActions
): IStateStatsTables {
	switch (action.type) {
		default:
			return state;
	}
}

const crudReducer = createCRUDReducer<IRStatsTable>(statsTableCRUDActionTypes);

export default function statsTables(
	state = {},
	action: StatsTableActions
): IStateStatsTables {
	const state1 = crudReducer(state, action);
	const newState = getNewStatsTableState(state1, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("stats-tables", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export type IStateStatsTables = IReduxStateInstance<IRStatsTable>;
