/* eslint-disable no-restricted-globals */
import * as queryString from "query-string";
import * as React from "react";
import api from "@app/api";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import SyncIcon from "@material-ui/icons/Sync";
import StatstIcon from "@material-ui/icons/Assessment";
import ClearIcon from "@material-ui/icons/Clear";
import fileDownload from "js-file-download";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "../widgets/loading";
import Popup, { PopupContentWithClose } from "../widgets/popup";
import { addLoader, animateWindowScroll } from "@app/commonJavascript";
import { connect } from "react-redux";
import { FilterOptionsComponent } from "./filter";
import { History } from "history";
import { ObjectId, WithKnownKeyType } from "@app/utils/generics";
import { IColumnData, ColumnType } from "@app/api/columns/helper-schemas";
import {
	IFilterOption,
	IFilterOptions,
	ISortOptions,
} from "@app/api/resource-data/helper-schemas";
import { inject } from "@app/modules";
import {
	IRGETManyResourcesData,
	IRPUTResourceData,
} from "@app/api/resource-data/validators";
import { IRootState } from "@app/reducers/root-state";
import { IRResourceGroup } from "@app/api/resource-groups/helper-schemas";
import { ListResourceGroups } from "../resource-groups/select";
import { match } from "react-router";
import { MenuAction } from "./right-click-context";
import { Paginator } from "../widgets/paginator";
import { TableData } from "./data";
import { User } from "@app/user";
import {
	ApiCallType,
	IAPICallAction,
	IRAPICall,
} from "@app/api/api-calls/helper-schemas";
import { APICallManyActionsViewOuter } from "./data/many-actions";
import { transformFilter } from "@app/utils/filter";
import { jsonDateParser, getTimezoneOffsetInMinutes } from "@app/utils/common";

interface IOwnProps {
	history: History;
	match: match<{ projectId: string; id: string }>;
	user: User;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IOwnProps;

interface IState {
	data?: IRGETManyResourcesData;
	limit: number;
	page: number;
	sortOptions?: ISortOptions;
	filterOptions?: IFilterOptions;
	filterPopup?: { colId: ObjectId };
	loadingInitialRequests?: boolean;
	resourceGroups?: IRResourceGroup[];
	resourceGroupId?: ObjectId;
	onlyMyUsers?: boolean;
	manyActionsPopup?: {
		api: WithKnownKeyType<IRAPICall, "info", IAPICallAction>;
		columnId: ObjectId;
		availableResourceIds: string[] | number[];
	};
}

class TablePage extends React.PureComponent<IProps, IState> {
	_Table = inject("Table");
	_Project = inject("Project");
	_APICall = inject("APICall");
	_Column = inject("Column");

	state: IState = {
		limit: 500,
		page: 0,
		loadingInitialRequests: true,
		onlyMyUsers: true,
	};

	projectId = this.props.match.params.projectId;
	tableId = this.props.match.params.id;
	pathname = this.props.history.location.pathname;

	_isMounted = false;

	lastSearchedInfo: {
		page?: number;
		sortOptions?: IState["sortOptions"];
		filterOptions?: IState["filterOptions"];
	} = {};
	unlistenHistory: () => void;

	constructor(props: IProps) {
		super(props);
		const parsedQuery = queryString.parse(location.search);
		if (parsedQuery.page) {
			this.state.page = (+parsedQuery.page || 1) - 1;
		}
		this._Project.findById({ id: this.projectId }).then();
		// console.log(this.pathname);
	}

	componentDidMount() {
		this._isMounted = true;
		Promise.all([
			api.resourceGroups.getReadableResourceGroups({
				projectId: this.projectId,
			}),
			this._APICall.getReadableAPICalls({ projectId: this.projectId }),
		]).then(([resourceGroups]) => {
			this.setState({ resourceGroups, loadingInitialRequests: false });
		});
		this.onLocationChange(window.location);
		// this.onPageSwitch(this.state.page, true);
		this.unlistenHistory = this.props.history.listen(this.onLocationChange);
	}

	onLocationChange = (location: any) => {
		if (!location) return;
		if (this.pathname === location.pathname) {
			try {
				const parsedQuery = queryString.parse(location.search);
				const updatable: Partial<IState> = {};
				if (parsedQuery.page) {
					updatable.page = (+parsedQuery.page || 1) - 1;
				}
				if (parsedQuery.sortOptions) {
					updatable.sortOptions = JSON.parse(
						parsedQuery.sortOptions as string,
						jsonDateParser
					); // TODO: do not trust this
				}
				if (parsedQuery.filterOptions) {
					updatable.filterOptions = JSON.parse(
						parsedQuery.filterOptions as string,
						jsonDateParser
					); // TODO: do not trust this
				}
				if (parsedQuery.resourceGroupId) {
					updatable.resourceGroupId = parsedQuery.resourceGroupId as string;
				}
				if (parsedQuery.onlyMyUsers) {
					updatable.onlyMyUsers = parsedQuery.onlyMyUsers === "true";
				}
				this.setState(
					() => updatable as IState,
					() => this.onPageSwitch(this.state.page)
				);
			} catch (e) {
				console.log(e);
			}
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
		this.unlistenHistory();
	}

	onPageSwitch = (page: number, initalCall = false) => {
		const searchOptions = {
			page,
			sortOptions: this.state.sortOptions,
			filterOptions: this.state.filterOptions,
			resourceGroupId: this.state.resourceGroupId,
			onlyMyUsers: this.state.onlyMyUsers,
		};
		if (
			!initalCall &&
			JSON.stringify(searchOptions) ===
				JSON.stringify(this.lastSearchedInfo)
		)
			return;
		this.lastSearchedInfo = searchOptions;
		const removeLoader = addLoader();
		api.resourceData
			.getCachedData({
				projectId: this.projectId,
				tableId: this.tableId,
				limit: this.state.limit,
				offset: this.state.limit * page,
				sortOptions: this.state.sortOptions,
				filterOptions: transformFilter(this.state.filterOptions),
				resourceGroupId: this.state.resourceGroupId,
				onlyMyUsers: this.state.onlyMyUsers,
				ignoreDateTimes: true,
				timeZoneOffsetInMinutes: getTimezoneOffsetInMinutes(),
			})
			.then(data => {
				this._Column.loadManySync(data.cols);
				this.setState({
					data,
					page,
				});
				removeLoader();
				animateWindowScroll(0, 200);
				let parsedQuery = queryString.parse(location.search);
				parsedQuery = {
					...parsedQuery,
					page: page + 1 + "",
					sortOptions: JSON.stringify(this.state.sortOptions),
					filterOptions: JSON.stringify(this.state.filterOptions),
					resourceGroupId: this.state.resourceGroupId || null,
					onlyMyUsers: "" + this.state.onlyMyUsers,
				};
				this.props.history.push(
					`/projects/${this.projectId}/tables/${
						this.tableId
					}?${queryString.stringify(parsedQuery)}`
				);
			})
			.catch(e => {
				console.log(e);
				removeLoader();
			});
	};

	changeResourceData = ({
		resourceId,
		colId,
		newValue,
	}: {
		resourceId: number | string;
		colId: ObjectId;
		newValue: IColumnData;
	}) => {
		this.setState({
			data: {
				...this.state.data!,
				resources: this.state.data!.resources.map((res, i) => {
					if (res.id !== resourceId) return res;
					return {
						...res,
						data: res.data.map((colDatum, index) => {
							if (this.state.data!.cols[index]!._id !== colId)
								return colDatum;
							return newValue as any;
						}),
					};
				}),
			},
		});
	};

	onDatumChange = (
		resourceId: number | string,
		colId: ObjectId,
		newValue: IColumnData
	): Promise<IRPUTResourceData> => {
		return api.resourceData
			.updateData({
				data: newValue as any,
				projectId: this.projectId,
				columnId: colId,
				resourceId,
			})
			.then(msg => {
				this.changeResourceData({ resourceId, colId, newValue });
				return msg;
			});
	};
	onTablesExport = () => {
		const removeLoader = addLoader();
		api.resourceData
			.exportCachedData({
				projectId: this.projectId,
				tableId: this.tableId,
				sortOptions: this.state.sortOptions,
				filterOptions: transformFilter(this.state.filterOptions),
				resourceGroupId: this.state.resourceGroupId,
				onlyMyUsers: this.state.onlyMyUsers,
				ignoreDateTimes: true,
				timeZoneOffsetInMinutes: getTimezoneOffsetInMinutes(),
			})
			.then(data => {
				removeLoader();
				if (!this._isMounted) return;
				const now = new Date();
				let dateStr = now
					.toLocaleString()
					.replace(",", "")
					.replace(/\//g, "-")
					.replace(/:/g, "-");
				dateStr = dateStr.substr(0, dateStr.length - 3);
				fileDownload(data, `export - ${dateStr}.xlsx`);
			})
			.catch(() => {
				removeLoader();
				if (!this._isMounted) return;
				alert("დაფიქსირდა შეცდომა");
			});
		console.log("a");
	};

	onGotoStatsTables = () => {
		this.props.history.push(
			`/projects/${this.projectId}/tables/${this.tableId}/stats`
		);
	};

	onContextMenuAction = (colId: ObjectId, action: MenuAction) => {
		if (!this.state.data) return;
		const col = this.state.data.cols.find(each => each._id === colId);
		if (!col) return;
		if (action === MenuAction.sortASC || action === MenuAction.sortDESC) {
			const sortOptions = {
				colId,
				type: (action === MenuAction.sortASC ? "ASC" : "DESC") as
					| "ASC"
					| "DESC",
			};
			this.setState(
				{
					data: undefined,
					sortOptions,
				},
				() => {
					this.onPageSwitch(0, true);
				}
			);
		} else if (action === MenuAction.filter) {
			this.setState({
				filterPopup: {
					colId,
				},
			});
		} else if (action === MenuAction.manyActions) {
			if (col.info.type !== ColumnType.api) return;
			const apiObj = this._APICall.findByIdSync({
				id: col.info.apiId,
				projectId: this.projectId,
			});
			if (!apiObj) return;
			if (apiObj.info.type !== ApiCallType.action) return;
			const removeLoader = addLoader();
			api.resourceData
				.getResourceIds({
					projectId: this.projectId,
					tableId: this.tableId,
					filterOptions: transformFilter(this.state.filterOptions),
					resourceGroupId: this.state.resourceGroupId,
					onlyMyUsers: this.state.onlyMyUsers,
					ignoreDateTimes: true,
					timeZoneOffsetInMinutes: getTimezoneOffsetInMinutes(),
				})
				.then(data => {
					this.setState({
						manyActionsPopup: {
							api: apiObj as WithKnownKeyType<
								IRAPICall,
								"info",
								IAPICallAction
							>,
							columnId: col._id,
							availableResourceIds: data.resourceIds,
						},
					});
					removeLoader();
				})
				.catch(e => {
					alert("დაფიქსირდა შეცდომა");
					removeLoader();
				});
		}
	};

	onFilterOptionsClose = () => this.setState({ filterPopup: undefined });
	onManyActionsPopupClose = () =>
		this.setState({ manyActionsPopup: undefined });
	onFiltersSelect = (
		colId: ObjectId,
		filterOption: IFilterOption,
		clearOtherFilters: boolean
	) => {
		let { filterOptions } = this.state;
		if (clearOtherFilters) {
			filterOptions = {
				[colId]: filterOption,
			};
		} else {
			filterOptions = {
				...filterOptions,
				[colId]: filterOption,
			};
		}
		this.setState(
			{
				data: undefined,
				filterOptions,
				filterPopup: undefined,
			},
			() => {
				this.onPageSwitch(0, true);
			}
		);
	};

	onFilterAndSortRemove = () => {
		this.setState(
			{
				data: undefined,
				filterOptions: undefined,
				sortOptions: undefined,
			},
			() => {
				this.onPageSwitch(0, true);
			}
		);
	};

	onResourceGroupChange = (resourceGroupId?: ObjectId) => {
		this.setState(
			{
				resourceGroupId,
				onlyMyUsers: false,
			},
			() => {
				this.onPageSwitch(0, true);
			}
		);
	};

	OnlyMyUsersChange = (e, checked: boolean) => {
		this.setState({ onlyMyUsers: checked }, () => {
			this.onPageSwitch(0, true);
		});
	};

	onDataReload = () => {
		const removeLoader = addLoader();
		api.resourceData
			.reload({
				type: "partial_project",
				projectId: this.projectId,
				tableId: this.tableId,
				filterOptions: transformFilter(this.state.filterOptions),
				resourceGroupId: this.state.resourceGroupId,
				onlyMyUsers: this.state.onlyMyUsers,
				ignoreDateTimes: true,
				timeZoneOffsetInMinutes: getTimezoneOffsetInMinutes(),
			})
			.then(() => {
				location.reload();
			})
			.catch(e => {
				console.log(e);
				removeLoader();
				alert("დაფიქსირდა შეცდომა");
			});
	};

	render() {
		const table = this._Table.findByIdSync(
			{ id: this.tableId, projectId: this.projectId },
			this.props.tables
		);
		if (this.state.loadingInitialRequests || !table || !this.state.data) {
			return <Loading />;
		}
		return (
			<>
				<div className="main main2">
					<h3>{table.name}</h3>
					<Button
						variant="contained"
						color="primary"
						onClick={this.onTablesExport}
					>
						<div className="VM">
							<DownloadIcon />
							<span style={{ margin: "4px 0 0 8px" }}>
								გადმოწერა
							</span>
						</div>
					</Button>{" "}
					{table.statsTableOrder && (
						<Button
							variant="contained"
							color="primary"
							onClick={this.onGotoStatsTables}
						>
							<StatstIcon />
							<span style={{ margin: "4px 0 0 8px" }}>
								სტატისტიკები
							</span>
						</Button>
					)}{" "}
					<Button
						variant="contained"
						color="primary"
						onClick={this.onDataReload}
					>
						<SyncIcon />
						<span style={{ margin: "4px 0 0 8px" }}>
							მონაცემების გაახლება
						</span>
					</Button>
					{this.state.resourceGroups && (
						<ListResourceGroups
							resourceGroups={this.state.resourceGroups}
							defaultValue={this.state.resourceGroupId}
							onChange={this.onResourceGroupChange}
							tableId={table._id}
						/>
					)}
					<FormControlLabel
						control={
							<Checkbox
								checked={
									this.state.onlyMyUsers === undefined
										? true
										: this.state.onlyMyUsers
								}
								onChange={this.OnlyMyUsersChange}
							/>
						}
						label="ჩანდეს მხოლოდ ჩემი მომხმარებლები"
					/>
					{(this.state.sortOptions || this.state.filterOptions) && (
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={this.onFilterAndSortRemove}
							>
								<ClearIcon />
								<span style={{ margin: "4px 0 0 8px" }}>
									ფილტრისა და დალაგების მოშორება
								</span>
							</Button>
						</div>
					)}
					<Paginator
						pageIndex={this.state.page}
						numOfPages={Math.ceil(
							this.state.data.numOfResources / this.state.limit
						)}
						onPageChange={this.onPageSwitch}
					/>
					<div style={{ textAlign: "center" }}>
						სულ: {this.state.data.numOfResources} მომხმარებელი
					</div>
				</div>
				<TableData
					resourceGroups={this.state.resourceGroups}
					data={this.state.data}
					table={table}
					onDatumChange={this.onDatumChange}
					onAction={this.onContextMenuAction}
					projectId={this.projectId}
					filterOptions={this.state.filterOptions}
					sortOptions={this.state.sortOptions}
					onAPICall={this.changeResourceData}
				/>
				<div className="main main2">
					<br />
					<br />
					<Paginator
						pageIndex={this.state.page}
						numOfPages={Math.ceil(
							this.state.data.numOfResources / this.state.limit
						)}
						onPageChange={this.onPageSwitch}
					/>
					<br />
					<br />
				</div>
				{this.state.filterPopup && this.state.data && (
					<Popup>
						<PopupContentWithClose
							onClose={this.onFilterOptionsClose}
						>
							<FilterOptionsComponent
								key={this.state.filterPopup.colId}
								column={
									this.state.data.cols.find(
										e =>
											e._id ===
											this.state.filterPopup!.colId
									)!
								}
								currentOpton={
									this.state.filterOptions &&
									this.state.filterOptions[
										this.state.filterPopup.colId
									]
								}
								showRemovingOtherFiltersOption={
									!!this.state.filterOptions
								}
								onConfirm={this.onFiltersSelect}
							/>
						</PopupContentWithClose>
					</Popup>
				)}
				{this.state.manyActionsPopup && this.state.data && (
					<APICallManyActionsViewOuter
						api={this.state.manyActionsPopup.api}
						columnId={this.state.manyActionsPopup.columnId}
						onClose={this.onManyActionsPopupClose}
						resourceIds={
							this.state.manyActionsPopup.availableResourceIds
						}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	tables: state.tables,
});

const connected = connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	TablePage
);

export { connected as TablePage };
