import * as React from "react";

const SvgForbidden = props => (
	<svg height="512pt" viewBox="0 0 512 512" width="512pt" {...props}>
		<path
			d="M445.293 256c-42.547 58.422-111.48 96.402-189.293 96.402a237.89 237.89 0 0 1-29.164-1.797C161.113 342.434 103.852 307 66.707 256c33.656-46.207 83.809-79.633 141.813-91.574 15.328-3.168 31.21-4.828 47.48-4.828 77.813 0 146.746 37.98 189.293 96.402zm0 0"
			fill="#d8edf7"
		/>
		<path
			d="M226.836 350.605C161.113 342.434 103.852 307 66.707 256c33.656-46.207 83.809-79.633 141.813-91.574-59.79 24.312-112.13 134.406 18.316 186.18zm0 0"
			fill="#a8c3cf"
		/>
		<path
			d="M256 360.238c-10.023 0-20.156-.625-30.125-1.855-65.895-8.192-126.215-43.828-165.504-97.77a7.832 7.832 0 0 1 0-9.226c35.621-48.903 87.672-82.512 146.57-94.637 16.024-3.309 32.532-4.988 49.059-4.988 38.77 0 77.324 9.398 111.488 27.18 33.055 17.199 62.153 42.253 84.14 72.445a7.832 7.832 0 0 1 0 9.226c-21.991 30.192-51.085 55.246-84.14 72.446-34.164 17.78-72.718 27.18-111.488 27.18zM76.492 256c36.774 47.867 91.606 79.406 151.313 86.828A230.765 230.765 0 0 0 256 344.566c36.262 0 72.313-8.789 104.254-25.41 29.215-15.203 55.129-36.972 75.254-63.156-20.125-26.184-46.04-47.953-75.254-63.156-31.941-16.621-67.992-25.41-104.254-25.41-15.465 0-30.906 1.57-45.895 4.668-53.199 10.953-100.43 40.66-133.613 83.898zm0 0"
			fill="#e09544"
		/>
		<path
			d="M330.656 215.195c0 12.27-9.941 22.211-22.21 22.211-12.266 0-22.212-9.941-22.212-22.21 0-12.266 9.946-22.212 22.211-22.212 12.27 0 22.211 9.946 22.211 22.211zm0 0"
			fill="#fff"
		/>
		<path
			d="M321.61 256c0 36.238-29.372 65.61-65.61 65.61s-65.61-29.372-65.61-65.61 29.372-65.61 65.61-65.61c13.082 0 25.277 3.837 35.516 10.438 12.402 7.996 21.933 20.063 26.707 34.317A65.515 65.515 0 0 1 321.609 256zm0 0"
			fill="#3a3d51"
		/>
		<path
			d="M318.223 235.145a22.05 22.05 0 0 1-9.778 2.265c-12.27 0-22.215-9.945-22.215-22.215a22.068 22.068 0 0 1 5.286-14.367c12.402 7.996 21.933 20.063 26.707 34.317zm0 0"
			fill="#65687f"
		/>
		<path
			d="M256 0C114.613 0 0 114.613 0 256s114.613 256 256 256 256-114.613 256-256S397.387 0 256 0zM46.047 256c0-71.156 35.406-134.031 89.55-172.004l200.419 366.152c-24.664 10.176-51.68 15.801-80.016 15.801C140.047 465.953 46.047 371.953 46.047 256zm330.348 172.008L175.98 61.852c24.66-10.176 51.684-15.805 80.02-15.805 115.953 0 209.941 94 209.941 209.953 0 71.156-35.402 134.035-89.546 172.008zm0 0"
			fill="#ff5a73"
		/>
	</svg>
);

export default SvgForbidden;
