import apiCalls from "./reducers/api-calls";
import columns from "./reducers/columns";
import projects from "./reducers/projects";
import statsTables from "./reducers/stats-tables";
import tables from "./reducers/tables";
import user from "./reducers/user";
import functions from "./reducers/functions";
import { combineReducers } from "redux";

const appReducer = combineReducers({
	user,
	projects,
	columns,
	tables,
	apiCalls,
	statsTables,
	functions,
});

export default appReducer;
