import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const RResourceGroupSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string().allow(""),
	adminIds: Joi.array()
		.items(Joi.number())
		.required(),
	numOfResources: Joi.number()
		.integer()
		.required(),
	tableIds: Joi.array().items(Joi.objectId()),
	author: Joi.number().required(),
});
export interface IRResourceGroup {
	_id: ObjectId;
	projectId: ObjectId;
	name: string;
	adminIds: number[];
	numOfResources: number;
	author: number;
	description?: string;
	tableIds?: ObjectId[];
}
