export function transposeOfArray<T>(a: ReadonlyArray<ReadonlyArray<T>>): T[][] {
	// Calculate the width and height of the Array
	const w = a.length || 0;
	const h = a[0] instanceof Array ? a[0].length : 0;

	// In case it is a zero matrix, no transpose routine needed.
	if (h === 0 || w === 0) {
		return [];
	}

	const t: T[][] = [];

	// Loop through every item in the outer array (height)
	for (let i = 0; i < h; i++) {
		// Insert a new row (array)
		t[i] = [];

		// Loop through every item per item in outer array (width)
		for (let j = 0; j < w; j++) {
			// Save transposed data.
			t[i][j] = a[j][i];
		}
	}

	return t;
}

export function sumArr(arr: readonly number[]): number {
	let sum = 0;
	for (const value of arr) {
		sum += value;
	}
	return sum;
}
export function shuffleArray<T extends any>(arr: ReadonlyArray<T>): T[] {
	const array = [...arr];
	let currentIndex = array.length,
		temporaryValue,
		randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}
