import OrigianlJoi, { ObjectSchema } from "joi";

type ObjectIdSchema = OrigianlJoi.AnySchema;

export interface ExtendedJoi extends OrigianlJoi.Root {
	objectId(): ObjectIdSchema;
}

const Joi: ExtendedJoi = OrigianlJoi.extend({
	type: "objectId",
	messages: {
		"objectId.base":
			"needs to be a string of 12 bytes or a string of 24 hex characters",
	},
	validate(value, helpers) {
		const objectIdRegEx = /^[0-9a-f]{24}$/;
		const isRequired = helpers.schema._flags.presence === "required";
		const error = helpers.error("objectId.base");
		return {
			value,
			errors: value
				? value.toString().match(objectIdRegEx)
					? null
					: error
				: isRequired
				? error
				: null,
		};
	},
})
	.extend({
		type: "object",
		base: OrigianlJoi.object(),
		coerce: {
			from: "string",
			method(value: any) {
				if (value[0] !== "{" && !/^\s*\{/.test(value)) {
					return;
				}

				try {
					return { value: JSON.parse(value) };
				} catch (ignoreErr) {}
			},
		},
	})
	.extend({
		type: "array",
		base: OrigianlJoi.array(),
		coerce: {
			from: "string",
			method(value: any) {
				if (
					typeof value !== "string" ||
					(value[0] !== "[" && !/^\s*\[/.test(value))
				) {
					return;
				}

				try {
					return { value: JSON.parse(value) };
				} catch (ignoreErr) {}
			},
		},
	});

export type JoiSchema = OrigianlJoi.Schema;

export default Joi;

export function getJoiObjectKeys<T extends ObjectSchema>(schema: T): string[] {
	if (
		typeof schema.$_terms === "object" &&
		typeof schema.$_terms.keys === "object"
	) {
		return schema.$_terms.keys.map((e: Record<any, any>) => e.key); // for Joi >= 16
	}
	return (schema as any)._inner.children.map((e: Record<any, any>) => e.key); // For Joi <= 15
}
