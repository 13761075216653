import Joi from "@app/utils/joi";
import { ColumnDataSchema, IColumnData } from "../columns/helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const ColumnValueSchema = Joi.object({
	data: ColumnDataSchema.required(),
	updatedAt: Joi.date().required(),
	updatedBy: Joi.number()
		.integer()
		.required(),
});
export interface IColumnValue {
	updatedAt: Date;
	updatedBy: number;
	data: IColumnData;
}

export const ResourceDataColumnSchema = Joi.object().pattern(
	/[\da-f]+/,
	ColumnValueSchema.required()
);
export type IResourceDataColumn = Record<string, IColumnValue>;

export enum FilterType {
	equal = "$eq",
	notEqual = "$ne",
	greaterThan = "$gt",
	greaterThanOrEqual = "$gte",
	lessThan = "$lt",
	lessThanOrEqual = "$lte",
	in = "$in",
}

export enum FilterTypeWithoutDollars {
	equal = "eq",
	notEqual = "ne",
	greaterThan = "gt",
	greaterThanOrEqual = "gte",
	lessThan = "lt",
	lessThanOrEqual = "lte",
	in = "in",
}

const FilterOptionSchema = Joi.object({
	[FilterType.equal]: ColumnDataSchema,
	[FilterType.notEqual]: ColumnDataSchema,
	[FilterType.greaterThan]: ColumnDataSchema,
	[FilterType.greaterThanOrEqual]: ColumnDataSchema,
	[FilterType.lessThan]: ColumnDataSchema,
	[FilterType.lessThanOrEqual]: ColumnDataSchema,
	[FilterType.in]: ColumnDataSchema,
});
export type IFilterOption = { [key in FilterType]?: IColumnData };

const FilterOptionWithoutDollarSchema = Joi.object({
	[FilterTypeWithoutDollars.equal]: ColumnDataSchema,
	[FilterTypeWithoutDollars.notEqual]: ColumnDataSchema,
	[FilterTypeWithoutDollars.greaterThan]: ColumnDataSchema,
	[FilterTypeWithoutDollars.greaterThanOrEqual]: ColumnDataSchema,
	[FilterTypeWithoutDollars.lessThan]: ColumnDataSchema,
	[FilterTypeWithoutDollars.lessThanOrEqual]: ColumnDataSchema,
	[FilterTypeWithoutDollars.in]: Joi.array().items(ColumnDataSchema),
});
export type IFilterOptionWithoutDollar = {
	[key in FilterTypeWithoutDollars]?: IColumnData;
};

export const FilterOptionWithoutDollarSchemaCorrespondence = {
	[FilterTypeWithoutDollars.equal]: FilterType.equal,
	[FilterTypeWithoutDollars.notEqual]: FilterType.notEqual,
	[FilterTypeWithoutDollars.greaterThan]: FilterType.greaterThan,
	[FilterTypeWithoutDollars.greaterThanOrEqual]:
		FilterType.greaterThanOrEqual,
	[FilterTypeWithoutDollars.lessThan]: FilterType.lessThan,
	[FilterTypeWithoutDollars.lessThanOrEqual]: FilterType.lessThanOrEqual,
	[FilterTypeWithoutDollars.in]: FilterType.in,
};

export const FilterOptionsSchema = Joi.object().pattern(
	/.+/,
	FilterOptionSchema
);
export type IFilterOptions = Record<string, IFilterOption | undefined>;

export const FilterOptionsSchemaWithoutDollar = Joi.object().pattern(
	/.+/,
	FilterOptionWithoutDollarSchema
);
export type IFilterOptionsWithoutDollar = Record<
	string,
	IFilterOptionWithoutDollar | undefined
>;

export const SortOptionsSchema = Joi.object({
	type: Joi.string()
		.valid("ASC", "DESC")
		.required(),
	colId: Joi.objectId().required(),
});
export interface ISortOptions {
	type: "ASC" | "DESC";
	colId: ObjectId;
}
