import * as React from "react";
import CancelIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import DatePicker from "react-datepicker";
import SvgClean from "../styles/icons/clean";
import Tooltip from "@material-ui/core/Tooltip";
import { css, cx } from "emotion";
import {
	IEnumColumnInfo,
	IListColumnInfo,
	IObjectColumnInfo,
} from "@app/api/columns/helper-schemas";
import {
	changeButtonCSS,
	ChangeContainerCSS,
	EnumSingleVal,
	textareaCellEditCSS,
	greyButton,
	greenButton,
	redButton,
} from "../styles";
import { ObjectId, IAnyObj } from "@app/utils/generics";
import { getEditComponent } from "../utils";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { removeKeys } from "@app/utils/common";

const SVGStyles2 = {
	width: 24,
	height: 24,
};

interface IChangeContainerProps {
	onConfirm?: () => void;
	onReject?: () => void;
	onClean?: () => void;
}

export const ChangeContainer: React.FC<IChangeContainerProps> = props => (
	<div className={ChangeContainerCSS}>
		<div>{props.children}</div>
		{props.onClean && (
			<div
				onClick={props.onClean}
				className={cx(changeButtonCSS, greyButton)}
			>
				<Tooltip title="გასუფთავება">
					<SvgClean style={SVGStyles2} />
				</Tooltip>
			</div>
		)}
		{props.onConfirm && (
			<div
				onClick={props.onConfirm}
				className={cx(changeButtonCSS, greenButton)}
			>
				<Tooltip title="დასტური">
					<CheckIcon />
				</Tooltip>
			</div>
		)}
		{props.onReject && (
			<div
				onClick={props.onReject}
				className={cx(changeButtonCSS, redButton)}
			>
				<Tooltip title="უარყოფა">
					<CancelIcon />
				</Tooltip>
			</div>
		)}
	</div>
);

interface IBasicInputDatumProps {
	type: "text" | "number";
	defaultValue: string | number | null;
	onChange: (newVal: string | number, confirm: boolean) => void;
	big: boolean;
}

export const BasicInputDatum: React.FC<IBasicInputDatumProps> = props => {
	const onChange = (val: string, confirm = false) => {
		props.type === "text"
			? props.onChange(val, confirm)
			: props.onChange(+val, confirm);
	};
	const isEmptyVal =
		props.defaultValue === null || props.defaultValue === undefined;
	if (props.big && props.type === "text") {
		return (
			<textarea
				defaultValue={isEmptyVal ? "" : props.defaultValue + ""}
				className={textareaCellEditCSS}
				onChange={e => onChange(e.target.value, false)}
				placeholder="ჩაწერეთ აქ"
				autoFocus={true}
			/>
		);
	}
	return (
		<input
			type={props.type}
			defaultValue={isEmptyVal ? "" : props.defaultValue + ""}
			onChange={e => onChange(e.target.value, false)}
			onKeyDown={e => {
				if (e.key === "Enter")
					onChange((e.target as HTMLInputElement).value, true);
			}}
			placeholder="ჩაწერეთ აქ"
			autoFocus={true}
		/>
	);
};

interface IEnumDatumProps {
	enums: IEnumColumnInfo["enumerables"];
	defaultValue?: number | null;
	onChange: (newVal: number, confirm: boolean) => void;
}

export class EnumDatum extends React.PureComponent<IEnumDatumProps> {
	state = {
		value: this.props.defaultValue,
	};

	onChange = value => {
		this.setState({ value }, () => {
			this.props.onChange(value, true);
		});
	};

	render() {
		return (
			<div>
				{this.props.enums.map(e => (
					<EnumSingleVal
						key={e.id}
						isSelected={e.id === this.state.value}
						onClick={() => this.onChange(e.id)}
					>
						{e.val}
					</EnumSingleVal>
				))}
			</div>
		);
	}
}

interface IBooleanDatumProps {
	defaultValue?: boolean | null;
	onChange: (newVal: boolean, confirm: boolean) => void;
}

export class BooleanDatum extends React.PureComponent<IBooleanDatumProps> {
	state = {
		value:
			typeof this.props.defaultValue === "boolean"
				? this.props.defaultValue
				: null,
	};

	onChange = value => {
		this.setState({ value }, () => {
			this.props.onChange(value, true);
		});
	};

	render() {
		return (
			<div>
				<EnumSingleVal
					isSelected={this.state.value === true}
					onClick={() => this.onChange(true)}
				>
					კი
				</EnumSingleVal>
				<EnumSingleVal
					isSelected={this.state.value === false}
					onClick={() => this.onChange(false)}
				>
					არა
				</EnumSingleVal>
			</div>
		);
	}
}

interface IDateDatumProps {
	defaultValue?: Date | null;
	onChange: (newVal: Date | null, confirm: boolean) => void;
	showTime?: boolean;
}

export class DateDatum extends React.PureComponent<IDateDatumProps> {
	state = {
		value:
			this.props.defaultValue instanceof Date
				? this.props.defaultValue
				: null,
	};

	onChange = (value: Date | null) => {
		this.setState({ value }, () => {
			this.props.onChange(value, false);
		});
	};

	render() {
		return (
			<div>
				<DatePicker
					selected={this.state.value}
					onChange={this.onChange}
					showTimeSelect={!!this.props.showTime}
					timeFormat="HH:mm"
					timeIntervals={15}
					dateFormat="d MMMM, yyyy h:mm aa"
					timeCaption="დრო"
				/>
			</div>
		);
	}
}

interface IListDatumProps {
	colInfo: IListColumnInfo;
	defaultValue?: any[] | null;
	onChange: (newVal: any[], confirm: boolean) => void;
	projectId: ObjectId;
	alwaysCallParentOnChange?: boolean;
	insertDefaultDataIfEmpty?: boolean;
}
interface IListDatumState {
	value: any[] | null;
	itemKeysCounter: number;
}
export class ListDatum extends React.PureComponent<
	IListDatumProps,
	IListDatumState
> {
	state: IListDatumState = {
		value: Array.isArray(this.props.defaultValue)
			? this.props.defaultValue
			: this.props.insertDefaultDataIfEmpty
			? [null]
			: null,
		itemKeysCounter: 0,
	};

	onItemChange = (index: number, val: any, confirm: boolean) => {
		this.setState(
			({ value }) => ({
				value: value!.map((e, i) => (i !== index ? e : val)),
			}),
			() => {
				this.props.onChange(
					this.state.value!,
					!!this.props.alwaysCallParentOnChange
				);
			}
		);
	};

	onItemDelete = (index: number) => {
		this.setState(
			({ value, itemKeysCounter }) => ({
				value: value!.filter((_, i) => i !== index),
				itemKeysCounter: itemKeysCounter + 1,
			}),
			() => {
				this.props.onChange(
					this.state.value!,
					!!this.props.alwaysCallParentOnChange
				);
			}
		);
	};

	onItemAdd = () => {
		this.setState(
			({ value }) => ({
				value: [...(value || []), null],
			}),
			() => {
				this.props.onChange(
					this.state.value!,
					!!this.props.alwaysCallParentOnChange
				);
			}
		);
	};

	render() {
		const { value } = this.state;
		const {
			colInfo,
			projectId,
			defaultValue,
			onChange,
			...rest
		} = this.props;
		const itemInfo = colInfo.itemsInfo;
		return (
			<div className={listContainer}>
				{value &&
					value.map((val, index) => {
						const editComp = getEditComponent(
							projectId,
							itemInfo,
							val,
							{
								...rest,
								onChange: (val, confirm) =>
									this.onItemChange(index, val, confirm),
								direction: "horizontal",
							},
							false,
							index + "_" + this.state.itemKeysCounter
						);
						return (
							<div key={index} className={listRowContainer}>
								{editComp}
								<CancelIcon
									onClick={() => this.onItemDelete(index)}
								/>
							</div>
						);
					})}
				<Button variant="outlined" onClick={this.onItemAdd}>
					<div className="middle">
						<AddIcon />
						<span>ჩანაწერის დამატება</span>
					</div>
				</Button>
			</div>
		);
	}
}

interface IObjectDatumProps {
	colInfo: IObjectColumnInfo;
	defaultValue?: IAnyObj | null;
	onChange: (newVal: IAnyObj, confirm: boolean) => void;
	projectId: ObjectId;
	direction?: "vertical" | "horizontal";
	alwaysCallParentOnChange?: boolean;
}
interface IObjectDatumState {
	value: IAnyObj | null;
	itemKeysCounter: Record<any, number | undefined>;
}
export class ObjectDatum extends React.PureComponent<
	IObjectDatumProps,
	IObjectDatumState
> {
	state: IObjectDatumState = {
		value:
			typeof this.props.defaultValue === "object"
				? this.props.defaultValue
				: null,
		itemKeysCounter: {},
	};

	onItemChange = (key: string, val: any, confirm: boolean) => {
		this.setState(
			({ value }) => ({
				value: { ...value!, [key]: val },
			}),
			() => {
				this.props.onChange(
					this.state.value!,
					!!this.props.alwaysCallParentOnChange
				);
			}
		);
	};

	onItemDelete = (key: string) => {
		this.setState(
			({ value, itemKeysCounter }) => ({
				value: removeKeys(value!, key),
				itemKeysCounter: {
					...itemKeysCounter,
					[key]: itemKeysCounter[key] ? itemKeysCounter[key]! + 1 : 1,
				},
			}),
			() => {
				this.props.onChange(
					this.state.value!,
					!!this.props.alwaysCallParentOnChange
				);
			}
		);
	};

	render() {
		const { value } = this.state;
		const {
			colInfo,
			projectId,
			defaultValue,
			onChange,
			...rest
		} = this.props;
		const direction = this.props.direction || "vertical";
		const itemProps = colInfo.props;
		return (
			<div
				className={cx(
					objectContainer,
					direction === "horizontal" && objectHorizontalContainer,
					direction === "vertical" && objectVerticalContainer
				)}
			>
				{itemProps.map((item, index) => {
					const editComp = getEditComponent(
						projectId,
						item.info,
						!value ? null : value[item.key],
						{
							...rest,
							onChange: (val, confirm) =>
								this.onItemChange(item.key, val, confirm),
						},
						false,
						item.key + (this.state.itemKeysCounter[item.key] || 0)
					);
					return (
						<div key={item.key}>
							<span className={objectPropHeader}>
								{item.name}
								<SvgClean
									className={objectPropCleaningIcon}
									onClick={() => this.onItemDelete(item.key)}
								/>
							</span>
							{editComp}
						</div>
					);
				})}
			</div>
		);
	}
}

const listRowContainer = css`
	display: flex;
	width: 100%;
	align-items: center;
	border-bottom: 1px solid #ccc;
	padding: 3px 0;
`;

const listContainer = css`
	.${listRowContainer}:last-of-type {
		border-bottom-color: transparent;
	}
`;

const objectContainer = css`
	display: flex;
`;

const objectHorizontalContainer = css`
	flex-direction: row;
`;
const objectVerticalContainer = css`
	flex-direction: column;
`;

const objectPropHeader = css`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const objectPropCleaningIcon = css`
	width: 19px;
	cursor: pointer;
	color: #4189cb;
`;
