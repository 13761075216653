import { SetState } from "./state-management";
import { useMemo, useCallback, useRef } from "react";
import { createMemoHook, depsDeepEquality } from "react-dependency-hooks";

export const useSelectProps = <V extends any>(
	val: V | undefined,
	onChange: ((val: V) => void) | SetState<V>,
	options: [V, string][]
) => {
	const onChangeRef = useRef(onChange);
	onChangeRef.current = onChange;
	const memoizedOptions = useDeepMemo(
		() => options.map(([value, label]) => ({ value, label })),
		[options]
	);
	const selectedOption = useMemo(
		() => memoizedOptions.find(e => e.value === val),
		[val, memoizedOptions]
	);
	const handleChange = useCallback((sel: { value: V; label: unknown }) => {
		const onChange = onChangeRef.current;
		if (!sel) {
			onChange(sel as any);
			return;
		}
		onChange(sel.value);
	}, []);
	return {
		options: memoizedOptions,
		value: selectedOption,
		onChange: handleChange,
	};
};
const useDeepMemo = createMemoHook(depsDeepEquality);
