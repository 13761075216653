import api from "@app/api";
import {
	addStatsTable,
	deleteStatsTable,
	loadStatsTableById,
	statsTableCRUDActions,
	updateStatsTable,
} from "@app/actions/stats-tables";
import {
	IAGETManyStatsTables,
	IAPOSTCreateStatsTable,
	IAPUTStatsTable,
	IRPOSTCreateStatsTable,
} from "@app/api/stats-tables/validators";
import { IDeleteOneArgs, ISearchManyArgs, ISearchOneArgs } from "./interfaces";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IRStatsTable } from "@app/api/stats-tables/helper-schemas";
import { Model } from ".";
import { Store } from "redux";
import { User } from "@app/user";

type MyInstance = IRStatsTable;

class StatsTableModel extends Model<"statsTables"> {
	constructor(store: Store<IRootState, IRootActions>) {
		super("statsTables", store);
		this.supportsLoadingByIdsFromApi = false;
	}

	getReadableStatsTables(args: IAGETManyStatsTables): Promise<MyInstance[]> {
		return api.statsTables
			.getReadableStatsTables(args)
			.then(statsTables => {
				this.dispatch(statsTableCRUDActions.loadMany(statsTables));
				return statsTables;
			});
	}

	getReadableStatsTablesSync(
		args: IAGETManyStatsTables,
		user: User
	): MyInstance[] {
		const instances = this.getState().statsTables;
		const colIds = Object.keys(instances);
		const myCols: MyInstance[] = [];
		for (let i = 0; i < colIds.length; ++i) {
			const statsTable = instances[colIds[i]]!.info;
			if (statsTable.projectId !== args.projectId) continue;
			if (user.canReadTable(statsTable._id, statsTable.projectId))
				myCols.push(statsTable);
		}
		return myCols;
	}

	async create(
		statsTable: IAPOSTCreateStatsTable
	): Promise<IRPOSTCreateStatsTable> {
		return addStatsTable(statsTable)(this.dispatch, this.getState, null);
	}
	async update(statsTable: IAPUTStatsTable): Promise<void> {
		return updateStatsTable(statsTable)(this.dispatch, this.getState, null);
	}

	public async deleteOne(info: IDeleteOneArgs["statsTables"]): Promise<void> {
		return deleteStatsTable({
			statsTableId: info.id,
			projectId: info.projectId,
		})(this.dispatch, this.getState, null);
	}

	protected async loadOne(
		info: ISearchOneArgs["statsTables"]
	): Promise<MyInstance> {
		return loadStatsTableById({
			statsTableId: info.id,
			projectId: info.projectId,
		})(this.dispatch, this.getState, null);
	}

	protected async loadManyByIds(
		infos: ISearchManyArgs["statsTables"]
	): Promise<MyInstance[]> {
		return [];
	}
}

export { StatsTableModel };
