import React from "react";
import { useTable } from "@app/hooks/tables";
import { ObjectId } from "@app/utils/generics";
import Loading from "@app/components/widgets/loading";
import { useSettabeResource, useFetch } from "@app/hooks/fetch";
import { inject } from "@app/modules";
import { TableColumOrder } from "../col-order";
import { useSetProps } from "@app/hooks/state-management";
import Button from "@material-ui/core/Button";
import { wrapInLoading } from "@app/utils/promise";
import { useHistory } from "react-router-dom";

export const TableStatsOrder: React.FC<{
	id: ObjectId;
	projectId: ObjectId;
}> = React.memo(({ id, projectId }) => {
	const history = useHistory();
	const tableRes = useTable(id);
	const [table, setTable] = useSettabeResource(tableRes, "doc");
	const setTableProps = useSetProps(setTable);
	const stats = useStats(projectId);

	const onSubmit = () => {
		if (!table) return;
		wrapInLoading(
			inject("Table")
				.update(table)
				.then(() => {
					history.push(`/projects/${projectId}`);
				})
		);
	};

	if (!table || !stats.doc) return <Loading />;
	return (
		<div className="main">
			<TableColumOrder
				allowRenaming={true}
				order={table.statsTableOrder || emptyArray}
				resources={stats.doc}
				onChange={setTableProps("statsTableOrder")}
				addResourceTitle="სტატისტიკის დამატება"
			/>
			<br />
			<Button variant="contained" color="primary" onClick={onSubmit}>
				შენახვა
			</Button>
		</div>
	);
});

const useStats = (projectId: ObjectId | null) => {
	return useFetch(
		() =>
			inject("StatsTable").getReadableStatsTables({
				projectId: projectId!,
			}),
		[projectId]
	);
};

const emptyArray = [];
