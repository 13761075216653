import React, { useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

type InputWithLabelProps = {
	label: string;
	value?: string | null;
	type?: "text" | "password" | "number" | "textarea";
	style?: React.CSSProperties;
} & (
	| {
			onChange: (value: string) => void;
			nullOnEmpty?: false;
			undefinedOnEmpty?: false;
	  }
	| {
			onChange: (value: string | null) => void;
			nullOnEmpty: true;
			undefinedOnEmpty?: false;
	  }
	| {
			onChange: (value: string | undefined) => void;
			undefinedOnEmpty: true;
			nullOnEmpty?: false;
	  }
);

export const InputWithLabel = React.memo((props: InputWithLabelProps) => {
	const { onChange, nullOnEmpty, undefinedOnEmpty } = props;
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (nullOnEmpty) {
				onChange((e.target.value || null) as string);
			} else if (undefinedOnEmpty) {
				onChange((e.target.value || undefined) as string);
			} else {
				onChange(e.target.value);
			}
		},
		[nullOnEmpty, onChange, undefinedOnEmpty]
	);
	return (
		<TextField
			multiline={props.type === "textarea"}
			label={props.label}
			value={props.value || ""}
			onChange={handleChange}
			margin="dense"
			variant="outlined"
			style={props.style}
			type={props.type}
		/>
	);
});

export const NumberInputWithLabel = React.memo(
	(
		props: {
			label: string;
			defaultValue?: number | null;
			value?: number | null;
			inputProps?: { min?: number; max?: number };
		} & (
			| {
					zeroOnEmpty?: false;
					onChange: (value: number | null) => void;
					undefinedOnEmrpty?: false;
			  }
			| {
					zeroOnEmpty: true;
					onChange: (value: number) => void;
					undefinedOnEmrpty?: false;
			  }
			| {
					zeroOnEmpty?: false;
					undefinedOnEmrpty: true;
					onChange: (value: number | undefined) => void;
			  }
		)
	) => {
		const {
			onChange,
			zeroOnEmpty,
			label,
			defaultValue,
			inputProps,
			value,
			undefinedOnEmrpty,
		} = props;
		const handleChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				onChange(
					!zeroOnEmpty && e.target.value === ""
						? undefinedOnEmrpty
							? undefined
							: (null as any)
						: +e.target.value
				);
			},
			[onChange, undefinedOnEmrpty, zeroOnEmpty]
		);

		return (
			<TextField
				label={label}
				defaultValue={defaultValue === null ? "" : defaultValue}
				value={value === null ? "" : value}
				onChange={handleChange}
				margin="dense"
				variant="outlined"
				type="number"
				inputProps={inputProps}
			/>
		);
	},
	(prevProps, nextProps) =>
		prevProps.label === nextProps.label &&
		prevProps.onChange === nextProps.onChange &&
		prevProps.value === nextProps.value
);

export const CheckboxWithLabel = React.memo(
	(props: {
		label: string;
		value: boolean;
		onChange: (value: boolean) => void;
	}) => {
		const { onChange } = props;
		const handleChange = useCallback(
			(e, checked: boolean) => {
				onChange(checked);
			},
			[onChange]
		);

		return (
			<FormControlLabel
				control={
					<Checkbox checked={props.value} onChange={handleChange} />
				}
				label={props.label}
			/>
		);
	}
);

export const SwitchWithLabel = React.memo(
	(props: {
		label: string;
		value: boolean;
		onChange: (value: boolean) => void;
	}) => {
		const { onChange } = props;
		const handleChange = useCallback(
			(e, checked: boolean) => {
				onChange(checked);
			},
			[onChange]
		);

		return (
			<FormControlLabel
				control={
					<Switch checked={props.value} onChange={handleChange} />
				}
				label={props.label}
			/>
		);
	}
);
