import * as React from "react";
import styled from "@emotion/styled";

interface IPaginatorProps {
	pageIndex: number;
	numOfPages: number;
	onPageChange: (pageIndex: number) => void;
}

export const Paginator: React.FC<IPaginatorProps> = React.memo(props => (
	<div style={{ textAlign: "center" }}>
		{new Array(props.numOfPages).fill(0).map((e, index) => (
			<Page
				key={index}
				isSelected={index === props.pageIndex}
				onClick={() => props.onPageChange(index)}
			>
				{index + 1}
			</Page>
		))}
	</div>
));

interface IPageProps {
	isSelected: boolean;
}

const Page = styled("div")`
	min-width: 30px;
	height: 30px;
	padding: 5px;
	margin: 5px;
	border-radius: 7px;
	border: 1px solid
		${(props: IPageProps) => (props.isSelected ? "#4189cb" : "#ccc")};
	background: ${(props: IPageProps) =>
		props.isSelected ? "#4189cb" : "white"};
	color: ${(props: IPageProps) => (props.isSelected ? "white" : "black")};
	display: inline-block;
	cursor: pointer;
	text-align: center;
`;
