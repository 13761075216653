import {
	IFilterOptions,
	FilterOptionWithoutDollarSchemaCorrespondence,
	IFilterOptionWithoutDollar,
	IFilterOption,
} from "@app/api/resource-data/helper-schemas";

export const transformFilter = (
	filters?: IFilterOptions
): IFilterOptions | undefined => {
	if (!filters) return filters;
	const newFilters = { ...filters };
	for (const colId in newFilters) {
		newFilters[colId] = { ...newFilters[colId] };
		for (const key in newFilters[colId]) {
			const value = newFilters[colId]![key];
			if (
				value &&
				typeof value === "object" &&
				value.type === "currentDate"
			) {
				const newValue = new Date();
				if (
					typeof value.inc === "number" &&
					Number.isFinite(value.inc)
				) {
					newValue.setDate(newValue.getDate() + value.inc);
				}
				newFilters[colId]![key] = newValue;
			}
		}
	}
	return newFilters;
};

export const withoutDollarToWith = (
	withoutDollar: IFilterOptionWithoutDollar
): IFilterOption => {
	const newObj = {};
	for (const key in withoutDollar) {
		if (key in FilterOptionWithoutDollarSchemaCorrespondence) {
			newObj[FilterOptionWithoutDollarSchemaCorrespondence[key]] =
				withoutDollar[key];
		} else {
			newObj[key] = withoutDollar[key];
		}
	}
	return newObj as IFilterOption;
};

export const withDollarToWithout = (
	withDollar: IFilterOption
): IFilterOptionWithoutDollar => {
	const newObj = {};
	const FilterOptionWithDollarSchemaCorrespondence = swapKeyValuePair(
		FilterOptionWithoutDollarSchemaCorrespondence
	);
	for (const key in withDollar) {
		if (key in FilterOptionWithDollarSchemaCorrespondence) {
			newObj[FilterOptionWithDollarSchemaCorrespondence[key]] =
				withDollar[key];
		} else {
			newObj[key] = withDollar[key];
		}
	}
	return newObj as IFilterOptionWithoutDollar;
};

function swapKeyValuePair<T extends Record<any, any>>(json: T): any {
	const ret = {} as any;
	for (const key in json) {
		ret[json[key]] = key;
	}
	return ret;
}
