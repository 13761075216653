import { css } from "emotion";

export const Cancel = css`
float: right;
color: #c0c0c0;
cursor: pointer;
position: absolute;
top: 0;
right: 0;
&:hover {
	color: grey;
}
`;

export const PopupBody = css`
padding: 10px;
padding-bottom: 40px;
>p {
	text-align: left;
}
`;

export const PopupCSS = css`
display: table;
min-width: 300px;
max-width: 90%;
background-color: white;
border-radius: 10px;
text-align: center;
margin: 0 auto;
margin-top: 80px;
margin-bottom: 80px;
position: relative;
font-family: BPGNinoMtavruli;
`;

export const PopupHeader = css`
height: 40px;
>h3 {
	padding: 10px 0px;
	text-align: center;
}
`;
