import { IAnyObj } from "./generics";

export function reorderArray<T>(
	list: T[],
	startIndex: number,
	endIndex: number
): T[] {
	const result = [...list] as T[];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

export function appendObjectToFormData(formData: FormData, object: IAnyObj) {
	for (const i in object) {
		if (object.hasOwnProperty(i)) {
			if (object[i] instanceof Object) {
				setFormData(formData, object[i], i);
			} else {
				formData.append(i, object[i]);
			}
		}
	}
}

function setFormData(formData: FormData, data, previousKey?: string) {
	if (data instanceof Object) {
		Object.keys(data).forEach(key => {
			const value = data[key];
			if (value instanceof Object && !Array.isArray(value)) {
				return setFormData(formData, value, key);
			}
			if (previousKey) {
				key = `${previousKey}[${key}]`;
			}
			if (Array.isArray(value)) {
				value.forEach(val => {
					formData.append(`${key}[]`, val);
				});
			} else {
				formData.append(key, value);
			}
		});
	}
}
