import * as React from "react";
import Button from "@material-ui/core/Button";
import Loading from "../widgets/loading";
import { History } from "history";
import { inject } from "@app/modules";
import { IRColumn, ColumnType } from "@app/api/columns/helper-schemas";
import { match } from "react-router";
import { OptionalKeys } from "@app/utils/generics";
import { removeKeys } from "@app/utils/common";
import { User } from "@app/user";
import {
	IRAPICall,
	ApiCallType,
	HTTPMethod,
	IAPICallInfo,
} from "@app/api/api-calls/helper-schemas";
import { InputWithLabel } from "../widgets/input";
import { APIInfoEdit } from "./api-info";
import { RadioNavigation } from "../widgets/radio-navigation";
import { APIActionEdit } from "./api-action";

interface IProps {
	match: match<{ projectId: string; id?: string }>;
	history: History;
	user: User;
}

interface IState {
	apiCall?: OptionalKeys<IRAPICall, "_id" | "author">;
	columns?: IRColumn[];
}

export class SaveAPICall extends React.Component<IProps, IState> {
	id = this.props.match.params.id;
	projectId = this.props.match.params.projectId;

	state: IState = {};

	_APICall = inject("APICall");
	_Column = inject("Column");

	componentDidMount() {
		this._Column
			.getReadableColumns({ projectId: this.projectId })
			.then(columns => {
				this.setState({ columns });
			});
		if (!this.id) {
			if (!this.props.user.canWriteAPICall(null, this.projectId)) {
				this.props.history.push(`/projects/${this.projectId}/api-call`);
				return;
			}
			this.setState({
				apiCall: {
					name: "",
					projectId: this.projectId,
					description: "",
					info: getDefaultInfoByType(ApiCallType.columnInfo),
				},
			});
		} else {
			this._APICall
				.findById({ id: this.id, projectId: this.projectId })
				.then(apiCall => {
					this.projectId = apiCall.projectId;
					if (
						!this.props.user.canWriteAPICall(
							apiCall._id,
							apiCall.projectId
						)
					) {
						this.props.history.push(
							`/projects/${this.projectId}/api-calls`
						);
						return;
					}
					this.setState({ apiCall: apiCall });
				});
		}
	}

	pasteOtherAPICall = () => {
		const res = JSON.parse(
			sessionStorage.getItem("copy-api-calls") || "null"
		) as { apiCallId: string; projectId: string } | null;
		if (!res) return;
		this._APICall
			.findById({
				id: res.apiCallId,
				projectId: res.projectId,
			})
			.then(apiCall => {
				this.setState({
					apiCall: {
						...removeKeys(apiCall, "_id", "author"),
						projectId: this.projectId,
					},
				});
			});
	};

	onSave = () => {
		const { apiCall: table } = this.state;
		if (!table) {
			return;
		}
		if (!this.id) {
			this._APICall.create(table as IRAPICall).then(col => {
				this.props.history.push(
					`/projects/${this.projectId}/api-calls`
				);
			});
		} else {
			this._APICall
				.update(removeKeys(table, "author") as IRAPICall)
				.then(() => {
					this.props.history.push(
						`/projects/${this.projectId}/api-calls`
					);
				});
		}
	};

	handleValueChange = <K extends keyof IRAPICall>(key: K) => (
		value: IRAPICall[K]
	) => {
		this.setState({
			apiCall: {
				...this.state.apiCall!,
				[key]: value,
			},
		});
	};

	onNameChange = this.handleValueChange("name");
	onDescriptionChange = this.handleValueChange("description");
	onInfoChange = this.handleValueChange("info");

	handleTypeChange = (type: ApiCallType) => {
		this.onInfoChange(getDefaultInfoByType(type));
	};

	render() {
		if (!this.state.apiCall || !this.state.columns) {
			return <Loading />;
		}
		const { apiCall } = this.state;
		return (
			<div className="main main2">
				{!apiCall._id && sessionStorage.getItem("copy-api-calls") && (
					<Button
						variant="contained"
						onClick={this.pasteOtherAPICall}
					>
						ჩაკოპირება
					</Button>
				)}
				<div>
					<InputWithLabel
						label="სახელი"
						value={apiCall.name}
						onChange={this.onNameChange}
					/>
				</div>
				<div>
					<InputWithLabel
						label="აღწერა"
						value={apiCall.description || ""}
						onChange={this.onDescriptionChange}
					/>
				</div>
				<APICallTypeSwitch
					value={apiCall.info.type}
					onChange={this.handleTypeChange}
				/>
				{apiCall.info.type === ApiCallType.columnInfo && (
					<APIInfoEdit
						info={apiCall.info}
						onChange={this.onInfoChange}
						projectId={this.projectId}
					/>
				)}
				{apiCall.info.type === ApiCallType.action && (
					<APIActionEdit
						info={apiCall.info}
						onChange={this.onInfoChange}
						projectId={this.projectId}
					/>
				)}
				<br />
				<br />
				<Button
					variant="contained"
					color="primary"
					onClick={this.onSave}
				>
					შენახვა
				</Button>
			</div>
		);
	}
}

const APICallTypeSwitch: React.FC<{
	value: ApiCallType;
	onChange: (type: ApiCallType) => void;
}> = React.memo(({ value, onChange }) => {
	const options = React.useMemo(
		(): { value: ApiCallType; label: string }[] => [
			{ value: ApiCallType.columnInfo, label: "ინფორმაციის წამოღება" },
			{ value: ApiCallType.action, label: "ქმედება" },
			{
				value: ApiCallType.refreshResourceIds,
				label: "რესურსების გადანაწილება",
			},
		],
		[]
	);
	const selectedTypeOption = React.useMemo(
		() => options.find(e => e.value === value),
		[options, value]
	);
	return (
		<div>
			<RadioNavigation
				options={options}
				value={selectedTypeOption}
				onChange={sel => onChange(sel.value)}
			/>
		</div>
	);
});

const getDefaultInfoByType = <T extends ApiCallType>(type: T): IAPICallInfo => {
	if (type === ApiCallType.columnInfo) {
		return {
			type: ApiCallType.columnInfo,
			responseSchema: {
				type: ColumnType.object,
				props: [],
			},
			getMany: {
				url: "",
				method: HTTPMethod.POST,
			},
		};
	} else if (type === ApiCallType.action) {
		return {
			type: ApiCallType.action,
			request: {
				url: "",
				method: HTTPMethod.POST,
			},
		};
	}
	return {
		type: ApiCallType.refreshResourceIds,
		request: {
			url: "",
			method: HTTPMethod.POST,
		},
		resourceGroups: [],
	};
};
