import * as React from "react";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import memoizeOne from "memoize-one";
import Select from "react-select";
import SortIcon from "@material-ui/icons/OpenWith";
import { css } from "emotion";
import { IColOrderValue } from "./add";
import { removeKeys, arrayToObject } from "@app/utils/common";
import { reorderArray } from "@app/utils";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ObjectId } from "@app/utils/generics";

/// ===========================================================================================

type IResource = {
	_id: ObjectId;
	name: string;
};

interface ITableColumOrderProps {
	resources: IResource[];
	order: IColOrderValue[];
	onChange: (newOrder: IColOrderValue[]) => void;
	allowRenaming: boolean;
	addResourceTitle?: string;
}

interface ITableColumOrderState {
	isAddingColumn: boolean;
}

export class TableColumOrder extends React.PureComponent<
	ITableColumOrderProps,
	ITableColumOrderState
> {
	state: ITableColumOrderState = {
		isAddingColumn: false,
	};

	getColumnsObject = memoizeOne((columns: IResource[]) =>
		arrayToObject(columns, "_id")
	);

	getColumnOptions = memoizeOne((columns: IResource[]) => {
		return columns.map(col => ({
			value: col._id,
			label: col.name,
		}));
	});

	onSwitchToAddingColumn = () => {
		this.setState({
			isAddingColumn: true,
		});
	};

	onSortEnd = ({
		oldIndex,
		newIndex,
	}: {
		oldIndex: number;
		newIndex: number;
	}) => {
		const colOrder = this.props.order;
		const newOrder = reorderArray(colOrder, oldIndex, newIndex);
		this.props.onChange(newOrder);
	};

	onColSelect = (sel: { value: string; label: string }) => {
		const newOrder = [
			...this.props.order,
			{
				id: sel.value,
			},
		];
		this.props.onChange(newOrder);
	};

	onColValueChange = (index: number, newVal: IColOrderValue) => {
		if (!this.props.allowRenaming) return;
		const newOrder = this.props.order.map((e, i) =>
			i === index ? newVal : e
		);
		this.props.onChange(newOrder);
	};

	onColRemove = (index: number) => {
		const newOrder = this.props.order.filter((e, i) => i !== index);
		this.props.onChange(newOrder);
	};

	render() {
		return (
			<div style={{ position: "relative", zIndex: 2 }}>
				<TableColumnSortComponent
					columnOrder={this.props.order}
					columns={this.getColumnsObject(this.props.resources)}
					onSortEnd={this.onSortEnd}
					onValChange={
						this.props.allowRenaming
							? this.onColValueChange
							: undefined
					}
					onRemove={this.onColRemove}
					distance={3}
					lockAxis="y"
				/>
				<br />
				{!this.state.isAddingColumn ? (
					<Button
						variant="outlined"
						onClick={this.onSwitchToAddingColumn}
					>
						{this.props.addResourceTitle ?? "სვეტის დამატება"}
					</Button>
				) : (
					<Select
						placeholder={"აირჩეთ"}
						key={this.props.order.length}
						options={this.getColumnOptions(this.props.resources)}
						onChange={this.onColSelect}
						value={null}
						defaultMenuIsOpen={true}
						autoFocus={true}
					/>
				)}
				<br />
			</div>
		);
	}
}

interface ITableColumnItemProps {
	value: IColOrderValue;
	column: IResource;
	index: number;
	i: number;
	onChange?: (index: number, value: IColOrderValue) => void;
	onRemove: (index: number) => void;
}

const TableSingleColumn = SortableElement((props: ITableColumnItemProps) => {
	const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!props.onChange) return;
		const newName = e.target.value;
		if (newName !== props.column.name) {
			props.onChange(props.i, {
				...props.value,
				customName: newName,
			});
			return;
		}
		props.onChange(props.i, removeKeys(props.value, "customName"));
	};
	return (
		<div className={TableColumnCSS}>
			<SortIcon />
			{props.onChange ? (
				<input
					value={props.value.customName || props.column.name}
					onChange={onValueChange}
				/>
			) : (
				<div>{props.value.customName || props.column.name}</div>
			)}
			<CloseIcon onClick={() => props.onRemove(props.i)} />
		</div>
	);
});

interface ITableColumnSortComponentProps {
	columnOrder: IColOrderValue[];
	columns: {
		[colId: string]: IResource | undefined;
	};
	onValChange?: (index: number, value: IColOrderValue) => void;
	onRemove: (index: number) => void;
}

const TableColumnSortComponent = SortableContainer(
	(props: ITableColumnSortComponentProps) => (
		<div>
			{props.columnOrder.map((col, index) => {
				if (props.columns[col.id] === undefined) return null;
				return (
					<TableSingleColumn
						key={`item-${index}-${col.id}`}
						index={index}
						i={index}
						value={col}
						column={props.columns[col.id]!}
						onChange={props.onValChange}
						onRemove={props.onRemove}
					/>
				);
			})}
		</div>
	)
);

const TableColumnCSS = css`
	border-radius: 5px;
	border: 1px solid #ccc;
	margin: 5px 0;
	padding: 5px;
	display: flex;
	width: 100%;
	align-items: center;
	position: relative;
	z-index: 1000;
	background: white;
	& > *:nth-child(2) {
		padding: 5px;
		background: transparent;
		width: 100%;
		border: none;
		outline: none;
		text-align: left;
	}
`;
