import * as React from "react";
import Button from "@material-ui/core/Button";
import Loading from "../widgets/loading";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import { History } from "history";
import { inject } from "@app/modules";
import { IRProject, ResourceIdType } from "@app/api/projects/helper-schemas";
import { match } from "react-router";
import { NotUndefined, OptionalKeys } from "@app/utils/generics";
import { removeKeys } from "@app/utils/common";
import { User } from "@app/user";

interface IProps {
	match: match<{ projectId?: string; }>;
	history: History;
	user: User;
}

interface IState {
	project?: OptionalKeys<IRProject, "_id" | "author">;
}

export class SaveProject extends React.Component<IProps, IState> {

	id = this.props.match.params.projectId;

	state: IState = {
	};

	_Project = inject("Project");

	userIdTypes = [
		{ value: ResourceIdType.number, label: "რიცხვი" },
		{ value: ResourceIdType.string, label: "სტრინგი" },
	];

	componentDidMount() {
		const user = new User(this.props.user);
		if (!this.id) {
			if (!this.props.user.canAddProjects) {
				this.props.history.push("/projects/");
				return;
			}
			this.setState({
				project: {
					name: "",
					resourceIdType: ResourceIdType.number,
				}
			});
		} else {
			this._Project.findById({ id: this.id }).then((project) => {
				if (!this.props.user.canWriteProject(project._id)) {
					this.props.history.push("/projects/");
					return;
				}
				this.setState({ project });
			});
		}
	}

	onSave = () => {
		if (!this.id) {
			this._Project.create(this.state.project!).then((proj) => {
				this.props.history.push(`/projects/${proj.project._id}`);
			});
		} else {
			this._Project.update(removeKeys(this.state.project!, "author") as IRProject).then(() => {
				this.props.history.push(`/projects/${this.id}`);
			});
		}
	}

	handleInputChange = (key: keyof NotUndefined<IState["project"]>): React.ChangeEventHandler<HTMLInputElement> => (e) => {
		this.setState({
			project: {
				...this.state.project!,
				[key]: e.target.value,
			}
		});
	}

	// tslint:disable-next-line:member-ordering
	onNameChange = this.handleInputChange("name");
	// tslint:disable-next-line:member-ordering
	onDescriptionChange = this.handleInputChange("description");

	onUserTypeChange = (sel: { value: ResourceIdType, label: string }) => {
		this.setState({
			project: {
				...this.state.project!,
				resourceIdType: sel.value,
			}
		});
	}

	render() {
		if (!this.state.project) {
			return <Loading />;
		}
		return (
			<div className="main main2">
				<div>
					<TextField
						label="სახელი"
						value={this.state.project.name}
						onChange={this.onNameChange}
						margin="dense"
						variant="outlined"
					/>
				</div>
				<div>
					<TextField
						label="აღწერა"
						value={this.state.project.description || ""}
						onChange={this.onDescriptionChange}
						margin="dense"
						variant="outlined"
					/>
				</div>
				<br/>
				მომხმარებლის აიდის ტიპი:
				<Select
					value={this.userIdTypes.find(e => e.value === this.state.project!.resourceIdType)}
					onChange={this.onUserTypeChange}
					options={this.userIdTypes}
				/>
				<br/>
				<Button variant="contained" color="primary" onClick={this.onSave}>
					შენახვა
				</Button>
			</div>
		);
	}
}
