import * as React from "react";
import Select from "react-select";
import { IRResourceGroup } from "@app/api/resource-groups/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { SelectContainerCSS } from "../columns/add";

interface IListResourceGroupsProps {
	resourceGroups: IRResourceGroup[];
	defaultValue?: ObjectId;
	onChange: (val?: ObjectId) => void;
	tableId?: ObjectId;
}

export class ListResourceGroups extends React.PureComponent<
	IListResourceGroupsProps
> {
	state = {
		selectedGroupId: this.props.defaultValue,
	};

	onChange = (sel: { value: ObjectId; label: string }) => {
		this.setState({
			selectedGroupId: sel.value,
		});
		this.props.onChange(sel.value);
	};

	render() {
		const options = [
			{ value: undefined as (ObjectId | undefined), label: "ყველა" },
		].concat(
			this.props.resourceGroups
				.filter(
					e =>
						!this.props.tableId ||
						!e.tableIds ||
						e.tableIds.indexOf(this.props.tableId) > -1
				)
				.map(e => ({
					value: e._id,
					label: e.name,
				}))
		);
		const selected = options.find(
			e => this.state.selectedGroupId === e.value
		);
		return (
			<div className={SelectContainerCSS}>
				<span>გაფილტრვა მომხმარებელთა ჯგუფების მიხედვით:</span>
				<Select
					options={options}
					value={selected}
					onChange={this.onChange}
					placeholder="აირჩიეთ"
				/>
			</div>
		);
	}
}
