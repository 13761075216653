import * as React from "react";
import classNames from "classnames";
import LoadingSVG from "@app/components/styles/img/loading-white";
import Popup from "./widgets/popup";
import { _console } from "@app/commonJavascript";
import { connect } from "react-redux";
import { ILogin, login } from "@app/actions/auth";
import { useState } from "react";
import api from "../api";
import { useBoolean } from "../hooks/general";

interface IOwnProps {
	onClose: (loggedInSuccessfully: boolean) => void;
	onSuccess?: Function;
}

interface IDispatchProps {
	login: ILogin;
}

interface IErrorType {
	mail?: boolean;
	password?: boolean;
}

interface IState {
	mail: string;
	password: string;
	signLoading: boolean;
	errors: IErrorType;
	showRegister: boolean;
}

type IProps = IOwnProps & IDispatchProps;

class LoginPopup extends React.Component<IProps> {
	_isMounted: boolean;
	state = {
		mail: "",
		password: "",
		signLoading: false,
		errors: {},
		showRegister: false,
	} as IState;

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	onMailChange = e => {
		this.setState({
			mail: e.target.value,
			errors: { ...this.state.errors, mail: false },
		});
	};
	onPasswordChange = e => {
		this.setState({
			password: e.target.value,
			errors: { ...this.state.errors, password: false },
		});
	};

	onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			this.onLoginClick();
		}
	};

	onLoginClick = () => {
		if (this.state.signLoading) {
			return;
		}
		if (!this.state.mail || !this.state.password) {
			this.setState({
				errors: {
					mail: !this.state.mail,
					password: !this.state.password,
				},
			});
			return;
		}
		this.setState({
			signLoading: true,
		});
		this.props
			.login({
				mail: this.state.mail,
				password: this.state.password,
			})
			.then(data => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					signLoading: false,
				});
				this.props.onClose(true);
				if (this.props.onSuccess) this.props.onSuccess();
			})
			.catch(err => {
				_console.error(err);
				if (!this._isMounted) {
					return;
				}
				const errors: IErrorType = {};
				if (err.response && err.response.data) {
					if (err.response.data.mail) {
						errors.mail = true;
					}
					if (err.response.data.password) {
						errors.password = true;
					}
				}
				this.setState({
					errors,
					signLoading: false,
				});
			});
	};

	onRegisterClick = (showRegister: boolean) => {
		this.setState({ showRegister });
	};

	render() {
		let errorText = "";
		if (
			this.state.mail &&
			this.state.errors.mail &&
			!this.state.errors.password
		) {
			errorText = "ელფოსტა არასწორია";
		} else if (
			this.state.password &&
			this.state.errors.password &&
			!this.state.errors.mail
		) {
			errorText = "პაროლი არასწორია";
		} else if (this.state.errors.mail || this.state.errors.password) {
			errorText = "ელფოსტა ან პაროლი არასწორია";
		}
		return (
			<div>
				<Popup onClose={() => this.props.onClose(false)}>
					<div className="loginPopupContent">
						<div className="Header">ავტორიზაცია</div>
						<div className="Body">
							<div className="Input">
								<input
									type="text"
									name="mail"
									className={classNames({
										"no-arrows": true,
										incorrect: !!this.state.errors.mail,
									})}
									value={this.state.mail}
									onChange={this.onMailChange}
									placeholder={"ელფოსტა"}
								/>
								<input
									type="password"
									className={classNames({
										incorrect: !!this.state.errors.password,
									})}
									value={this.state.password}
									onChange={this.onPasswordChange}
									onKeyPress={this.onKeyPress}
									placeholder={"პაროლი"}
								/>
							</div>
							{errorText && <span>{errorText}</span>}
							<div className="Buttons">
								<button onClick={this.onLoginClick}>
									<span>შესვლა</span>
									{this.state.signLoading && (
										<LoadingSVG width={25} height={25} />
									)}
								</button>
								<button
									onClick={() =>
										this.onRegisterClick(
											!this.state.showRegister
										)
									}
								>
									რეგისტრაცია
								</button>
							</div>
						</div>
					</div>
				</Popup>
				{this.state.showRegister && (
					<RegisterPopup
						onRegisterClick={this.onRegisterClick}
						showRegister={this.state.showRegister}
						changePass={this.onPasswordChange}
						changeEmail={this.onMailChange}
						onLogin={this.onLoginClick}
					/>
				)}
			</div>
		);
	}
}

const RegisterPopup: React.FC<{
	onRegisterClick: (r: boolean) => void;
	showRegister: boolean;
	changePass: (e) => void;
	changeEmail: (e) => void;
	onLogin: () => void;
}> = React.memo(props => {
	const [name, setName] = useState<string>("");
	const [lastname, setLastname] = useState<string>("");
	const [email, setMail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const { value: exists, setTrue } = useBoolean();
	const handleRegister = () => {
		api.users
			.registerUser({ password, name, email, lastname })
			.then(x => {
				props.onLogin();
			})
			.catch(e => {
				if (e.message.includes("code 409")) setTrue();
			});
	};
	const handleMailChange = e => {
		setMail(e.target.value);
		props.changeEmail(e);
	};
	const handlePassChange = e => {
		setPassword(e.target.value);
		props.changePass(e);
	};

	return (
		<Popup>
			<div className="loginPopupContent">
				<div className="Header">რეგისტრაცია</div>
				<div className="Body">
					<div className="Input">
						<input
							type="text"
							value={name}
							onChange={e => setName(e.target.value)}
							placeholder={"სახელი"}
						/>
						<input
							type="text"
							value={lastname}
							onChange={e => setLastname(e.target.value)}
							placeholder={"გვარი"}
						/>
						<input
							type="text"
							name={"mail"}
							value={email}
							onChange={handleMailChange}
							placeholder={"მეილი"}
						/>
						<input
							type="password"
							value={password}
							onChange={handlePassChange}
							placeholder={"პაროლი"}
						/>
					</div>
					{exists && <span>{"მომხმარებელი არსებობს"}</span>}
					<div className="Buttons">
						<button
							onClick={() =>
								props.onRegisterClick(!props.showRegister)
							}
						>
							<span>დაბრუნება</span>
						</button>
						<button onClick={handleRegister}>რეგისტრაცია</button>
					</div>
				</div>
			</div>
		</Popup>
	);
});
const mapDispatchToProps = {
	login,
};

export default connect<null, IDispatchProps, IOwnProps>(
	null,
	mapDispatchToProps as any
)(LoginPopup);
