import React from "react";
import { match } from "react-router-dom";

export const ProjectContext = React.createContext<string | null>(null);

export const ProjectWatcher: React.FC<{
	match: match<{ projectId: string }>;
}> = React.memo(({ match, children }) => {
	const projectId = match.params.projectId;
	return (
		<ProjectContext.Provider value={projectId || null}>
			{children}
		</ProjectContext.Provider>
	);
});
