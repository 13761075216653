import * as React from "react";
import api from "@app/api";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { addLoader } from "@app/commonJavascript";
import { apiCallCRUDActions } from "@app/actions/api-calls";
import {
	ApiCallType,
	IAPICallAction,
	IAPICallRequestData,
	IRAPICall,
	IRequestPropertiesData,
	IRequestPropertyData,
} from "@app/api/api-calls/helper-schemas";
import {
	ColumnType,
	IAPIColumnInfo,
	IColumnData,
} from "@app/api/columns/helper-schemas";
import { connect } from "react-redux";
import { css } from "emotion";
import { IAnyObj, ObjectId, WithKnownKeyType } from "@app/utils/generics";
import { inject } from "@app/modules";
import { removeKeys } from "@app/utils/common";
import { TableResourceSingleDatum } from ".";
import { ToDispatchType } from "@app/reducers/root-state";

interface IOwnProps {
	api: WithKnownKeyType<IRAPICall, "info", IAPICallAction>;
	onCall: (args: {
		resourceId: number | string;
		colId: ObjectId;
		newValue: IColumnData;
	}) => void;
	resourceId?: number | string;
	columnId?: ObjectId;
	onClose: () => void;
}

interface IAPICallActionViewOuterState {
	requestType?: IRequestPropertiesData;
	requestData?: IAPICallRequestData;
}

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;
type IProps = IDispatchProps & IOwnProps;

class APICallActionViewOuter extends React.PureComponent<
	IProps,
	IAPICallActionViewOuterState
> {
	state: IAPICallActionViewOuterState = {};

	componentDidMount = () => {
		const removeLoader = addLoader();
		api.apiCalls
			.getActionFillableForm({
				_id: this.props.api._id,
				projectId: this.props.api.projectId,
				resIds: this.props.resourceId,
			})
			.then(data => {
				if (data.apiInfo && data.apiInfo.length > 0) {
					this.props.loadManyAPICalls(data.apiInfo);
				}
				this.setState({
					requestData: data.requestData,
					requestType: data.requestType,
				});
				removeLoader();
			})
			.catch(() => {
				removeLoader();
			});
	};

	onPopupClose = () => {
		this.props.onClose();
	};

	onRequestDataChange = (newData: IAPICallRequestData) => {
		this.setState({
			requestData: newData,
		});
	};

	onConfirm = () => {
		if (!this.state.requestData || !this.state.requestType) return;
		const removeLoader = addLoader();
		api.apiCalls
			.callAction({
				_id: this.props.api._id,
				projectId: this.props.api.projectId,
				resIds: this.props.resourceId,
				columnId: this.props.columnId,
				requestData: this.state.requestData,
			})
			.then(() => {
				removeLoader();
				this.props.onCall({
					resourceId: this.props.resourceId!,
					colId: this.props.columnId!,
					newValue: new Date(),
				});
				this.props.onClose();
			})
			.catch(e => {
				console.log(e);
				alert("ქმედება წარუმატებლად დასრულდა");
				removeLoader();
			});
	};

	render() {
		return (
			<Popup>
				<PopupContentWithClose onClose={this.onPopupClose}>
					{this.state.requestData && this.state.requestType && (
						<FillActionRequest
							requestData={this.state.requestData}
							requestType={this.state.requestType}
							resourceId={this.props.resourceId}
							onRequestDataChange={this.onRequestDataChange}
							projectId={this.props.api.projectId}
							onConfirm={this.onConfirm}
						/>
					)}
				</PopupContentWithClose>
			</Popup>
		);
	}
}

interface IFillActionRequestProps {
	requestType: IRequestPropertiesData;
	requestData: IAPICallRequestData;
	resourceId?: number | string;
	onRequestDataChange: (IAPICallRequestData) => void;
	projectId: ObjectId;
	onConfirm: () => void;
}

class FillActionRequest extends React.PureComponent<IFillActionRequestProps> {
	onDataChange = (which: keyof IAPICallRequestData, newData: IAnyObj) => {
		const data = { ...this.props.requestData, [which]: newData };
		this.props.onRequestDataChange(data);
	};

	render() {
		const commonProps = {
			resourceId: this.props.resourceId,
			onDataChange: this.onDataChange,
			projectId: this.props.projectId,
		};
		return (
			<div>
				<FillableActionRequest
					which="headers"
					{...commonProps}
					data={this.props.requestData.headers}
					types={this.props.requestType.headers}
				/>
				<FillableActionRequest
					which="params"
					{...commonProps}
					data={this.props.requestData.params}
					types={this.props.requestType.params}
				/>
				<FillableActionRequest
					which="query"
					{...commonProps}
					data={this.props.requestData.query}
					types={this.props.requestType.query}
				/>
				<FillableActionRequest
					which="body"
					{...commonProps}
					data={this.props.requestData.body}
					types={this.props.requestType.body}
				/>
				<button onClick={this.props.onConfirm}>დასტური</button>
			</div>
		);
	}
}

interface IFillableActionRequestProps {
	which: keyof IAPICallRequestData;
	data?: IAnyObj;
	types?: { [k: string]: IRequestPropertyData };
	resourceId?: number | string;
	onDataChange: (which: keyof IAPICallRequestData, newData: IAnyObj) => void;
	projectId: ObjectId;
}

class FillableActionRequest extends React.PureComponent<
	IFillableActionRequestProps
> {
	_APICall = inject("APICall");

	merge = (
		original: IRequestPropertyData,
		newOne: IRequestPropertyData
	): IRequestPropertyData => {
		return { ...newOne, ...removeKeys(original, "type") };
	};

	onDatumChange = (
		resourceId,
		key: string,
		newData: IColumnData
	): Promise<void> => {
		return new Promise(resolve => {
			const data = { ...this.props.data, [key]: newData };
			this.props.onDataChange(this.props.which, data);
			resolve();
		});
	};

	render() {
		if (!this.props.types) return null;
		const typeKeys = Object.keys(this.props.types || {});
		return (
			<div>
				{typeKeys.map(key => {
					let colInfo = this.props.types![key];
					if (!colInfo) return null;
					if (colInfo.isHidden || colInfo.isSecret) return null;
					if (colInfo.type === ColumnType.api) {
						const thisApi = this._APICall.findByIdSync({
							id: colInfo.apiId,
							projectId: this.props.projectId,
						});
						if (
							!thisApi ||
							thisApi.info.type !== ApiCallType.columnInfo
						)
							return null;
						if (colInfo.key === undefined) {
							colInfo = this.merge(
								colInfo,
								thisApi.info.responseSchema
							);
						} else if (
							thisApi.info.responseSchema.type !==
							ColumnType.object
						) {
							return null;
						} else {
							const prop = thisApi.info.responseSchema.props.find(
								e => e.key === (colInfo as IAPIColumnInfo).key
							);
							if (!prop) return null;
							colInfo = this.merge(colInfo, prop.info);
						}
					}
					return (
						<div key={key} className={rowContainerCSS}>
							<div>{colInfo.customName}</div>
							<TableResourceSingleDatum
								key={key}
								col={{ _id: key, info: colInfo }}
								implicitColInfo={colInfo}
								datum={this.props.data && this.props.data[key]}
								readonly={!colInfo.canBeChanged}
								resourceId={this.props.resourceId || ""}
								projectId={this.props.projectId}
								onDatumChange={this.onDatumChange}
								onActionPopup={emptyFunction as any}
							/>
						</div>
					);
				})}
			</div>
		);
	}
}

const emptyFunction = () => {};

const mapDispatchToProps = {
	loadManyAPICalls: apiCallCRUDActions.loadMany,
};

const rowContainerCSS = css`
	display: flex;
	width: 100%;
	align-items: center;
`;

const connected = connect<null, IDispatchProps, IOwnProps>(
	null,
	mapDispatchToProps
)(APICallActionViewOuter);
export { connected as APICallActionViewOuter };
