import api from "@app/api";
import createCRUDActions from "./crud";
import {
	DELETE_APICALL,
	LOAD_APICALL_BY_ID,
	LOAD_APICALLS,
	UPDATE_APICALL,
} from "@app/action-types";
import { IRAPICall } from "@app/api/api-calls/helper-schemas";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { ThunkAction } from "redux-thunk";
import {
	IAGETAPICall,
	IAPOSTCreateAPICall,
	IAPUTAPICall,
	IRGETManyAPICalls,
	IRGETAPICall,
	IRPOSTCreateAPICall,
	IADELETEAPICall,
	IAGETManyAPICalls,
} from "@app/api/api-calls/validators";

export const addAPICall = (
	args: IAPOSTCreateAPICall
): ThunkAction<
	Promise<IRPOSTCreateAPICall>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.apiCalls.add(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const updateAPICall = (
	args: IAPUTAPICall
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.apiCalls.update(args).then(() => {
		dispatch(crudActions.update(args));
	});
};

export const loadAPICalls = (
	args: IAGETManyAPICalls
): ThunkAction<
	Promise<IRGETManyAPICalls>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.apiCalls.getReadableAPICalls(args).then(data => {
		dispatch(crudActions.loadMany(data));
		return data;
	});
};

export const loadAPICallById = (
	args: IAGETAPICall
): ThunkAction<
	Promise<IRGETAPICall>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.apiCalls.getById(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const deleteAPICall = (
	args: IADELETEAPICall
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.apiCalls.deleteById(args).then(() => {
		dispatch(crudActions.delete(args.APICallId));
	});
};

export type APICallActions =
	| ReturnType<typeof crudActions["delete"]>
	| ReturnType<typeof crudActions["loadMany"]>
	| ReturnType<typeof crudActions["loadOne"]>
	| ReturnType<typeof crudActions["update"]>;

export const apiCallCRUDActionTypes = {
	update: UPDATE_APICALL as typeof UPDATE_APICALL,
	loadOne: LOAD_APICALL_BY_ID as typeof LOAD_APICALL_BY_ID,
	loadMany: LOAD_APICALLS as typeof LOAD_APICALLS,
	delete: DELETE_APICALL as typeof DELETE_APICALL,
};

const crudActions = createCRUDActions<IRAPICall, typeof apiCallCRUDActionTypes>(
	apiCallCRUDActionTypes
);
export { crudActions as apiCallCRUDActions };
