import styled from "@emotion/styled";
import { css } from "emotion";

export const tableCSS = css`
	width: 100%;
	border-spacing: 0;
	text-align: center;
	& > thead {
		th {
			background: #4189cb;
			color: white;
			padding: 10px 5px;
			position: sticky;
			top: 80px;
			z-index: 5;
		}
	}
	& > tbody {
		& > tr:nth-of-type(2n) > td {
			background: #eee;
		}
	}
`;

export const ChangeContainerCSS = css`
	width: 100%;
	border-radius: 3px;
	background: white;
	margin: 2px;
	display: flex;
	align-items: stretch;
	min-width: 260px;
	box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px;
	& > div:nth-of-type(1) {
		flex: 1;
		& > input {
			width: 100%;
			border: none;
			outline: none;
			padding: 5px;
			border-radius: 5px;
		}
	}
	& > div:last-child {
		border-radius: 0 3px 3px 0;
	}
`;

export const changeButtonCSS = css`
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	cursor: pointer;
	box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1) inset;
	transition: 0.25s;
`;
export const greyButton = css`
	background: #9a9a9a;
	&:hover {
		background: #7f7f7f;
	}
`;
export const greenButton = css`
	background: #1ab91a;
	&:hover {
		background: #179c17;
	}
`;
export const redButton = css`
	background: #eb1616;
	&:hover {
		background: #d91111;
	}
`;

interface IEnumSingleValProps {
	isSelected: boolean;
}

export const EnumSingleVal = styled("div")`
	background: ${(props: IEnumSingleValProps) =>
		props.isSelected ? "#4189cb" : "#eee"};
	color: ${(props: IEnumSingleValProps) =>
		props.isSelected ? "#fff" : "inherit"};
	padding: 5px;
	margin: 5px;
	display: inline-block;
	vertical-align: middle;
	border-radius: 3px;
	border: 1px solid #ccc;
	cursor: pointer;
	transition: all 0.3s;
	user-select: none;
`;

export const textareaCellEditCSS = css`
	width: 100%;
	display: block;
	min-height: 100px;
`;
