import {
	IADELETEAPICall,
	IAGETAPICall,
	IAGETManyAPICalls,
	IAPOSTCallAPI,
	IAPOSTCreateAPICall,
	IAPOSTFilledAPICall,
	IAPUTAPICall,
	IRGETAPICall,
	IRGETManyAPICalls,
	IRPOSTCreateAPICall,
	IRPOSTFilledAPICall,
	RGETAPICallSchema,
	RGETManyAPICallsSchema,
	RPOSTCreateAPICallSchema,
	RPOSTFilledAPICall,
} from "./validators";
import { Requests } from "..";

// tslint:disable:no-duplicate-string
const apiCalls = {
	add: (data: IAPOSTCreateAPICall): Promise<IRPOSTCreateAPICall> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/api-calls/",
			data,
			null,
			{
				responseSchema: RPOSTCreateAPICallSchema,
			}
		),
	update: (data: IAPUTAPICall): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/api-calls/:_id",
			data,
			null
		),
	getById: (data: IAGETAPICall): Promise<IRGETAPICall> =>
		Requests.send(
			"GET",
			"/api/projects/:projectId/api-calls/:APICallId",
			data,
			null,
			{
				responseSchema: RGETAPICallSchema,
			}
		),
	deleteById: (data: IADELETEAPICall): Promise<void> =>
		Requests.send(
			"DELETE",
			"/api/projects/:projectId/api-calls/:APICallId",
			data
		),
	getReadableAPICalls: (
		data: IAGETManyAPICalls
	): Promise<IRGETManyAPICalls> =>
		Requests.send("GET", "/api/projects/:projectId/api-calls", data, null, {
			responseSchema: RGETManyAPICallsSchema,
		}),
	getActionFillableForm: (
		data: IAPOSTFilledAPICall
	): Promise<IRPOSTFilledAPICall> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/api-calls/:_id/filled",
			data,
			null,
			{
				responseSchema: RPOSTFilledAPICall,
			}
		),
	callAction: (data: IAPOSTCallAPI): Promise<void> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/api-calls/:_id/call",
			data,
			null
		),
};
export default apiCalls;
