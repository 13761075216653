import Joi from "@app/utils/joi";
import {
	ColumnDataSchema,
	RColumnSchema,
	IColumnData,
	IRColumn,
} from "../columns/helper-schemas";
import {
	FilterOptionsSchema,
	SortOptionsSchema,
	IFilterOptions,
	ISortOptions,
} from "./helper-schemas";
import { markKeysStripped } from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { ResIdSchema, IResId, ResIdsSchema, IResIds } from "../common-schemas";

export const AGETManyResourcesDataSchema = Joi.object({
	projectId: Joi.objectId().required(),
	tableId: Joi.objectId().required(),
	resourceGroupId: Joi.objectId(),
	limit: Joi.number().integer(),
	offset: Joi.number().integer(),
	filterOptions: FilterOptionsSchema,
	sortOptions: SortOptionsSchema,
	onlyMyUsers: Joi.boolean(),
	timeZoneOffsetInMinutes: Joi.number()
		.integer()
		.required(),
	ignoreDateTimes: Joi.boolean().required(),
});
export interface IAGETManyResourcesData {
	projectId: ObjectId;
	tableId: ObjectId;
	resourceGroupId?: ObjectId;
	limit?: number;
	offset?: number;
	filterOptions?: IFilterOptions;
	sortOptions?: ISortOptions;
	onlyMyUsers?: boolean;
	timeZoneOffsetInMinutes: number;
	ignoreDateTimes: boolean;
}

export const RGETManyResourcesDataSchema = Joi.object({
	cols: Joi.array()
		.items(RColumnSchema.keys({ customName: Joi.string() }))
		.required(),
	resources: Joi.array()
		.items(
			Joi.object({
				id: ResIdSchema.required(),
				data: Joi.array()
					.items(ColumnDataSchema)
					.required(),
				resGroups: Joi.array()
					.items(Joi.objectId())
					.required(),
			})
		)
		.required(),
	numOfResources: Joi.number().required(),
});
export interface IRGETManyResourcesData {
	cols: (IRColumn & { customName?: string })[];
	resources: {
		id: IResId;
		data: IColumnData[];
		resGroups: ObjectId[];
	}[];
	numOfResources: number;
}

///

export const AGETManyResourceIdsSchema = AGETManyResourcesDataSchema.fork(
	"sortOptions",
	markKeysStripped
);
export type IAGETManyResourceIds = Omit<IAGETManyResourcesData, "sortOptions">;

export const RGETManyResourceIdsSchema = Joi.object({
	resourceIds: ResIdsSchema.required(),
});

export interface IRGETManyResourceIds {
	resourceIds: IResIds;
}

///

export const APUTResourceDataSchema = Joi.object({
	projectId: Joi.objectId().required(),
	resourceId: ResIdSchema.required(),
	columnId: Joi.objectId().required(),
	data: ColumnDataSchema.required(),
});
export interface IAPUTResourceData {
	projectId: ObjectId;
	resourceId: IResId;
	columnId: ObjectId;
	data: IColumnData;
}

export type IRPUTResourceData = void;

///

export const APOSTImportDataSchema = Joi.object({
	projectId: Joi.objectId().required(),
	columnIds: Joi.array()
		.items(Joi.objectId())
		.required(),
	updateExistingEntries: Joi.boolean(),
});
export interface IAPOSTImportData {
	projectId: ObjectId;
	columnIds: ObjectId[];
	updateExistingEntries?: boolean;
}

export type IRPOSTImportData = void;
///

export const APUTReloadDataSchema = Joi.alternatives(
	Joi.object({
		type: Joi.string()
			.valid("whole_project")
			.required(),
		projectId: Joi.objectId().required(),
	}),
	Joi.object({
		type: Joi.string()
			.valid("partial_project")
			.required(),
		projectId: Joi.objectId().required(),
		tableId: Joi.objectId().required(),
		resourceGroupId: Joi.objectId(),
		filterOptions: FilterOptionsSchema,
		onlyMyUsers: Joi.boolean(),
		timeZoneOffsetInMinutes: Joi.number()
			.integer()
			.required(),
		ignoreDateTimes: Joi.boolean().required(),
	})
);
export type IAPUTReloadData =
	| {
			type: "whole_project";
			projectId: ObjectId;
	  }
	| {
			type: "partial_project";
			projectId: ObjectId;
			tableId: ObjectId;
			resourceGroupId?: ObjectId;
			filterOptions?: IFilterOptions;
			onlyMyUsers?: boolean;
			timeZoneOffsetInMinutes: number;
			ignoreDateTimes: boolean;
	  };
