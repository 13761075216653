export const CAN_READ_PROJECT = 1 << 0;
export const CAN_WRITE_PROJECT = 1 << 1;
export const CAN_DELETE_PROJECT = 1 << 2;
export const CAN_MANAGE_ADMINS_IN_PROJECT = 1 << 3;
export const CAN_MANAGE_USERS_IN_PROJECT = 1 << 4;

export const CAN_READ_APICALLS = 1 << 0;
export const CAN_WRITE_APICALLS = 1 << 1;
export const CAN_DELETE_APICALLS = 1 << 2;
export const CAN_EXECUTE_APICALLS = 1 << 3;
export const CAN_READ_HIDDEN_DATAVALUES_OF_APICALLS = 1 << 4;

export const CAN_READ_TABLES = 1 << 0;
export const CAN_WRITE_TABLES = 1 << 1;
export const CAN_DELETE_TABLES = 1 << 2;

export const CAN_READ_COLUMNS = 1 << 0;
export const CAN_WRITE_COLUMNS = 1 << 1;
export const CAN_DELETE_COLUMNS = 1 << 2;

export const CAN_READ_RESOURCE_GROUPS = 1 << 0;
export const CAN_WRITE_RESOURCE_GROUPS = 1 << 1;
export const CAN_DELETE_RESOURCE_GROUPS = 1 << 2;

export const CAN_READ_OWN_USERS_FIELD_DATA = 1 << 0;
export const CAN_WRITE_OWN_USERS_FIELD_DATA = 1 << 1;
export const CAN_DELETE_OWN_USERS_FIELD_DATA = 1 << 2;
export const CAN_READ_OTHERS_USERS_FIELD_DATA = 1 << 3;
export const CAN_WRITE_OTHERS_USERS_FIELD_DATA = 1 << 4;
export const CAN_DELETE_OTHERS_USERS_FIELD_DATA = 1 << 5;

export const CAN_READ_FUNCTIONS = 1 << 0;
export const CAN_WRITE_FUNCTIONS = 1 << 1;
export const CAN_DELETE_FUNCTIONS = 1 << 2;
