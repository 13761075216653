import api from "@app/api";
import {
	addFunction,
	deleteFunction,
	loadFunctionById,
	functionCRUDActions,
	updateFunction,
} from "@app/actions/functions";
import {
	IAGETManyFunctions,
	IAPOSTCreateFunction,
	IAPUTFunction,
	IRPOSTCreateFunction,
} from "@app/api/functions/validators";
import { IDeleteOneArgs, ISearchManyArgs, ISearchOneArgs } from "./interfaces";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IRFunction } from "@app/api/functions/helper-schemas";
import { Model } from ".";
import { Store } from "redux";
import { User } from "@app/user";

type MyInstance = IRFunction;

class FunctionModel extends Model<"functions"> {
	constructor(store: Store<IRootState, IRootActions>) {
		super("functions", store);
		this.supportsLoadingByIdsFromApi = false;
	}

	getReadableFunctions(args: IAGETManyFunctions): Promise<MyInstance[]> {
		return api.functions.getReadableFunctions(args).then(funcs => {
			this.dispatch(functionCRUDActions.loadMany(funcs));
			return funcs;
		});
	}

	getReadableFunctionsSync(
		args: IAGETManyFunctions,
		user: User
	): MyInstance[] {
		const instances = this.getState().functions;
		const colIds = Object.keys(instances);
		const myFunctions: MyInstance[] = [];
		for (let i = 0; i < colIds.length; ++i) {
			const func = instances[colIds[i]]!.info;
			if (func.projectId !== args.projectId) continue;
			if (user.canReadFunction(func._id, func.projectId))
				myFunctions.push(func);
		}
		return myFunctions.sort((a, b) => {
			return a._id < b._id ? -1 : 1;
		});
	}

	async create(func: IAPOSTCreateFunction): Promise<IRPOSTCreateFunction> {
		return addFunction(func)(this.dispatch, this.getState, null);
	}
	async update(func: IAPUTFunction): Promise<void> {
		return updateFunction(func)(this.dispatch, this.getState, null);
	}

	public async deleteOne(info: IDeleteOneArgs["functions"]): Promise<void> {
		return deleteFunction({
			functionId: info.id,
			projectId: info.projectId,
		})(this.dispatch, this.getState, null);
	}

	protected async loadOne(
		info: ISearchOneArgs["functions"]
	): Promise<MyInstance> {
		return loadFunctionById({
			functionId: info.id,
			projectId: info.projectId,
		})(this.dispatch, this.getState, null);
	}

	protected async loadManyByIds(
		infos: ISearchManyArgs["functions"]
	): Promise<MyInstance[]> {
		return [];
	}
}

export { FunctionModel };
