/* eslint-disable no-restricted-globals */
import * as React from "react";
import Button from "@material-ui/core/Button";
import { AdminTable } from "../widgets/admin-table";
import { History } from "history";
import { inject } from "@app/modules";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";
import { User } from "@app/user";
import CopyIcon from "@material-ui/icons/FilterNone";

interface IProps {
	history: History;
	match: match<{ projectId: string }>;
	user: User;
}

export class APICallsList extends React.PureComponent<IProps> {
	projectId = this.props.match.params.projectId;

	_APICall = inject("APICall");

	componentDidMount() {
		this._APICall.getReadableAPICalls({ projectId: this.projectId }).then();
	}

	canDelete = (id: ObjectId) => {
		return this.props.user.canDeleteAPICall(id, this.projectId);
	};
	canEdit = (id: ObjectId) => {
		return this.props.user.canDeleteAPICall(id, this.projectId);
	};

	onDelete = (id: ObjectId) => {
		if (confirm("დარწმუნებული ხართ????")) {
			this._APICall.deleteOne({ id, projectId: this.projectId });
		}
	};

	onEdit = (id: ObjectId) => {
		this.props.history.push(
			`/projects/${this.projectId}/api-calls/${id}/edit`
		);
	};

	onAPICallAdd = () => {
		this.props.history.push(`/projects/${this.projectId}/api-calls/add`);
	};

	onRedirectToFunctions = () => {
		this.props.history.push(`/projects/${this.projectId}/functions`);
	}

	additionalComponents: React.ComponentType<{
		rowId: ObjectId;
		iconClassName: string;
	}> = ({ rowId, iconClassName }) => {
		return (
			<>
				<CopyIcon
					className={iconClassName}
					onClick={() => this.copy(rowId)}
				/>
			</>
		);
	};

	copy = (apiCallId: ObjectId) => {
		sessionStorage.setItem(
			"copy-api-calls",
			JSON.stringify({ apiCallId, projectId: this.projectId })
		);
	};

	render() {
		const tables = this._APICall.getReadableAPICallsSync(
			{ projectId: this.projectId },
			this.props.user
		);
		return (
			<div className="main main2">
				<h2>თუ თემური არ გქვია, ფრთხილად ამ გვერდზე</h2>
				<br />
				{this.props.user.canWriteAPICall(null, this.projectId) && (
					<Button
						variant="contained"
						color="primary"
						onClick={this.onAPICallAdd}
					>
						API call-ის დამატება
					</Button>
				)}
				<Button
					variant="contained"
					color="primary"
					onClick={this.onRedirectToFunctions}
				>
					functions
				</Button>
				<AdminTable
					canDelete={this.canDelete}
					canEdit={this.canEdit}
					onEdit={this.onEdit}
					onDelete={this.onDelete}
					rows={tables}
					additionalComponents={this.additionalComponents}
				/>
			</div>
		);
	}
}
