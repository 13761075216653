import { ObjectId, Omit } from "@app/utils/generics";

export interface ICRUDActionTypes {
	update: string;
	loadOne: string;
	loadMany: string;
	delete: string;
}

export interface IUpdateAction<T extends IDocument, Type extends string> {
	type: Type;
	info: Partial<Omit<T, "_id">> & IDocument;
}

export interface ILoadOneAction<T extends IDocument, Type extends string> {
	type: Type;
	info: T;
	loadTime: Date;
}

export interface ILoadManyAction<T extends IDocument, Type extends string> {
	type: Type;
	infos: T[];
	loadTime: Date;
}

export interface IDeleteAction<Type extends string> {
	type: Type;
	_id: ObjectId;
}

export type ICRUDActions<T extends IDocument> =
	| IUpdateAction<T, any>
	| ILoadOneAction<T, any>
	| ILoadManyAction<T, any>
	| IDeleteAction<any>;

export interface IDocument {
	_id: ObjectId;
}

function createCRUDActions<
	DOC extends IDocument,
	Types extends ICRUDActionTypes
>(actionTypes: Types) {
	return {
		update: (
			info: Partial<Omit<DOC, "_id">> & IDocument
		): IUpdateAction<DOC, Types["update"]> => ({
			type: actionTypes.update,
			info,
		}),
		loadOne: (
			info: DOC,
			loadTime: Date = new Date()
		): ILoadOneAction<DOC, Types["loadOne"]> => ({
			type: actionTypes.loadOne,
			info,
			loadTime,
		}),
		loadMany: (
			infos: DOC[],
			loadTime: Date = new Date()
		): ILoadManyAction<DOC, Types["loadMany"]> => ({
			type: actionTypes.loadMany,
			infos,
			loadTime,
		}),
		delete: (_id: ObjectId): IDeleteAction<Types["delete"]> => ({
			type: actionTypes.delete,
			_id,
		}),
	};
}

export default createCRUDActions;
