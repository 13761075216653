import { ObjectId } from "@app/utils/generics";
import { useProjectId } from "./projects";
import { useFetch } from "./fetch";
import { inject } from "@app/modules";

export const useTable = (tableId: ObjectId | null) => {
	const projectId = useProjectId();

	return useFetch(
		() =>
			inject("Table").findById({
				projectId: projectId!,
				id: tableId!,
			}),
		[projectId, tableId]
	);
};
