import * as React from "react";
import api from "@app/api";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "../widgets/loading";
import { appendObjectToFormData } from "@app/utils";
import { ColumnType, IRColumn } from "@app/api/columns/helper-schemas";
import { IAPOSTImportData } from "@app/api/resource-data/validators";
import { IColOrderValue } from "../tables/add";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { TableColumOrder } from "../tables/col-order";
import { User } from "@app/user";

interface IProps {
	user: User;
	projectId: ObjectId;
	onSuccess: () => void;
}

interface IState {
	columns?: IRColumn[];
	file?: File;
	loading: boolean;
	columnOrder: Array<{ id: ObjectId }>;
	updateExistingEntries: boolean;
}

export class ImportDatabase extends React.Component<IProps, IState> {
	state: IState = {
		loading: false,
		columnOrder: [],
		updateExistingEntries: false,
	};

	_Column = inject("Column");

	_isMounted = false;

	rand = Math.random();

	componentDidMount() {
		this._isMounted = true;
		this._Column
			.getReadableColumns({ projectId: this.props.projectId })
			.then(data => {
				this.setState({ columns: data });
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	isResourceIdSelected = () => {
		if (!this.state.columns) return false;
		const columnIds = this.state.columnOrder.map(e => e.id);
		for (let i = 0; i < columnIds.length; ++i) {
			const colId = columnIds[i];
			const col = this.state.columns.find(e => e._id === colId);
			if (col && col.info.type === ColumnType.resourceId) {
				return true;
			}
		}
		return false;
	};

	onSave = () => {
		if (!this.state.file) {
			alert("აირჩიეთ ფაილი");
			return;
		}
		if (!this.isResourceIdSelected()) {
			alert(
				"არჩეული სვეტებიდან ერთ-ერთი მაინც უნდა იყოს მომხმარებლის id-ზე მიმთითებელი"
			);
			return;
		}
		const formData = new FormData();
		const args: IAPOSTImportData = {
			columnIds: this.state.columnOrder.map(e => e.id),
			projectId: this.props.projectId,
			updateExistingEntries: this.state.updateExistingEntries,
		};
		formData.append("file", this.state.file);
		appendObjectToFormData(formData, args);
		api.resourceData
			.importFile((formData as any) as typeof args)
			.then(e => {
				this.setState({ loading: false });
				this.props.onSuccess();
			})
			.catch(e => {
				alert("დაფიქსირდა შეცდომა");
				this.setState({ loading: false });
			});
	};

	onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files![0];
		this.setState({ file });
	};

	onReorder = (columnOrder: IColOrderValue[]) => {
		this.setState({ columnOrder });
	};

	onUpdateExistingEntries = (e, checked: boolean) => {
		this.setState({
			updateExistingEntries: checked,
		});
	};

	render() {
		if (!this.state.columns || this.state.loading) {
			return <Loading />;
		}
		return (
			<div className="main main2">
				<input
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					id={`${this.props.projectId}-${this.rand}`}
					multiple={false}
					type="file"
					style={{ display: "none" }}
					onChange={this.onFileChange}
				/>
				აირჩიეთ სვეტების თანმიმდევრობა ზუსტად ისე, როგორც ფაილშია:
				<br />
				<TableColumOrder
					order={this.state.columnOrder}
					resources={this.state.columns}
					allowRenaming={false}
					onChange={this.onReorder}
				/>
				<br />
				<br />
				<label htmlFor={`${this.props.projectId}-${this.rand}`}>
					<Button variant="contained" component={"span" as any}>
						ფაილის მიმაგრება
					</Button>
				</label>
				<br />
				<br />
				<FormControlLabel
					control={
						<Checkbox
							checked={this.state.updateExistingEntries}
							onChange={this.onUpdateExistingEntries}
							value="checkedA"
						/>
					}
					label="გადაეწეროს არსებულ ჩანაწერებსაც"
				/>
				<br />
				<br />
				<Button
					variant="contained"
					color="primary"
					onClick={this.onSave}
				>
					ატვირთვა
				</Button>
			</div>
		);
	}
}
