import Joi from "@app/utils/joi";

export const ResIdSchema = Joi.alternatives(Joi.string(), Joi.number());
export type IResId = string | number;

export const ResIdsSchema = Joi.alternatives(
	Joi.array().items(Joi.string()),
	Joi.array().items(Joi.number())
);
export type IResIds = string[] | number[];

export const ResIdOneOrManySchema = Joi.alternatives(ResIdSchema, ResIdsSchema);
export type IResIdOneOrMany = string | number | number[] | string[];
