import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import Loading from "../widgets/loading";
import { css, cx } from "emotion";
import { History } from "history";
import { inject } from "@app/modules";
import { IRProject } from "@app/api/projects/helper-schemas";
import { User } from "@app/user";

interface IProps {
	history: History;
	user: User;
}

interface IState {
	projects?: IRProject[];
}

export class MyProjects extends React.Component<IProps, IState> {

	state: IState = {

	};

	_Project = inject("Project");

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this._Project.loadMyProjects().then((projects) => {
			this.setState({ projects });
		});
	}

	onAdd = () => {
		this.props.history.push("/project/add");
	}

	onProjectClick = (_id: IRProject["_id"]) => {
		this.props.history.push(`/projects/${_id}`);
	}

	render() {
		if (!this.state.projects) {
			return <Loading />;
		}
		return (
			<div className="main main2">
				{this.state.projects.map(project => (
					<MyProjectCard key={project._id} project={project} onClick={this.onProjectClick} />
				))}
				{this.props.user.canAddProjects && (
					<div className={cx(ProjectContainerCSS, ProjectAddCSS)} onClick={this.onAdd}>
						<span>პროექტის დამატება</span> <AddIcon />
					</div>
				)}
			</div>
		);
	}
}

const MyProjectCard: React.FC<{ project: IRProject; onClick: (_id: IRProject["_id"]) => void }> = (props) => {
	return (
		<div className={ProjectContainerCSS} onClick={() => props.onClick(props.project._id)}>
			<div>{props.project.name}</div>
			<div>{props.project.description}</div>
		</div>
	);
};

const ProjectContainerCSS = css`
	display: inline-block;
	vertical-align: middle;
	margin: 20px;
	width: 300px;
	padding: 15px;
	border-radius: 5px;
	border: 1px solid #ccc;
	cursor: pointer;
	text-align: center;
`;

const ProjectAddCSS = css`
	&>* {
		vertical-align: middle;
	}
`;
