import * as React from "react";
import api from "@app/api";
import Button from "@material-ui/core/Button";
import Loading from "../widgets/loading";
import { css } from "emotion";
import { History } from "history";
import { inject } from "@app/modules";
import { IRResourceGroup } from "@app/api/resource-groups/helper-schemas";
import { match } from "react-router";
import { User } from "@app/user";
import Select from "react-select";
import { getValidIds } from "./tools/helper";
import { ResourceAdditionStrategy } from "@app/api/resource-groups/validators";

interface IProps {
	match: match<{ projectId: string; id: string }>;
	history: History;
	user: User;
}

enum ResourceIdsAction {
	addStraightForward,
	addIfHomeless,
	addSeize,
	remove,
	set,
	setAndRemoveFromOthers,
}

interface IState {
	resourceGroup?: IRResourceGroup;
	idsVal: string;
	numOfValidIds: number;
	action: ResourceIdsAction;
}

export class AddResourcesToResourceGroup extends React.Component<
	IProps,
	IState
> {
	id = this.props.match.params.id;
	projectId = this.props.match.params.projectId;

	state: IState = {
		idsVal: "",
		numOfValidIds: 0,
		action: ResourceIdsAction.addStraightForward,
	};

	_Project = inject("Project");

	componentDidMount() {
		api.resourceGroups
			.getById({ resourceGroupId: this.id, projectId: this.projectId })
			.then(resourceGroup => {
				this.projectId = resourceGroup.projectId;
				if (
					!this.props.user.canWriteResourceGroup(
						resourceGroup._id,
						resourceGroup.projectId
					)
				) {
					this.props.history.push(
						`/projects/${this.projectId}/resource-groups`
					);
					return;
				}
				this.setState({ resourceGroup });
			});
	}

	onSave = () => {
		//
		const validIds = this.getValidIds();
		let promise: Promise<any>;
		if (
			this.state.action === ResourceIdsAction.set ||
			this.state.action === ResourceIdsAction.setAndRemoveFromOthers
		) {
			promise = api.resourceGroups.setUserIds({
				_id: this.id,
				projectId: this.projectId,
				resourceIds: validIds,
				removeFromOtherResourceGroups:
					this.state.action ===
					ResourceIdsAction.setAndRemoveFromOthers,
			});
		} else if (
			this.state.action === ResourceIdsAction.addStraightForward ||
			this.state.action === ResourceIdsAction.addIfHomeless ||
			this.state.action === ResourceIdsAction.addSeize
		) {
			promise = api.resourceGroups.addUserIds({
				_id: this.id,
				projectId: this.projectId,
				resourceIds: validIds,
				strategy:
					this.state.action === ResourceIdsAction.addStraightForward
						? ResourceAdditionStrategy.straightforward
						: this.state.action === ResourceIdsAction.addSeize
						? ResourceAdditionStrategy.seize
						: ResourceAdditionStrategy.addIfHomeless,
			});
		} else if (this.state.action === ResourceIdsAction.remove) {
			promise = api.resourceGroups.removeUserIds({
				_id: this.id,
				projectId: this.projectId,
				resourceIds: validIds,
			});
		} else {
			return;
		}
		promise.then(() => {
			this.props.history.push(
				`/projects/${this.projectId}/resource-groups`
			);
		});
	};

	getValidIds = (): number[] | string[] => {
		const project = this._Project.findByIdSync({ id: this.projectId });
		if (!project) return [];
		const text = this.state.idsVal;
		return getValidIds(text, project.resourceIdType);
	};

	recalculateValidIds = () => {
		this.setState({
			numOfValidIds: this.getValidIds().length,
		});
	};

	onValChange = e => {
		this.setState({ idsVal: e.target.value }, () => {
			this.recalculateValidIds();
		});
	};

	actionsOptions = [
		{ value: ResourceIdsAction.addStraightForward, label: "დამატება" },
		{
			value: ResourceIdsAction.addIfHomeless,
			label:
				"დამატება მხოლოდ ისეთების, რომლებიც სხვასთან არ არიან მიბმული",
		},
		{
			value: ResourceIdsAction.addSeize,
			label: "დამატება. სხვებიდან კი ეს id-ები ამოიშალოს",
		},
		{ value: ResourceIdsAction.remove, label: "ამოშლა" },
		{
			value: ResourceIdsAction.set,
			label: "მხოლოდ ეს id-ები ჰქონდეს, სხვა id-ები წაეშალოს",
		},
		{
			value: ResourceIdsAction.setAndRemoveFromOthers,
			label:
				"მხოლოდ ეს id-ები ჰქონდეს, სხვა id-ები წაეშალოს. სხვა ჯგუფებიდან კი ეს id-ები ამოიშალოს",
		},
	];

	onActionChange = (
		act: { value: ResourceIdsAction; label: string } | undefined | null
	) => {
		if (!act) return;
		this.setState({
			action: act.value,
		});
	};

	render() {
		const project = this._Project.findByIdSync({ id: this.projectId });
		if (!this.state.resourceGroup || !project) {
			return <Loading />;
		}
		const { resourceGroup } = this.state;
		return (
			<div className="main main2">
				<h2>{resourceGroup.name}</h2>
				<textarea
					className={textareaCSS}
					value={this.state.idsVal}
					onChange={this.onValChange}
					placeholder="ჩააკოპირეთ მომხმარებელთა id-ები"
				/>
				<br />
				{this.state.numOfValidIds} ვალიდური id
				<br />
				<br />
				<Select
					options={this.actionsOptions}
					value={this.actionsOptions.find(
						e => e.value === this.state.action
					)}
					placeholder={"ქმედების ტიპი"}
					onChange={this.onActionChange}
				/>
				<br />
				<Button
					variant="contained"
					color="primary"
					onClick={this.onSave}
				>
					შენახვა
				</Button>
			</div>
		);
	}
}

const textareaCSS = css`
	width: 100%;
	padding: 10px;
	border-radius: 10px;
	border: 2px solid #6f9dc7;
	resize: vertical;
	min-height: 300px;
	&:focus {
		border-color: transparent;
		outline: none;
		box-shadow: rgba(111, 157, 199, 0.6) 0 0 10px;
	}
`;
