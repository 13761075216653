import React, { useCallback } from "react";
import {
	Transformation,
	TranformationType,
	SumTransformation,
	PercentageTransformation,
	MultiplicationTransformation,
	SubtractionTransformation,
	FractionTransformation,
	TransformationValue,
} from "@app/api/transformation-schemas";
import { SetState, useSetProps } from "@app/hooks/state-management";
import { useSelectProps } from "@app/hooks/values";
import { RadioNavigation } from "../widgets/radio-navigation";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFuncDistributor } from "@app/hooks/general";
import { css } from "emotion";

type TransformationChooserProps<T extends any> = {
	transformation: Transformation<T>;
	onChange: SetState<Transformation<T>>;
	getDefaultRawValue: () => T;
	RawValueComponent: React.FC<{
		transformation: TransformationValue<T>;
		onChange: SetState<TransformationValue<T>>;
	}>;
};

type Fn = <T extends any>(
	props: TransformationChooserProps<T>
) => JSX.Element | null;

const getDefault = <T extends any>(
	type: TranformationType,
	getDefaultRawValue: () => T
): Transformation<T> => {
	if (type === TranformationType.sum) {
		return {
			type: TranformationType.sum,
			addenda: [
				getDefault(TranformationType.value, getDefaultRawValue),
				getDefault(TranformationType.value, getDefaultRawValue),
			],
		};
	}
	if (type === TranformationType.fraction) {
		return {
			type: TranformationType.fraction,
			dividend: getDefault(TranformationType.value, getDefaultRawValue),
			divisor: getDefault(TranformationType.value, getDefaultRawValue),
		};
	}
	if (type === TranformationType.multiplication) {
		return {
			type: TranformationType.multiplication,
			factors: [
				getDefault(TranformationType.value, getDefaultRawValue),
				getDefault(TranformationType.value, getDefaultRawValue),
			],
		};
	}
	if (type === TranformationType.subtraction) {
		return {
			type: TranformationType.subtraction,
			minuend: getDefault(TranformationType.value, getDefaultRawValue),
			subtrahend: getDefault(TranformationType.value, getDefaultRawValue),
		};
	}
	if (type === TranformationType.percentage) {
		return {
			type: TranformationType.percentage,
			value: getDefault(TranformationType.value, getDefaultRawValue),
		};
	}
	if (type === TranformationType.value) {
		return {
			type: TranformationType.value,
			val: getDefaultRawValue(),
		};
	}
	throw new Error(`type ${type} not supported`);
};

const changeType = <T extends any>(
	transformation: Transformation<T>,
	newType: TranformationType,
	getDefaultRawValue: () => T
): Transformation<T> => {
	return getDefault(newType, getDefaultRawValue);
};

export const TransformationChooser: Fn = React.memo(
	({
		onChange,
		transformation,
		getDefaultRawValue,
		RawValueComponent,
	}: TransformationChooserProps<unknown>) => {
		const handleTypeChange = useCallback(
			(newType: TranformationType) => {
				onChange(x => changeType(x, newType, getDefaultRawValue));
			},
			[onChange, getDefaultRawValue]
		);
		const selectProps = useSelectProps(
			transformation.type,
			handleTypeChange,
			[
				[TranformationType.value, "მნიშვნელობა"],
				[TranformationType.sum, "ჯამი"],
				[TranformationType.multiplication, "ნამრავლი"],
				[TranformationType.subtraction, "სხვაობა"],
				[TranformationType.fraction, "შეფარდება"],
				[TranformationType.percentage, "პროცენტი"],
			]
		);
		const commonProps = {
			onChange,
			transformation,
			getDefaultRawValue,
			RawValueComponent,
		};
		return (
			<div>
				<RadioNavigation {...selectProps} direction="left" />
				<div className={container}>
					{transformation.type === TranformationType.sum && (
						<SumTransformationComp {...commonProps} />
					)}
					{transformation.type ===
						TranformationType.multiplication && (
						<MultiplicationTransformationComp {...commonProps} />
					)}
					{transformation.type === TranformationType.percentage && (
						<PercentageTransformationComp {...commonProps} />
					)}
					{transformation.type === TranformationType.subtraction && (
						<SubtractionTransformationComp {...commonProps} />
					)}
					{transformation.type === TranformationType.fraction && (
						<FractionTransformationComp {...commonProps} />
					)}
					{transformation.type === TranformationType.value && (
						<RawValueComponent
							{...commonProps}
							transformation={transformation}
							onChange={
								onChange as SetState<TransformationValue<any>>
							}
						/>
					)}
				</div>
			</div>
		);
	}
);

export const SumTransformationComp: Fn = React.memo(
	({ onChange, transformation, ...rest }) => {
		const trans = transformation as SumTransformation<unknown>;
		const setPropsFn = useSetProps(
			onChange as SetState<SumTransformation<unknown>>
		);
		const handleAddendaChange = setPropsFn("addenda");
		const addendaChangeFns = useSetProps(handleAddendaChange, {
			add: (state: Transformation<unknown>[]) => {
				return [
					...state,
					getDefault(
						TranformationType.value,
						rest.getDefaultRawValue
					),
				];
			},
			delete: (state: Transformation<unknown>[], index: number) => {
				return state.filter((_, i) => i !== index);
			},
		});
		const onDeleteFns = useFuncDistributor(
			addendaChangeFns("delete"),
			trans.addenda.length
		);
		return (
			<div>
				{trans.addenda.map((each, i) => (
					<div key={i} className={flexWithCloseIcon}>
						<TransformationChooser
							transformation={each}
							onChange={addendaChangeFns(i)}
							{...rest}
						/>
						<CloseIcon onClick={onDeleteFns[i]} />
					</div>
				))}
				<Button onClick={addendaChangeFns("add")}>
					შესაკრების დამატება
				</Button>
			</div>
		);
	}
);

export const MultiplicationTransformationComp: Fn = React.memo(
	({ onChange, transformation, ...rest }) => {
		const trans = transformation as MultiplicationTransformation<unknown>;
		const setPropsFn = useSetProps(
			onChange as SetState<MultiplicationTransformation<unknown>>
		);
		const handleFactorsChange = setPropsFn("factors");
		const factorsChangeFns = useSetProps(handleFactorsChange, {
			add: (state: Transformation<unknown>[]) => {
				return [
					...state,
					getDefault(
						TranformationType.value,
						rest.getDefaultRawValue
					),
				];
			},
			delete: (state: Transformation<unknown>[], index: number) => {
				return state.filter((_, i) => i !== index);
			},
		});
		const onDeleteFns = useFuncDistributor(
			factorsChangeFns("delete"),
			trans.factors.length
		);
		return (
			<div>
				{trans.factors.map((each, i) => (
					<div key={i} className={flexWithCloseIcon}>
						<TransformationChooser
							transformation={each}
							onChange={factorsChangeFns(i)}
							{...rest}
						/>
						<CloseIcon onClick={onDeleteFns[i]} />
					</div>
				))}
				<Button onClick={factorsChangeFns("add")}>
					მამრავლის დამატება
				</Button>
			</div>
		);
	}
);

export const PercentageTransformationComp: Fn = React.memo(
	({ onChange, transformation, ...rest }) => {
		const trans = transformation as PercentageTransformation<unknown>;
		const setPropsFn = useSetProps(
			onChange as SetState<PercentageTransformation<unknown>>
		);
		return (
			<div>
				<TransformationChooser
					transformation={trans.value}
					onChange={setPropsFn("value")}
					{...rest}
				/>
			</div>
		);
	}
);

export const SubtractionTransformationComp: Fn = React.memo(
	({ onChange, transformation, ...rest }) => {
		const trans = transformation as SubtractionTransformation<unknown>;
		const setPropsFn = useSetProps(
			onChange as SetState<SubtractionTransformation<unknown>>
		);
		return (
			<div>
				საკლები:
				<TransformationChooser
					transformation={trans.minuend}
					onChange={setPropsFn("minuend")}
					{...rest}
				/>
				მაკლები:
				<TransformationChooser
					transformation={trans.subtrahend}
					onChange={setPropsFn("subtrahend")}
					{...rest}
				/>
			</div>
		);
	}
);

export const FractionTransformationComp: Fn = React.memo(
	({ onChange, transformation, ...rest }) => {
		const trans = transformation as FractionTransformation<unknown>;
		const setPropsFn = useSetProps(
			onChange as SetState<FractionTransformation<unknown>>
		);
		return (
			<div>
				გასაყოფი:
				<TransformationChooser
					transformation={trans.dividend}
					onChange={setPropsFn("dividend")}
					{...rest}
				/>
				გამყოფი:
				<TransformationChooser
					transformation={trans.divisor}
					onChange={setPropsFn("divisor")}
					{...rest}
				/>
			</div>
		);
	}
);

const flexWithCloseIcon = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const container = css`
	width: 100%;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid #ccc;
`;
