import React, { useCallback, useMemo, useState } from "react";
import {
	IRequestPropertyData,
	AdditionalProps,
} from "@app/api/api-calls/helper-schemas";
import { useDynamicRef, useValueChange, useFuncCall } from "@app/hooks/general";
import { removeKeys } from "@app/utils/common";
import { InputWithLabel, CheckboxWithLabel } from "../widgets/input";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import { ColumnType } from "@app/api/columns/helper-schemas";
import { ColumnInfoType } from "../columns/add";
import { ObjectId } from "@app/utils/generics";
import { getEditComponent } from "../tables/utils";

export const RequestPropertiesEdit: React.FC<{
	data: Record<string, IRequestPropertyData>;
	onChange: (info: Record<string, IRequestPropertyData>) => void;
	projectId: ObjectId;
}> = React.memo(({ data, onChange, projectId }) => {
	const dataRef = useDynamicRef(data);
	const keys = Object.keys(data);
	const handleChange = useCallback(
		(data: IRequestPropertyData, oldKey: string, newKey?: string) => {
			if (newKey === undefined || newKey === oldKey) {
				onChange({ ...dataRef.current, [oldKey]: data });
			} else {
				onChange({
					...removeKeys(dataRef.current, oldKey),
					[newKey]: data,
				});
			}
		},
		[dataRef, onChange]
	);

	const handleAddingKey = useCallback(() => {
		onChange({ ...dataRef.current, "": getDefaultData() });
	}, [dataRef, onChange]);

	const handleDeletingKey = useCallback(
		(key: string) => {
			onChange(removeKeys(dataRef.current, key));
		},
		[dataRef, onChange]
	);

	return (
		<div>
			{keys.map((key, index) => (
				<RequestDataEdit
					key={index}
					keyName={key}
					data={data[key]!}
					onChange={handleChange}
					onDelete={handleDeletingKey}
					projectId={projectId}
				/>
			))}
			{keys.indexOf("") === -1 && (
				<Button onClick={handleAddingKey}>add key</Button>
			)}
		</div>
	);
});

const getDefaultData = (): IRequestPropertyData => {
	return {
		type: ColumnType.number,
		canBeChanged: true,
	};
};

const defaultAdditionalProps: AdditionalProps = {
	canBeChanged: true,
};

const RequestDataEdit: React.FC<{
	keyName: string;
	data: IRequestPropertyData;
	onChange: (
		data: IRequestPropertyData,
		oldKey: string,
		newKey?: string
	) => void;
	onDelete?: (key: string) => void;
	projectId: ObjectId;
}> = React.memo(({ keyName, data, onChange, onDelete, projectId }) => {
	const dataRef = useDynamicRef(data);
	const keyRef = useDynamicRef(keyName);

	const handleKeyChange = useCallback(
		(newKey: string) => {
			onChange(dataRef.current, keyRef.current, newKey);
		},
		[dataRef, keyRef, onChange]
	);

	const handleDataChange = useCallback(
		(data: IRequestPropertyData) => {
			onChange(data, keyRef.current);
		},
		[keyRef, onChange]
	);

	const handleDelete = useCallback(() => {
		if (!onDelete) return;
		onDelete(keyRef.current);
	}, [keyRef, onDelete]);

	return (
		<div
			style={{
				display: "flex",
				border: "1px solid #ccc",
				padding: 10,
				borderRadius: 5,
				margin: "10px 0",
			}}
		>
			<div style={{ flex: 1 }}>
				<InputWithLabel
					label="key"
					value={keyName}
					onChange={handleKeyChange}
				/>
				<ColumnInfoType
					info={data}
					allow="all"
					onChange={handleDataChange}
					projectId={projectId}
					hasNestedProps={true}
					additionalPropsComponent={AdditionalPropsComponent}
					defaultAdditionalProps={defaultAdditionalProps}
				/>
			</div>
			{onDelete && (
				<div style={{ width: 30 }}>
					<CancelIcon onClick={handleDelete} />
				</div>
			)}
		</div>
	);
});

const AdditionalPropsComponent: React.FC<{
	info: IRequestPropertyData;
	onChange: (info: IRequestPropertyData) => void;
	projectId: ObjectId;
}> = ({ info, onChange, projectId }) => {
	const infoRef = useDynamicRef(info);
	const handleValueChange = useValueChange(onChange, infoRef);
	const handleCanBeChangesChange = useFuncCall(
		handleValueChange,
		"canBeChanged" as const
	);
	const handleIsHiddenChange = useFuncCall(
		handleValueChange,
		"isHidden" as const
	);
	const handleIsSecretChange = useFuncCall(
		handleValueChange,
		"isSecret" as const
	);
	const handleCustomNameChange = useFuncCall(
		handleValueChange,
		"customName" as const
	);
	const handleDefaultValueChange = useFuncCall(
		handleValueChange,
		"defaultValue" as const
	);

	const [defaultInputKey, setDefaultInputKey] = useState(1);

	const component = useMemo(
		() =>
			getEditComponent(
				projectId,
				info,
				info.defaultValue,
				{
					onChange: handleDefaultValueChange,
					alwaysCallParentOnChange: true,
				},
				false,
				defaultInputKey
			),
		[defaultInputKey, handleDefaultValueChange, info, projectId]
	);

	return (
		<div>
			<div style={{ display: "flex" }}>
				{component}
				{info.type !== ColumnType.resourceId &&
					info.type !== ColumnType.list &&
					info.type !== ColumnType.object && (
						<div>
							<CancelIcon
								onClick={() => {
									handleDefaultValueChange(undefined);
									setDefaultInputKey(x => x + 1);
								}}
							/>
						</div>
					)}
			</div>
			<InputWithLabel
				label="custom სახელი"
				value={info.customName}
				onChange={handleCustomNameChange}
			/>
			<CheckboxWithLabel
				value={!!info.canBeChanged}
				onChange={handleCanBeChangesChange}
				label="შეიძლებოდეს მნიშვნელობის შეცვლა"
			/>
			<CheckboxWithLabel
				value={!!info.isHidden}
				onChange={handleIsHiddenChange}
				label="დამალული იყოს"
			/>
			<CheckboxWithLabel
				value={!!info.isSecret}
				onChange={handleIsSecretChange}
				label="საიდუმლო იყოს"
			/>
		</div>
	);
};

/* const getAdditionalPropsComponent = (
	onChange: (info: IRequestPropertyData) => void
) => {
	return (props: { info: IRequestPropertyData }) => (
		<AdditionalPropsComponent {...props} onChange={onChange} />
	);
};
 */
