import { Requests } from "..";

export interface IRegisterUser {
	name: string;
	lastname: string;
	email: string;
	password: string;
}
const users = {
	getChatToken: (): Promise<string> =>
		Requests.send("GET", "/api/users/chat-token"),
	registerUser: (args: IRegisterUser): Promise<any> =>
		Requests.send("PUT", "/api/users/register", args),
	getAll: (): Promise<any> => Requests.send("GET", "/api/users/"),
};
export default users;
