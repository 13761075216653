import { ResourceIdType } from "@app/api/projects/helper-schemas";

export const getValidIds = (text: string, resourceIdType: ResourceIdType) => {
	const ids = text.split(/\s/);
	const validIds: number[] | string[] = [];
	for (let i = 0; i < ids.length; ++i) {
		let id = ids[i];
		if (id.length === 0) continue;
		if (resourceIdType === ResourceIdType.number) {
			const numId = parseInt(id, 10);
			if (numId + "" !== id) continue;
			id = numId as any;
		}
		(validIds as string[]).push(id as any);
	}
	return validIds;
};
