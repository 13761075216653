import {
	ICRUDActions,
	ICRUDActionTypes,
	IDeleteAction,
	IDocument,
	ILoadManyAction,
	ILoadOneAction,
	IUpdateAction
	} from "@app/actions/crud";
import { IRootActions } from "./root-state";

function createCRUDReducer<Doc extends IDocument>(actionTypes: ICRUDActionTypes) {
	return function getNewCourseState(state = {}, origianalAction: ICRUDActions<Doc> | IRootActions) {
		if (origianalAction.type === actionTypes.update) {
			const action = origianalAction as IUpdateAction<Doc, any>;
			return {
				...state,
				[action.info._id]: {
					...state[action.info._id]!,
					info: {
						...(state[action.info._id] || {}).info,
						...action.info
					}
				}
			};
		}
		if (origianalAction.type === actionTypes.loadOne) {
			const action = origianalAction as ILoadOneAction<Doc, any>;
			return {
				...state,
				[action.info._id]: {
					info: action.info,
					loadTime: action.loadTime
				}
			};
		}
		if (origianalAction.type === actionTypes.loadMany) {
			const action = origianalAction as ILoadManyAction<Doc, any>;
			const newState1 = {
				...state,
			};
			action.infos.forEach((c) => {
				newState1[c._id] = { info: c, loadTime: action.loadTime };
			});
			return newState1;
		}
		if (origianalAction.type === actionTypes.delete) {
			const action = origianalAction as IDeleteAction<any>;
			const newState = { ...state };
			delete newState[action._id];
			return newState;
		}
		return state;
	};
}

export default createCRUDReducer;
