import {
	IADELETEColumn,
	IAGETColumn,
	IAGETManyColumns,
	IAPOSTCreateColumn,
	IAPUTColumn,
	IRGETColumn,
	IRGETManyColumns,
	IRPOSTCreateColumn,
	RGETColumnSchema,
	RGETManyColumnsSchema,
	RPOSTCreateColumnSchema,
} from "./validators";
import { Requests } from "..";

// tslint:disable:no-duplicate-string
const columns = {
	add: (data: IAPOSTCreateColumn): Promise<IRPOSTCreateColumn> =>
		Requests.send("POST", "/api/projects/:projectId/columns/", data, null, {
			responseSchema: RPOSTCreateColumnSchema,
		}),
	update: (data: IAPUTColumn): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/columns/:_id",
			data,
			null
		),
	getById: (data: IAGETColumn): Promise<IRGETColumn> =>
		Requests.send(
			"GET",
			"/api/projects/:projectId/columns/:columnId",
			data,
			null,
			{
				responseSchema: RGETColumnSchema,
			}
		),
	deleteById: (data: IADELETEColumn): Promise<void> =>
		Requests.send(
			"DELETE",
			"/api/projects/:projectId/columns/:columnId",
			data
		),
	getReadableColumns: (data: IAGETManyColumns): Promise<IRGETManyColumns> =>
		Requests.send("GET", "/api/projects/:projectId/columns", data, null, {
			responseSchema: RGETManyColumnsSchema,
		}),
};
export default columns;
