import api from "@app/api";
import createCRUDActions from "./crud";
import {
	DELETE_PROJECT,
	LOAD_PROJECT_BY_ID,
	LOAD_PROJECTS,
	UPDATE_PROJECT,
} from "@app/action-types";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IRProject } from "@app/api/projects/helper-schemas";
import { ThunkAction } from "redux-thunk";
import {
	IAGETProject,
	IAPOSTCreateProject,
	IAPUTProject,
	IRGETManyProjects,
	IRGETProject,
	IRPOSTCreateProject,
	IADELETEProject,
} from "@app/api/projects/validators";

export const addProject = (
	args: IAPOSTCreateProject
): ThunkAction<
	Promise<IRPOSTCreateProject>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.projects.add(args).then(data => {
		dispatch(crudActions.loadOne(data.project));
		return data;
	});
};

export const updateProject = (
	args: IAPUTProject
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.projects.update(args).then(() => {
		dispatch(crudActions.update(args));
	});
};

export const loadProjects = (): ThunkAction<
	Promise<IRGETManyProjects>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.projects.getMyProjects().then(data => {
		dispatch(crudActions.loadMany(data));
		return data;
	});
};

export const loadProjectById = (
	args: IAGETProject
): ThunkAction<
	Promise<IRGETProject>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.projects.getById(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const deleteProject = (
	args: IADELETEProject
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.projects.deleteById(args).then(() => {
		dispatch(crudActions.delete(args.id));
	});
};

export type ProjectActions =
	| ReturnType<typeof crudActions["delete"]>
	| ReturnType<typeof crudActions["loadMany"]>
	| ReturnType<typeof crudActions["loadOne"]>
	| ReturnType<typeof crudActions["update"]>;

export const projectCRUDActionTypes = {
	update: UPDATE_PROJECT as typeof UPDATE_PROJECT,
	loadOne: LOAD_PROJECT_BY_ID as typeof LOAD_PROJECT_BY_ID,
	loadMany: LOAD_PROJECTS as typeof LOAD_PROJECTS,
	delete: DELETE_PROJECT as typeof DELETE_PROJECT,
};

const crudActions = createCRUDActions<IRProject, typeof projectCRUDActionTypes>(
	projectCRUDActionTypes
);
export { crudActions as projectCRUDActions };
