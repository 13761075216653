import React, { useMemo, useCallback } from "react";
import { IAPICallRequest, HTTPMethod } from "@app/api/api-calls/helper-schemas";
import { useDynamicRef, useValueChange, useFuncCall } from "@app/hooks/general";
import { InputWithLabel, SwitchWithLabel } from "../widgets/input";
import { RadioNavigation } from "../widgets/radio-navigation";
import { removeKeys } from "@app/utils/common";
import { RequestPropertiesEdit } from "./request-properties";
import { ObjectId } from "@app/utils/generics";

export const CallRequestEdit: React.FC<{
	info: IAPICallRequest;
	onChange: (info: IAPICallRequest) => void;
	projectId: ObjectId;
}> = React.memo(({ info, onChange, projectId }) => {
	const infoRef = useDynamicRef(info);
	const handleValueChange = useValueChange(onChange, infoRef);
	const handleUrlChange = useFuncCall(handleValueChange, "url" as const);
	const handleMethodChange = useFuncCall(
		handleValueChange,
		"method" as const
	);
	const handleHeadersChange = useFuncCall(
		handleValueChange,
		"headers" as const
	);
	const handleBodyChange = useFuncCall(handleValueChange, "body" as const);
	const handleQueryChange = useFuncCall(handleValueChange, "query" as const);
	const handleParamsChange = useFuncCall(
		handleValueChange,
		"params" as const
	);
	const handleSwitch = useCallback(
		(
			key: Extract<
				keyof IAPICallRequest,
				"headers" | "body" | "query" | "params"
			>
		) => (checked: boolean) => {
			if (!checked) {
				onChange(removeKeys(infoRef.current, key));
			} else {
				onChange({ ...infoRef.current, [key]: {} });
			}
		},
		[infoRef, onChange]
	);
	const handleHeadersSwitch = useFuncCall(handleSwitch, "headers" as const);
	const handleParamsSwitch = useFuncCall(handleSwitch, "params" as const);
	const handleQuerySwitch = useFuncCall(handleSwitch, "query" as const);
	const handleBodySwitch = useFuncCall(handleSwitch, "body" as const);

	return (
		<div>
			<InputWithLabel
				label="url"
				value={info.url}
				onChange={handleUrlChange}
				style={fullWidthStyle}
			/>
			<MethodSwitcher
				method={info.method}
				onChange={handleMethodChange}
			/>
			<div>
				<SwitchWithLabel
					value={!!info.headers}
					label="headers"
					onChange={handleHeadersSwitch}
				/>
				{info.headers && (
					<RequestPropertiesEdit
						data={info.headers}
						onChange={handleHeadersChange}
						projectId={projectId}
					/>
				)}
			</div>
			<hr />
			<div>
				<SwitchWithLabel
					value={!!info.params}
					label="params"
					onChange={handleParamsSwitch}
				/>
				{info.params && (
					<RequestPropertiesEdit
						data={info.params}
						onChange={handleParamsChange}
						projectId={projectId}
					/>
				)}
			</div>
			<hr />
			<div>
				<SwitchWithLabel
					value={!!info.query}
					label="query"
					onChange={handleQuerySwitch}
				/>
				{info.query && (
					<RequestPropertiesEdit
						data={info.query}
						onChange={handleQueryChange}
						projectId={projectId}
					/>
				)}
			</div>
			<hr />
			<div>
				<SwitchWithLabel
					value={!!info.body}
					label="body"
					onChange={handleBodySwitch}
				/>
				{info.body && (
					<RequestPropertiesEdit
						data={info.body}
						onChange={handleBodyChange}
						projectId={projectId}
					/>
				)}
			</div>
		</div>
	);
});

const MethodSwitcher: React.FC<{
	method: HTTPMethod;
	onChange: (newMethod: HTTPMethod) => void;
}> = React.memo(({ method, onChange }) => {
	const options = useMemo(
		(): { value: HTTPMethod; label: string }[] => [
			{ value: HTTPMethod.GET, label: "GET" },
			{ value: HTTPMethod.PUT, label: "PUT" },
			{ value: HTTPMethod.POST, label: "POST" },
			{ value: HTTPMethod.DELETE, label: "DELETE" },
		],
		[]
	);
	const selectedGetTypeOption = useMemo(
		() => options.find(e => e.value === method),
		[method, options]
	);
	return (
		<div>
			<RadioNavigation
				prefix="httpMethod:"
				options={options}
				value={selectedGetTypeOption}
				onChange={sel => onChange(sel.value)}
			/>
		</div>
	);
});
const fullWidthStyle: React.CSSProperties = { width: "100%" };
