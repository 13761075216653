import createCRUDReducer from "./crud";
import { ColumnActions, columnCRUDActionTypes } from "@app/actions/columns";
import { IRColumn } from "@app/api/columns/helper-schemas";
import { IReduxStateInstance } from "./root-state";

function getNewColumnState(
	state: IStateColumns = {},
	action: ColumnActions
): IStateColumns {
	switch (action.type) {
		default:
			return state;
	}
}

const crudReducer = createCRUDReducer<IRColumn>(columnCRUDActionTypes);

export default function columns(
	state = {},
	action: ColumnActions
): IStateColumns {
	const state1 = crudReducer(state, action);
	const newState = getNewColumnState(state1, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("columns", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export type IStateColumns = IReduxStateInstance<IRColumn>;
