import * as React from "react";
import api from "@app/api";
import Button from "@material-ui/core/Button";
import Loading from "../widgets/loading";
import { addLoader } from "@app/commonJavascript";
import { connect } from "react-redux";
import { History } from "history";
import { inject } from "@app/modules";
import { IRootState } from "@app/reducers/root-state";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";
import { TableWindow } from "../tables/window";
import { User } from "@app/user";

interface IOwnProps {
	history: History;
	match: match<{ projectId: string }>;
	user: User;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IOwnProps;

class ProjectPage extends React.PureComponent<IProps> {
	id = this.props.match.params.projectId;

	_Project = inject("Project");
	_Table = inject("Table");

	componentDidMount() {
		this._Table.getReadableTables({ projectId: this.id }).then();
	}

	onDataReload = () => {
		const removeLoader = addLoader();
		api.resourceData
			.reload({
				type: "whole_project",
				projectId: this.id,
			})
			.then(() => {
				removeLoader();
			})
			.catch(e => {
				console.log(e);
				removeLoader();
				alert("დაფიქსირდა შეცდომა");
			});
	};

	onTableSelect = (tableId: ObjectId) => {
		this.props.history.push(`/projects/${this.id}/tables/${tableId}`);
	};

	onTableAdd = () => {
		this.props.history.push(`/projects/${this.id}/table/add`);
	};

	render() {
		const { user } = this.props;
		const project = this._Project.findByIdSync(
			{ id: this.id },
			this.props.projects
		);
		const tables = this._Table.getReadableTablesSync(
			{ projectId: this.id },
			user
		);
		if (!project || !tables) return <Loading />;
		return (
			<div className="main main2">
				<h3>{project.name}</h3>
				{user.canWriteProject(this.id) && (
					<Button
						variant="contained"
						color="primary"
						onClick={this.onDataReload}
					>
						მონაცემების გაახლება
					</Button>
				)}
				<br />
				<br />
				{tables.map(table => (
					<TableWindow
						key={table._id}
						table={table}
						onClick={this.onTableSelect}
					/>
				))}
				{tables.length === 0 && (
					<div>
						<b>
							პროექტის ახლად შექმნისას გთხოვთ, ერთხელ დაარეფრეშოთ
							გვერდი
						</b>
					</div>
				)}
				<br />
				{this.props.user.canWriteTable(null, this.id) && (
					<Button
						variant="contained"
						color="primary"
						onClick={this.onTableAdd}
					>
						ცხრილის დამატება
					</Button>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	projects: state.projects,
	tables: state.tables,
});

const connected = connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	ProjectPage
);

export { connected as ProjectPage };
