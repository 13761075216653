import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ObjectId } from "@app/utils/generics";

export function useForceUpdate() {
	const [, set] = useState(0);
	const forceUpdate = useCallback(() => {
		set(v => v + 1);
	}, []);
	return forceUpdate;
}

export function useBoolean(defaultValue?: boolean) {
	const [value, setValue] = useState(defaultValue || false);
	const setTrue = useCallback(() => {
		setValue(true);
	}, []);
	const setFalse = useCallback(() => {
		setValue(false);
	}, []);
	const switchValue = useCallback(() => {
		setValue(c => !c);
	}, []);
	return { value, setValue, setTrue, setFalse, switchValue } as const;
}

export const useDynamicRef = <T extends any>(
	value: T
): { readonly current: T } => {
	const ref = useRef(value);
	ref.current = value;
	return ref;
};

export interface IMountingInfo {
	isMounted: boolean;
	isFirstMounting: boolean;
	hasFinishedFirstMountingCycle: boolean;
}
export const useMountingInfo = (): IMountingInfo => {
	const isMounted = useState({
		isMounted: true,
		isFirstMounting: true,
		hasFinishedFirstMountingCycle: false,
	})[0];
	useEffect(() => {
		if (!isMounted.isFirstMounting) {
			isMounted.hasFinishedFirstMountingCycle = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMounted.isFirstMounting]);
	useEffect(() => {
		isMounted.isMounted = true;
		isMounted.isFirstMounting = false;
		setTimeout(() => {
			isMounted.hasFinishedFirstMountingCycle = true;
		}, 0);
		return () => {
			isMounted.isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return isMounted;
};

export const useValueChange = <T extends {}>(
	onChange: (newVal: T) => void,
	ref: React.MutableRefObject<T>
): (<K extends keyof T>(key: K) => (value: T[K]) => void) => {
	return useCallback(
		<K extends keyof T>(key: K) => (value: T[K]) => {
			onChange({
				...ref.current,
				[key]: value,
			});
		},
		[onChange, ref]
	);
};

export const useFuncCall = <
	F extends (...args: A) => any,
	A extends readonly any[]
>(
	func: F,
	...args: A
): F extends (...args: A) => infer R ? R : never => {
	return useMemo(() => func(...args), [args, func]);
};

export const useGotoPage = (url: string) => {
	const history = useHistory();
	const urlRef = useRef(url);
	urlRef.current = url;
	return useCallback(() => {
		history.push(urlRef.current);
	}, [history]);
};

export const useGotoProjectPage = (url: string) => {
	const history = useHistory();
	const match = useRouteMatch<{ projectId: ObjectId }>();
	const urlRef = useRef(url);
	urlRef.current = url;
	const projectId = match.params.projectId;
	return useCallback(() => {
		history.push(`/projects/${projectId}/${urlRef.current}`);
	}, [history, projectId]);
};

export const useFuncDistributor = <Arg2 extends any[], R>(
	fn: (index: number, ...arg2: Arg2) => R,
	num: number
): ((...arg: Arg2) => R)[] => {
	const fnRef = useRef(fn);
	fnRef.current = fn;
	return useMemo(() => {
		return new Array(num).fill(null).map((e, i) => {
			return (...arg2: Arg2) => {
				return fnRef.current(i, ...arg2);
			};
		});
	}, [num]);
};

export const useEmptyArray = (length: number) => {
	return useMemo(() => {
		return new Array(length).fill(null);
	}, [length]);
};

export const useFuncChain = <Fn extends (...args: any) => any>(
	fn_0: Fn,
	...restFunction: CallableFunction[]
): Fn => {
	const fnRefs = useRef<CallableFunction[]>([]);
	fnRefs.current = [fn_0, ...restFunction];
	const finalFnRef = useRef<any>();
	if (!finalFnRef.current) {
		finalFnRef.current = (...args: any[]) => {
			const fns = fnRefs.current;
			const result = fns[0](...args);
			for (let i = 1; i < fns.length; ++i) {
				fns[i]();
			}
			return result;
		};
	}
	return finalFnRef.current;
};
