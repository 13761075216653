import Joi from "@app/utils/joi";
import { IRAdminRole } from "@app/api/admin-roles/helper-schemas";
import { IRUser, RUserSchema } from "@app/api/users/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import {
	CAN_DELETE_APICALLS,
	CAN_DELETE_COLUMNS,
	CAN_DELETE_PROJECT,
	CAN_DELETE_TABLES,
	CAN_MANAGE_ADMINS_IN_PROJECT,
	CAN_MANAGE_USERS_IN_PROJECT,
	CAN_READ_APICALLS,
	CAN_READ_COLUMNS,
	CAN_READ_PROJECT,
	CAN_READ_TABLES,
	CAN_WRITE_APICALLS,
	CAN_WRITE_COLUMNS,
	CAN_WRITE_PROJECT,
	CAN_WRITE_TABLES,
	CAN_READ_RESOURCE_GROUPS,
	CAN_WRITE_RESOURCE_GROUPS,
	CAN_DELETE_RESOURCE_GROUPS,
	CAN_READ_FUNCTIONS,
	CAN_WRITE_FUNCTIONS,
	CAN_DELETE_FUNCTIONS,
} from "@app/api/admin-roles/consts";

export class User implements IRUser {
	private static validateUserObject(userObject: IRUser) {
		return RUserSchema.keys({
			iat: Joi.number()
				.integer()
				.optional(),
			exp: Joi.number()
				.integer()
				.optional(),
		}).validate(userObject, {
			stripUnknown: true,
		});
	}

	id: number;
	murtskuId: number;
	mobile: string | null;
	mail: string | null;
	username?: string;
	firstname?: string | null;
	lastname?: string | null;
	canAddProjects: boolean;
	projectRoles: IRUser["projectRoles"];

	constructor(user: IRUser) {
		const validationResult = User.validateUserObject(user);
		if (validationResult.error) {
			throw validationResult.error;
		}
		user = validationResult.value!;

		this.id = user.id;
		this.murtskuId = user.murtskuId;
		this.mobile = user.mobile;
		this.mail = user.mail || null;
		this.username = user.username;
		this.firstname = user.firstname;
		this.lastname = user.lastname;
		this.canAddProjects = user.canAddProjects;
		this.projectRoles = user.projectRoles;
	}

	getMyprojectIds(): ObjectId[] {
		if (!this.projectRoles) return [];
		return Object.keys(this.projectRoles);
	}

	addProjectRole(projectId: ObjectId, role: IRAdminRole) {
		if (!this.projectRoles) this.projectRoles = {};
		this.projectRoles![projectId] = role;
	}

	getProjectRole(projectId: ObjectId): IRAdminRole | null {
		if (!this.projectRoles) return null;
		if (!this.projectRoles[projectId]) return null;
		if (this.projectRoles[projectId]!.projectId !== projectId) {
			// tslint:disable-next-line:no-console
			console.log(
				`user with id ${this.id} has incorrect projectRoles for id ${projectId}. key id and projectId are not equal`
			);
			return null;
		}
		return this.projectRoles[projectId]!;
	}

	hasAccessToProject(projectId: ObjectId) {
		return !!this.getProjectRole(projectId);
	}

	canReadProject(projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const role = this.getProjectRole(projectId);
		if (!role) return false;
		return (role.projectPermission & CAN_READ_PROJECT) === CAN_READ_PROJECT;
	}
	canWriteProject(projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const role = this.getProjectRole(projectId);
		if (!role) return false;
		return (
			(role.projectPermission & CAN_WRITE_PROJECT) === CAN_WRITE_PROJECT
		);
	}
	canDeleteProject(projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const role = this.getProjectRole(projectId);
		if (!role) return false;
		return (
			(role.projectPermission & CAN_DELETE_PROJECT) === CAN_DELETE_PROJECT
		);
	}
	canManageAdminsInProject(projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const role = this.getProjectRole(projectId);
		if (!role) return false;
		return (
			(role.projectPermission & CAN_MANAGE_ADMINS_IN_PROJECT) ===
			CAN_MANAGE_ADMINS_IN_PROJECT
		);
	}
	canManageUsersInProject(projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const role = this.getProjectRole(projectId);
		if (!role) return false;
		return (
			(role.projectPermission & CAN_MANAGE_USERS_IN_PROJECT) ===
			CAN_MANAGE_USERS_IN_PROJECT
		);
	}

	hasFullAccessOnProject(projectId: ObjectId): boolean {
		const role = this.getProjectRole(projectId);
		if (!role) return false;
		return role.projectPermission === -1;
	}

	getAPICallPermission(apiId: ObjectId | null, projectId: ObjectId): number {
		const role = this.getProjectRole(projectId);
		if (!role) return 0;
		if (!role.apiCallsPermissions) return 0;
		if (
			apiId &&
			role.apiCallsPermissions.individuals &&
			role.apiCallsPermissions.individuals[apiId] !== undefined
		) {
			return role.apiCallsPermissions.individuals[apiId] || 0;
		}
		return role.apiCallsPermissions.general || 0;
	}

	canReadAPICall(apiId: ObjectId, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getAPICallPermission(apiId, projectId);
		return (permission & CAN_READ_APICALLS) === CAN_READ_APICALLS;
	}
	canWriteAPICall(apiId: ObjectId | null, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getAPICallPermission(apiId, projectId);
		return (permission & CAN_WRITE_APICALLS) === CAN_WRITE_APICALLS;
	}
	canDeleteAPICall(apiId: ObjectId, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getAPICallPermission(apiId, projectId);
		return (permission & CAN_DELETE_APICALLS) === CAN_DELETE_APICALLS;
	}

	getTablePermission(tableId: ObjectId | null, projectId: ObjectId): number {
		const role = this.getProjectRole(projectId);
		if (!role) return 0;
		if (!role.tablePermissions) return 0;
		if (
			tableId &&
			role.tablePermissions.individuals &&
			role.tablePermissions.individuals[tableId] !== undefined
		) {
			return role.tablePermissions.individuals[tableId] || 0;
		}
		return role.tablePermissions.general || 0;
	}

	canReadTable(tableId: ObjectId, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getTablePermission(tableId, projectId);
		return (permission & CAN_READ_TABLES) === CAN_READ_TABLES;
	}
	canWriteTable(tableId: ObjectId | null, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getTablePermission(tableId, projectId);
		return (permission & CAN_WRITE_TABLES) === CAN_WRITE_TABLES;
	}
	canDeleteTable(tableId: ObjectId, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getTablePermission(tableId, projectId);
		return (permission & CAN_DELETE_TABLES) === CAN_DELETE_TABLES;
	}

	getFunctionPermission(
		funcId: ObjectId | null,
		projectId: ObjectId
	): number {
		const role = this.getProjectRole(projectId);
		if (!role) return 0;
		if (!role.functionPermissions) return CAN_READ_FUNCTIONS;
		if (
			funcId &&
			role.functionPermissions.individuals &&
			role.functionPermissions.individuals[funcId] !== undefined
		) {
			return role.functionPermissions.individuals[funcId] || 0;
		}
		return role.functionPermissions.general || 0;
	}

	canReadFunction(tableId: ObjectId, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getFunctionPermission(tableId, projectId);
		return (permission & CAN_READ_FUNCTIONS) === CAN_READ_FUNCTIONS;
	}
	canWriteFunction(tableId: ObjectId | null, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getFunctionPermission(tableId, projectId);
		return (permission & CAN_WRITE_FUNCTIONS) === CAN_WRITE_FUNCTIONS;
	}
	canDeleteFunction(tableId: ObjectId, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getTablePermission(tableId, projectId);
		return (permission & CAN_DELETE_FUNCTIONS) === CAN_DELETE_FUNCTIONS;
	}

	getColumnPermission(
		columnId: ObjectId | null,
		projectId: ObjectId
	): number {
		const role = this.getProjectRole(projectId);
		if (!role) return 0;
		if (!role.columnPermissions) return 0;
		if (
			columnId &&
			role.columnPermissions.individuals &&
			role.columnPermissions.individuals[columnId] !== undefined
		) {
			return role.columnPermissions.individuals[columnId] || 0;
		}
		return role.columnPermissions.general || 0;
	}

	canReadColumn(columnId: ObjectId, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getColumnPermission(columnId, projectId);
		return (permission & CAN_READ_COLUMNS) === CAN_READ_COLUMNS;
	}
	canWriteColumn(columnId: ObjectId | null, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getColumnPermission(columnId, projectId);
		return (permission & CAN_WRITE_COLUMNS) === CAN_WRITE_COLUMNS;
	}
	canDeleteColumn(columnId: ObjectId, projectId: ObjectId): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getColumnPermission(columnId, projectId);
		return (permission & CAN_DELETE_COLUMNS) === CAN_DELETE_COLUMNS;
	}

	getResourceGroupPermission(
		resourceGroupId: ObjectId | null,
		projectId: ObjectId
	): number {
		const role = this.getProjectRole(projectId);
		if (!role) return 0;
		if (!role.resourceGroupPermissions) return 0;
		if (
			resourceGroupId &&
			role.resourceGroupPermissions.individuals &&
			role.resourceGroupPermissions.individuals[resourceGroupId] !==
				undefined &&
			role.resourceGroupPermissions.individuals[resourceGroupId]!
				.permission !== undefined
		) {
			return (
				role.resourceGroupPermissions.individuals[resourceGroupId]!
					.permission || 0
			);
		}
		return role.resourceGroupPermissions.general || 0;
	}

	canReadResourceGroup(
		resourceGroupId: ObjectId,
		projectId: ObjectId
	): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getResourceGroupPermission(
			resourceGroupId,
			projectId
		);
		return (
			(permission & CAN_READ_RESOURCE_GROUPS) === CAN_READ_RESOURCE_GROUPS
		);
	}
	canWriteResourceGroup(
		resourceGroupId: ObjectId | ObjectId[] | null,
		projectId: ObjectId
	): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		if (Array.isArray(resourceGroupId)) {
			return resourceGroupId.every(id =>
				this.canWriteResourceGroup(id, projectId)
			);
		}
		const permission = this.getResourceGroupPermission(
			resourceGroupId,
			projectId
		);
		return (
			(permission & CAN_WRITE_RESOURCE_GROUPS) ===
			CAN_WRITE_RESOURCE_GROUPS
		);
	}
	canDeleteResourceGroup(
		resourceGroupId: ObjectId,
		projectId: ObjectId
	): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getResourceGroupPermission(
			resourceGroupId,
			projectId
		);
		return (
			(permission & CAN_DELETE_RESOURCE_GROUPS) ===
			CAN_DELETE_RESOURCE_GROUPS
		);
	}

	getDataFieldPermission(
		columnId: ObjectId,
		resourceGroupId: ObjectId,
		projectId: ObjectId
	): number {
		const role = this.getProjectRole(projectId);
		if (!role) return 0;
		if (!role.resourceGroupPermissions) return 0;
		if (
			resourceGroupId &&
			role.resourceGroupPermissions.individuals &&
			role.resourceGroupPermissions.individuals[resourceGroupId] !==
				undefined
		) {
			const resGroupsPermissions = role.resourceGroupPermissions
				.individuals[resourceGroupId]!;
			if (
				resGroupsPermissions.dataIndividualColumns !== undefined &&
				resGroupsPermissions.dataIndividualColumns[columnId] !==
					undefined
			) {
				const columnPermissions = resGroupsPermissions
					.dataIndividualColumns[columnId]!;
				return columnPermissions || 0;
			}
			if (resGroupsPermissions.dataPermission !== undefined) {
				return resGroupsPermissions.dataPermission || 0;
			}
		}
		return role.resourceGroupPermissions.dataGeneral || 0;
	}

	canReadResourceData(
		columnId: ObjectId,
		resourceGroupId: ObjectId,
		projectId: ObjectId
	): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getDataFieldPermission(
			columnId,
			resourceGroupId,
			projectId
		);
		return (
			(permission & CAN_READ_RESOURCE_GROUPS) === CAN_READ_RESOURCE_GROUPS
		);
	}
	canWriteResourceData(
		columnId: ObjectId,
		resourceGroupId: ObjectId,
		projectId: ObjectId
	): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getDataFieldPermission(
			columnId,
			resourceGroupId,
			projectId
		);
		return (
			(permission & CAN_WRITE_RESOURCE_GROUPS) ===
			CAN_WRITE_RESOURCE_GROUPS
		);
	}
	canDeleteResourceData(
		columnId: ObjectId,
		resourceGroupId: ObjectId,
		projectId: ObjectId
	): boolean {
		if (this.hasFullAccessOnProject(projectId)) return true;
		const permission = this.getDataFieldPermission(
			columnId,
			resourceGroupId,
			projectId
		);
		return (
			(permission & CAN_DELETE_RESOURCE_GROUPS) ===
			CAN_DELETE_RESOURCE_GROUPS
		);
	}

	toObject(): IRUser {
		return {
			id: this.id,
			murtskuId: this.murtskuId,
			username: this.username,
			firstname: this.firstname,
			lastname: this.lastname,
			mail: this.mail,
			mobile: this.mobile,
			canAddProjects: this.canAddProjects,
			projectRoles: this.projectRoles,
		};
	}
}
