import React, { useRef, useState } from "react";
import { css } from "emotion";
import { useSelectProps } from "@app/hooks/values";
import Select from "react-select";
import { useFuncChain } from "@app/hooks/general";
import Button from "@material-ui/core/Button";
import { getValidIds } from "./helper";
import { ResourceIdType } from "@app/api/projects/helper-schemas";
import {
	getSubtractRightSetFromLeftSet,
	getUnionOfSets,
	getIntersectionOfSets,
} from "@app/utils/set";
import { shuffleArray } from "@app/utils/array";
import { NumberInputWithLabel } from "@app/components/widgets/input";

enum Action {
	add,
	intersect,
	subtract,
	random,
}

export const IdsTools = React.memo<{}>(({}) => {
	const inputA = useRef<HTMLTextAreaElement>(null);
	const inputB = useRef<HTMLTextAreaElement>(null);
	const resultInput = useRef<HTMLTextAreaElement>(null);
	const [randomCoeff, setRandomCoeff] = useState(0);
	const [action, setAction] = useState(Action.intersect);

	const clearResult = () => {
		if (!resultInput.current) return;
		resultInput.current.value = "";
	};

	const handleActionChange = useFuncChain(setAction, clearResult);

	const selectProps = useSelectProps(action, handleActionChange, [
		[Action.add, "გაერთიანება"],
		[Action.intersect, "თანაკვეთა"],
		[Action.subtract, "A-ს გამოკლებული B"],
		[Action.random, "რანდომ x ცალი A სვეტიდან"],
	]);
	const calculate = () => {
		if (!resultInput.current) return;
		clearResult();
		const aText = inputA.current!.value;
		const bText = inputB.current!.value;
		const aIds = getValidIds(aText, ResourceIdType.string);
		const bIds = getValidIds(bText, ResourceIdType.string);
		const aSet = new Set(aIds as string[]);
		const bSet = new Set(bIds as string[]);
		let resultSet: Set<string>;
		if (action === Action.add) {
			resultSet = getUnionOfSets(aSet, bSet);
		} else if (action === Action.intersect) {
			resultSet = getIntersectionOfSets(aSet, bSet);
		} else if (action === Action.subtract) {
			resultSet = getSubtractRightSetFromLeftSet(aSet, bSet);
		} else if (action === Action.random) {
			const arr = shuffleArray([...aSet]);
			const length =
				randomCoeff === 0
					? arr.length
					: Math.min(arr.length, randomCoeff);
			resultSet = new Set(arr.slice(0, length));
		} else {
			return;
		}
		const text = [...resultSet].join("\n");
		resultInput.current.value = text;
	};

	return (
		<div className="main main2">
			<div className={container}>
				<div style={{ flex: 1 }}>
					<div style={{ textAlign: "center" }}>A</div>
					<textarea
						className={textareaCSS}
						placeholder="ჩააკოპირეთ მომხმარებელთა id-ები ან ნომრები"
						ref={inputA}
						onChange={clearResult}
					/>
				</div>
				<div style={{ width: 30 }} />
				<div style={{ flex: 1 }}>
					<div style={{ textAlign: "center" }}>B</div>
					<textarea
						className={textareaCSS}
						placeholder="ჩააკოპირეთ მომხმარებელთა id-ები ან ნომრები"
						ref={inputB}
						onChange={clearResult}
					/>
				</div>
			</div>
			<div>
				<Select {...selectProps} />
			</div>
			{action === Action.random && (
				<NumberInputWithLabel
					label="რაოდენობა"
					value={randomCoeff}
					onChange={setRandomCoeff}
					zeroOnEmpty={true}
				/>
			)}
			<br />
			<Button variant="contained" color="primary" onClick={calculate}>
				გამოთვლა
			</Button>
			<div style={{ flex: 1 }}>
				<div style={{ textAlign: "center" }}>შედეგი</div>
				<textarea
					className={textareaCSS}
					placeholder="დააჭირეთ გამოთვლას შედეგისთვის"
					ref={resultInput}
				/>
			</div>
		</div>
	);
});

const container = css`
	display: flex;
	align-items: flex-start;
	justify-content: center;
`;

const textareaCSS = css`
	width: 100%;
	padding: 10px;
	border-radius: 10px;
	border: 2px solid #6f9dc7;
	resize: vertical;
	min-height: 300px;
	&:focus {
		border-color: transparent;
		outline: none;
		box-shadow: rgba(111, 157, 199, 0.6) 0 0 10px;
	}
`;
