import Joi from "@app/utils/joi";
import {
	ColumnDataSchema,
	getCustomColumnInfoSchema,
	IColumnData,
	IColumnInfo,
} from "../columns/helper-schemas";
import { IAnyObj, ObjectId } from "@app/utils/generics";

export enum HTTPMethod {
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	DELETE = "DELETE",
}
export const HTTPMethods = [
	HTTPMethod.GET,
	HTTPMethod.POST,
	HTTPMethod.PUT,
	HTTPMethod.DELETE,
];

export const RequestPropertyDataSchema = getCustomColumnInfoSchema({
	defaultValue: ColumnDataSchema,
	canBeChanged: Joi.boolean(),
	isSecret: Joi.boolean(),
	isHidden: Joi.boolean(),
	customName: Joi.string().allow(""),
});

export type IRequestPropertyData = IColumnInfo<{
	defaultValue?: IColumnData;
	canBeChanged?: boolean;
	isSecret?: boolean;
	isHidden?: boolean;
	customName?: string;
}>;

export interface AdditionalProps {
	defaultValue?: IColumnData;
	canBeChanged?: boolean;
	isSecret?: boolean;
	isHidden?: boolean;
	customName?: string;
}

export const RequestPropertiesDataSchema = Joi.object({
	headers: Joi.object().pattern(/.+/, RequestPropertyDataSchema),
	params: Joi.object().pattern(/.+/, RequestPropertyDataSchema),
	query: Joi.object().pattern(/.+/, RequestPropertyDataSchema),
	body: Joi.object().pattern(/.+/, RequestPropertyDataSchema),
});
export type IRequestPropertiesData = {
	[key in "headers" | "params" | "query" | "body"]?: Record<
		string,
		IRequestPropertyData
	>;
};

export const APICallRequestSchema = RequestPropertiesDataSchema.keys({
	url: Joi.string().required(),
	method: Joi.string()
		.valid(...HTTPMethods)
		.required(),
});
export interface IAPICallRequest extends IRequestPropertiesData {
	url: string;
	method: HTTPMethod;
}

const ApiCallResponseSchema = getCustomColumnInfoSchema({
	updateOne: APICallRequestSchema,
	deleteOne: APICallRequestSchema,
	createOne: APICallRequestSchema,
	keyOfId: Joi.string(),
});
export type IApiCallResponse = IColumnInfo<ResponseAdditionalProps>;

export interface ResponseAdditionalProps {
	updateOne?: IAPICallRequest;
	deleteOne?: IAPICallRequest;
	createOne?: IAPICallRequest;
	keyOfId?: string;
}

export enum ApiCallType {
	columnInfo = "CLMNINFO",
	action = "ACTION",
	refreshResourceIds = "RFRSHRSID",
}

const APIInfoSchema = Joi.object({
	type: Joi.string()
		.valid(ApiCallType.columnInfo)
		.required(),
	getOne: APICallRequestSchema,
	getMany: APICallRequestSchema,
	responseSchema: ApiCallResponseSchema.required(),
}).or("getOne", "getMany");
export type IAPIInfo = {
	type: ApiCallType.columnInfo;
	responseSchema: IApiCallResponse;
} & (
	| {
			getOne: IAPICallRequest;
			getMany?: undefined;
	  }
	| {
			getMany: IAPICallRequest;
			getOne?: undefined;
	  }
);

const APICallActionSchema = Joi.object({
	type: Joi.string()
		.valid(ApiCallType.action)
		.required(),
	request: APICallRequestSchema.required(),
	canBeCalledMultipleTimes: Joi.boolean(),
});
export interface IAPICallAction {
	type: ApiCallType.action;
	request: IAPICallRequest;
	canBeCalledMultipleTimes?: boolean;
}

const RefreshResourceGroupItemSchema = Joi.object({
	projectId: Joi.objectId().required(),
	resourceGroupKeys: Joi.array()
		.items(Joi.string().valid("name", "description", "resourceIds"))
		.allow(null)
		.required(),
	bodyKey: Joi.string().required(),
});
export interface IRefreshResourceGroupItem {
	projectId: ObjectId;
	resourceGroupKeys: ("name" | "description" | "resourceIds")[] | null;
	bodyKey: string;
}

const APICallRefreshResourceIdsSchema = Joi.object({
	type: Joi.string()
		.valid(ApiCallType.refreshResourceIds)
		.required(),
	request: APICallRequestSchema.required(),
	resourceGroups: Joi.array()
		.items(RefreshResourceGroupItemSchema.required())
		.required(),
});
export interface IAPICallRefreshResourceIds {
	type: ApiCallType.refreshResourceIds;
	request: IAPICallRequest;
	resourceGroups: IRefreshResourceGroupItem[];
}

export const APICallInfoSchema = Joi.alternatives([
	APIInfoSchema,
	APICallActionSchema,
	APICallRefreshResourceIdsSchema,
]);
export type IAPICallInfo =
	| IAPIInfo
	| IAPICallAction
	| IAPICallRefreshResourceIds;

export const RAPICallSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string().allow(""),
	info: (APICallInfoSchema as any).required(),
	author: Joi.number()
		.integer()
		.required(),
});
export interface IRAPICall {
	_id: ObjectId;
	projectId: ObjectId;
	name: string;
	description?: string;
	info: IAPICallInfo;
	author: number;
}

export const APICallRequestDataSchema = Joi.object({
	headers: Joi.object(),
	params: Joi.object(),
	query: Joi.object(),
	body: Joi.object(),
});
export type IAPICallRequestData = {
	[key in "headers" | "params" | "query" | "body"]?: IAnyObj;
};
