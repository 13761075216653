import { useProjectId } from "./projects";
import { useFetch } from "./fetch";
import { inject } from "@app/modules";

export const useColumns = () => {
	const projectId = useProjectId();

	return useFetch(
		() =>
			inject("Column").getReadableColumns({
				projectId: projectId!,
			}),
		[projectId]
	);
};
