import {
	IADELETETable,
	IAGETManyTables,
	IAGETTable,
	IAPOSTCreateTable,
	IAPUTTable,
	IRGETManyTables,
	IRGETTable,
	IRPOSTCreateTable,
	RGETManyTablesSchema,
	RGETTableSchema,
	RPOSTCreateTableSchema
	} from "./validators";
import { Requests } from "..";

// tslint:disable:no-duplicate-string
const tables = {
	add: (data: IAPOSTCreateTable): Promise<IRPOSTCreateTable> =>
		Requests.send("POST", "/api/projects/:projectId/tables/", data, null, {
			responseSchema: RPOSTCreateTableSchema,
		}),
	update: (data: IAPUTTable): Promise<void> =>
		Requests.send("PUT", "/api/projects/:projectId/tables/:_id", data, null),
	getById: (data: IAGETTable): Promise<IRGETTable> =>
		Requests.send("GET", "/api/projects/:projectId/tables/:tableId", data, null, {
			responseSchema: RGETTableSchema
		}),
	deleteById: (data: IADELETETable): Promise<void> =>
		Requests.send("DELETE", "/api/projects/:projectId/tables/:tableId", data),
	getReadableTables: (data: IAGETManyTables): Promise<IRGETManyTables> =>
		Requests.send("GET", "/api/projects/:projectId/tables", data, null, {
			responseSchema: RGETManyTablesSchema,
		}),
};
export default tables;
