import * as React from "react";
import Button from "@material-ui/core/Button";
import Loading from "../widgets/loading";
import { History } from "history";
import { inject } from "@app/modules";
import { IRColumn, ColumnType } from "@app/api/columns/helper-schemas";
import { match } from "react-router";
import { OptionalKeys } from "@app/utils/generics";
import { removeKeys } from "@app/utils/common";
import { User } from "@app/user";
import {
	IRFunction,
	FunctionLogicType,
} from "@app/api/functions/helper-schemas";
import { InputWithLabel } from "../widgets/input";
import { FunctionInfo } from "./info";

interface IProps {
	match: match<{ projectId: string; id?: string }>;
	history: History;
	user: User;
}

interface IState {
	function?: OptionalKeys<IRFunction, "_id" | "author">;
	columns?: IRColumn[];
}

export class SaveFunction extends React.Component<IProps, IState> {
	id = this.props.match.params.id;
	projectId = this.props.match.params.projectId;

	state: IState = {};

	_Function = inject("Function");
	_Column = inject("Column");

	componentDidMount() {
		this._Column
			.getReadableColumns({ projectId: this.projectId })
			.then(columns => {
				this.setState({ columns });
			});
		if (!this.id) {
			if (!this.props.user.canWriteFunction(null, this.projectId)) {
				this.props.history.push(
					`/projects/${this.projectId}/functions`
				);
				return;
			}
			this.setState({
				function: {
					name: "",
					projectId: this.projectId,
					description: null,
					info: {
						inputSchema: {
							type: ColumnType.number,
						},
						outputSchema: {
							type: ColumnType.number,
						},
						logic: {
							type: FunctionLogicType.discrete,
							pairs: [{ in: 1, out: 2 }],
							defaultValue: null,
						},
					},
				},
			});
		} else {
			this._Function
				.findById({ id: this.id, projectId: this.projectId })
				.then(func => {
					this.projectId = func.projectId;
					if (
						!this.props.user.canWriteFunction(
							func._id,
							func.projectId
						)
					) {
						this.props.history.push(
							`/projects/${this.projectId}/functions`
						);
						return;
					}
					this.setState({ function: func });
				});
		}
	}

	pasteOtherFunction = () => {
		const res = JSON.parse(
			sessionStorage.getItem("copy-functions") || "null"
		) as { funcId: string; projectId: string } | null;
		if (!res) return;
		this._Function
			.findById({
				id: res.funcId,
				projectId: res.projectId,
			})
			.then(func => {
				this.setState({
					function: {
						...removeKeys(func, "_id", "author"),
						projectId: this.projectId,
					},
				});
			});
	};

	onSave = () => {
		const func = this.state.function;
		if (!func) {
			return;
		}
		if (!this.id) {
			this._Function.create(func as IRFunction).then(col => {
				this.props.history.push(
					`/projects/${this.projectId}/functions`
				);
			});
		} else {
			this._Function
				.update(removeKeys(func, "author") as IRFunction)
				.then(() => {
					this.props.history.push(
						`/projects/${this.projectId}/functions`
					);
				});
		}
	};

	handleValueChange = <K extends keyof IRFunction>(key: K) => (
		value: IRFunction[K]
	) => {
		this.setState({
			function: {
				...this.state.function!,
				[key]: value,
			},
		});
	};

	onNameChange = this.handleValueChange("name");
	onDescriptionChange = this.handleValueChange("description");
	onInfoChange = this.handleValueChange("info");

	render() {
		const func = this.state.function;
		if (!func) {
			return <Loading />;
		}
		return (
			<div className="main main2">
				{!func._id && sessionStorage.getItem("copy-functions") && (
					<Button
						variant="contained"
						onClick={this.pasteOtherFunction}
					>
						ჩაკოპირება
					</Button>
				)}
				<div>
					<InputWithLabel
						label="სახელი"
						value={func.name}
						onChange={this.onNameChange}
					/>
				</div>
				<div>
					<InputWithLabel
						label="აღწერა"
						value={func.description || ""}
						onChange={this.onDescriptionChange}
					/>
				</div>
				<FunctionInfo
					info={func.info}
					onChange={this.onInfoChange}
					projectId={this.projectId}
				/>
				<br />
				<br />
				<Button
					variant="contained"
					color="primary"
					onClick={this.onSave}
				>
					შენახვა
				</Button>
			</div>
		);
	}
}
