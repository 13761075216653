import * as React from "react";
import Loading from "../widgets/loading";
import { connect } from "react-redux";
import { inject } from "@app/modules";
import { IRootState } from "@app/reducers/root-state";
import { match } from "react-router";

interface IOwnProps {
	match: match<{ projectId: string; id: string }>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IOwnProps;

class ColumnPage extends React.PureComponent<IProps> {
	id = this.props.match.params.id;
	projectId = this.props.match.params.projectId;

	_Column = inject("Column");

	render() {
		const column = this._Column.findByIdSync(
			{ id: this.id, projectId: this.projectId },
			this.props.columns
		);
		if (!column) return <Loading />;
		return (
			<div className="main main2">
				<h3>{column.name}</h3>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	columns: state.columns,
});

const connected = connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	ColumnPage
);

export { connected as ColumnPage };
