import Joi from "@app/utils/joi";
import {
	ColumnDataSchema,
	ColumnInfoSchema,
	IColumnData,
	IColumnInfo,
} from "../columns/helper-schemas";
import { ObjectId } from "@app/utils/generics";

export enum FunctionLogicType {
	discrete = 1,
	continuous = 2,
}

const OneToOneMapPairSchema = Joi.object({
	in: Joi.any().required(), // TODO: replace with ColumnDataSchema
	out: Joi.any().required(), // TODO: replace with ColumnDataSchema
});
export interface IOneToOneMapPair {
	in: IColumnData;
	out: IColumnData;
}

const OneToOneMapLogicSchema = Joi.object({
	type: Joi.valid(FunctionLogicType.discrete).required(),
	defaultValue: ColumnDataSchema.required(),
	pairs: Joi.array()
		.items(OneToOneMapPairSchema)
		.required(),
});

export interface IOneToOneMapLogic {
	type: FunctionLogicType.discrete;
	defaultValue: IColumnData;
	pairs: IOneToOneMapPair[];
}

const FunctionLogicSchema = Joi.alternatives(OneToOneMapLogicSchema);
export type IFunctionLogic = IOneToOneMapLogic;

export const FunctionInfoSchema = Joi.object({
	inputSchema: ColumnInfoSchema,
	outputSchema: ColumnInfoSchema,
	logic: FunctionLogicSchema.required(),
});
export interface IFunctionInfo {
	inputSchema?: IColumnInfo;
	outputSchema?: IColumnInfo;
	logic: IFunctionLogic;
}

export const RFunctionSchema = Joi.object({
	_id: Joi.objectId().required(),
	projectId: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string()
		.allow("", null)
		.required(),
	info: FunctionInfoSchema.required(),
	author: Joi.number().required(),
});
export interface IRFunction {
	_id: ObjectId;
	projectId: ObjectId;
	name: string;
	description: string | null;
	info: IFunctionInfo;
	author: number;
}
