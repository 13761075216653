import {
	Transformation,
	TranformationType,
} from "@app/api/transformation-schemas";

export class TransformationService {
	calculate = <T>(
		transformation: Transformation<T>,
		calcValue: (val: T) => number
	): number => {
		if (transformation.type === TranformationType.value) {
			return calcValue(transformation.val);
		}
		if (transformation.type === TranformationType.sum) {
			let sum = 0;
			for (const value of transformation.addenda) {
				sum += this.calculate(value, calcValue);
			}
			return sum;
		}
		if (transformation.type === TranformationType.multiplication) {
			let product = 1;
			for (const value of transformation.factors) {
				product *= this.calculate(value, calcValue);
			}
			return product;
		}
		if (transformation.type === TranformationType.percentage) {
			return 100 * this.calculate(transformation.value, calcValue);
		}
		if (transformation.type === TranformationType.subtraction) {
			return (
				this.calculate(transformation.minuend, calcValue) -
				this.calculate(transformation.subtrahend, calcValue)
			);
		}
		if (transformation.type === TranformationType.fraction) {
			const divisor = this.calculate(transformation.divisor, calcValue);
			if (divisor === 0) return 0;
			return this.calculate(transformation.dividend, calcValue) / divisor;
		}
		return 0;
	};
}
