import api from "@app/api";
import createCRUDActions from "./crud";
import {
	DELETE_FUNCTION,
	LOAD_FUNCTION_BY_ID,
	LOAD_FUNCTIONS,
	UPDATE_FUNCTION,
} from "@app/action-types";
import { IRootActions, IRootState } from "@app/reducers/root-state";
import { IRFunction } from "@app/api/functions/helper-schemas";
import { ThunkAction } from "redux-thunk";
import {
	IAGETFunction,
	IAPOSTCreateFunction,
	IAPUTFunction,
	IRGETManyFunctions,
	IRGETFunction,
	IRPOSTCreateFunction,
	IADELETEFunction,
	IAGETManyFunctions,
} from "@app/api/functions/validators";

export const addFunction = (
	args: IAPOSTCreateFunction
): ThunkAction<
	Promise<IRPOSTCreateFunction>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.functions.add(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const updateFunction = (
	args: IAPUTFunction
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.functions.update(args).then(() => {
		dispatch(crudActions.update(args));
	});
};

export const loadFunctions = (
	args: IAGETManyFunctions
): ThunkAction<
	Promise<IRGETManyFunctions>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.functions.getReadableFunctions(args).then(data => {
		dispatch(crudActions.loadMany(data));
		return data;
	});
};

export const loadFunctionById = (
	args: IAGETFunction
): ThunkAction<
	Promise<IRGETFunction>,
	IRootState,
	null,
	IRootActions
> => dispatch => {
	return api.functions.getById(args).then(data => {
		dispatch(crudActions.loadOne(data));
		return data;
	});
};

export const deleteFunction = (
	args: IADELETEFunction
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => dispatch => {
	return api.functions.deleteById(args).then(() => {
		dispatch(crudActions.delete(args.functionId));
	});
};

export type FunctionActions =
	| ReturnType<typeof crudActions["delete"]>
	| ReturnType<typeof crudActions["loadMany"]>
	| ReturnType<typeof crudActions["loadOne"]>
	| ReturnType<typeof crudActions["update"]>;

export const functionCRUDActionTypes = {
	update: UPDATE_FUNCTION as typeof UPDATE_FUNCTION,
	loadOne: LOAD_FUNCTION_BY_ID as typeof LOAD_FUNCTION_BY_ID,
	loadMany: LOAD_FUNCTIONS as typeof LOAD_FUNCTIONS,
	delete: DELETE_FUNCTION as typeof DELETE_FUNCTION,
};

const crudActions = createCRUDActions<
	IRFunction,
	typeof functionCRUDActionTypes
>(functionCRUDActionTypes);
export { crudActions as functionCRUDActions };
