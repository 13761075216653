import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "../widgets/loading";
import memoizeOne from "memoize-one";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import {
	ApiCallType,
	IAPICallAction,
	IAPIInfo,
	IRAPICall,
} from "@app/api/api-calls/helper-schemas";
import { css } from "emotion";
import { getUniqueId, removeKeys } from "@app/utils/common";
import { History } from "history";
import { inject } from "@app/modules";
import { match } from "react-router";
import {
	NotUndefined,
	ObjectId,
	OptionalKeys,
	WithKnownKeyType,
	Diff,
} from "@app/utils/generics";
import { User } from "@app/user";
import {
	ColumnType,
	IColumnInfo,
	IRColumn,
	ISimpleColumnInfo,
	IAPIColumnInfo,
	IObjectColumnInfo,
	IEnumColumnInfo,
	IStringColumnInfo,
	IColReferenceColumnInfo,
} from "@app/api/columns/helper-schemas";

interface IProps {
	match: match<{ projectId: string; id?: string }>;
	history: History;
	user: User;
}

interface IState {
	column?: OptionalKeys<IRColumn, "_id" | "author">;
}

type AllowType = "primitives" | "simple" | "all";

export class SaveColumn extends React.Component<IProps, IState> {
	id = this.props.match.params.id;
	projectId = this.props.match.params.projectId;

	state: IState = {};

	_Column = inject("Column");

	componentDidMount() {
		if (!this.id) {
			if (!this.props.user.canWriteColumn(null, this.projectId)) {
				this.props.history.push(`/projects/${this.projectId}/columns`);
				return;
			}
			this.setState({
				column: {
					name: "",
					projectId: this.projectId,
					info: { type: ColumnType.string } as {
						type: ColumnType.string;
					},
				},
			});
		} else {
			this._Column
				.findById({ id: this.id, projectId: this.projectId })
				.then(column => {
					this.projectId = column.projectId;
					if (
						!this.props.user.canWriteColumn(
							column._id,
							column.projectId
						)
					) {
						this.props.history.push(
							`/projects/${this.projectId}/columns`
						);
						return;
					}
					this.setState({ column });
				});
		}
	}

	onSave = () => {
		const { column } = this.state;
		if (!column || typeof column.info === undefined) {
			return;
		}
		if (!this.id) {
			this._Column.create(column as IRColumn).then(col => {
				this.props.history.push(`/projects/${this.projectId}/columns`);
			});
		} else {
			this._Column
				.update(removeKeys(column, "author") as IRColumn)
				.then(() => {
					this.props.history.push(
						`/projects/${this.projectId}/columns`
					);
				});
		}
	};

	handleInputChange = (
		key: keyof NotUndefined<IState["column"]>
	): React.ChangeEventHandler<HTMLInputElement> => e => {
		this.setState({
			column: {
				...this.state.column!,
				[key]: e.target.value,
			},
		});
	};

	// tslint:disable-next-line:member-ordering
	onNameChange = this.handleInputChange("name");
	// tslint:disable-next-line:member-ordering
	onDescriptionChange = this.handleInputChange("description");

	onInfoChange = (info: IRColumn["info"]) => {
		this.setState({
			column: {
				...this.state.column!,
				info,
			},
		});
	};

	render() {
		if (!this.state.column) {
			return <Loading />;
		}
		const { column } = this.state;
		const columnInfo = column.info;
		return (
			<div className="main main2">
				<div>
					<TextField
						label="სახელი"
						value={column.name}
						onChange={this.onNameChange}
						margin="dense"
						variant="outlined"
					/>
				</div>
				<div>
					<TextField
						label="აღწერა"
						value={column.description || ""}
						onChange={this.onDescriptionChange}
						margin="dense"
						variant="outlined"
					/>
				</div>
				<br />
				<ColumnInfoType
					info={columnInfo}
					allow="all"
					onChange={this.onInfoChange}
					projectId={this.projectId}
				/>
				<br />
				<Button
					variant="contained"
					color="primary"
					onClick={this.onSave}
				>
					შენახვა
				</Button>
			</div>
		);
	}
}

type IColumnInfoTypeProps<T extends IColumnInfo = IColumnInfo> = {
	allow: AllowType;
	defaultText?: string;
	projectId: ObjectId;
} & (
	| {
			hasNestedProps?: false;
			info: IColumnInfo;
			onChange: (info: IColumnInfo) => void;
	  }
	| {
			hasNestedProps: true;
			info: T;
			onChange: (info: T) => void;
			defaultAdditionalProps: any;
			additionalPropsComponent: React.ComponentType<{
				info: T;
				projectId: ObjectId;
				onChange: (info: T) => void;
			}>;
	  }
);

interface IColumnInfoTypeState {
	apiCalls?: IRAPICall[];
	columns?: IRColumn[];
}
export class ColumnInfoType<
	T extends IColumnInfo = IColumnInfo
> extends React.PureComponent<IColumnInfoTypeProps<T>, IColumnInfoTypeState> {
	state: IColumnInfoTypeState = {};

	_APICalls = inject("APICall");
	_Column = inject("Column");

	onTypeChange = (type: ColumnType) => {
		if (this.props.info.type === type) return; // unchanged
		this.props.onChange(
			getDefaultInfo(
				type,
				this.props.hasNestedProps
					? this.props.defaultAdditionalProps
					: undefined
			)
		);
	};

	componentDidMount() {
		this._APICalls
			.getReadableAPICalls({ projectId: this.props.projectId })
			.then(data => {
				this.setState({ apiCalls: data });
			});
		this._Column
			.getReadableColumns({ projectId: this.props.projectId })
			.then(data => {
				this.setState({ columns: data });
			});
	}

	onListItemChange = (listItem: ISimpleColumnInfo) => {
		if (this.props.info.type !== ColumnType.list) return;
		this.props.onChange({
			...this.props.info,
			itemsInfo: listItem,
		} as any);
	};

	render() {
		const { info } = this.props;
		return (
			<div>
				<SelectColumnInfoType
					allow={this.props.allow}
					selectedType={info.type}
					onTypeChange={this.onTypeChange}
					defaultText={this.props.defaultText}
				/>
				{info.type === ColumnType.string && (
					<div
						style={{
							padding: "0 10px",
							margin: "10px 0",
							borderRadius: 4,
							border: "1px solid #ccc",
						}}
					>
						<ColumnString
							onChange={this.props.onChange as any}
							info={
								info as Extract<
									typeof info,
									{ type: ColumnType.string }
								>
							}
						/>
					</div>
				)}
				{info.type === ColumnType.list && (
					<div
						style={{
							padding: "0 10px",
							margin: "10px 0",
							borderRadius: 4,
							border: "1px solid #ccc",
						}}
					>
						<ColumnInfoType
							{...(this.props as any)}
							allow={getDownAllow(this.props.allow, info.type)}
							onChange={this.onListItemChange}
							info={
								(info as Extract<
									typeof info,
									{ type: ColumnType.list }
								>).itemsInfo
							}
							defaultText="თითო მონაცემის ტიპი:"
							projectId={this.props.projectId}
						/>
					</div>
				)}
				{info.type === ColumnType.object && (
					<div
						style={{
							padding: "0 10px 10px 10px",
							margin: "10px 0",
							borderRadius: 4,
							border: "1px solid #ccc",
						}}
					>
						<ColumnObject
							{...(this.props as any)}
							info={
								info as Extract<
									typeof info,
									{ type: ColumnType.object }
								>
							}
							onChange={this.props.onChange as any}
							projectId={this.props.projectId}
							allow={this.props.allow}
						/>
					</div>
				)}
				{info.type === ColumnType.enum && (
					<div
						style={{
							padding: "0 10px 10px 10px",
							margin: "10px 0",
							borderRadius: 4,
							border: "1px solid #ccc",
						}}
					>
						<ColumnEnum
							info={
								info as Extract<
									typeof info,
									{ type: ColumnType.enum }
								>
							}
							onChange={this.props.onChange as any}
						/>
					</div>
				)}
				{info.type === ColumnType.api && (
					<div
						style={{
							padding: "10px",
							margin: "10px 0",
							borderRadius: 4,
							border: "1px solid #ccc",
						}}
					>
						<ColumnApiSelect
							apiCalls={this.state.apiCalls}
							info={
								info as Extract<
									typeof info,
									{ type: ColumnType.api }
								>
							}
							onChange={this.props.onChange as any}
						/>
					</div>
				)}
				{info.type === ColumnType.colReference && (
					<div
						style={{
							padding: "10px",
							margin: "10px 0",
							borderRadius: 4,
							border: "1px solid #ccc",
						}}
					>
						<ColReferenceSelect
							columns={this.state.columns}
							info={
								info as Extract<
									typeof info,
									{ type: ColumnType.colReference }
								>
							}
							onChange={this.props.onChange as any}
						/>
					</div>
				)}
				{this.props.hasNestedProps && (
					<this.props.additionalPropsComponent
						info={info as T}
						onChange={this.props.onChange as any}
						projectId={this.props.projectId}
					/>
				)}
			</div>
		);
	}
}

interface ISelectColumnInfoTypeProps {
	selectedType: ColumnType;
	allow: AllowType;
	onTypeChange: (type: ColumnType) => void;
	defaultText?: string;
}

class SelectColumnInfoType extends React.PureComponent<
	ISelectColumnInfoTypeProps
> {
	getTypes = memoizeOne((allow: ISelectColumnInfoTypeProps["allow"]) => {
		const columnInfoTypes = [
			{ value: ColumnType.string, label: "ტექსტი" },
			{ value: ColumnType.number, label: "რიცხვი" },
			{ value: ColumnType.date, label: "თარიღი" },
			{ value: ColumnType.boolean, label: "კი/არა" },
			{
				value: ColumnType.enum,
				label: "წინასწარ განსაზღვრული მნიშვნელობები (enum)",
			},
		];
		if (allow === "simple" || allow === "all") {
			columnInfoTypes.push(
				{ value: ColumnType.resourceId, label: "id" },
				{ value: ColumnType.list, label: "მიმდევრობა" },
				{ value: ColumnType.object, label: "ობიექტი" }
			);
		}
		if (allow === "all") {
			columnInfoTypes.push({ value: ColumnType.api, label: "api" });
			columnInfoTypes.push({
				value: ColumnType.colReference,
				label: "სვეტი",
			});
		}
		return columnInfoTypes;
	});

	onChange = (sel: { value: ColumnType; label: string }) => {
		this.props.onTypeChange(sel.value);
	};

	render() {
		const types = this.getTypes(this.props.allow);
		return (
			<div className={SelectContainerCSS}>
				<span>
					{this.props.defaultText === undefined
						? "მონაცემის ტიპი:"
						: this.props.defaultText}
				</span>
				<div>
					<Select
						value={types.find(
							e => e.value === this.props.selectedType
						)}
						onChange={this.onChange}
						options={types}
					/>
				</div>
			</div>
		);
	}
}

export const SelectContainerCSS = css`
	display: flex;
	width: 100%;
	align-items: center;
	margin: 10px 0;
	z-index: 50;
	position: relative;
	& > div {
		flex: 1;
	}
	& > span {
		padding: 9px;
	}
`;

interface IColumnObjectProps {
	info: IObjectColumnInfo;
	onChange: (info: IObjectColumnInfo) => void;
	allow: AllowType;
	projectId: ObjectId;
}

class ColumnObject extends React.PureComponent<IColumnObjectProps> {
	onObjectItemTypeChange = (index: number, info: ISimpleColumnInfo) => {
		if (this.props.info.type !== ColumnType.object) return;
		this.props.onChange({
			...this.props.info,
			props: this.props.info.props.map((e, i) =>
				i === index ? { ...e, info } : e
			),
		});
	};

	onObjItemNameChange = (index: number, propName: "name" | "key", event) => {
		if (this.props.info.type !== ColumnType.object) return;
		this.props.onChange({
			...this.props.info,
			props: this.props.info.props.map((e, i) =>
				i === index ? { ...e, [propName]: event.target.value } : e
			),
		});
	};

	onObjectPropAdd = () => {
		if (this.props.info.type !== ColumnType.object) return;
		const defType =
			this.props.info.props.length > 0
				? this.props.info.props[this.props.info.props.length - 1].info
						.type
				: ColumnType.string;
		this.props.onChange({
			...this.props.info,
			props: [
				...this.props.info.props,
				{
					key: "key" + (this.props.info.props.length + 1),
					name: "სახელი",
					info: getDefaultInfo(
						defType,
						(this.props as any).defaultAdditionalProps
					),
				},
			],
		});
	};

	onObjectPropRemove = (index: number) => {
		if (this.props.info.type !== ColumnType.object) return;
		this.props.onChange({
			...this.props.info,
			props: this.props.info.props.filter((e, i) => i !== index),
		});
	};

	render() {
		return (
			<div>
				{this.props.info.props.map((each, index) => (
					<div
						key={index}
						style={{
							padding: "0 10px",
							margin: "10px 0",
							borderRadius: 4,
							border: "1px solid #ccc",
						}}
					>
						<CancelIcon
							onClick={() => this.onObjectPropRemove(index)}
						/>
						<TextField
							label="სახელი"
							value={each.name}
							onChange={event =>
								this.onObjItemNameChange(index, "name", event)
							}
							margin="dense"
							variant="outlined"
						/>
						<TextField
							label="key"
							value={each.key}
							onChange={event =>
								this.onObjItemNameChange(index, "key", event)
							}
							margin="dense"
							variant="outlined"
						/>
						<ColumnInfoType
							key={index}
							{...this.props}
							allow={getDownAllow(
								this.props.allow,
								this.props.info.type
							)}
							onChange={data =>
								this.onObjectItemTypeChange(
									index,
									data as ISimpleColumnInfo
								)
							}
							info={each.info}
							defaultText="მონაცემის ტიპი:"
							projectId={this.props.projectId}
						/>
					</div>
				))}
				<br />
				<Button variant="outlined" onClick={this.onObjectPropAdd}>
					<div className="middle">
						<AddIcon />
						<span>ფროფერთის დამატება</span>
					</div>
				</Button>
			</div>
		);
	}
}

interface IColumnStringProps {
	info: IStringColumnInfo;
	onChange: (info: IStringColumnInfo) => void;
}

class ColumnString extends React.PureComponent<IColumnStringProps> {
	onCheck = (e, checked: boolean) => {
		this.props.onChange({
			...this.props.info,
			textarea: checked,
		});
	};

	render() {
		return (
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checked={!!this.props.info.textarea}
							onChange={this.onCheck}
						/>
					}
					label="დიდი ჩასაწერი"
				/>
			</div>
		);
	}
}

interface IColumnEnumProps {
	info: IEnumColumnInfo;
	onChange: (info: IEnumColumnInfo) => void;
}

class ColumnEnum extends React.PureComponent<IColumnEnumProps> {
	onEnumerableAdd = () => {
		if (this.props.info.type !== ColumnType.enum) return;
		const [uniqueId] = getUniqueId(
			this.props.info.enumerables.map(e => e.id),
			1
		);
		this.props.onChange({
			...this.props.info,
			enumerables: [
				...this.props.info.enumerables,
				{
					id: uniqueId,
					val: "",
				},
			],
		});
	};

	onEnumerableValChange = (index: number, val: string) => {
		if (this.props.info.type !== ColumnType.enum) return;
		this.props.onChange({
			...this.props.info,
			enumerables: this.props.info.enumerables.map((e, i) =>
				i === index ? { ...e, val } : e
			),
		});
	};
	onEnumerableRemove = (index: number) => {
		if (this.props.info.type !== ColumnType.enum) return;
		this.props.onChange({
			...this.props.info,
			enumerables: this.props.info.enumerables.filter(
				(e, i) => i !== index
			),
		});
	};

	render() {
		return (
			<div>
				{this.props.info.enumerables.map((e, index) => (
					<div key={e.id}>
						<CancelIcon
							onClick={() => this.onEnumerableRemove(index)}
						/>
						<TextField
							label="მნიშვნელობა"
							value={e.val}
							onChange={event =>
								this.onEnumerableValChange(
									index,
									event.target.value
								)
							}
							margin="dense"
							variant="outlined"
						/>
					</div>
				))}
				<br />
				<Button variant="outlined" onClick={this.onEnumerableAdd}>
					<div className="middle">
						<AddIcon />
						<span>შესაძლო მნიშვნელობის დამატება</span>
					</div>
				</Button>
			</div>
		);
	}
}

interface IColumnApiSelectProps {
	apiCalls?: IRAPICall[];
	info: IAPIColumnInfo;
	onChange: (info: IAPIColumnInfo) => void;
}

class ColumnApiSelect extends React.PureComponent<IColumnApiSelectProps> {
	getOptions = memoizeOne((apiCalls: IRAPICall[]) => {
		return apiCalls
			.filter(
				e =>
					e.info.type === ApiCallType.columnInfo ||
					e.info.type === ApiCallType.action
			)
			.map(e => ({
				value: e._id,
				label: e.name,
			}));
	});

	getAPIResponseKeys = memoizeOne((props: IObjectColumnInfo["props"]) => {
		return [
			{ value: "", label: "არაფერი" },
			...props.map(e => ({
				value: e.key,
				label: `${e.key} (${e.name})`,
			})),
		];
	});

	onAPIChange = (sel: { value: ObjectId; label: string }) => {
		const newInfo: IAPIColumnInfo = {
			...this.props.info,
			apiId: sel.value,
		};
		this.props.onChange(newInfo);
	};

	onAPIKeyChange = (sel: { value: string; label: string }) => {
		let newInfo: IAPIColumnInfo;
		if (sel.value === "") {
			newInfo = removeKeys(this.props.info, "key");
		} else {
			newInfo = {
				...this.props.info,
				key: sel.value,
			};
		}
		this.props.onChange(newInfo);
	};

	render() {
		if (!this.props.apiCalls) return <Loading />;
		const options = this.getOptions(this.props.apiCalls);
		const selectedValue = options.find(
			e => e.value === this.props.info.apiId
		);
		const selectedAPI = !selectedValue
			? undefined
			: (this.props.apiCalls as WithKnownKeyType<
					IRAPICall,
					"info",
					IAPIInfo | IAPICallAction
			  >[]).find(e => e._id === this.props.info.apiId);
		return (
			<div>
				აირჩიეთ api:
				<Select
					options={options}
					value={selectedValue}
					onChange={this.onAPIChange}
				/>
				{selectedAPI &&
					selectedAPI.info.type === ApiCallType.columnInfo &&
					selectedAPI.info.responseSchema.type ===
						ColumnType.object && (
						<>
							<br />
							აირჩიეთ ობიექტის key, თუკი კონკრეტული ინფორმაციის
							წამოღება გსურთ ამ სვეტში:
							<Select
								options={this.getAPIResponseKeys(
									selectedAPI.info.responseSchema.props
								)}
								value={this.getAPIResponseKeys(
									selectedAPI.info.responseSchema.props
								).find(e => e.value === this.props.info.key)}
								onChange={this.onAPIKeyChange}
							/>
						</>
					)}
			</div>
		);
	}
}

const ColReferenceSelect: React.FC<{
	columns?: IRColumn[];
	info: IColReferenceColumnInfo;
	onChange: (info: IColReferenceColumnInfo) => void;
}> = React.memo(({ columns, info, onChange }) => {
	const options = React.useMemo(() => {
		if (!columns) return null;
		return columns.map(e => ({
			value: e._id,
			label: e.name,
		}));
	}, [columns]);
	const selectedValue = React.useMemo(() => {
		if (!options) return null;
		return options.find(e => e.value === info.colId);
	}, [info.colId, options]);
	if (!options) {
		return null;
	}
	return (
		<div>
			აირჩიეთ სვეტი:
			<Select
				options={options}
				value={selectedValue}
				onChange={(sel: { value: ObjectId; label: string }) => {
					onChange({ ...info, colId: sel.value });
				}}
			/>
		</div>
	);
});

const getDownAllow = (
	allow: IColumnInfoTypeProps["allow"],
	type: ColumnType
): IColumnInfoTypeProps["allow"] => {
	if (allow === "primitives") return "primitives";
	if (allow === "all") return "all";
	if (type === ColumnType.list) {
		return "simple";
	}
	return "simple";
};

const getDefaultInfo = <T extends {} = {}>(
	type: ColumnType,
	additionalProps?: T
): IColumnInfo & T => {
	switch (type) {
		case ColumnType.string:
		case ColumnType.resourceId:
		case ColumnType.number:
		case ColumnType.date:
		case ColumnType.boolean:
			return { type, ...additionalProps } as any;
		case ColumnType.list:
			return {
				type,
				itemsInfo: getDefaultInfo(ColumnType.string, additionalProps),
				...additionalProps,
			} as any;
		case ColumnType.enum:
			return {
				type,
				enumerables: [{ id: 1, val: "მნიშვნელობა 1" }],
				...additionalProps,
			} as any;
		case ColumnType.object:
			return {
				type,
				props: [
					{
						key: "key1",
						name: "სახელი",
						info: getDefaultInfo(
							ColumnType.string,
							additionalProps
						),
					},
				],
				...additionalProps,
			} as any;
		case ColumnType.api:
			return {
				type,
				apiId: "",
				...additionalProps,
			} as any;
		case ColumnType.colReference:
			return {
				type,
				colId: "",
				...additionalProps,
			} as any;
		default:
			throw new Error(`column type ${type} is not supported`);
	}
};
