import {
	IADELETEResourceGroup,
	IAGETManyResourceGroups,
	IAGETResourceGroup,
	IAPOSTCreateResourceGroup,
	IAPUTAddResourceToGroup,
	IAPUTResourceGroup,
	IRGETManyResourceGroups,
	IRGETResourceGroup,
	IRPOSTCreateResourceGroup,
	RGETManyResourceGroupsSchema,
	RGETResourceGroupSchema,
	RPOSTCreateResourceGroupSchema,
	IADELETEResourceToGroup,
	IAPUTSetResourceToGroup,
} from "./validators";
import { Requests } from "..";

// tslint:disable:no-duplicate-string
const resourceGroups = {
	add: (
		data: IAPOSTCreateResourceGroup
	): Promise<IRPOSTCreateResourceGroup> =>
		Requests.send(
			"POST",
			"/api/projects/:projectId/resource-groups/",
			data,
			null,
			{
				responseSchema: RPOSTCreateResourceGroupSchema,
			}
		),
	update: (data: IAPUTResourceGroup): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/resource-groups/:_id",
			data
		),
	addUserIds: (data: IAPUTAddResourceToGroup): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/resource-groups/resources",
			data
		),
	removeUserIds: (data: IADELETEResourceToGroup): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/resource-groups/remove-resources",
			data
		),
	setUserIds: (data: IAPUTSetResourceToGroup): Promise<void> =>
		Requests.send(
			"PUT",
			"/api/projects/:projectId/resource-groups/set-resources",
			data
		),
	getById: (data: IAGETResourceGroup): Promise<IRGETResourceGroup> =>
		Requests.send(
			"GET",
			"/api/projects/:projectId/resource-groups/:resourceGroupId",
			data,
			null,
			{
				responseSchema: RGETResourceGroupSchema,
			}
		),
	deleteById: (data: IADELETEResourceGroup): Promise<void> =>
		Requests.send(
			"DELETE",
			"/api/projects/:projectId/resource-groups/:resourceGroupId",
			data
		),
	getReadableResourceGroups: (
		data: IAGETManyResourceGroups
	): Promise<IRGETManyResourceGroups> =>
		Requests.send(
			"GET",
			"/api/projects/:projectId/resource-groups",
			data,
			null,
			{
				responseSchema: RGETManyResourceGroupsSchema,
			}
		),
};
export default resourceGroups;
